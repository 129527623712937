import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToEditBankDetails } from '../../../../store/customer-support/orders/ordersSlice';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';
import Input from '../../../../shared/input';


interface ISellerBankInfoModal {
    openModal: boolean;
    bankDetails?: {
        iban?: string;
        bankName?: string;
        sortCode?: string;
        accountName?: string;
        accountNumber?: string;
        country?: string;
    };
    orderId: string;
    handleCloseModal: () => void;
    onSuccessfullySaved: () => void;
}

type FormValues = {
    bankName?: string;
    iban?: string;
    accountNumber?: string;
    accountName?: string;
    sortCode?: string;
    country?: string;
};

const SellerBankInfoModal = ({
    openModal,
    bankDetails,
    orderId,
    handleCloseModal,
    onSuccessfullySaved
}: ISellerBankInfoModal) => {
    const dispatch = useAppDispatch();
    const [saveError, setSaveError] = useState<string>();
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue
    } = useForm<FormValues>({});

    useEffect(() => {
        if (bankDetails) {
            setValue('iban', bankDetails?.iban || undefined)
            setValue('sortCode', bankDetails?.sortCode || undefined)
            setValue('accountName', bankDetails?.accountName || undefined)
            setValue('accountNumber', bankDetails?.accountNumber || undefined)
            setValue('bankName', bankDetails?.bankName || undefined)
            setValue('country', bankDetails?.country || undefined)
        } else {
            reset();
        }
    }, [openModal, bankDetails])

    const onCloseModal = () => {
        handleCloseModal();
        setSaveError(undefined)
        setLoadingSubmit(false)
        reset();
    }

    const onSubmit = async (data: FormValues) => {
        if (loadingSubmit) {
            return;
        }
        setLoadingSubmit(true)
        try {
            const formatData = {
                bankName: data?.bankName !== '' ? data?.bankName : undefined,
                iban: data?.iban !== '' ? data?.iban : undefined,
                accountNumber: data?.accountNumber !== '' ? data?.accountNumber : undefined,
                accountName: data?.accountName !== '' ? data?.accountName : undefined,
                sortCode: data?.sortCode !== '' ? data?.sortCode : undefined,
                country: bankDetails?.country || undefined
            }
            await dispatch(tryToEditBankDetails({ orderId: orderId, data: formatData })).unwrap()
            onSuccessfullySaved()
            setSaveError(undefined)
            reset();
            onCloseModal()
        } catch (err) {
            setSaveError(`${err}`)
            setLoadingSubmit(false)
        }
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mb-4 font-semibold text-sm'>Edit Seller Bank Details</p>
                {saveError && <Error text={saveError} />}
                <form className='mt-5' onSubmit={handleSubmit(onSubmit)}>
                    <Input
                        placeholder=' '
                        label={'Bank Name'}
                        dataQa={'bank-name'}
                        register={register('bankName')}
                        error={errors.bankName?.message}
                    />
                    <Input
                        placeholder=' '
                        label={'Account Name'}
                        dataQa={'account-name'}
                        register={register('accountName')}
                        error={errors.accountName?.message}
                    />
                    <Input
                        placeholder=' '
                        label={'Account Number'}
                        dataQa={'account-number'}
                        register={register('accountNumber')}
                        error={errors.accountNumber?.message}
                    />
                    <Input
                        label='Sort Code'
                        placeholder=' '
                        dataQa={'sort-code'}
                        register={register('sortCode')}
                        error={errors.sortCode?.message}
                    />
                    <Input
                        label='IBAN'
                        placeholder=' '
                        dataQa={'iban'}
                        register={register('iban')}
                        error={errors.iban?.message}
                    />
                    <Input
                        label='Country'
                        placeholder=' '
                        dataQa={'country'}
                        disabled={true}
                        inputStyle='opacity-90 cursor-pointer'
                        register={register('country')}
                        error={errors.country?.message}
                    />
                    <div className='min-w-full flex flex-row justify-center'>
                        <Button
                            label={'Submit'}
                            dataQa={'submit'}
                            className={`${loadingSubmit ? 'btn-primary-rounded-less-disable' : 'btn-primary-rounded-less'} my-3 min-w-[130px] !shadow-none`}
                            type='submit'
                        />
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default SellerBankInfoModal;