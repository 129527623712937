
import React from 'react'
import { IMarket } from '../../../../interfaces/markets/IMarket';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IBlackListedItemFilters {
    markets?: Array<IMarket>;
    sectors?: Array<IAssetTemplate>;
    selectedMarket?: string;
    selectedSector?: string;
    onSelectFilterOption: (value: any, name?: string) => void;
}

const BlacklistedItemsFilters = ({
    markets,
    sectors,
    selectedMarket,
    selectedSector,
    onSelectFilterOption
}: IBlackListedItemFilters) => {
    return (
        <div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1 md:gap-2'>
                <SelectCheckbox
                    name={'Market'}
                    containerStyle={'!min-w-[200px]'}
                    placeholder={selectedMarket || 'Select'}
                    options={markets || []}
                    uniqueName={'market'}
                    onChangeSelectedOption={onSelectFilterOption}
                    dataQa={`${(selectedMarket || '')?.toLowerCase().replaceAll(' ', '-')}`}
                />
                <SelectCheckbox
                    name={'Category'}
                    containerStyle={'!min-w-[200px]'}
                    placeholder={selectedSector || 'Select'}
                    options={sectors || []}
                    uniqueName={'category'}
                    onChangeSelectedOption={onSelectFilterOption}
                    dataQa={`${selectedSector?.toLowerCase().replaceAll(' ', '-')}`}
                />
            </div>
        </div>
    )
}

export default BlacklistedItemsFilters