import React from 'react';
import { ISingleOrderSchema } from '../../../../interfaces/orders/IOrdersSchema';
import WhiteContainer from '../../../../shared/white-container';
import Button from '../../../../shared/button';


interface ISellerAndReturnInfo {
    orderDetails?: ISingleOrderSchema;
    accessControl?: any;
    pageAccess?: any;
    bgColor?: string;
    toggleModal: () => void;
    toggleOriginalModal: () => void;
}

const SellerAndReturnInfo = ({
    orderDetails,
    accessControl,
    pageAccess,
    bgColor,
    toggleModal,
    toggleOriginalModal
}: ISellerAndReturnInfo) => {
    return (
        <WhiteContainer containerStyle={`${bgColor || ''} !h-auto !rounded my-2`}>
            <div className='text-[14px] font-semibold'>
                <p className='!font-bold text-[16px] mb-4'>Seller Info</p>
                {orderDetails?.seller?.name &&
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <div className='flex gap-2'>
                                <img src={'/assets/order-actions/seller-icons/user.svg'} className={'w-[23px] h-[23px]'} alt='' />
                                <p>Full Name</p>
                            </div>
                            <p className='break-words'>{orderDetails?.seller?.name}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                }
                {orderDetails?.seller?.email &&
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <div className='flex gap-2'>
                                <img src={'/assets/order-actions/seller-icons/email.svg'} className={'w-[23px] h-[23px]'} alt='' />
                                <p>E-mail</p>
                            </div>
                            <p className='break-words'>{orderDetails?.seller?.email}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                }
                {orderDetails?.seller?.phone &&
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <div className='flex gap-2'>
                                <img src={'/assets/order-actions/seller-icons/phone.svg'} className={'w-[23px] h-[23px]'} alt='' />
                                <p>Mobile Phone Number</p>
                            </div>
                            <p className='break-words'>{orderDetails?.seller?.phone}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                }
                <div className='flex flex-rows items-start'>
                    <img src={'/assets/order-actions/seller-icons/address.svg'} className={'w-[23px] h-[23px] mr-2'} alt='seller-address' />
                    <div>
                        {orderDetails?.seller?.street &&
                            <div className='grid grid-cols-2 gap-4'>
                                <p>Street</p>
                                <p className='break-words ml-1'>{orderDetails?.seller?.street}</p>
                            </div>
                        }
                        {orderDetails?.seller?.addressNo &&
                            <div className='grid grid-cols-2 gap-4'>
                                <p>House/Flat Number</p>
                                <p className='break-words ml-1'>{orderDetails?.seller?.addressNo}</p>
                            </div>
                        }
                        {orderDetails?.seller?.city &&
                            <div className='grid grid-cols-2 gap-4'>
                                <p>City</p>
                                <p className='break-words ml-1'>{orderDetails?.seller?.city}</p>
                            </div>
                        }
                        {orderDetails?.seller?.postcode &&
                            <div className='grid grid-cols-2 gap-4'>
                                <p>Postal Code</p>
                                <p className='break-words ml-1'>{orderDetails?.seller?.postcode}</p>
                            </div>
                        }
                        {(orderDetails?.seller?.country) &&
                            <div className='grid grid-cols-2 gap-4'>
                                <p>Country</p>
                                <p className='break-words ml-1'>{orderDetails?.seller?.country}</p>
                            </div>
                        }
                        {(orderDetails?.seller?.state) &&
                            <div className='grid grid-cols-2 gap-4'>
                                <p>State</p>
                                <p className='break-words ml-1'>{orderDetails?.seller?.state}</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {(!accessControl || pageAccess?.actions?.['Update Seller Address'])
                && <div>
                    <Button
                        label={'Update Seller Address'}
                        dataQa={'update-seller-address'}
                        className={'btn-main w-full my-3'}
                        onClickButton={toggleModal}
                    />
                </div>
            }
            {(orderDetails?.shipment?.oldShippingAddressData && orderDetails?.shipment?.oldShippingAddressData?.length > 0) &&
                <div>
                    <Button
                        label={'View Old Address'}
                        dataQa={'view-old-address'}
                        className={'btn-main w-full mt-3'}
                        onClickButton={toggleOriginalModal}
                    />
                </div>
            }
        </WhiteContainer>
    )
}

export default SellerAndReturnInfo;