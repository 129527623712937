import React from 'react';


interface ITextarea {
    placeholder: string;
    className?: string;
    disabled?: boolean;
    value: any;
    dataQa?: string;
    onChangeTextarea: (e: any, type?: string) => void;
    textareaType?: string;
    rows?: number;
    cols?: number;
    containerStyle?: string;
    error?: string;
}

const Textarea = ({
    value,
    className,
    placeholder,
    disabled,
    dataQa,
    onChangeTextarea,
    textareaType,
    rows,
    cols,
    containerStyle,
    error
}: ITextarea) => {
    return (
        <div className={`min-w-full ${containerStyle || ''}`}>
            <textarea
                disabled={disabled || false}
                className={className || 'textarea-primary'}
                data-qa={dataQa || ''}
                rows={rows || 4}
                cols={cols || 3}
                value={value || ''}
                placeholder={placeholder}
                onChange={(e) => onChangeTextarea(e, textareaType)}

            >

            </textarea>
            {error ? <span className='block text-xs text-red-500'>{error}</span> : <span className='block h-2 mt-2' />}
        </div>
    );
};

export default Textarea;