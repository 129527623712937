import { AxiosResponse } from 'axios';
import { ORDERS_URL } from '../../../config';
import ordersAxios from '../../../utils/axios/orders.axios';
import { ApiResponse } from '../../../interfaces/models/models/paginated-response.model';
import { PaginatedReferralTransactions, PaginatedReferrals } from '../../../interfaces/referrals/paginatedStore.type';


const tryToFetchAllReferrals = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: any,
): Promise<AxiosResponse<PaginatedReferrals>> => {
    const url = filters
        ? `${ORDERS_URL}/referral-rewards/all?page=${pageNumber}&size=${pageSize}&search=${filters?.search}`
        : `${ORDERS_URL}/referral-rewards/all?page=${pageNumber}&size=${pageSize}`
    return ordersAxios.put<ApiResponse<any>>(url, data);
};

const tryToFetchReferralDetails = (
    pageNumber: number,
    pageSize: number,
    referralId: string,
    data?: any
): Promise<AxiosResponse<any>> => {
    const url = `${ORDERS_URL}/referral-rewards/owner/${referralId}/users?page=${pageNumber}&size=${pageSize}`
    return ordersAxios.put<any>(url, data);
};

const tryToFetchReferralTransactions = (
    pageNumber: number,
    pageSize: number,
    userId: string
): Promise<AxiosResponse<PaginatedReferralTransactions>> => {
    return ordersAxios.put(`${ORDERS_URL}/referral-transactions/user/${userId}?page=${pageNumber}&size=${pageSize}`);
};

const tryToFetchCompletedReferralsTransactions = (
    _id: string,
    data?: any
): Promise<AxiosResponse<any>> => {
    const url = `${ORDERS_URL}/referral-transactions/${_id}/complete`
    return ordersAxios.put<any>(url, data);
};

export const ReferralsService = {
    tryToFetchAllReferrals,
    tryToFetchReferralDetails,
    tryToFetchReferralTransactions,
    tryToFetchCompletedReferralsTransactions
}