import React from 'react';

interface IDropDown {
    open?: boolean;
    items: Array<string | number>;
    onClickItem?: (item: any) => void;
}

const DropDown = ({ open, items, onClickItem }: IDropDown) => {
    return (
        <div className={`${open ? '' : 'hidden'} dropdown-menu absolute bottom-0 bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 w-[100px] max-h-150 overflow-y-auto m-0 bg-clip-padding border-none`}>
            {items?.length > 0 && items?.map((item: string | number, idx: number) => {
                return (
                    <div data-qa={`${item}`} className='py-1' key={idx} onClick={() => onClickItem?.(item)}>
                        <p className='block py-2 px-4 text-sm text-black hover:bg-[#f5f5f5]'>{item}</p>
                    </div>
                )
            })}
        </div>
    );
};

export default DropDown;
