import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ApiResponse } from '../../../interfaces/models/models/paginated-response.model';
import { ITicketListState } from '../../../interfaces/customer-care/ITicketList';
import { CustomerCareService } from './customerCareApi';
import { PaginatedTicket } from '../../../interfaces/customer-care/paginatedStore.type';
import {
    ITicket,
    ITicketThread,
} from '../../../interfaces/customer-care/ITicket';
import { ITicketSchema } from '../../../interfaces/customer-care/ITicketSchema';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';

const initialState: ITicketListState = {
    ticketsAreLoading: false,
    ticketIsLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchFilteredTickets = createAsyncThunk<PaginatedTicket, any>(
    'cs/tryToFetchFilteredTickets',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await CustomerCareService.tryToFetchFilteredTickets(
            pageNumber,
            pageSize,
            filters,
            data,
        );
        return result?.data;
    },
);

export const tryToFetchFilteredOrderTickets = createAsyncThunk<PaginatedTicket, any>(
    'cs/tryToFetchFilteredTickets',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await CustomerCareService.tryToFetchUserTickets(pageNumber, pageSize, filters, data);
        return result?.data;
    },
);

export const tryToFetchUserTickets = createAsyncThunk<PaginatedTicket, any>(
    'cs/tryToFetchUserTickets',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await CustomerCareService.tryToFetchUserTickets(
            pageNumber,
            pageSize,
            filters,
            data,
        );
        return result?.data;
    },
);

export const tryToFetchCsTickets = createAsyncThunk<
    PaginatedTicket,
    IPaginationPayload
>('tickets/tryToFetchTickets', async ({ pageNumber, pageSize, filters }) => {
    const result = await CustomerCareService.tryToFetchCsTickets(
        pageNumber,
        pageSize,
        filters,
    );
    return result?.data;
});

export const tryToFetchSingleTicket = createAsyncThunk<ITicket, string>(
    'tickets/tryToFetchSingleTicket',
    async (id: string) => {
        const result = await CustomerCareService.tryToFetchSingleTicket(id);
        return result?.data?.data;
    },
);

export const tryToAddTicket = createAsyncThunk<ApiResponse<ITicket>, any>(
    'cs/tryToAddTicket',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await CustomerCareService.tryToAddTicket(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToRaiseATicket = createAsyncThunk<any, any>(
    'cs/tryToRaiseATicket',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await CustomerCareService.tryToRaiseATicket(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDeleteTicket = createAsyncThunk<any, any>(
    'cs/tryToDeleteTicket',
    async (id: string, { rejectWithValue }) => {
        try {
            const result = await CustomerCareService.tryToDeleteTicket(id);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToDeleteTicketThread = createAsyncThunk<any, any>(
    'cs/tryToDeleteTicketThread',
    async ({ id, threadId }, { rejectWithValue }) => {
        try {
            const result = await CustomerCareService.tryToDeleteTicketThread(
                id,
                threadId,
            );
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFetchMarkets = createAsyncThunk<IPaginationPayload>(
    'markets/tryToFetchMarkets',
    async () => {
        const result = await CustomerCareService.tryToFetchMarkets();
        return result?.data;
    },
);

export const tryToFetchWarehouses = createAsyncThunk<any, any>(
    'warehouses/tryToFetchWarehouses',
    async (data, { rejectWithValue }) => {
        try {
            const result = await CustomerCareService.tryToFetchWarehouses(data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToCloseTicket = createAsyncThunk<ApiResponse<ITicketSchema>, { id: string }>(
    'tickets/tryToCloseTicket',
    async (data, { rejectWithValue }) => {
        try {
            const results = await CustomerCareService.tryToCloseTicket(data?.id);
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    });

export const tryToChangeAssignedUserTicket = createAsyncThunk<ApiResponse<any>, any>(
    'tickets/tryToChangeAssignedUserTicket',
    async (data, { rejectWithValue }) => {
        try {
            const results = await CustomerCareService.tryToChangeAssignedUserTicket(data);
            return results.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    });

export const tryToCreateThread = createAsyncThunk<any, { ticketId: string; data: ITicketThread }>(
    'cs/tryToAddTicketThread',
    async ({ ticketId, data }, { rejectWithValue }) => {
        try {
            const result = await CustomerCareService.tryToCreateThread(
                ticketId,
                data,
            );
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToAddTicketThread = createAsyncThunk<ApiResponse<ITicketSchema>, { ticketId: string; data: ITicketThread }>(
    'cs/tryToAddTicketThread',
    async ({ ticketId, data }, { rejectWithValue }) => {
        try {
            const result = await CustomerCareService.tryToAddTicketThread(
                ticketId,
                data,
            );
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToGenerateLabelPacks = createAsyncThunk<ApiResponse<ITicketSchema>, any>(
    'cs/tryToGenerateLabelPacks',
    async (data, { rejectWithValue }) => {
        try {
            const result = await CustomerCareService.tryToGenerateLabelPacks(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const csSlice = createSlice({
    name: 'cs',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchFilteredTickets
            .addCase(tryToFetchFilteredTickets.pending, (state) => {
                state.ticketsAreLoading = true;
            })
            .addCase(tryToFetchFilteredTickets.rejected, (state) => {
                state.ticketsAreLoading = false;
            })
            .addCase(tryToFetchFilteredTickets.fulfilled, (state, action) => {
                state.ticketsAreLoading = false;
                state.tickets = action.payload;
            })

            // tryToFetchUserTickets
            .addCase(tryToFetchUserTickets.pending, (state) => {
                state.ticketsAreLoading = true;
            })
            .addCase(tryToFetchUserTickets.rejected, (state) => {
                state.ticketsAreLoading = false;
            })
            .addCase(tryToFetchUserTickets.fulfilled, (state, action) => {
                state.ticketsAreLoading = false;
                state.orderTickets = action.payload;
            })

            // tryToFetchCsTickets
            .addCase(tryToFetchCsTickets.pending, (state) => {
                state.ticketsAreLoading = true;
            })
            .addCase(tryToFetchCsTickets.rejected, (state) => {
                state.ticketsAreLoading = false;
            })
            .addCase(tryToFetchCsTickets.fulfilled, (state, action) => {
                state.ticketsAreLoading = false;
                state.tickets = action.payload;
            })

            // tryToAddTicket
            .addCase(tryToAddTicket.pending, (state) => {
                state.ticketIsLoading = true;
            })
            .addCase(tryToAddTicket.rejected, (state) => {
                state.ticketIsLoading = false;
            })
            .addCase(tryToAddTicket.fulfilled, (state, action) => {
                state.ticketIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data;
                if (state.tickets?.data)
                    state.tickets.data.elements = [
                        ...state.tickets?.data.elements,
                        data,
                    ];
            })

            // tryToRaiseATicket
            .addCase(tryToRaiseATicket.pending, (state) => {
                state.ticketIsLoading = true;
            })
            .addCase(tryToRaiseATicket.rejected, (state) => {
                state.ticketIsLoading = false;
            })
            .addCase(tryToRaiseATicket.fulfilled, (state, action) => {
                state.ticketIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                if (state.tickets?.data)
                    state.tickets.data.elements = [
                        ...state.tickets?.data.elements,
                        data,
                    ];
            })

            // tryToFetchSingleTicket
            .addCase(tryToFetchSingleTicket.pending, (state) => {
                state.ticketsAreLoading = true;
            })
            .addCase(tryToFetchSingleTicket.rejected, (state) => {
                state.ticketIsLoading = false;
            })
            .addCase(tryToFetchSingleTicket.fulfilled, (state, action) => {
                state.ticketIsLoading = false;
                state.ticketDetails = action.payload;
            })

            // tryToFetchMarkets
            .addCase(tryToFetchMarkets.pending, (state) => {
                state.marketsAreLoading = true;
            })
            .addCase(tryToFetchMarkets.rejected, (state) => {
                state.marketsAreLoading = false;
            })
            .addCase(tryToFetchMarkets.fulfilled, (state, action) => {
                state.ticketIsLoading = false;
                state.markets = action.payload;
            })

            // tryToDeleteTicket
            .addCase(tryToDeleteTicket.pending, (state) => {
                state.ticketIsLoading = true;
            })
            .addCase(tryToDeleteTicket.rejected, (state) => {
                state.ticketIsLoading = false;
            })
            .addCase(tryToDeleteTicket.fulfilled, (state, action) => {
                state.ticketIsLoading = false;
                state.requestStatus = 'success';
                if (state.tickets?.data.elements)
                    state.tickets.data.elements =
                        { ...state }.tickets?.data.elements.filter(
                            (ticket) => ticket?.ticket?._id !== action.meta.arg,
                        ) || [];
            })

            // tryToCloseTicket
            .addCase(tryToCloseTicket.pending, (state) => {
                state.ticketIsLoading = true;
            })
            .addCase(tryToCloseTicket.rejected, (state) => {
                state.ticketIsLoading = false;
            })
            .addCase(tryToCloseTicket.fulfilled, (state, action) => {
                state.ticketIsLoading = false;
                state.requestStatus = 'success';
                if (state.tickets?.data.elements)
                    state.tickets.data.elements =
                        { ...state }.tickets?.data.elements.filter(
                            (ticket) => ticket?.ticket?._id !== action.meta.arg?.id,
                        ) || [];
            })

            // tryToChangeAssignedUserTicket
            .addCase(tryToChangeAssignedUserTicket.pending, (state) => {
                state.ticketIsLoading = true;
            })
            .addCase(tryToChangeAssignedUserTicket.rejected, (state) => {
                state.ticketIsLoading = false;
            })
            .addCase(tryToChangeAssignedUserTicket.fulfilled, (state, action) => {
                state.ticketIsLoading = false;
                state.requestStatus = 'success';
                if (state.tickets?.data?.elements)
                    state.tickets.data.elements =
                        { ...state }.tickets?.data.elements.map((ticket) => {
                            if (ticket?.ticket?._id === action.meta.arg?.ticketId) {
                                return {
                                    ...ticket,
                                    ticket: {
                                        ...ticket?.ticket || {},
                                        assignedCs: action?.payload?.data?.assignedCs
                                    }
                                }
                            } else {
                                return ticket
                            }
                        }) || [];
            })

            // tryToCreateThread
            .addCase(tryToCreateThread.pending, (state) => {
                state.ticketIsLoading = true;
            })
            .addCase(tryToCreateThread.rejected, (state) => {
                state.ticketIsLoading = false;
            })
            .addCase(tryToCreateThread.fulfilled, (state, action) => {
                state.ticketIsLoading = false;
                state.requestStatus = 'success';
                if (state.orderTickets?.data)
                    state.orderTickets.data.elements =
                        { ...state }.orderTickets?.data.elements.map((ticket) => {
                            if (
                                ticket?.ticket?._id ===
                                action.meta?.arg?.ticketId
                            ) {
                                return {
                                    ...ticket,
                                    ticket: {
                                        ...(ticket?.ticket || {}),
                                        threads: action?.payload?.data?.threads,
                                    },
                                };
                            } else {
                                return ticket;
                            }
                        }) || [];
            })

            // tryToDeleteTicketThread
            .addCase(tryToDeleteTicketThread.pending, (state) => {
                state.ticketIsLoading = true;
            })
            .addCase(tryToDeleteTicketThread.rejected, (state) => {
                state.ticketIsLoading = false;
            })
            .addCase(tryToDeleteTicketThread.fulfilled, (state, action) => {
                state.ticketIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data?.data;
                if (state.orderTickets?.data)
                    state.orderTickets.data.elements =
                        { ...state }.orderTickets?.data.elements.map((ticket) => {
                            if (
                                ticket?.ticket?._id ===
                                action.meta?.arg?.id
                            ) {
                                return {
                                    ...ticket,
                                    ticket: {
                                        ...(ticket?.ticket || {}),
                                        threads: data?.threads,
                                    },
                                };
                            } else {
                                return ticket;
                            }
                        }) || [];
            });
    },
});

export default csSlice.reducer;
