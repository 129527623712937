import React from 'react';
import { IPageAccess } from '../../../../interfaces/components/IPageAccess';
import { ISingleOrderSchema } from '../../../../interfaces/orders/IOrdersSchema';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';
import WhiteContainer from '../../../../shared/white-container';
import Button from '../../../../shared/button';


interface ISellerBankDetails {
    orderDetails?: ISingleOrderSchema;
    accessControl?: Array<IAccessControl>;
    pageAccess?: IPageAccess;
    toggleModal: () => void;
}

const SellerBankDetails = ({
    orderDetails,
    accessControl,
    pageAccess,
    toggleModal,
}: ISellerBankDetails) => {
    return (
        <WhiteContainer containerStyle='!h-auto !rounded my-2'>
            <div className='text-[14px] font-semibold'>
                <p className='!font-bold text-[16px] mb-4'>Seller Bank Details</p>
                {orderDetails?.seller?.bankDetails?.bankName &&
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <div className='flex gap-4'>
                                <p className=''>Bank Name</p>
                            </div>
                            <p className='!break-all'>{orderDetails?.seller?.bankDetails?.bankName}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                }
                {
                    orderDetails?.seller?.bankDetails?.accountName &&
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <div className='flex gap-4'>
                                <p className=''>Account Name</p>
                            </div>
                            <p className='!break-all'>{orderDetails?.seller?.bankDetails?.accountName}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                }
                {
                    orderDetails?.seller?.bankDetails?.accountNumber &&
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <div className='flex gap-4'>
                                <p className=''>Account Number</p>
                            </div>
                            <p className='!break-all'>{orderDetails?.seller?.bankDetails?.accountNumber}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                }
                {
                    orderDetails?.seller?.bankDetails?.sortCode &&
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <div className='flex gap-4'>
                                <p className=''>Sort Code</p>
                            </div>
                            <p className='!break-all'>{orderDetails?.seller?.bankDetails?.sortCode}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                }
                {
                    orderDetails?.seller?.bankDetails?.iban &&
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <div className='flex gap-4'>
                                <p className=''>IBAN</p>
                            </div>
                            <p className='!break-all'>{orderDetails?.seller?.bankDetails?.iban}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                }
                {
                    orderDetails?.seller?.bankDetails?.country &&
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <div className='flex gap-4'>
                                <p className=''>Country</p>
                            </div>
                            <p className='!break-all'>{orderDetails?.seller?.bankDetails?.country}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                }
            </div >
            {(!accessControl || pageAccess?.actions?.['Edit Bank Details'])
                && <div>
                    <Button
                        label={'Edit Bank Details'}
                        dataQa={'edit-bank-details'}
                        className={'btn-main w-full'}
                        onClickButton={toggleModal}
                    />
                </div>
            }
        </WhiteContainer >
    )
}

export default SellerBankDetails;