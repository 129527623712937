import React from 'react';
import Modal from '../../../../shared/modal';


interface IOldAddressModal {
    openModal: boolean;
    itemId?: string | null;
    shipment?: any;
    handleCloseModal: () => void;
}

const OldAddressModal = ({
    openModal,
    shipment,
    handleCloseModal,
}: IOldAddressModal) => {

    const onCloseModal = () => {
        handleCloseModal()
    }

    const findOldShippingAddressData = shipment?.oldShippingAddressData && shipment?.oldShippingAddressData[shipment?.oldShippingAddressData.length - 1]
    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mt-2 mb-4'>Old Seller Address</p>
                <div className='grid gap-2'>
                    {(findOldShippingAddressData?.state === null) ?
                        null : <>
                            <div className=' grid gap-2 mb-3'>
                                <p className='block text-primary-light font-normal text-base mb-1'>State</p>
                                <p className='flex border  rounded pl-4 pr-4 pt-2 pb-2'>{findOldShippingAddressData?.state}</p>
                            </div>
                        </>
                    }
                    <div className=' grid gap-2 mb-3'>
                        <p className='block text-primary-light font-normal text-base mb-1'>Street</p>
                        <p className='flex border  rounded pl-4 pr-4 pt-2 pb-2'>{findOldShippingAddressData?.street}</p>
                    </div>
                    <div className=' grid gap-2 mb-3'>
                        <p className='block text-primary-light font-normal text-base mb-1'>Address</p>
                        <p className='flex border  rounded pl-4 pr-4 pt-2 pb-2'>{findOldShippingAddressData?.addressNo}</p>
                    </div>
                    <div className='grid gap-2 mb-3'>
                        <p className='block text-primary-light font-normal text-base mb-1'>City</p>
                        <p className='flex border  rounded pl-4 pr-4 pt-2 pb-2'>{findOldShippingAddressData?.city}</p>
                    </div>
                    <div className='grid gap-2 mb-3'>
                        <p className='block text-primary-light font-normal text-base mb-1'>Postcode</p>
                        <p className='flex border  rounded pl-4 pr-4 pt-2 pb-2'>{findOldShippingAddressData?.postcode}</p>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default OldAddressModal;