import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { TICKETS_STATUS } from '../../../utils/constants/tickets-status';
import { IToast } from '../../../interfaces/components/IToast';
import { ITicket } from '../../../interfaces/customer-care/ITicket';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import {
    tryToCloseTicket,
    tryToDeleteTicket,
    tryToFetchFilteredTickets,
} from '../../../store/customer-support/customer-care/customerCareSlice';
import { tryToFetchAllCsUsers } from '../../../store/customer-support/partners/partnersSlice';
import CustomerCareTable from './tables/CustomerCareTable';
import RaiseTicketModal from './modals/RaiseTicketModal';
import CsAssignedModal from './modals/CsAssignedModal';
import TicketFilters from './details/TicketFilters';
import SearchWithButton from '../../../shared/search-with-button';
import CloseTicketModal from '../../../shared/closeTicketModal';
import EmptyContainer from '../../../shared/empty-container';
import DeleteModal from '../../../shared/delete-modal';
import Button from '../../../shared/button';
import Toast from '../../../shared/toast';


const customerCareCategories = [
    { name: 'Open Tickets', status: true },
    { name: 'Closed Tickets', status: false },
];

const CustomerCare = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<ITicket[]>([]);
    const [activeCategory, setActiveCategory] = useState<{
        name: string;
        status: boolean;
    }>(customerCareCategories?.[0]);
    const [search, setSearch] = useState<string>();
    const [filtersValues, setFiltersValues] = useState<any>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedStatus, setSelectedStatus] = useState<any>(TICKETS_STATUS?.[0])
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [ticketItem, setTicketItem] = useState<string>();
    const [raiseTicketModal, setRaiseTicketModal] = useState<boolean>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
        filters: '',
    });
    const [pageAccess, setPageAccess] = useState<any>()
    const [csUsers, setCsUsers] = useState<any>()
    const [selectedUser, setSelectedUser] = useState<any>()
    const [loadingCloseTicket, setLoadingCloseTicket] = useState<boolean>(false)
    const [showAssignedModal, setShowAssignedModal] = useState<{ show: boolean, ticket: string, current: any }>()
    const paginationData = useAppSelector((state) => state.cs.tickets?.data?.page);
    const rowsLoading = useAppSelector((state) => state.cs.ticketsAreLoading);
    const state = useAppSelector((state) => state.cs);
    const accessControl = useAppSelector((state) => state?.partners?.navigation);

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Tickets')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onGettingCSUsersData = async () => {
        const response = await dispatch(tryToFetchAllCsUsers(null)).unwrap()
        const formatUsers = response && response?.length > 0 && response?.map((item: any) => ({ label: item?.name, value: item?.username }))
        setCsUsers(formatUsers || [])
    }

    useEffect(() => {
        onGettingCSUsersData()
    }, [])

    useEffect(() => {
        if (state.tickets) {
            const rows =
                state.tickets?.data?.elements &&
                    Array.isArray(state.tickets?.data.elements)
                    ? state.tickets?.data.elements.map((r) => ({
                        ...r,
                        id: r._id,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state.tickets]);

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined);
        onResetPaginationState();
    };

    const onChangeActiveCategory = (data: { name: string; status: boolean; }) => {
        setActiveCategory(data);
        setFiltersValues(undefined);
        setSearch(undefined);
        onResetPaginationState();
    };

    const onClickClearAllFilters = () => {
        setFiltersValues(undefined);
        setSearch(undefined);
        setSelectedUser(undefined)
        onResetPaginationState();
    };

    const onChangeFilterValue = (value: string | null | undefined, type: string) => {
        setFiltersValues({
            ...filtersValues,
            [type]: value || undefined,
        });
        onResetPaginationState()
    };

    const onChangeSelectedCountry = (e: any, type?: string) => {
        setFiltersValues({
            ...filtersValues,
            country: e || undefined,
        });
        onResetPaginationState()
    };

    const onChangeSelectedBroker = (e: any, type?: string) => {
        setFiltersValues({
            ...filtersValues,
            name: e || undefined,
        });
        onResetPaginationState()
    };

    const onChangeSelectedWarehouse = (e: any, type?: string) => {
        setFiltersValues({
            ...filtersValues,
            warehouseId: e || undefined,
        });
        onResetPaginationState()
    };

    useEffect(() => {
        if (activeCategory) {
            const filters = filtersValues && Object.keys(filtersValues);
            let formatFilters;
            const filtersValuesFormat =
                filters &&
                filters?.length > 0 &&
                filters
                    ?.filter(
                        (key: any) =>
                            key !== 'maxDateCreated' &&
                            key !== 'minDateCreated',
                    )
                    .map((item: any) => {
                        if (item === 'name') {
                            return {
                                key: item,
                                value: Object.keys(filtersValues)
                                    ? filtersValues?.[item].value || undefined
                                    : undefined,
                            };
                        } else {
                            return {
                                key: item,
                                value: Object.keys(filtersValues)
                                    ? filtersValues?.[item].name || undefined
                                    : undefined,
                            };
                        }
                    });

            if (filtersValuesFormat && filtersValuesFormat?.length > 0) {
                formatFilters = {
                    value: filtersValuesFormat?.filter(
                        (key: any) => key?.value,
                    ),
                };
            }
            if (
                filtersValues?.maxDateCreated ||
                filtersValues?.minDateCreated
            ) {
                formatFilters = {
                    ...(formatFilters || {}),
                    range: [
                        {
                            key: 'createdAt',
                            to: new Date(filtersValues?.maxDateCreated),
                            from: new Date(filtersValues?.minDateCreated),
                        },
                    ],
                };
            }
            let data: any = {
                opened: activeCategory?.status,
            }
            if (selectedStatus && selectedStatus?.value !== 'all') data.readTicketsByCs = selectedStatus?.value === 'read'
            if (formatFilters?.range || formatFilters?.value) {
                data = {
                    ...data,
                    ...formatFilters,
                };
            }
            if (selectedUser?.value) {
                data = {
                    ...data,
                    assignedCs: selectedUser?.value
                };
            }
            const fetchData = {
                ...(paginationState || {}),
                filters: search,
                data,
            };
            dispatch(tryToFetchFilteredTickets(fetchData));
        }
    }, [activeCategory, paginationState]);

    const onSeeCsDetails = async (id: string, itemId: string, country: string) => {
        navigate(`/tickets/${id}/${itemId}/country/${country}`);
    };

    const onCloseTicket = async () => {
        setLoadingCloseTicket(true)
        try {
            const data = {
                id: ticketItem!,
            };
            await dispatch(tryToCloseTicket(data)).unwrap()
            setShowToast({
                type: 'success',
                message: 'Ticket successfully closed',
            });
        } catch (error) {
            setShowToast({ type: 'error', message: `${error}` });
        }
        setTicketItem(undefined);
        setShowModal(false);
        setLoadingCloseTicket(false)
    };

    const onCloseModal = () => {
        setShowModal(false);
    };

    const onCloseTicketRequest = (id: string) => {
        setShowModal(true);
        setTicketItem(id);
    };

    const onShowHideRaiseTicketModal = () => {
        setRaiseTicketModal(!raiseTicketModal)
    }

    const onSuccessfullyRaised = (type: string, message: string) => {
        setShowToast({ type, message })
        setRaiseTicketModal(false)
    }

    const onDeleteTicket = (id: string) => {
        setShowDeleteModal(true)
        setTicketItem(id)
    }

    const onConfirmDelete = async () => {
        try {
            await dispatch(tryToDeleteTicket(ticketItem)).unwrap()
            setShowToast({
                type: 'success',
                message: 'Ticket successfully deleted',
            });
        } catch (err) {
            setShowToast({ type: 'error', message: `${err || 'Something went wrong!'}` });
        }
        setTicketItem(undefined)
        setShowDeleteModal(false)
    }

    const onChangeManageAssignedUser = (id: string, current: any) => {
        setShowAssignedModal({ show: true, ticket: id, current })
    }

    const onSuccessfullyAssignedUser = (data: any) => {
        setShowToast({ type: 'success', message: 'Ticket assigned user successfully changed!' })
        setShowAssignedModal(undefined)
    }

    const onChangeSelectedAssignedUser = (value: any, type?: string) => {
        setSelectedUser(value)
        onResetPaginationState()
    }

    const onChangeStatusSelected = (value: any, type?: string) => {
        setSelectedStatus(value)
        onResetPaginationState()
    }

    return (
        <div className='h-full w-full flex flex-col gap-6'>
            <div className='flex h-10'>
                <div className='flex justify-start'>
                    <p className='page-title'>Customer Care</p>
                </div>
            </div>
            <div className='flex gap-4'>
                {customerCareCategories?.filter((item: any) => (!accessControl || pageAccess?.actions?.[item?.name]))?.map((category, index) => {
                    return (
                        <Button
                            dataQa={`${index}`}
                            key={index}
                            label={category?.name}
                            className={`btn-categories whitespace-nowrap mr-3 mb-3 cursor-pointer text-center text-[#484A4B]' ${activeCategory.name === category?.name && 'text-[#202020] bg-gradient-to-r from-[#FCEE21] to-[#F7931E] border-none'}`}
                            onClickButton={() => onChangeActiveCategory(category)}
                        />
                    );
                })}
            </div>
            <div>
                <TicketFilters
                    filters={filtersValues}
                    users={csUsers}
                    selectedUser={selectedUser}
                    selectedStatus={selectedStatus}
                    onChangeStatusSelected={onChangeStatusSelected}
                    onChangeSelectedAssignedUser={onChangeSelectedAssignedUser}
                    onChangeFilterValue={onChangeFilterValue}
                    onChangeSelectedCountry={onChangeSelectedCountry}
                    onChangeSelectedBroker={onChangeSelectedBroker}
                    onChangeSelectedWarehouse={onChangeSelectedWarehouse}
                />
            </div>
            <div className='flex h-10'>
                <div className='w-full'>
                    <SearchWithButton
                        placeholder='Search'
                        buttonLabel='Raise A Ticket'
                        buttonStyle={`${(!accessControl || pageAccess?.actions?.['Raise A Ticket']) ? 'btn-main' : 'btn-main-disable'} col-span-5 lg:col-span-2`}
                        showScndBtn={true}
                        scndButtonStyle={'btn-primary-text-underline min-w-auto col-span-5 lg:col-span-2 flex flex-row justify-center lg:justify-end'}
                        scndButtonLabel={'Clear all filters'}
                        onChangeSearchValue={onChangeSearchValue}
                        search={search}
                        inputStyle={'bg-transparent'}
                        onClickButton={() => (!accessControl || pageAccess?.actions?.['Raise A Ticket']) && onShowHideRaiseTicketModal()}
                        onClickSecondButton={onClickClearAllFilters}
                        mainContainerStyle={'!bg-white !grid-cols-10'}
                    />
                </div>
            </div>
            <div className='mb-5 relativ'>
                {(rows?.length > 0 || rowsLoading) ? (
                    <CustomerCareTable
                        rows={rows.filter(row => row.ticket?.opened === activeCategory.status)}
                        rowsLoading={rowsLoading}
                        paginationData={paginationData}
                        activeCategory={activeCategory}
                        actions={pageAccess?.actions}
                        accessControl={accessControl}
                        setPaginationState={setPaginationState}
                        onSeeCsDetails={onSeeCsDetails}
                        onCloseTicketRequest={onCloseTicketRequest}
                        onDeleteTicket={onDeleteTicket}
                        onChangeManageAssignedUser={onChangeManageAssignedUser}
                    />
                ) : (
                    <EmptyContainer
                        text={'No ticket added yet.'}
                        showImage={true}
                    />
                )}
                <CloseTicketModal
                    openCloseTicketModal={showModal}
                    loadingClose={loadingCloseTicket}
                    handleCloseTicketModal={onCloseModal}
                    onApproveClosing={onCloseTicket}
                />
                {raiseTicketModal &&
                    <RaiseTicketModal
                        openModal={raiseTicketModal}
                        handleCloseModal={onShowHideRaiseTicketModal}
                        onSuccessfullyRaised={onSuccessfullyRaised}
                    />
                }
                {showToast?.message && (
                    <Toast
                        type={showToast?.type}
                        text={showToast?.message}
                        onHandleCloseToast={() => setShowToast(null)}
                    />
                )}
                {showAssignedModal?.show &&
                    <CsAssignedModal
                        openModal={showAssignedModal?.show}
                        users={csUsers}
                        assignedUser={showAssignedModal?.current}
                        id={showAssignedModal?.ticket || ''}
                        onSuccessfullySaved={onSuccessfullyAssignedUser}
                        handleCloseModal={() => setShowAssignedModal(undefined)}
                    />
                }
                <DeleteModal
                    openDeleteModal={showDeleteModal}
                    handleCloseDeleteModal={() => {
                        setShowDeleteModal(false)
                        setTicketItem(undefined)
                    }}
                    onApproveDeletion={onConfirmDelete}
                />
            </div>
        </div>
    );
};

export default CustomerCare;
