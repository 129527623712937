import React from 'react';
import WhiteContainer from '../../../../shared/white-container';
import { onFindSourceName } from '../../../../utils/constants/order-source-format';
import { onFormatOrderStatus } from '../../../../utils/constants/format-order-statuses';


interface IOrderInfo {
    trackingNumber?: string;
    specialTrackingNumber?: string;
    returnTrackingNumber?: string;
    source?: string;
    blackListStatus?: string;
    orderNumber?: string;
    returnShippingLabel?: string;
    orderStatus?: string;
    itemCode?: string;
    itemId?: string;
    invoiceUrl?: string;
}

const OrderInfo = ({
    trackingNumber,
    specialTrackingNumber,
    returnTrackingNumber,
    source,
    blackListStatus,
    orderNumber,
    returnShippingLabel,
    orderStatus,
    itemCode,
    itemId,
    invoiceUrl,
}: IOrderInfo) => {
    const findSource = onFindSourceName(source || '')
    const formatOrderStatus = onFormatOrderStatus(orderStatus || '')
    const orderStatusItem = formatOrderStatus?.color && <div className={`${formatOrderStatus?.color} flex flex-row justify-center text-center px-1 min-w-[150px] ${formatOrderStatus?.text || 'text-black'} text-sm py-1 font-medium rounded-2xl`}>{formatOrderStatus?.name}</div>
    return (
        <WhiteContainer containerStyle='!h-auto mb-2'>
            <p className='font-bold text-[20px] mb-4'>Order Details</p>
            <div>
                <div className='grid grid-cols-2 gap-3 my-2'>
                    <p className='font-bold text-md'>Property</p>
                    <p className='font-bold text-md'>Value</p>
                </div>
                {itemCode && (
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>IMEI</p>
                            {itemCode}
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                )}
                {blackListStatus &&
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p>IMEI Status</p>
                            <p className='!break-all'>{blackListStatus === 'Yes' ? 'Blacklisted' : 'Clean'}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                }
                {trackingNumber && (
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>Tracking Number</p>
                            <a
                                rel='noreferrer'
                                data-qa={'tracking-number'}
                                href={`https://parcelsapp.com/en/tracking/${trackingNumber}`}
                                target='_blank'
                                className='text-primary-default !break-all'
                            >
                                {trackingNumber}
                            </a>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                )}
                {specialTrackingNumber && (
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>Special Tracking Number</p>
                            <a
                                rel='noreferrer'
                                data-qa={'special-tracking-number'}
                                href={`https://parcelsapp.com/en/tracking/${specialTrackingNumber}`}
                                target='_blank'
                                className='text-primary-default !break-all'
                            >
                                {specialTrackingNumber}
                            </a>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                )}
                {itemId &&
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p>Item ID</p>
                            <p className='!break-all'>{itemId}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                }
                {returnTrackingNumber && (
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>Return Tracking Number</p>
                            <a
                                rel='noreferrer'
                                data-qa={'return-tracking-number'}
                                href={`https://parcelsapp.com/en/tracking/${returnTrackingNumber}`}
                                target='_blank'
                                className='text-primary-default !break-all'
                            >
                                {returnTrackingNumber}
                            </a>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                )}
                {returnShippingLabel && (
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>Return Shipping Label</p>
                            <a
                                rel='noreferrer'
                                data-qa={'return-shipping-label'}
                                href={`${returnShippingLabel}`}
                                target='_blank'
                                className='text-primary-default'
                            >
                                Get Label
                            </a>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                )}
                {invoiceUrl && (
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>Invoice Details</p>
                            <a
                                rel='noreferrer'
                                data-qa={'details'}
                                href={`${invoiceUrl}`}
                                target='_blank'
                                className='text-primary-default'
                            >
                                See Details
                            </a>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                )}
                {orderNumber && (
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>Order Number</p>
                            <p>{orderNumber}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                )}
                {source && (
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>Order Source</p>
                            <p>{findSource || source}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                )}
                {orderStatus &&
                    (
                        <>
                            <div className='grid grid-cols-2 gap-3 my-2'>
                                <p className=''>Order status</p>
                                <p>{orderStatusItem}</p>
                            </div>
                            <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                        </>
                    )}
            </div>
        </WhiteContainer>
    );
};

export default OrderInfo;