import React, { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToMarkAsCompleted } from '../../../../store/customer-support/orders/ordersSlice';
import AddNoteForm from '../details/AddNoteForm';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';


interface IMarkAsContactedModal {
    openModal: boolean;
    id: string;
    itemId: string;
    handleCloseModal: () => void;
    onSuccessfullySaved: () => void;
    onSuccessfullyAddedNote: (body: any) => void;
    activeTab: any;
}

const MarkAsContactedModal = ({
    openModal,
    id,
    itemId,
    handleCloseModal,
    onSuccessfullySaved,
    onSuccessfullyAddedNote,
    activeTab
}: IMarkAsContactedModal) => {
    const dispatch = useAppDispatch()
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const [submitError, setSubmitError] = useState<string>()

    const onCloseModal = () => {
        handleCloseModal()
        setSubmitError(undefined)
        setLoadingSubmit(false)
    }
    const onSubmit = async (type?: string) => {
        setLoadingSubmit(true)
        try {
            const body = {
                orderId: id,
                itemId: itemId,
            }
            await dispatch(tryToMarkAsCompleted(body)).unwrap()
            onSuccessfullySaved()
            onCloseModal()
        } catch (err) {
            setSubmitError(`${err}`)
        }
        setLoadingSubmit(false)
    }


    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            dataQa='add-a-note'
            showInRight>
            <div className={'p-2 min-w-[450px]'}>
                <p className='mb-2 font-semibold text-sm'>Order Actions</p>
                <p className='mb-4 text-xl font-bold'>Mark the user of this order as resolved.</p>
                {submitError && <Error text={submitError} />}
                <AddNoteForm onSuccessfullySaved={onSuccessfullyAddedNote} onCloseModal={onCloseModal}
                    id={id} itemId={itemId} />
                <div className='flex flex-col items-start gap-3 bg-[#F8F9FC] px-4 py-6'>
                    <p className='text-base'>Do you want to mark this <span className='font-dm_sansbold'>{activeTab.name} Open Case</span> as <span className='font-dm_sansbold'>Resolved?</span></p>
                    <p className='text-[#8A8A8E] text-base'>Doing this action will be irreversible.</p>
                    <Button
                        className={`${loadingSubmit ? 'btn-primary-rounded-less-disable' : 'btn-primary-rounded-less'} !shadow-none min-w-[260px]`}
                        label='Resolve Case'
                        dataQa={'mark-as-completed-btn'}
                        onClickButton={onSubmit}
                    />
                </div>
            </div >
        </Modal >
    )
}
export default MarkAsContactedModal;