import React from 'react';


const SellerTabsContainer = () => {
    return (
        <div>
            <div className='flex flex-row justify-start items-center mb-10'>
                <div className='max-w-sm animate-pulse ml-3'>
                    <div className='h-[40px] bg-gray-200 rounded-full dark:bg-gray-200 w-[150px] mb-1'></div>
                </div>
                <div className='max-w-sm animate-pulse ml-3'>
                    <div className='h-[40px] bg-gray-200 rounded-full dark:bg-gray-200 w-[150px] mb-1'></div>
                </div>
                <div className='max-w-sm animate-pulse ml-3'>
                    <div className='h-[40px] bg-gray-200 rounded-full dark:bg-gray-200 w-[150px] mb-1'></div>
                </div>
            </div>
        </div>
    )
}

export default SellerTabsContainer;