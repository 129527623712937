export const CASHOUT_STATUSES = [
    { label: 'Waiting Review', value: 'waiting_review', name: 'waiting_review' },
    { label: 'Donated', value: 'donated', name: 'donated' },
    { label: 'Waiting Confirmation', value: 'waiting_confirmation', name: 'waiting_confirmation' },
    { label: 'Not Shipped', value: 'not_shipped', name: 'not_shipped' },
    { label: 'Canceled', value: 'canceled', name: 'canceled' },
    { label: 'Declined', value: 'Declined', name: 'Declined' },
    { label: 'Shipped', value: 'shipped', name: 'shipped' },
    { label: 'Rejected', value: 'rejected', name: 'rejected' },
    { label: 'Failed', value: 'failed', name: 'failed' },
]