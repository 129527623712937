import { AxiosResponse } from 'axios';
import { ORDERS_URL } from '../../../config';
import { readFromStorage } from '../../../utils/webStorage';
import ordersAxios from '../../../utils/axios/orders.axios';
import {
	IEditOrder,
	ISingleOrderSchema,
} from '../../../interfaces/orders/IOrdersSchema';
import { ITicket } from '../../../interfaces/customer-care/ITicket';
import { IOrderFilterBody } from '../../../interfaces/orders/IOrderFilterBody';
import { PaginatedOrders } from '../../../interfaces/orders/paginatedStore.type';
import { ApiResponse } from '../../../interfaces/models/models/paginated-response.model';
import { ICashoutsOrderView } from '../../../interfaces/orders/ICashoutsOrderView';

const tryToFetchOrders = (
	pageNumber: number,
	pageSize: number,
	filters?: string,
	data?: IOrderFilterBody,
): Promise<AxiosResponse<PaginatedOrders>> => {
	let url = `${ORDERS_URL}/shipments?page=${pageNumber}&size=${pageSize}`;
	if (filters) {
		url = `${ORDERS_URL}/shipments?page=${pageNumber}&size=${pageSize}&filters=${filters}`;
	}
	return ordersAxios.put<PaginatedOrders>(url, data);
};

const tryToFetchCashouts = (
	pageNumber: number,
	pageSize: number,
	data?: any,
	filters?: string,
): Promise<AxiosResponse<PaginatedOrders>> => {
	let url = `${ORDERS_URL}/shipments/cashouts?page=${pageNumber}&size=${pageSize}`;
	if (filters) {
		url = `${ORDERS_URL}/shipments/cashouts?page=${pageNumber}&size=${pageSize}&filters=${filters}`;
	}
	return ordersAxios.put<PaginatedOrders>(url, data);
};

const tryToFetchCashoutItems = (
	pageNumber: number,
	pageSize: number,
	id: string,
	data?: any,
): Promise<AxiosResponse<PaginatedOrders>> => {
	const url =
		data?.pagination === false
			? `${ORDERS_URL}/shipments/cashouts/${id}?pagination=false`
			: `${ORDERS_URL}/shipments/cashouts/${id}?page=${pageNumber}&size=${pageSize}`;
	return ordersAxios.get<PaginatedOrders>(url);
};

const tryToFetchCashoutOrders = (
	pageNumber: number,
	pageSize: number,
	search?: string,
	data?: ICashoutsOrderView,
): Promise<AxiosResponse<PaginatedOrders>> => {
	let url = `${ORDERS_URL}/shipments/cashouts/order-level?page=${pageNumber}&size=${pageSize}`;
	if (search) {
		url = `${ORDERS_URL}/shipments/cashouts/order-level?page=${pageNumber}&size=${pageSize}&search=${search}`;
	}
	return ordersAxios.put<PaginatedOrders>(url, data);
};

const tryToFetchOrdersCSV = (
	pageNumber: number,
	pageSize: number,
	search?: string,
	data?: ICashoutsOrderView,
): Promise<AxiosResponse<PaginatedOrders>> => {
	let url = `${ORDERS_URL}/shipments/cashouts/order-level/download?page=${pageNumber}&size=${pageSize}`;
	if (search) {
		url = `${ORDERS_URL}/shipments/cashouts/order-level/download?page=${pageNumber}&size=${pageSize}&search=${search}`;
	}
	return ordersAxios.put<PaginatedOrders>(url, data);
};

const tryToFetchHighLevelOrders = (
	pageNumber: number,
	pageSize: number,
	filters?: string,
	data?: IOrderFilterBody,
): Promise<AxiosResponse<PaginatedOrders>> => {
	let url = `${ORDERS_URL}/shipments/order-view?page=${pageNumber}&size=${pageSize}`;
	if (filters) {
		url = `${ORDERS_URL}/shipments/order-view?page=${pageNumber}&size=${pageSize}&filters=${filters}`;
	}
	return ordersAxios.put<PaginatedOrders>(url, data);
};

const tryToFetchInboundOrders = (
	pageNumber: number,
	pageSize: number,
	filters?: string,
	data?: IOrderFilterBody,
): Promise<AxiosResponse<PaginatedOrders>> => {
	const group = readFromStorage('group');
	let url: string;
	if (group === 'cs') {
		url = `${ORDERS_URL}/support/shipments?page=${pageNumber}&size=${pageSize}`;
		if (filters) {
			url = `${ORDERS_URL}/support/shipments?page=${pageNumber}&size=${pageSize}&filters=${filters}`;
		}
	} else {
		url = `${ORDERS_URL}/shipments/inbound?page=${pageNumber}&size=${pageSize}`;
		if (filters) {
			url = `${ORDERS_URL}/shipments/inbound?page=${pageNumber}&size=${pageSize}&filters=${filters}`;
		}
	}
	return ordersAxios.put<PaginatedOrders>(url, data);
};

const tryToFetchOrdersInReview = (
	pageNumber: number,
	pageSize: number,
	filters?: string,
	data?: IOrderFilterBody,
): Promise<AxiosResponse<PaginatedOrders>> => {
	let url = `${ORDERS_URL}/shipments/in-review?page=${pageNumber}&size=${pageSize}`;
	if (filters) {
		url = `${ORDERS_URL}/shipments/in-review?page=${pageNumber}&size=${pageSize}&filters=${filters}`;
	}
	return ordersAxios.put<PaginatedOrders>(url, data);
};

const tryToFetchOrdersInCs = (
	pageNumber: number,
	pageSize: number,
	filters?: string,
	data?: IOrderFilterBody,
): Promise<AxiosResponse<PaginatedOrders>> => {
	let url = `${ORDERS_URL}/shipments/in-cs?page=${pageNumber}&size=${pageSize}`;
	if (filters) {
		url = `${ORDERS_URL}/shipments/in-cs?page=${pageNumber}&size=${pageSize}&filters=${filters}`;
	}
	return ordersAxios.put<PaginatedOrders>(url, data);
};

const tryToFetchResolvedOrders = (
	pageNumber: number,
	pageSize: number,
	filters?: string,
	data?: IOrderFilterBody,
): Promise<AxiosResponse<PaginatedOrders>> => {
	let url = `${ORDERS_URL}/shipments/resolved?page=${pageNumber}&size=${pageSize}`;
	if (filters) {
		url = `${ORDERS_URL}/shipments/resolved?page=${pageNumber}&size=${pageSize}&filters=${filters}`;
	}
	return ordersAxios.put<PaginatedOrders>(url, data);
};

const tryToFetchRecentOrders = (
	pageNumber: number,
	pageSize: number,
	filters?: string,
	data?: IOrderFilterBody,
): Promise<AxiosResponse<PaginatedOrders>> => {
	let url = `${ORDERS_URL}/shipments/recent-changes?page=${pageNumber}&size=${pageSize}`;
	if (filters) {
		url = `${ORDERS_URL}/shipments/recent-changes?page=${pageNumber}&size=${pageSize}&filters=${filters}`;
	}
	return ordersAxios.put<PaginatedOrders>(url, data);
};

const tryToFetchSingleOrder = (
	orderId: string,
	data: any,
): Promise<AxiosResponse<ApiResponse<ISingleOrderSchema>>> => {
	const isCS = readFromStorage('group') === 'cs';
	const url = isCS
		? `${ORDERS_URL}/support/shipments/${orderId}`
		: `${ORDERS_URL}/shipments/${orderId}`;
	return ordersAxios.put<ApiResponse<ISingleOrderSchema>>(url, data);
};

const tryToFetchAllSellers = (
	pageNumber: number,
	pageSize: number,
	filters?: string,
	data?: any,
): Promise<AxiosResponse<ApiResponse<ISingleOrderSchema>>> => {
	const url = filters
		? `${ORDERS_URL}/sellers/all?page=${pageNumber}&size=${pageSize}&search=${filters}`
		: `${ORDERS_URL}/sellers/all?page=${pageNumber}&size=${pageSize}`;
	return ordersAxios.put<ApiResponse<ISingleOrderSchema>>(url, data);
};

const tryToFetchSellerOrders = (
	pageNumber: number,
	pageSize: number,
	sellerId: string,
	search?: string,
): Promise<AxiosResponse<ApiResponse<ISingleOrderSchema>>> => {
	const url = search
		? `${ORDERS_URL}/sellers/${sellerId}/orders?page=${pageNumber}&size=${pageSize}&search=${search}`
		: `${ORDERS_URL}/sellers/${sellerId}/orders?page=${pageNumber}&size=${pageSize}`;
	return ordersAxios.put<ApiResponse<ISingleOrderSchema>>(url);
};

const tryToFindItemByImei = (imei: string): Promise<AxiosResponse> => {
	return ordersAxios.put(`${ORDERS_URL}/shipments/item-details`, {
		imei: imei,
	});
};

const tryToFindItemByTrackingNumber = (
	trackingNumber: string,
): Promise<AxiosResponse> => {
	return ordersAxios.put(
		`${ORDERS_URL}/shipments/shipment-filters/${trackingNumber}`,
	);
};

const tryToEditBankDetails = (
	orderId: string,
	data: any,
): Promise<AxiosResponse> => {
	return ordersAxios.put(
		`${ORDERS_URL}/shipments/orders/${orderId}/bank-details/edit`,
		data,
	);
};

const tryToChangeImei = (
	itemId: string,
	imei: string,
): Promise<AxiosResponse<void>> => {
	return ordersAxios.post<void>(`${ORDERS_URL}/shipments/${itemId}/imei`, {
		imei: imei,
	});
};

const tryToAddReturnTrackingNumber = (
	itemId: string,
	data: {
		returnTrackingNumber: string;
		carrier: string;
		carrierId: string;
	},
): Promise<AxiosResponse<void>> => {
	return ordersAxios.put<void>(
		`${ORDERS_URL}/shipments/item/${itemId}/edit-return-tracking-number`,
		data,
	);
};

const tryToFetchOrdersCount = (data: {
	country: string;
	marketId: string;
}): Promise<AxiosResponse<any>> => {
	return ordersAxios.put<void>(`${ORDERS_URL}/shipments/count`, data);
};

const tryToAcceptOrder = (
	orderId: string,
	data: any,
): Promise<AxiosResponse<void>> => {
	return ordersAxios.post<void>(
		`${ORDERS_URL}/shipments/${orderId}/accept`,
		data,
	);
};

const tryToSetMissingPackage = (data: {
	shipmentId: string;
	itemId: string;
}): Promise<AxiosResponse<void>> => {
	return ordersAxios.put<void>(
		`${ORDERS_URL}/shipments/item/mark-as-missing`,
		data,
	);
};

const tryToDeliverOrder = (
	trackingNumber: string,
): Promise<AxiosResponse<void>> => {
	return ordersAxios.put<void>(
		`${ORDERS_URL}/shipments/${trackingNumber}/change-status`,
	);
};

const tryToRejectOrder = (
	orderId: string,
	data: any,
): Promise<AxiosResponse<void>> => {
	return ordersAxios.post<void>(
		`${ORDERS_URL}/shipments/${orderId}/reject`,
		data,
	);
};

const tryToRevalueOrder = (
	orderId: string,
	data: any,
): Promise<AxiosResponse<void>> => {
	return ordersAxios.post<void>(
		`${ORDERS_URL}/shipments/${orderId}/revalue`,
		data,
	);
};

const tryToToggleFMI = (
	orderId: string,
	fmi: boolean,
): Promise<AxiosResponse<void>> => {
	return ordersAxios.post<void>(
		`${ORDERS_URL}/shipments/${orderId}/toggle-fmi`,
		{ fmi: fmi },
	);
};

const tryToGetTickets = (
	orderId: string,
): Promise<AxiosResponse<ITicket[]>> => {
	const isCS = readFromStorage('group') === 'cs';
	const url = isCS
		? `${ORDERS_URL}/support/shipments/${orderId}/tickets`
		: `${ORDERS_URL}/shipments/${orderId}/tickets`;
	return ordersAxios.get<ITicket[]>(url);
};

const tryToFetchAllInboundOrders = (): Promise<
	AxiosResponse<PaginatedOrders>
> => {
	const url = `${ORDERS_URL}/shipments/inbound`;
	return ordersAxios.put<PaginatedOrders>(url);
};

const tryToFetchAllOrdersInReview = (): Promise<
	AxiosResponse<PaginatedOrders>
> => {
	const url = `${ORDERS_URL}/shipments/in-review`;
	return ordersAxios.put<PaginatedOrders>(url);
};

const tryToEditSellerInfo = (data: IEditOrder): Promise<AxiosResponse> => {
	return ordersAxios.put(
		`${ORDERS_URL}/shipments/order/edit-shipping-address`,
		data,
	);
};

const tryToReturnRequired = (itemId: string): Promise<AxiosResponse<void>> => {
	return ordersAxios.put<void>(
		`${ORDERS_URL}/shipments/item/${itemId}/require-return`,
	);
};

const tryToRemoveBlacklistedStatus = (
	orderId: string,
	itemId: string,
): Promise<AxiosResponse<void>> => {
	return ordersAxios.put<void>(
		`${ORDERS_URL}/shipments/resolve-blacklisted`,
		{
			orderId,
			itemId,
		},
	);
};

const tryToAcceptItem = (
	orderId: string,
	itemId: string,
): Promise<AxiosResponse<void>> => {
	return ordersAxios.post<void>(`${ORDERS_URL}/shipments/accept-item`, {
		orderId,
		itemId,
	});
};

const tryToReturnToWing = (
	orderId: string,
	itemId: string,
): Promise<AxiosResponse<void>> => {
	return ordersAxios.post<void>(`${ORDERS_URL}/shipments/ship-to-wingpay`, {
		orderId,
		itemId,
	});
};

const tryToAcceptOrderOnUserBehalf = (
	orderId: string,
	itemId: string,
): Promise<AxiosResponse<void>> => {
	return ordersAxios.put<void>(
		`${ORDERS_URL}/shipments/order/${orderId}/item/${itemId}/accept`,
	);
};

const tryToAcceptOrderOnUserBehalfWithGesture = (
	orderId: string,
	itemId: string,
	amount: number,
): Promise<AxiosResponse<void>> => {
	return ordersAxios.put<void>(
		`${ORDERS_URL}/shipments/order/${orderId}/item/${itemId}/accept`,
		{
			gestureAmount: amount,
		},
	);
};
const tryToRejectOrderOnUserBehalf = (
	orderId: string,
	itemId: string,
): Promise<AxiosResponse<void>> => {
	return ordersAxios.put<void>(
		`${ORDERS_URL}/shipments/order/${orderId}/item/${itemId}/reject`,
	);
};

const tryToAddLocationOnOrder = (
	trackingNumber: string,
	data: any,
): Promise<AxiosResponse<void>> => {
	return ordersAxios.put<void>(
		`${ORDERS_URL}/shipments/${trackingNumber}/inventory`,
		data,
	);
};

const tryToFetchAllBlacklistedItems = (
	pageNumber: number,
	pageSize: number,
	data?: IOrderFilterBody,
): Promise<AxiosResponse<PaginatedOrders>> => {
	const url = `${ORDERS_URL}/shipments/blacklisted?page=${pageNumber}&size=${pageSize}`;
	return ordersAxios.put<PaginatedOrders>(url, {
		...data,
		status: 'blacklisted',
	});
};

const tryToFetchAllAutoAcceptedRevaluations = (
	pageNumber: number,
	pageSize: number,
	data?: IOrderFilterBody,
	filters?: string,
): Promise<AxiosResponse<PaginatedOrders>> => {
	const url = filters
		? `${ORDERS_URL}/shipments/by-cs/?page=${pageNumber}&size=${pageSize}&filters=${filters}`
		: `${ORDERS_URL}/shipments/by-cs/?page=${pageNumber}&size=${pageSize}`;
	return ordersAxios.put<PaginatedOrders>(url, {
		...data,
		autoAccept: true,
	});
};

const tryToFetchAllRejectedRevaluations = (
	pageNumber: number,
	pageSize: number,
	data?: IOrderFilterBody,
	filters?: string,
): Promise<AxiosResponse<PaginatedOrders>> => {
	const url = filters
		? `${ORDERS_URL}/shipments/by-cs/?page=${pageNumber}&size=${pageSize}&filters=${filters}`
		: `${ORDERS_URL}/shipments/by-cs/?page=${pageNumber}&size=${pageSize}`;
	return ordersAxios.put<PaginatedOrders>(url, {
		...data,
		userRevalueReject: true,
	});
};

const tryToCancelOrderOnUserBehalf = (
	orderId: string,
): Promise<AxiosResponse<void>> => {
	return ordersAxios.put<void>(`${ORDERS_URL}/shipments/${orderId}/cancel`);
};

const tryToFetchAllRevaluedFromBroker = (
	pageNumber: number,
	pageSize: number,
	data?: IOrderFilterBody,
	filters?: string,
): Promise<AxiosResponse<PaginatedOrders>> => {
	const url = filters
		? `${ORDERS_URL}/shipments/by-cs/?page=${pageNumber}&size=${pageSize}&filters=${filters}`
		: `${ORDERS_URL}/shipments/by-cs/?page=${pageNumber}&size=${pageSize}`;
	return ordersAxios.put<PaginatedOrders>(url, {
		...data,
		status: 'revaluated',
		userRevalueReject: false,
	});
};
const tryToFetchAllRejectedFromBroker = (
	pageNumber: number,
	pageSize: number,
	data?: IOrderFilterBody,
	filters?: string,
): Promise<AxiosResponse<PaginatedOrders>> => {
	const url = filters
		? `${ORDERS_URL}/shipments/by-cs/?page=${pageNumber}&size=${pageSize}&filters=${filters}`
		: `${ORDERS_URL}/shipments/by-cs/?page=${pageNumber}&size=${pageSize}`;
	return ordersAxios.put<PaginatedOrders>(url, {
		...data,
		status: 'warehouse_rejected',
	});
};
const tryToFetchAllAwaitingUserResponse = (
	pageNumber: number,
	pageSize: number,
	data?: IOrderFilterBody,
	filters?: string,
): Promise<AxiosResponse<PaginatedOrders>> => {
	const url = filters
		? `${ORDERS_URL}/shipments/by-cs/?page=${pageNumber}&size=${pageSize}&filters=${filters}`
		: `${ORDERS_URL}/shipments/by-cs/?page=${pageNumber}&size=${pageSize}`;
	return ordersAxios.put<PaginatedOrders>(url, {
		...data,
		userContactedByCs: true,
	});
};
const tryToFetchAllClosedCases = (
	pageNumber: number,
	pageSize: number,
	data?: IOrderFilterBody,
	filters?: string,
): Promise<AxiosResponse<PaginatedOrders>> => {
	const url = filters
		? `${ORDERS_URL}/shipments/by-cs/?page=${pageNumber}&size=${pageSize}&filters=${filters}`
		: `${ORDERS_URL}/shipments/by-cs/?page=${pageNumber}&size=${pageSize}`;
	return ordersAxios.put<PaginatedOrders>(url, {
		...data,
		caseClosedByCs: true,
	});
};

const tryToAssignCSUser = (data: any): Promise<AxiosResponse<any>> => {
	return ordersAxios.put(`${ORDERS_URL}/shipments/case/assign/cs`, data);
};
const tryToAddNote = (data: any): Promise<AxiosResponse<any>> => {
	return ordersAxios.post(`${ORDERS_URL}/item-notes/`, data);
};

const tryToFetchNotes = (itemId: string): Promise<AxiosResponse<any>> => {
	return ordersAxios.get(`${ORDERS_URL}/item-notes/itemId/${itemId}`);
};

const tryToMarkAsContacted = (data: any): Promise<AxiosResponse<any>> => {
	return ordersAxios.put(`${ORDERS_URL}/shipments/mark-as-contacted`, data);
};
const tryToMarkAsCompleted = (data: any): Promise<AxiosResponse<any>> => {
	return ordersAxios.put(`${ORDERS_URL}/shipments/mark-as-closed`, data);
};
const tryToFetchMaxGestureAmount = (
	orderId: string,
	itemId: string,
): Promise<AxiosResponse<any>> => {
	return ordersAxios.get(
		`${ORDERS_URL}/shipments/${orderId}/maximum-gesture-amount/${itemId}`,
	);
};

const tryToResetOrder = (
	orderId: string,
	itemId: string,
	reason: string,
	details: string,
): Promise<AxiosResponse<void>> => {
	return ordersAxios.put<void>(`${ORDERS_URL}/shipments/reset-order`, {
		orderId,
		itemId,
		reason: reason !== 'Other' ? reason : details,
	});
};

const tryToTakeOutboundAction = (
	orderId: string,
	outboundStatus: string,
): Promise<AxiosResponse<void>> => {
	return ordersAxios.put<void>(`${ORDERS_URL}/shipments/${orderId}/take-outbound-action`, {
		outboundStatus,
	});
};

const tryToAssignCSUserOutbound = (orderId: string, data: any): Promise<AxiosResponse<any>> => {
	return ordersAxios.put(`${ORDERS_URL}/shipments/${orderId}/assign-cs-user-outbound-action`, data);
};

const tryToFetchOutboundDetails = (
	orderId: string,
): Promise<AxiosResponse<any>> => {
	return ordersAxios.get(
		`${ORDERS_URL}/shipments/cashouts/${orderId}/outbound-details`,
	);
};

const tryToAddOutboundNote = (orderId: string, data: any): Promise<AxiosResponse<any>> => {
	return ordersAxios.put(`${ORDERS_URL}/shipments/cashouts/${orderId}/add-cs-note`, data);
};

export const OrdersService = {
	tryToFetchOrders,
	tryToFetchCashouts,
	tryToFetchCashoutItems,
	tryToFetchHighLevelOrders,
	tryToFetchInboundOrders,
	tryToFetchOrdersInReview,
	tryToFetchOrdersInCs,
	tryToFetchResolvedOrders,
	tryToFetchSingleOrder,
	tryToFetchRecentOrders,
	tryToFetchOrdersCount,
	tryToFindItemByImei,
	tryToAddReturnTrackingNumber,
	tryToChangeImei,
	tryToAcceptOrder,
	tryToRejectOrder,
	tryToRevalueOrder,
	tryToSetMissingPackage,
	tryToToggleFMI,
	tryToGetTickets,
	tryToDeliverOrder,
	tryToFetchAllInboundOrders,
	tryToFetchAllOrdersInReview,
	tryToFetchAllSellers,
	tryToFetchSellerOrders,
	tryToFindItemByTrackingNumber,
	tryToEditSellerInfo,
	tryToReturnRequired,
	tryToAddLocationOnOrder,
	tryToEditBankDetails,
	tryToAcceptOrderOnUserBehalf,
	tryToFetchAllBlacklistedItems,
	tryToRemoveBlacklistedStatus,
	tryToAcceptItem,
	tryToReturnToWing,
	tryToFetchAllRejectedRevaluations,
	tryToAcceptOrderOnUserBehalfWithGesture,
	tryToRejectOrderOnUserBehalf,
	tryToCancelOrderOnUserBehalf,
	tryToFetchAllAutoAcceptedRevaluations,
	tryToFetchAllRejectedFromBroker,
	tryToFetchAllRevaluedFromBroker,
	tryToFetchAllAwaitingUserResponse,
	tryToFetchAllClosedCases,
	tryToAssignCSUser,
	tryToAddNote,
	tryToFetchNotes,
	tryToMarkAsContacted,
	tryToMarkAsCompleted,
	tryToFetchMaxGestureAmount,
	tryToResetOrder,
	tryToFetchCashoutOrders,
	tryToTakeOutboundAction,
	tryToAssignCSUserOutbound,
	tryToFetchOutboundDetails,
	tryToAddOutboundNote,
	tryToFetchOrdersCSV
};
