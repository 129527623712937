import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { tryToFetchCashoutItems } from '../../../store/customer-support/orders/ordersSlice';
import { useNavigate, useParams } from 'react-router-dom';
import EmptyContainer from '../../../shared/empty-container';
import CashoutItemsTable from './tables/CashoutItemsTable';
import CashoutItemModal from './modals/CashoutItemModal';


const CashoutDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<any>([]);
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [showCashoutItemModal, setShowCashoutItemModal] = useState<{ show: boolean, item: any }>()
    const [pageAccess, setPageAccess] = useState<any>()
    const paginationData = useAppSelector((state) => state.orders.cashoutItems?.data?.page,);
    const rowsLoading = useAppSelector((state) => state.orders.cashoutIsLoading);
    const state = useAppSelector((state) => state.orders);
    const accessControl = useAppSelector((state) => state?.partners?.navigation);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Orders')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Cashout Details')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state.cashoutItems) {
            const rows =
                state.cashoutItems?.data?.elements &&
                    Array.isArray(state.cashoutItems?.data.elements)
                    ? state.cashoutItems?.data.elements.map((r: any) => ({
                        ...r,
                        id: r._id,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state.cashoutItems]);

    const onGettingCashoutItemsData = async () => {
        try {
            const formatData: any = {
                ...paginationState || {},
                id: id || ''
            }
            await dispatch(tryToFetchCashoutItems(formatData)).unwrap();
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        if (id) {
            onGettingCashoutItemsData()
        }
    }, [paginationState, id]);

    const onSeeDetails = (item: any) => {
        setShowCashoutItemModal({ show: true, item: item })
    }

    // const onClickClearAllFilters = () => {
    //     setPaginationState({
    //         pageNumber: 1,
    //         pageSize: 10,
    //     });
    // }

    const onGoBack = () => {
        navigate(-1)
    }

    return (
        <div className='h-auto w-full flex flex-col my-6'>
            <div className='flex flex-row items-center cursor-pointer' onClick={onGoBack}>
                <div data-qa={'back-button'} className='md:-ml-2 xl:-ml-3 md:mr-2 xl:mr-3'>
                    <img src={'/assets/shared/go-back.svg'} className={'w-[14px] object-contain cursor-pointer'} />
                </div>
                <p className='page-title !text-[16px] !mb-2'>Cashouts</p>
            </div>
            <p className='page-title mb-3'>Cashout Items</p>
            <div className='flex flex-row items-center'>
                <p>Cashout ID:</p>
                <p className='rounded-full ml-2 px-2 bg-[#fefefe] text-[#464E5F]'>{id}</p>
            </div>
            <div className='my-5 relativ'>
                <>
                    {(rowsLoading || rows?.length > 0) ? (
                        <CashoutItemsTable
                            rows={rows}
                            actions={pageAccess?.actions}
                            accessControl={accessControl}
                            cashoutsLoading={rowsLoading}
                            paginationData={paginationData}
                            setPaginationState={setPaginationState}
                            onSeeDetails={onSeeDetails}
                        />
                    ) : (
                        <EmptyContainer
                            text={'No cashout items found.'}
                            showImage={true}
                        />
                    )}
                </>
            </div>
            {showCashoutItemModal?.show &&
                <CashoutItemModal
                    openModal={showCashoutItemModal?.show}
                    itemData={showCashoutItemModal?.item}
                    handleCloseModal={() => setShowCashoutItemModal(undefined)}
                />
            }
        </div>
    )
}

export default CashoutDetails;