import React from 'react';
import moment from 'moment'
import { ITicket } from '../../../../interfaces/customer-care/ITicket';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { onFormatOrderStatus } from '../../../../utils/constants/format-order-statuses';
import Button from '../../../../shared/button';
import Table from '../../../../shared/table';

const tableColumns = ['Order ID', 'Updated Date', 'Item Name', 'Purchase Price', 'Company Name', 'Warehouse', 'Subject', 'Tracking Number', 'CS User Assigned', 'Manage Assigned User', 'Order Status', 'Actions'];

interface ICustomerCareTable {
    rows: ITicket[];
    paginationData?: IPage;
    activeCategory: { name: string, status: boolean };
    accessControl?: any;
    actions?: any;
    rowsLoading: boolean;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onSeeCsDetails: (id: string, itemId: string, country: string) => void;
    onCloseTicketRequest: (id: string) => void;
    onDeleteTicket: (id: string) => void;
    onChangeManageAssignedUser: (id: string, current: any) => void;
}

const CustomerCareTable = ({
    rows,
    paginationData,
    activeCategory,
    accessControl,
    actions,
    rowsLoading,
    onSeeCsDetails,
    onCloseTicketRequest,
    onDeleteTicket,
    setPaginationState,
    onChangeManageAssignedUser
}: ICustomerCareTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 &&
                        rows?.map((row: ITicket, idx: number) => {
                            const formatOrderStatus = onFormatOrderStatus(row?.ticket?.orderStatus || '')
                            const orderStatusItem = formatOrderStatus?.color && <div className={`${formatOrderStatus?.color} flex flex-row justify-center text-center px-1 min-w-[150px] ${formatOrderStatus?.text || 'text-black'} text-sm py-1 font-medium rounded-2xl`}>{formatOrderStatus?.name}</div>
                            return (
                                <tr
                                    key={idx}
                                    title={row?.ticket?.readByCs === false ? 'Unread' : 'Read'}
                                    className={`border-b hover:bg-gray-50 ${row?.ticket?.readByCs === false ? 'font-medium text-[#000000] bg-[#dffffa10]' : 'font-normal bg-white'}`}>
                                    <td className='px-6 py-4'>{row?.ticket?.orderId}</td>
                                    <td className='px-6 py-4'>{row?.ticket?.updatedAt && moment(row?.ticket?.updatedAt).fromNow()}</td>
                                    <td className='px-6 py-4'>{row?.item?.name}</td>
                                    <td className='px-6 py-4'>{row?.item?.revaluatedPrice || row?.item?.price}</td>
                                    <td className='px-6 py-4'>{row?.ticket?.user?.partnerName}</td>
                                    <td className='px-6 py-4'>{row?.ticket?.warehouse?.name}</td>
                                    <td className='px-6 py-4'>{row?.ticket?.subject}</td>
                                    <td className='px-6 py-4 text-[#F7A21E] font-semibold underline underline-offset-1 break-all min-w-[150px]' data-qa={'tracking-number'}>{row?.ticket?.trackingNumber}</td>
                                    <td className='px-6 py-4'>{row?.ticket?.assignedCs?.name || 'Unassinged'}</td>
                                    <td className='px-3 py-2'>
                                        <Button
                                            label={'Manage'}
                                            dataQa={'manage'}
                                            className={(!accessControl || actions?.['Manage Assigned User']) ? 'btn-primary-rounded-less' : 'btn-primary-rounded-less-disable'}
                                            onClickButton={() => (!accessControl || actions?.['Manage Assigned User']) && onChangeManageAssignedUser(row?.ticket?._id || '', row?.ticket?.assignedCs)}
                                        />
                                    </td>
                                    <td className='px-6 py-4'>
                                        {orderStatusItem}
                                    </td>
                                    <td className='px-2 py-4'>
                                        <div className='flex flex-row justify-around'>
                                            <div data-qa={'view-details'} onClick={() => (!accessControl || actions?.['See Details']) && row?.ticket?.orderId && onSeeCsDetails(row?.ticket?.orderId, row?.ticket?.itemId || '', row?.ticket?.country || row?.item?.country || '')}>
                                                <img title={'View Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['See Details']) ? 'cursor-pointer' : 'cursor-not-allowed'}`} />
                                            </div>
                                            {activeCategory?.name !== 'Closed Tickets'
                                                && <div data-qa={'closed-ticket'} onClick={() => (!accessControl || actions?.['Close Ticket']) && row?.ticket?._id && onCloseTicketRequest(row?.ticket?._id)}>
                                                    <img title={'Close Ticket'} alt='close ticket' src={'/assets/table-actions/close.svg'} className={`h-[22px] object-contain ${(!accessControl || actions?.['Close Ticket']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-1 pb-1`} />
                                                </div>
                                            }
                                            {row?.ticket?.csAuthor && <div data-qa={'delete-ticket'} onClick={() => (!accessControl || actions?.['Delete Ticket']) && row?.ticket?._id && onDeleteTicket(row?.ticket?._id)}>
                                                <img title={'Delete Ticket'} alt='delete ticket' src={'/assets/table-actions/delete-close.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['Delete Ticket']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-1`} />
                                            </div>
                                            }
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                </>
            }
        </Table>
    );
};

export default CustomerCareTable;
