import React from 'react';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import { capitalizeAndFormat, onFormatCSAction, onFormatCashoutType, onFormatOrderStatus, onFormatPackageStatus } from '../../../../utils/constants/format-order-statuses';
import Table from '../../../../shared/table';
import moment from 'moment';
import Button from '../../../../shared/button';

const tableColumns = ['Cashout ID', 'User ID', 'Item ID', 'Full Name','User Email', 'Price', 'Status', 'Cashout Type', 'Single/Multiple', 'Order Number',  'Tracking Number', 'Shipping Provider', 'Shipping  Label', 'Actions']
const tableKeys = ['id', 'userId', 'itemId', 'fullName','seller.email', 'price', 'orderStatus', 'cashoutType', 'isMultipleCashout', 'orderNumber', 'trackingNumber', 'shippingProvider', 'shippingLabel']

const orderTableColumns = ['CS Actions', 'Assignee', 'Cashout Date', 'Single/Multiple', 'User Full Name', 'User’s Phone Nr.', 'User email', 'Price', 'Cashout ID', 'Order Status', 'Order Number', 'User ID', 'Tracking Number', 'Shipping Provider', 'Shipping  Label', 'Actions']
const orderTableKeys = ['outBoundStatus', 'assignee', 'createdAt', 'type', 'userFullName', 'seller.phone', 'seller.email', 'price', 'id', 'status', 'orderNumber', 'userId', 'trackingNumber', 'shippingProvider', 'shippingLabel']

interface ICashoutsTable {
    rows: any;
    paginationData?: IPage;
    cashoutsLoading: boolean;
    accessControl?: any;
    actions?: any;
    onSeeDetails: (id: string, itemId: string, country: string) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onCashoutType?: (id: string, itemId: string) => void
    orderRows: any;
    showOrders?: boolean;
    onTakeAction?: (id: string, isMultipleCashout: boolean, fullName: string) => void;
    onAssignCsUser?: (id: string) => void;
    ordersPaginationData?: IPage;
}

const CashoutsTable = ({
    rows,
    paginationData,
    cashoutsLoading,
    accessControl,
    actions,
    onSeeDetails,
    setPaginationState,
    onCashoutType,
    orderRows,
    showOrders,
    onTakeAction,
    onAssignCsUser,
    ordersPaginationData
}: ICashoutsTable) => {

    return (
        <>
        {
            !showOrders ? 
            <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}>
            {cashoutsLoading ?
                <>
                    {[...Array(10)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {tableColumns?.map(element => {
                                    return (
                                        <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                {tableKeys?.length > 0 && tableKeys?.map((key, index) => {
                                    if (key !== 'trackingNumber') {
                                        let value = row?.[key]
                                        let item = null
                                        if (key === 'orderStatus') {
                                            value = onFormatPackageStatus(row?.[key])?.name
                                            const color = onFormatPackageStatus(row?.[key])?.color
                                            const text = onFormatPackageStatus(row?.[key])?.text || 'text-black'
                                            item = color && <div className={`${color} flex flex-row justify-center text-center px-1 min-w-[150px] ${text} text-sm py-1 font-medium rounded-2xl`}>{value}</div>
                                        }
                                        if (key === 'price') {
                                            value = Number(row?.[key] || 0).toFixed(2)
                                            item = `${CURRENCY_SYMBOLS?.[`${(row?.currency || '')}`]}${value}`
                                        }
                                        if (key === 'id') {
                                            value = row?.id
                                        }
                                        if (key === 'seller.email') {
                                            value = row?.seller?.email || ''
                                        }
                                        if (key === 'cashoutType') {
                                            value = onFormatCashoutType(row?.[key])?.name
                                            const color = onFormatCashoutType(row?.[key])?.color
                                            item = color && <div className={`${color} flex flex-row justify-center text-center text-red px-1 min-w-[150px] text-black text-sm py-1 font-medium rounded-2xl`}>{value}</div>
                                        }
                                        if (key === 'isMultipleCashout') {
                                            value = row?.[key] ? <button type='button' className='bg-transparent outline-none underline' onClick={() => onCashoutType && onCashoutType(row?.id, row?.itemId)}>Multiple Cashout</button> : <p className='text-center'>Single Cashout</p>
                                        }
                                        if (key === 'fullName') {
                                            value = `${row?.seller?.profile?.firstName || ''} ${row?.seller?.profile?.lastName || ''}`
                                        }
                                        if (key === 'userId') {
                                            value = row?.sellerId || row?.seller?._id || row?.seller?.profile?._id || ''
                                        }
                                        if (key === 'shippingLabel' && (row?.qrCode || row?.shippingLabel || row?.shippingUrl)) {
                                            value = <a
                                                rel='noreferrer'
                                                href={row?.qrCode || row?.shippingLabel || row?.shippingUrl}
                                                target='_blank'
                                                className='text-primary-default'
                                                data-qa={`shipping_label_${row?.trackingNumber || ''}`}
                                            >{row?.qrCode ? 'QR CODE' : row?.shippingLabel ? 'SHIPPING LABEL' : row?.shippingUrl ? 'SHIPPING URL' : null}</a>
                                        }
                                        if (key === 'invoiceUrl' && row?.invoiceUrl) {
                                            value = <a
                                                rel='noreferrer'
                                                href={row?.invoiceUrl}
                                                target='_blank'
                                                className='text-primary-default'
                                                data-qa={`invoice_url_${row?.trackingNumber}`}
                                            >{row?.invoiceUrl}</a>
                                        }
                                        return (
                                            <td
                                                key={index}
                                                className={'px-6 py-4'}>
                                                {item || value}
                                            </td>
                                        )
                                    } else {
                                        return (
                                            <td key={index} className='px-6 py-4'>
                                                {row?.[key] ?
                                                    <a
                                                        rel='noreferrer'
                                                        href={`https://parcelsapp.com/en/tracking/${row?.[key]}`}
                                                        target='_blank'
                                                        className='text-primary-default'>
                                                        {row?.[key]}
                                                    </a>
                                                    : <p className='!text-[#F7A21E]'>{'NO TRACKING'}</p>
                                                }
                                            </td>
                                        )
                                    }
                                })}
                                <td className='px-6 py-4 min-w-[120px]'>
                                    <div className='flex flex-row justify-around'>
                                        <div data-qa={'view-details'} onClick={() => (!accessControl || actions?.['See Order Details']) && row?.id && row?.itemId && row?.isMultipleCashout && onCashoutType ? onCashoutType(row?.id, row?.itemId) : onSeeDetails(row?.id, row?.itemId || '', row?.region || '')}>
                                            <img title={'View Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['See Order Details']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-2`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
        :
        <Table
            columns={orderTableColumns}
            paginationData={ordersPaginationData}
            setPaginationState={setPaginationState}>
            {cashoutsLoading ?
                <>
                    {[...Array(10)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {orderTableColumns?.map(element => {
                                    return (
                                        <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {orderRows?.length > 0 && orderRows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                {orderTableKeys?.length > 0 && orderTableKeys?.map((key, index) => {
                                    if (key !== 'trackingNumber') {
                                        let value = row?.[key]
                                        let item = null
                                        if (key === 'seller.phone') {
                                            value = row?.seller?.phone
                                        }
                                        if(key === 'outBoundStatus') {
                                            value = row?.outBoundStatus?.outboundStatus ? <button onClick={() => onTakeAction && onTakeAction(row?.id, row?.type, row?.userFullName)} type='button' className={onFormatCSAction(row?.outBoundStatus?.outboundStatus)}>{capitalizeAndFormat(row?.outBoundStatus?.outboundStatus) || ''}</button> : <Button onClickButton={() => onTakeAction && onTakeAction(row?.id, row?.type, row?.userFullName)} label='Take Action' className='w-[180px] bg-[#EBEDEF] rounded-[10px] h-[26px]'/>
                                        }
                                        if(key === 'assignee') {
                                            value = row?.assignee ? <p onClick={() => onAssignCsUser && onAssignCsUser(row?._id)} className='font-dm_sansregular text-center underline cursor-pointer'>{row?.assignee}</p> : <Button onClickButton={() => onAssignCsUser && onAssignCsUser(row?._id)} className='bg-[#EBEDEF] w-[100px] h-[28px] rounded-[5px]' label='Assign'/>
                                        }
                                        if(key === 'createdAt') {
                                            value = moment(row?.createdAt).calendar()
                                        }
                                        if (key === 'status') {
                                            value = onFormatOrderStatus(row?.[key])?.name
                                            const text = onFormatOrderStatus(row?.[key])?.text || 'text-black'
                                            const borderColor = onFormatOrderStatus(row?.[key])?.borderColor || 'black'
                                            item = <div className={`flex flex-row justify-center items-center text-center px-1 min-w-[150px] h-[34px] ${text} text-sm py-1 font-medium border ${borderColor} rounded-[10px]`}>{value}</div>
                                        }
                                        if (key === 'price') {
                                            value = Number(row?.[key] || 0).toFixed(2)
                                            item = `${CURRENCY_SYMBOLS?.[`${(row?.localizedPrice?.currencyCode || row?.currency || '')}`]}${value}`
                                        }
                                        if (key === 'id') {
                                            value = row?.id
                                        }
                                        if (key === 'seller.email') {
                                            value = row?.seller?.email || ''
                                        }
                                        if (key === 'type') {
                                            value = row?.[key] !== 'Single Cashout' ? <button type='button' className='bg-transparent outline-none underline' onClick={() => onCashoutType && onCashoutType(row?.id, row?.itemId)}>Multiple Cashout</button> : <p className='text-center'>Single Cashout</p>
                                        }
                                        if (key === 'userFullName') {
                                            value = `${row?.seller?.profile?.firstName || ''} ${row?.seller?.profile?.lastName || ''}`
                                        }
                                        if (key === 'userId') {
                                            value = row?.sellerId || row?.seller?._id || row?.seller?.profile?._id || ''
                                        }
                                        if (key === 'shippingLabel' && (row?.qrCode || row?.shippingLabel || row?.shippingUrl)) {
                                            value = <a
                                                rel='noreferrer'
                                                href={row?.qrCode || row?.shippingLabel || row?.shippingUrl}
                                                target='_blank'
                                                className='text-primary-default'
                                                data-qa={`shipping_label_${row?.trackingNumber || ''}`}
                                            >{row?.qrCode ? 'QR CODE' : row?.shippingLabel ? 'Download' : row?.shippingUrl ? 'SHIPPING URL' : null}</a>
                                        }
                                        if (key === 'invoiceUrl' && row?.invoiceUrl) {
                                            value = <a
                                                rel='noreferrer'
                                                href={row?.invoiceUrl}
                                                target='_blank'
                                                className='text-primary-default'
                                                data-qa={`invoice_url_${row?.trackingNumber}`}
                                            >{row?.invoiceUrl}</a>
                                        }
                                        return (
                                            <td
                                                key={index}
                                                className={'px-6 py-4'}>
                                                {item || value}
                                            </td>
                                        )
                                    } else {
                                        return (
                                            <td key={index} className='px-6 py-4'>
                                                {row?.[key] ?
                                                    <a
                                                        rel='noreferrer'
                                                        href={`https://parcelsapp.com/en/tracking/${row?.[key]}`}
                                                        target='_blank'
                                                        className='text-primary-default'>
                                                        {row?.[key]}
                                                    </a>
                                                    : <p className='!text-[#F7A21E]'>{'NO TRACKING'}</p>
                                                }
                                            </td>
                                        )
                                    }
                                })}
                                <td className='px-6 py-4 min-w-[120px]'>
                                    <div className='flex flex-row justify-around'>
                                        <div data-qa={'view-details'} onClick={() => (!accessControl || actions?.['See Order Details']) && row?._id && row?.type === 'Multiple Cashout' && onCashoutType ? onCashoutType(row?._id, row?.itemIds[0]) : onSeeDetails(row?._id, row?.itemIds[0] || '', row?.region || '')}>
                                            <img title={'View Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['See Order Details']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-2`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
        }
        </>
    )
}

export default CashoutsTable;