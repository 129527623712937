import React, { useState, useEffect, useLayoutEffect } from 'react';
import {
    useNavigate,
    useParams,
    useLocation,
    useSearchParams
} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { tryToFetchSingleSeller } from '../../../store/customer-support/sellers/sellersSlice';
import SellerDetailsLoading from './loading/SellerDetailsLoading';
import UserActivityTab from './tabs/UserActivityTab';
import TransactionsTab from './tabs/TransactionsTab';
import CashoutsTab from './tabs/CashoutsTab';
import PaymentsTab from './tabs/PaymentsTab';
import DetailsTab from './tabs/DetailsTab';
import OrdersTab from './tabs/OrdersTab';


const sellerTabs = [
    { name: 'Details', element: <DetailsTab /> },
    { name: 'Cashouts', element: <CashoutsTab /> },
    { name: 'Orders', element: <OrdersTab /> },
    { name: 'Transactions', element: <TransactionsTab /> },
    { name: 'User Activity', element: <UserActivityTab /> },
    { name: 'Payments', element: <PaymentsTab /> },
]

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SellerDetails = () => {
    const query = useQuery();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState<any>(sellerTabs[0])
    const [pageAccess, setPageAccess] = useState<any>()
    const accessControl = useAppSelector((state) => state?.partners?.navigation);
    const sellerLoading = useAppSelector((state) => state?.sellers?.sellerIsLoading);
    const sellerDetails = useAppSelector((state) => state?.sellers?.sellerDetails);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Wing Users')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Wing User Details')
        setPageAccess(findChild || undefined)
        if (findChild) {
            const findActiveTab = findChild?.actions?.[sellerTabs[0]?.name] ? sellerTabs[0] : sellerTabs?.find((item: any) => findChild?.actions?.[item?.name])
            setActiveTab(findActiveTab)
        }
    }, [accessControl])

    const onChangeActiveTab = (item: any) => {
        setActiveTab(item)
        searchParams.set('type', item?.name)
        setSearchParams(searchParams)
    }

    useLayoutEffect(() => {
        if (query?.get('type') && query?.get('type') !== activeTab?.name) {
            const findActiveTab = sellerTabs?.find((item) => item?.name === query?.get('type'))
            setActiveTab(findActiveTab || undefined)
        }
    }, [query?.get('type')])

    const onGettingSellerDetails = async () => {
        try {
            await dispatch(tryToFetchSingleSeller(id || '')).unwrap()
        } catch (err) {
            // error here
        }
    };

    useEffect(() => {
        onGettingSellerDetails()
    }, [id])

    const onGoBack = () => {
        navigate('/wing-users');
    };

    return (
        <div>
            <div className='flex flex-row items-center cursor-pointer' onClick={onGoBack}>
                <div data-qa={'back-button'} className='md:-ml-2 xl:-ml-3 md:mr-2 xl:mr-3'>
                    <img src={'/assets/shared/go-back.svg'} className={'w-[14px] object-contain cursor-pointer'} />
                </div>
                <p className='page-title !text-[16px] !mb-2'>Wing Users</p>
            </div>
            {sellerLoading ?
                <SellerDetailsLoading />
                : <>
                    <div className='flex flex-row justify-start items-center mb-10'>
                        <div className="avatar placeholder">
                            <div className="bg-[#fff] flex flex-rows justify-center items-center text-neutral-content rounded-full w-[50px] h-[50px] mr-3">
                                <span className='font-semibold gradient-text'>{sellerDetails?.seller?.profile?.firstName && (sellerDetails?.seller?.profile?.firstName || '').slice(0, 1).toUpperCase()}{sellerDetails?.seller?.profile?.lastName && (sellerDetails?.seller?.profile?.lastName || '').slice(0, 1).toUpperCase()}</span>
                            </div>
                        </div>
                        <div>
                            <p className='page-title break-all'>{sellerDetails?.seller?.email || ''}</p>
                            <div className='flex flex-row items-center mb-1'>
                                <p>User ID:</p>
                                <p className='rounded-full ml-2 px-2 bg-[#fefefe] text-[#464E5F]'>{id}</p>
                            </div>
                            <div className='flex flex-row items-center'>
                                <p>Username:</p>
                                <p className='rounded-full ml-2 px-2 bg-[#fefefe] text-[#464E5F]'>{sellerDetails?.seller?.username}</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-row flex-wrap items-center mt-5 mb-3'>
                        {sellerTabs?.filter((item: any) => (!accessControl || pageAccess?.actions?.[item?.name]))?.map((tab, index) => {
                            return (
                                <div
                                    key={index}
                                    className={'btn-categories min-w-[140px] mr-3 bg-white cursor-pointer px-6 py-2 mb-2'}
                                    onClick={() => onChangeActiveTab(tab)}>
                                    <p className={activeTab?.name === tab?.name ? 'text-primary-default' : 'text-[#a4a4a4] font-semibold'}>{tab?.name}</p>
                                </div>
                            )
                        })}
                    </div>
                    <div className='my-2'>
                        {(!accessControl || pageAccess?.actions?.[activeTab?.name]) ? activeTab?.element : <></>}
                    </div>
                </>
            }
        </div >
    )
}

export default SellerDetails;