import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useEditUserMutation } from '../../../../store/user/userApi';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Select from '../../../../shared/select';

interface IEditUsername {
    openModal: boolean;
    handleCloseModal: () => void;
    usernameUpdatedSuccessfully: (data: any) => void;
    username: string;
    currentData?: any;
}

type FormValues = {
    name: string;
    email: string;
    country?: string;
};

const EditUsername = ({
    openModal,
    username,
    handleCloseModal,
    usernameUpdatedSuccessfully,
    currentData,
}: IEditUsername) => {
    const [editUserMutation] = useEditUserMutation();
    const [errorChanging, setErrorChanging] = useState<string>();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormValues>({});

    useEffect(() => {
        if (currentData) {
            currentData?.name && setValue('name', currentData?.name);
            currentData?.country && setValue('country', currentData?.country);
            currentData?.email && setValue('email', currentData?.email);
        }
    }, [currentData]);

    const onCloseModal = () => {
        handleCloseModal();
        reset();
    };

    const onSubmit = async (data: FormValues) => {
        try {
            const endData = {
                data: {
                    name: currentData?.name,
                },
                id: username,
            };
            await editUserMutation(endData).unwrap();
            usernameUpdatedSuccessfully(data);
            reset();
        } catch (error: any) {
            setErrorChanging(
                `${
                    error?.data?.message ||
                    error?.response ||
                    error?.message ||
                    error
                }`,
            );
        }
    };

    return (
        <Modal open={openModal} onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <p className='mb-6 font-bold text-md'>Edit Details</p>
                    {errorChanging && <Error text={errorChanging} />}
                    <Input
                        label='Name'
                        type={'text'}
                        register={register('name', {
                            required: {
                                message: 'Name is required',
                                value: true,
                            },
                            validate: (value: string) =>
                                !!value.trim() || 'Name is required.',
                        })}
                        error={errors.name?.message}
                        dataQa={'name'}
                    />
                    <Input
                        label='Email'
                        type={'text'}
                        disabled={true}
                        register={register('email', {
                            required: {
                                message: 'Email is required',
                                value: true,
                            },
                            validate: (value: string) =>
                                !!value.trim() || 'Email is required.',
                        })}
                        error={errors.name?.message}
                        dataQa={'email'}
                    />
                    <Select
                        name='Country'
                        dataQa={'country'}
                        disabled={true}
                        showValue={true}
                        placeholder={currentData?.country}
                        options={[]}
                        error={errors?.country?.message}
                    />
                    <Button
                        label={'Save Changes'}
                        dataQa={'save-changes'}
                        className={
                            'btn-primary-rounded-less min-w-full my-2 !py-2'
                        }
                        type={'submit'}
                    />
                </form>
            </div>
        </Modal>
    );
};
export default EditUsername;
