import { AxiosResponse } from 'axios';
import { ORDERS_URL } from '../../../config';
import { PaginatedTransaction } from '../../../interfaces/transactions/paginatedStore.type';
import ordersAxios from '../../../utils/axios/orders.axios';

const tryToFetchTransactions = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: any,
): Promise<AxiosResponse<PaginatedTransaction>> => {
    const url = filters
        ? `${ORDERS_URL}/payments/failed-transactions?page=${pageNumber}&size=${pageSize}&search=${filters}`
        : `${ORDERS_URL}/payments/failed-transactions?page=${pageNumber}&size=${pageSize}`;
    return ordersAxios.put<PaginatedTransaction>(url, data);
};

const tryToFetchTransactionById = (transactionId: string): Promise<AxiosResponse<any>> => {
    return ordersAxios.get<any>(`${ORDERS_URL}/payments/failed-transactions/${transactionId}`);
};

const tryToUpdateTransactionById = (data: any): Promise<AxiosResponse<any>> => {
    return ordersAxios.put<any>(`${ORDERS_URL}/payments/retry-failed-transaction`, data);
};


export const TransactionsService = {
    tryToFetchTransactions,
    tryToFetchTransactionById,
    tryToUpdateTransactionById
}