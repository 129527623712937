import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { CSGuard } from './guards/RolesGuard';
import { UserGuard } from './guards/UserGuard';
import { routes } from './router/routes';
import RouteWrapper from './router/RouteWrapper';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { tryToFetchNavigation } from './store/customer-support/partners/partnersSlice';


function App() {
    const dispatch = useAppDispatch()
    const token = localStorage.getItem('accessToken')
    const [redirectCS, setRedirectCS] = useState<any>()
    const redirectNotExistingRoutes = UserGuard() ? (CSGuard() ? '/tickets' : '/login') : '/login'
    const navigation = useAppSelector(state => state?.partners?.navigation)

    const getNavigationData = async () => {
        try {
            await dispatch(tryToFetchNavigation()).unwrap()
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        if (!navigation && token) {
            getNavigationData()
        }
    }, [])

    const findParentIfExist = (parent: any) => {
        const findParent = (navigation && navigation?.length > 0) && navigation?.find((item: any) => item?.path === parent)
        return !navigation || !!findParent
    }

    const findChildIfExist = (parent: any, childPath: any) => {
        const findParent = (navigation && navigation?.length > 0) && navigation?.find((item: any) => item?.path === parent)
        const findChild = (findParent && findParent?.children && findParent?.children?.length > 0) && findParent?.children?.find((item: any) => item?.path === childPath)
        return !navigation || !!findChild
    }

    useEffect(() => {
        if (navigation && token && (!redirectCS || redirectCS === '/login')) {
            const findIfInsightsExists = (navigation && navigation?.length > 0) && navigation?.find((item: any) => item?.path === '/tickets')
            if (!findIfInsightsExists) {
                const getFirstPage = (navigation && navigation?.length > 0) && navigation?.[0]
                setRedirectCS(getFirstPage?.path)
            } else {
                setRedirectCS('/tickets')
            }
        }
        else if (!token && !redirectCS) {
            setRedirectCS('/login')
        }
    }, [navigation, token])

    return (
        <div className='App !font-dm_sansregular'>
            <Routes>
                {routes?.filter((item: any) => token && item?.csCheck ? findParentIfExist(item?.path) : true)?.map((route: any, index: number) => {
                    const children = (route?.csCheck && route?.childrens && route?.childrens?.length > 0 && token && navigation) ? route?.childrens?.filter((item: any) => item?.path === '' || findChildIfExist(route?.path, item?.path)) : route?.childrens
                    return (
                        children ? (
                            <React.Fragment key={index}>
                                {route.index && <Route
                                    path={route.path}
                                    element={<Navigate to={route.index} replace />}
                                />}
                                <Route path={route.path} element={<RouteWrapper guard={route.guard} element={route.element} />}>
                                    {children?.map((r: any, i: number) => (
                                        <Route key={i} path={r.path} element={<RouteWrapper guard={r.guard || route.guard} element={r.element} />} />
                                    ))}
                                </Route>
                            </React.Fragment>
                        ) : (
                            <Route
                                key={index}
                                path={route.path}
                                element={<RouteWrapper guard={route.guard} element={route.element} />}
                            />
                        )
                    )
                }
                )}
                <Route path='*' element={<Navigate replace to={token ? redirectCS || redirectNotExistingRoutes || '/login' : '/login'} />} />
            </Routes>
        </div>
    );
}

// export default App;
export default Sentry.withProfiler(App);