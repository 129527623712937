import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { tryToEditSellerInfo } from '../../../../store/customer-support/orders/ordersSlice';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';
import Button from '../../../../shared/button';
import Loading from '../../../../shared/Loading';


interface IOrderTemplateModal {
    openModal: boolean;
    itemId?: string | null;
    seller?: any;
    handleCloseModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

type FormValues = {
    state?: string;
    street: string;
    addressNo: string;
    city: string;
    postcode: string;
};

const OrderTemplateModal = ({
    openModal,
    itemId,
    seller,
    handleCloseModal,
    onSaveChanges,
}: IOrderTemplateModal) => {
    const dispatch = useAppDispatch();
    const sellerLoading = useAppSelector((state) => state?.orders?.orderIsLoading);
    const [generalError, setGeneralError] = useState<string>();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<FormValues>({});

    useEffect(() => {
        if (seller) {
            setValue('state', itemId ? seller?.state || undefined : undefined)
            setValue('street', itemId ? seller?.street || '' : '')
            setValue('addressNo', itemId ? seller?.addressNo || '' : '')
            setValue('city', itemId ? seller?.city || '' : '')
            setValue('postcode', itemId ? seller?.postcode || '' : '')
        }
    }, [seller, itemId]);

    const onCloseModal = () => {
        setGeneralError(undefined)
        reset()
        handleCloseModal()
    }

    const onSubmit = async (data: FormValues) => {
        const formatData: any = {
            itemId: itemId,
            state: data?.state || undefined,
            street: data?.street,
            addressNo: data?.addressNo,
            city: data?.city,
            postcode: data?.postcode,
        }
        try {
            if (itemId) {
                const payload = {
                    id: itemId || '',
                    data: formatData
                }
                await dispatch(tryToEditSellerInfo(payload)).unwrap();
            }
            onSaveChanges('success', 'Seller info successfully edited')
            onCloseModal()
            reset();
        } catch (error) {
            setGeneralError(`${error}`)
        }
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mt-2 mb-4'>Update Seller Address</p>
                {generalError && <Error text={generalError} />}
                <form onSubmit={handleSubmit(onSubmit)}>
                    {((itemId
                        && !sellerLoading
                    ) || !itemId) ?
                        <div className='flex flex-col my-4'>
                            <Input
                                placeholder='State'
                                label={itemId ? 'State' : ''}
                                register={register('state', {
                                    // required: {
                                    //     message: 'State is required',
                                    //     value: true,
                                    // },
                                })}
                                error={errors.state?.message}
                                dataQa={'state'}
                            />
                            <Input
                                placeholder='Street'
                                label={itemId ? 'Street' : ''}
                                register={register('street', {
                                    required: {
                                        message: 'Street is required',
                                        value: true,
                                    },
                                })}
                                error={errors.street?.message}
                                dataQa={'street'}
                            />
                            <Input
                                placeholder='Address No.'
                                label={itemId ? 'Address' : ''}
                                register={register('addressNo', {
                                    required: {
                                        message: 'Address is required',
                                        value: true,
                                    },
                                })}
                                error={errors.addressNo?.message}
                                dataQa={'address-no'}
                            />
                            <Input
                                placeholder='City'
                                label={itemId ? 'City' : ''}
                                register={register('city', {
                                    required: {
                                        message: 'City is required',
                                        value: true,
                                    },
                                })}
                                error={errors.city?.message}
                                dataQa={'city'}
                            />
                            <Input
                                placeholder='Postcode'
                                label={itemId ? 'Postcode' : ''}
                                register={register('postcode', {
                                    required: {
                                        message: 'Postcode is required',
                                        value: true,
                                    },
                                })}
                                error={errors.postcode?.message}
                                dataQa={'postcode'}
                            />
                            <div className='flex flex-row justify-end mt-4'>
                                <Button
                                    label='Save'
                                    dataQa={'submit'}
                                    type={'submit'}
                                    className={'btn-primary'}
                                />
                            </div>
                        </div>
                        : <div className='my-3 flex flex-row justify-center items-center'>
                            <Loading />
                        </div>
                    }
                </form>
            </div>
        </Modal>
    )
}
export default OrderTemplateModal;