export const onFormatOrderStatus = (status: string, selectedFilter?: any, isRecentChanges?: any) => {
    switch (status) {
        case 'device_inbound':
            return { name: 'Inbound', color: 'bg-[#ebedef]', text: 'text-[#63F6FF]', borderColor: 'border-[#63F6FF]' };
        case 'not_shipped':
            return { name: 'Not Shipped', color: 'bg-[#ebedef]', text: 'text-[#FC5555]', borderColor: 'border-[#FC5555]' };
        case 'shipped':
            return { name: 'Shipped', color: 'bg-[#ebedef]', text: 'text-[#7B61FF]', borderColor: 'border-[#7B61FF]' };
        case 'device_in_review':
            return { name: 'In Review', color: 'bg-[#ebedef]', text: 'text-[#1389E6]', borderColor: 'border-[#1389E6]' };
        case 'device_accepted':
            return { name: isRecentChanges ? 'Delivered' : 'Accepted', color: 'bg-[#ebedef]', text: 'text-[#00B67A]', borderColor: 'border-[#00B67A]' };
        case 'device_rejected':
            return { name: 'Rejected', color: 'bg-[#ebedef]', text: 'text-[#E50000]', borderColor: 'border-[#E50000]' };
        case 'canceled':
            return { name: 'Canceled', color: 'bg-[#ebedef]', text: 'text-[#E50000]', borderColor: 'border-[#E50000]' };
        case 'awaiting_user_response':
            return { name: isRecentChanges ? 'Revaluation Awaiting Response' : 'Awaiting User Response', color: 'bg-[#ebedef]', text: 'text-[#fb6f21]', borderColor: 'border-[#fb6f21]' };
        case 'awaiting_cs_response':
            return { name: 'Awaiting CS Response', color: 'bg-[#ebedef]', text: 'text-[#fb6f21]', borderColor: 'border-[#fb6f21]' };
        case 'user_accepted_offer':
            return { name: isRecentChanges ? 'Price Revaluation Accepted' : 'User Accepted Offer', color: 'bg-[#ebedef]', text: 'text-[#00B67A]', borderColor: 'border-[#00B67A]' };
        case 'user_rejected_offer':
            return { name: 'User Rejected Offer', color: 'bg-[#ebedef]', text: 'text-[#E50000]', borderColor: 'border-[#E50000]' };
        case 'cs_ticket_response':
            return { name: 'CS Ticket Response', color: 'bg-[#ebedef]', text: 'text-[#FB6F21]', borderColor: 'border-[#FB6f21]' };
        case 'required_return':
            return { name: 'Return Required', color: 'bg-[#ebedef]', text: 'text-[#FB6F21]', borderColor: 'border-[#FB6f21]' };
        case 'returned':
            return { name: 'Returned', color: 'bg-[#ebedef]', text: 'text-[#FC5555]', borderColor: 'border-[#FC5555]' };
        case 'cs_required_return':
            return { name: 'CS Required Return', color: 'bg-[#ebedef]', text: 'text-[#FB6F21]', borderColor: 'border-[#FB6f21]' };
        case 'item_missing':
            return { name: 'Missing', color: 'bg-[#ebedef]', text: 'text-[#E50000]', borderColor: 'border-[#E50000]' };
        default:
            return { name: selectedFilter === 'In Review' ? 'In Review' : selectedFilter === 'Inbound' ? 'Inbound' : status, color: 'bg-[#ebedef]', text: 'text-[#1389E6]', borderColor: 'border-[#1389E6]' };
    }
};


export const onFormatPackageStatus = (status: string) => {
    switch (status) {
        case 'canceled':
            return { name: 'Canceled', color: 'bg-[#ebedef]', text: 'text-[#E50000]' }
        case 'failed':
            return { name: 'Failed', color: 'bg-[#FF2F32]', text: 'text-[#ffffff]' }
        case 'declined':
            return { name: 'Declined', text: 'bg-[#FF2F32]', color: 'text-[#ebedef]' }
        case 'shipped':
            return { name: 'Shipped', color: 'bg-[#ebedef]', text: 'text-[#7B61FF]' }
        case 'removed':
            return { name: 'Removed', color: 'bg-[#ebedef]', text: 'text-[#E50000]' }
        case 'delivered':
            return { name: 'Delivered', color: 'bg-[#ebedef]', text: 'text-[#7B61FF]' }
        case 'reviewed':
            return { name: 'Reviewed', color: 'bg-[#ebedef]', text: 'text-[#00B67A]' }
        case 'rejected':
            return { name: 'Rejected', color: 'bg-[#ebedef]', text: 'text-[#E50000]' }
        case 'accepted':
            return { name: 'Accepted', color: 'bg-[#ebedef]', text: 'text-[#00B67A]' }
        case 'not_shipped':
            return { name: 'Not Shipped', color: 'bg-[#ebedef]', text: 'text-[#FC5555]' }
        default:
            return { name: status, color: 'bg-[#FFF964]' }
    }
}

export const onFormatCashoutType = (status: string) => {
    switch (status) {
        case 'pending':
            return { name: 'Pending', color: 'bg-[#ebedef] !text-[#67bccf]' }
        case 'instant':
            return { name: 'Instant', color: 'bg-[#ebedef] !text-[#A7FFF2]' }
        case 'regular':
            return { name: 'Regular', color: 'bg-[#ebedef] !text-[#a7eae0]' }
        default:
            return { name: status, color: 'bg-[#ebedef] !text-[#000]' }
    }
}

export const onFormatCSAction = (status: string) => {
    switch (status) {
        case 'answered':
            return 'bg-transparent outline-none font-dm_sansregular text-center underline text-[#00B67A]'
        case 'outbound_call_made':
            return 'bg-transparent outline-none font-dm_sansregular text-center underline text-[#1389E6]'
        default:
            return 'text-[#000]'
    }
}

export const capitalizeAndFormat = (str: string): string => {
    if (!str) return str;
    return str
        .toLowerCase()
        .replace(/_/g, ' ')
        .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
};

export const onFormatCashoutItemStatus = (status: string) => {
    switch (status) {
        case 'created':
            return { name: 'Created', color: 'border !border-[#e8b233] !text-[#e8b233]' }
        case 'removed':
            return { name: 'Removed', color: 'border !border-[#d41106] !text-[#d41106]' }
        case 'cashed_out':
            return { name: 'Cashed Out', color: 'border !border-[#a7eae0] !text-[#a7eae0]' }
        case 'reviewed':
            return { name: 'Reviewed', color: 'border !border-[#a7eae0] !text-[#a7eae0]' }
        case 'pending_listing':
            return { name: 'Pending Listing', color: 'border !border-[#e8b233] !text-[#e8b233]' }
        case 'declined_cashout':
            return { name: 'Desclined Cashout', color: 'border !border-[#d41106] !text-[#d41106]' }
        default:
            return { name: status, color: 'border !border-[#e8b233] !text-[#e8b233]' }
    }
}

export const onFormatPaymentStatus = (status: string) => {
    switch (status) {
        case 'succeeded':
            return { name: 'Succeeded', color: 'border !border-[#1BD7BB] !text-[#1BD7BB]' }
        case 'pending':
            return { name: 'Pending', color: 'border !border-[#e8b233] !text-[#e8b233]' }
        case 'failed':
            return { name: 'Failed', color: 'border !border-[#d41106] !text-[#d41106]' }

        default:
            return { name: status, color: 'border !border-[#e8b233] !text-[#e8b233]' }
    }
}

export const onFormatTransactionStatus = (status: string) => {
    switch (status) {
        case 'sent':
            return { name: 'Sent', color: 'border !border-[#1BD7BB] !text-[#1BD7BB]' }
        case 'processing':
            return { name: 'Processing', color: 'border !border-[#1BD7BB] !text-[#1BD7BB]' }
        case 'pending':
            return { name: 'Pending', color: 'border !border-[#e8b233] !text-[#e8b233]' }
        case 'cancelled':
            return { name: 'Cancelled', color: 'border !border-[#d41106] !text-[#d41106]' }
        case 'error':
            return { name: 'Error', color: 'border !border-[#d41106] !text-[#d41106]' }
        case 'returned':
            return { name: 'Returned', color: 'border !border-[#d41106] !text-[#d41106]' }
        case 'declined':
            return { name: 'Declined', color: 'border !border-[#d41106] !text-[#d41106]' }
        case 'quarantined':
            return { name: 'Quarantined', color: 'border !border-[#d41106] !text-[#d41106]' }
        default:
            return { name: status, color: 'border !border-[#e8b233] !text-[#e8b233]' }
    }
}
