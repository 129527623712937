import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IFlaggedUserListState } from '../../../interfaces/flagged-users/IFlaggedUsersList';
import { PaginatedFlaggedUsers } from '../../../interfaces/flagged-users/paginatedStore.type';
import { ApiResponse } from '../../../interfaces/models/models/paginated-response.model';
import { FlaggedUserService } from './FlaggedUserApi';


const initialState: IFlaggedUserListState = {
    flaggedUsersAreLoading: false,
    flaggedUserIsLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchAllFlaggedUsers = createAsyncThunk<PaginatedFlaggedUsers, any>(
    'flaggedUsers/tryToFetchAllFlaggedUsers',
    async ({ pageNumber, pageSize, filters }) => {
        const result = await FlaggedUserService.tryToFetchAllFlaggedUsers(
            pageNumber,
            pageSize,
            filters
        );
        return result?.data;
    },
);

export const tryToFetchFlaggedUsersDetails = createAsyncThunk<any, string>(
    'flaggedUsers/tryToFetchFlaggedUsersDetails',
    async (sellerId) => {
        const result = await FlaggedUserService.tryToFetchFlaggedUsersDetails(sellerId);
        return result?.data;
    },
);


export const tryToEnableSeller = createAsyncThunk<ApiResponse<any>, any>(
    'flaggedUsers/tryToEnableSeller',
    async (userId, { rejectWithValue }) => {
        try {
            const result = await FlaggedUserService.tryToEnableSeller(userId);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);


export const tryToDisableCashoutFee = createAsyncThunk<ApiResponse<any>, any>(
    'flaggedUsers/tryToDisableCashoutFee',
    async (userId, { rejectWithValue }) => {
        try {
            const result = await FlaggedUserService.tryToDisableCashoutFee(userId);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const FlaggedUserSlice = createSlice({
    name: 'flaggedUsers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchAllFlaggedUsers
            .addCase(tryToFetchAllFlaggedUsers.pending, (state) => {
                state.flaggedUsersAreLoading = true;
            })
            .addCase(tryToFetchAllFlaggedUsers.rejected, (state) => {
                state.flaggedUsersAreLoading = false;
            })
            .addCase(tryToFetchAllFlaggedUsers.fulfilled, (state, action) => {
                state.flaggedUsersAreLoading = false;
                state.flaggedUsers = action.payload;
            })


            // tryToFetchFlaggedUsersDetails
            .addCase(tryToFetchFlaggedUsersDetails.pending, (state) => {
                state.flaggedUserIsLoading = true;
            })
            .addCase(tryToFetchFlaggedUsersDetails.rejected, (state) => {
                state.flaggedUserIsLoading = false;
            })
            .addCase(tryToFetchFlaggedUsersDetails.fulfilled, (state, action) => {
                state.flaggedUserIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                state.flaggedUserDetail = data
            })

            // tryToEnableSeller
            .addCase(tryToEnableSeller.pending, (state) => {
                state.flaggedUserIsLoading = true;
            })
            .addCase(tryToEnableSeller.rejected, (state) => {
                state.flaggedUserIsLoading = false;
            })
            .addCase(tryToEnableSeller.fulfilled, (state, action) => {
                state.flaggedUserIsLoading = false;
                state.requestStatus = 'success';
                if (state.flaggedUsers?.data.elements) {
                    state.flaggedUsers.data.elements =
                        { ...state }.flaggedUsers?.data.elements.map(
                            (flaggedUser: any) => {
                                if (flaggedUser?._id === action.meta?.arg.userId) {
                                    return {
                                        ...flaggedUser,
                                        enabled: true,
                                    };
                                } else {
                                    return flaggedUser;
                                }
                            },
                        ) || [];
                }
            })

            // tryToDisableCashoutFee
            .addCase(tryToDisableCashoutFee.pending, (state) => {
                state.flaggedUsersAreLoading = true;
            })
            .addCase(tryToDisableCashoutFee.rejected, (state) => {
                state.flaggedUsersAreLoading = false;
            })
            .addCase(tryToDisableCashoutFee.fulfilled, (state, action) => {
                state.flaggedUsersAreLoading = false;
                state.requestStatus = 'success';
                if (state.flaggedUserDetail && state?.flaggedUserDetail?.cashoutInfo) {
                    const formatCashout = state?.flaggedUserDetail?.cashoutInfo?.cashoutFees?.map((item: any) => {
                        return {
                            ...item,
                            active: false
                        }
                    })
                    state.flaggedUserDetail = {
                        ...state?.flaggedUserDetail || {},
                        cashoutInfo: {
                            ...state?.flaggedUserDetail?.cashoutInfo || {},
                            cashoutFees: formatCashout || []
                        }
                    }
                }
            })
    },
});

export default FlaggedUserSlice.reducer;