import React, { useEffect, useLayoutEffect, useState } from 'react'
import {
    useLocation,
    useNavigate,
    useSearchParams
} from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { ORDER_TYPES } from '../../../utils/constants/order-details-actions'
import {
    useGetAllCsUsersMutation,
    useGetAllMarketsMutation
} from '../../../store/user/userApi'
import {
    tryToFetchAllAutoAcceptedRevaluations,
    tryToFetchAllAwaitingUserResponse,
    tryToFetchAllClosedCases,
    tryToFetchAllRejectedFromBroker,
    tryToFetchAllRejectedRevaluations,
    tryToFetchAllRevaluedFromBroker
} from '../../../store/customer-support/orders/ordersSlice'
import { tryToFetchCasesCount } from '../../../store/customer-support/partners/partnersSlice'
import { tryToFetchAllAssetsTemplates } from '../../../store/customer-support/asset-templates/assetsTemplatesSlice';
import { IAssetTemplate } from '../../../interfaces/asset-templates/IAssetTemplate';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { IOrderFilterBody } from '../../../interfaces/orders/IOrderFilterBody';
import { IAllMarkets } from '../../../interfaces/markets/IAllMarkets';
import { IToast } from '../../../interfaces/components/IToast';
import { IMarket } from '../../../interfaces/markets/IMarket';
import { IOrders } from '../../../interfaces/orders/IOrders';
import MarkAsContactedModal from './modals/MarkAsContactedModal';
import MarkAsCompletedModal from './modals/MarkAsCompletedModal';
import AssignCsUserModal from './modals/AssignCsUserModal';
import LeaveANoteModal from './modals/LeaveANoteModal';
import AutoAcceptedRevaluationsTab from './tabs/AutoAcceptedRevaluationsTab';
import AwaitingUserResponseTab from './tabs/AwaitingUserResponseTab';
import RevaluedFromBrokerTab from './tabs/RevaluedFromBrokerTab';
import RejectedFromBrokerTab from './tabs/RejectedFromBrokerTab';
import RejectedFromUserTab from './tabs/RejectedFromUserTab';
import ClosedTab from './tabs/ClosedTab';
import Toast from '../../../shared/toast';


const casesTabs = [
    { name: 'Revalued from Broker', element: RevaluedFromBrokerTab },
    { name: 'Rejected from Broker', element: RejectedFromBrokerTab },
    { name: 'Awaiting User Response', element: AwaitingUserResponseTab },
    { name: 'Rejected from User', element: RejectedFromUserTab },
    { name: 'Auto Accepted Revaluations', element: AutoAcceptedRevaluationsTab },
    { name: 'Closed', element: ClosedTab },
]

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const OpenCases = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const query = useQuery();
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState<any>(casesTabs[0])
    const [pageAccess, setPageAccess] = useState<any>()
    const accessControl = useAppSelector((state) => state?.partners?.navigation);
    const cases = useAppSelector(state => state?.partners?.cases)
    const [markets, setMarkets] = useState<IAllMarkets[]>();
    const [sectors, setSectors] = useState<IAssetTemplate[]>();
    const [selectedMarket, setSelectedMarket] = useState<string>();
    const [selectedSector, setSelectedSector] = useState<string>();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
        filters: undefined,
    });
    const [rows, setRows] = useState<Array<IOrders>>([]);
    const [assignees, setAssignees] = useState<any[]>();
    const [searchValue, setSearchValue] = useState<any>()
    const [showToast, setShowToast] = useState<IToast | null>();
    const [selectedAssignee, setSelectedAssignee] = useState<string>()
    const [selectedOrderType, setSelectedOrderType] = useState<string>()
    const [showAssignCsModal, setShowAssignCSModal] = useState<{ show: boolean, id: string, itemId: string, current: any }>()
    const [showLeaveANoteModal, setShowLeaveANoteModal] = useState<{ show: boolean, id: string, itemId: string }>()
    const [showMarkAsContactedModal, setShowMarkAsContactedModal] = useState<{ show: boolean, id: string, itemId: string }>()
    const [showMarkAsCompletedModal, setShowMarkAsCompletedModal] = useState<{ show: boolean, id: string, itemId: string }>()
    const paginationData = useAppSelector((state) => state?.orders?.orders?.data?.page);
    const state = useAppSelector((state) => state.orders);
    const rowsLoading = useAppSelector((state) => state?.orders?.ordersAreLoading);
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const [getAllMarkets] = useGetAllMarketsMutation();
    const [getAllCsUsers] = useGetAllCsUsersMutation();

    const getCountData = async () => {
        await dispatch(tryToFetchCasesCount()).unwrap()
    }

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearchValue(value)
        setPaginationState({
            ...(paginationState || {}),
            pageNumber: 1,
            filters: (value && encodeURIComponent(value)) || undefined,
        });
        if (value) searchParams.set('search', value);
        else searchParams.delete('search');
        searchParams.set('page', '1');
        setSearchParams(searchParams);
    };

    useEffect(() => {
        getCountData()
    }, [location])

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Open Cases')
        console.log(findPage)
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Open Cases')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const getFiltersData = async () => {
        const marketResponse = await getAllMarkets(null).unwrap();
        const formatMarkets =
            marketResponse &&
            marketResponse?.length > 0 &&
            marketResponse?.map((market: IMarket) => ({
                ...market,
                name: market?.label,
                displayName: market?.name,
            }));
        setMarkets([{ displayName: 'All Markets', name: 'All Markets', label: 'All Markets' }, ...formatMarkets || []]);
        setSelectedMarket(query?.get('market') || 'All Markets')
        const csUsersResponse = await getAllCsUsers(null).unwrap();
        const formatCsUsers = csUsersResponse && csUsersResponse.data?.length > 0 && csUsersResponse.data.map((user: any) => ({ ...user, displayName: user.name, name: user.name, label: user.name, value: user._id }))
        setAssignees([{ displayName: 'All Cases', name: 'All Cases', label: 'All Cases' }, { displayName: 'Unassigned', name: 'Unassigned', label: 'Unassigned', value: null }, ...formatCsUsers])
        setSelectedAssignee(query?.get('assignee') || 'All Cases')
        setSelectedOrderType(query?.get('orderType') || 'All Order Types')
        setSearchValue(query.get('search'))
        setPaginationState({
            ...paginationState,
            pageNumber: Number(query.get('page') || 1),
            filters: undefined,
        });
    };

    const getCasesData = async () => {
        const findCategory =
            sectors &&
            sectors?.length > 0 &&
            sectors?.find((category: any) => category?.name === selectedSector); if (!selectedSector || !sectors || !findCategory || !selectedMarket) {
                return;
            }
        const data: IOrderFilterBody = {};
        if (selectedAssignee && selectedAssignee !== 'All Cases' && assignees) {
            const findAssignee = assignees.find(assignee => assignee.name === selectedAssignee)
            data.assignedCs = findAssignee?.value
        }
        const findOrderType = ORDER_TYPES.find(orderType => orderType.name === selectedOrderType)

        if (findOrderType?.value !== undefined) {
            data.isMultiple = findOrderType.value
        }
        if (selectedMarket && markets) {
            const findMarket: any =
                markets &&
                markets.length > 0 &&
                markets?.find(
                    (market: IMarket) => market?.name === selectedMarket,
                );
            if (findMarket?.displayName !== 'All Markets') {
                data.country = findMarket?.displayName || undefined;
                data.marketId = findMarket?._id;
            }
        } else {
            return;
        }
        if (selectedSector && selectedSector !== 'All Categories') {
            data.category = {
                id: findCategory?._id || '',
                name: selectedSector,
            };
        }
        const resolvedData = { ...paginationState, data };
        searchParams.set('page', `${paginationState?.pageNumber}`);
        searchParams.set('size', `${paginationState?.pageSize}`);
        setSearchParams(searchParams);
        switch (activeTab.name) {
            case 'Revalued from Broker':
                dispatch(tryToFetchAllRevaluedFromBroker(resolvedData)).unwrap()
                break;
            case 'Rejected from Broker':
                dispatch(tryToFetchAllRejectedFromBroker(resolvedData)).unwrap()
                break;
            case 'Awaiting User Response':
                dispatch(tryToFetchAllAwaitingUserResponse(resolvedData)).unwrap()
                break;
            case 'Rejected from User':
                dispatch(tryToFetchAllRejectedRevaluations(resolvedData)).unwrap()
                break;
            case 'Auto Accepted Revaluations':
                dispatch(tryToFetchAllAutoAcceptedRevaluations(resolvedData)).unwrap()
                break;
            case 'Closed':
                dispatch(tryToFetchAllClosedCases(resolvedData)).unwrap()
                break;
            default:
                break;
        }
    };

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
            filters: paginationState?.filters || undefined,
        });
        searchParams.set('page', '1');
        searchParams.set('size', `${paginationState?.pageSize || 10}`);
        setSearchParams(searchParams);
    };

    const onChangeActiveTab = (item: any) => {
        setActiveTab(item)
        searchParams.set('type', item?.name)
        setSearchParams(searchParams)
        onResetPaginationSettings()
    }

    const onViewCaseDetails = (id: string, itemId: string, country: string) => {
        navigate(`/tickets/${id}/${itemId}/country/${country}`);
    }

    const onAssign = (id: string, itemId: string, country: string, current: any) => {
        setShowAssignCSModal({ show: true, id, itemId, current })
    }

    const onWriteANote = (id: string, itemId: string, country: string) => {
        setShowLeaveANoteModal({ show: true, id, itemId })
    }

    const onMarkAsCompleted = (id: string, itemId: string, country: string) => {
        setShowMarkAsCompletedModal({ show: true, id, itemId })
    }

    const onMarkAsContacted = (id: string, itemId: string, country: string) => {
        setShowMarkAsContactedModal({ show: true, id, itemId })
    }

    const onClickAction = (data: { actionName: string, id: string, itemId: string, country: string, current: any }) => {
        const { actionName, id, itemId, country, current } = data
        switch (actionName) {
            case 'View':
                onViewCaseDetails(id, itemId, country)
                break;
            case 'Assign':
                onAssign(id, itemId, country, current)
                break;
            case 'Mark as contacted':
                onMarkAsContacted(id, itemId, country)
                break;
            case 'Mark as completed':
                onMarkAsCompleted(id, itemId, country)
                break;
            case 'Write a note':
                onWriteANote(id, itemId, country)
                break;
            default:
                break;
        }
    }

    const onSuccessfullyAssignedCSUser = () => {
        setShowToast({ type: 'success', message: 'Case successfully assigned to user!' })
        setShowAssignCSModal(undefined)
    }

    function onSuccessfullyAddedANote(): void {
        setShowToast({ type: 'success', message: 'Note successfully added!' })
    }

    function onSuccessfullyMarkedAsContacted(): void {
        setShowToast({ type: 'success', message: 'Case successfully marked as contacted!' })
        setShowMarkAsContactedModal(undefined)
        getCasesData();
    }

    function onSuccessfullyMarkedAsCompleted(): void {
        setShowToast({ type: 'success', message: 'Case successfully marked as resolved!' })
        setShowMarkAsCompletedModal(undefined)
        getCasesData();
    }

    const onSelectFilterOption = (value: any, name?: string) => {
        if (name) {
            switch (name) {
                case 'market':
                    setSelectedMarket(value?.name);
                    searchParams.set('market', value?.name);
                    break;
                case 'category':
                    setSelectedSector(value?.name);
                    searchParams.set('category', value?.name);
                    break;
                case 'assignee':
                    setSelectedAssignee(value?.name);
                    searchParams.set('assignee', value?.name);
                    break;
                case 'orderType':
                    setSelectedOrderType(value?.name);
                    searchParams.set('orderType', value?.name);
                    break;
            }
            setSearchParams(searchParams);
            onResetPaginationSettings();
        }
    };

    const clearAllFilters = () => {
        setPaginationState({
            ...paginationState,
            pageNumber: 1,
            filters: undefined,
        });
        setSearchValue(undefined)
        setSelectedAssignee('All Cases')
        setSelectedMarket('All Markets')
        setSelectedSector('All Categories')
        setSelectedOrderType('All Order Types')
        searchParams.delete('search');
        searchParams.delete('assignee');
        searchParams.delete('market');
        searchParams.delete('category');
        searchParams.delete('orderType');
        searchParams.set('page', '1');
        setSearchParams(searchParams);
    };

    useEffect(() => {
        getFiltersData();
        dispatch(tryToFetchAllAssetsTemplates());
    }, []);

    useEffect(() => {
        if (state.orders) {
            const rowsData =
                state.orders?.data?.elements &&
                    Array.isArray(state.orders?.data.elements)
                    ? state.orders?.data.elements.map((r) => ({
                        ...r,
                        id: r.id,
                    }))
                    : [];
            if (JSON.stringify(rows) !== JSON.stringify(rowsData)) {
                setRows(rowsData);
            }
        }
    }, [state.orders]);

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows = stateAssets.allAssetTemplates &&
                Array.isArray(stateAssets.allAssetTemplates)
                ? stateAssets.allAssetTemplates.map((r) => ({
                    ...(r || {}),
                    name: r?.name,
                    label: r?.displayName,
                    value: r?._id,
                }))
                : [];
            const formatAssets = rows?.length > 0
                ? rows?.filter(
                    (r) =>
                        (r?.name || '').toLowerCase() !== 'currencies',
                )
                : [];
            setSectors([{ name: 'All Categories', label: 'All Categories' }, ...formatAssets]);
            if (!selectedSector) {
                setSelectedSector(query?.get('category') || 'All Categories');
            }
            onResetPaginationSettings();
        }
    }, [stateAssets.allAssetTemplates]);

    useLayoutEffect(() => {
        if (selectedMarket && selectedSector && selectedAssignee && selectedOrderType && paginationState) {
            getCasesData();
        }
    }, [selectedMarket, selectedSector, selectedAssignee, selectedOrderType, paginationState, activeTab]);

    useLayoutEffect(() => {
        if (query?.get('type') && query?.get('type') !== activeTab?.name) {
            const findActiveTab = casesTabs.find((item) => item?.name === query?.get('type'))
            setActiveTab(findActiveTab || undefined)
        }
        if (query?.get('page') && Number(query?.get('page')) !== paginationState.pageNumber) {
            setPaginationState((previous) => ({ ...previous, pageNumber: Number(query.get('page')) }))
        }
    }, [query?.get('type'), query?.get('page')])

    return (
        <div className='w-full'>
            <p className='page-title mb-10'>Orders</p>
            <div className='flex flex-row flex-wrap  items-center my-5'>
                {casesTabs.filter((item: any) => (!accessControl || pageAccess?.actions?.[item?.name]))?.map((tab: { name: string, element: any }, index) => {
                    return (
                        <div
                            key={index}
                            className={`btn-categories relative whitespace-nowrap mr-3 mb-3  cursor-pointer text-center ${activeTab.name === tab?.name && 'bg-gradient-to-r from-[#FCEE21] to-[#F7931E] border-none'}`}
                            onClick={() => onChangeActiveTab(tab)}>
                            <p data-qa={tab?.name} className={activeTab.name === tab?.name ? 'text-[#202020]' : 'text-[#484A4B]'}>{tab?.name}</p>
                            {['Revalued from Broker', 'Rejected from Broker', 'Rejected from User'].includes(tab.name) && <div className='bg-[#E50000] absolute -right-2 -top-3 w-7 h-7 text-center rounded-full border-white border-2 text-xs font-primary font-normal text-white flex justify-center items-center'><span>{tab.name === 'Revalued from Broker' && cases.revaluatedByBroker}{tab.name === 'Rejected from Broker' && cases.rejectedByBroker}{tab.name === 'Rejected from User' && cases.rejectedByUser}</span></div>}
                        </div>
                    )
                })}
            </div>
            <div className='my-2'>
                {(!accessControl || pageAccess?.actions?.[activeTab?.name]) ? React.createElement(activeTab.element, { sectors, markets, selectedMarket, selectedSector, onSelectFilterOption, assignees, selectedAssignee, orderTypes: ORDER_TYPES, selectedOrderType, rows, rowsLoading, paginationData, setPaginationState, onClickAction, accessControl, searchValue, onChangeSearchValue, clearAllFilters, pageAccess }) : <></>}
            </div>
            {showAssignCsModal?.show &&
                <AssignCsUserModal
                    openModal={showAssignCsModal?.show}
                    users={assignees?.slice(2) || []}
                    id={showAssignCsModal.id}
                    itemId={showAssignCsModal.itemId}
                    onSuccessfullySaved={onSuccessfullyAssignedCSUser}
                    handleCloseModal={() => setShowAssignCSModal(undefined)}
                    itemStatus={activeTab?.name}
                    currentUser={showAssignCsModal.current} />
            }
            {showLeaveANoteModal?.show &&
                <LeaveANoteModal
                    openModal={showLeaveANoteModal.show}
                    id={showLeaveANoteModal.id}
                    itemId={showLeaveANoteModal.itemId}
                    onSuccessfullySaved={onSuccessfullyAddedANote}
                    handleCloseModal={() => setShowLeaveANoteModal(undefined)} />
            }
            {showMarkAsContactedModal?.show &&
                <MarkAsContactedModal
                    openModal={showMarkAsContactedModal.show}
                    id={showMarkAsContactedModal.id}
                    itemId={showMarkAsContactedModal.itemId}
                    onSuccessfullySaved={onSuccessfullyMarkedAsContacted}
                    onSuccessfullyAddedNote={onSuccessfullyAddedANote}
                    handleCloseModal={() => setShowMarkAsContactedModal(undefined)} />
            }
            {showMarkAsCompletedModal?.show &&
                <MarkAsCompletedModal
                    openModal={showMarkAsCompletedModal.show}
                    id={showMarkAsCompletedModal.id}
                    itemId={showMarkAsCompletedModal.itemId}
                    onSuccessfullySaved={onSuccessfullyMarkedAsCompleted}
                    onSuccessfullyAddedNote={onSuccessfullyAddedANote}
                    handleCloseModal={() => setShowMarkAsCompletedModal(undefined)}
                    activeTab={activeTab} />
            }
            {showToast?.message && (
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            )}
        </div>
    )
}

export default OpenCases