import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { tryToRaiseATicket } from '../../../../store/customer-support/customer-care/customerCareSlice';
import { tryToFetchAllPartners } from '../../../../store/customer-support/partners/partnersSlice';
import { useAppDispatch } from '../../../../app/hooks';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Textarea from '../../../../shared/textarea';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';
import Input from '../../../../shared/input';


interface IRaiseTicketModal {
    openModal: boolean;
    handleCloseModal: () => void;
    onSuccessfullyRaised: (type: string, message: string) => void;
}

type FormValues = {
    trackingNumber: string;
    itemId: string;
    subject: string;
    description: string;
    brokerUsername: string;
};

const RaiseTicketModal = ({
    openModal,
    handleCloseModal,
    onSuccessfullyRaised
}: IRaiseTicketModal) => {
    const dispatch = useAppDispatch();
    const [saveError, setSaveError] = useState<string>();
    const [brokers, setBrokers] = useState<any>();
    const [brokerSelected, setBrokerSelected] = useState<any>();
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        watch
    } = useForm<FormValues>({});

    const getPartnersData = async () => {
        const partnerResponse: any = await dispatch(tryToFetchAllPartners(null)).unwrap()
        const formatPartners = partnerResponse?.data && partnerResponse?.data?.length > 0 && partnerResponse?.data?.map((partner: any) => ({ ...partner, label: partner?.username, name: partner?.username, }))
        setBrokers(formatPartners || [])
    }

    useEffect(() => {
        getPartnersData()
    }, [])

    const onCloseModal = () => {
        handleCloseModal();
        setSaveError(undefined)
        setLoadingSubmit(false)
        reset();
    }

    const onSubmit = async (data: FormValues) => {
        if (loadingSubmit) {
            return;
        }
        setLoadingSubmit(true)
        try {
            await dispatch(tryToRaiseATicket(data)).unwrap()
            onSuccessfullyRaised('success', 'Ticket Raised successfully.')
            setSaveError(undefined)
            reset();
            onCloseModal()
        } catch (err) {
            setSaveError(`${err}`)
            setLoadingSubmit(false)
        }
    }

    const onChangeSelectedOption = (e: any, type?: string) => {
        if (type) {
            switch (type) {
                case 'broker':
                    setBrokerSelected(e)
                    setValue('brokerUsername', e?.label)
                    break;
            }
        }
    }

    const onChangeValue = (e: any) => {
        setValue('description', e?.target?.value)
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mb-4 font-semibold text-sm'>Raise A Ticket</p>
                {saveError && <Error text={saveError} />}
                <form className='mt-5' onSubmit={handleSubmit(onSubmit)}>
                    <SelectCheckbox
                        selectedOption={brokerSelected}
                        key={1}
                        placeholder=' '
                        name='Broker'
                        dataQa={'broker'}
                        uniqueName='broker'
                        onChangeSelectedOption={onChangeSelectedOption}
                        options={brokers || []}
                        error={errors?.brokerUsername?.message}
                    />
                    <Input
                        placeholder=' '
                        label={'Tracking Number'}
                        dataQa={'tracking-number'}
                        register={register('trackingNumber', {
                            required: {
                                message: 'Tracking Number is required',
                                value: true,
                            },
                            validate: (value: string) => !!value.trim() || 'Tracking Number is required'
                        })}
                        error={errors.trackingNumber?.message}
                    />
                    <Input
                        placeholder=' '
                        label={'Item ID'}
                        dataQa={'item-id'}
                        register={register('itemId', {
                            required: {
                                message: 'Item ID is required',
                                value: true,
                            },
                            validate: (value: string) => !!value.trim() || 'Item ID is required'
                        })}
                        error={errors.itemId?.message}
                    />
                    <Input
                        label='Subject'
                        placeholder=' '
                        dataQa={'subject'}
                        register={register('subject', {
                            required: {
                                message: 'Subject is required',
                                value: true,
                            },
                            validate: (value: string) => !!value.trim() || 'Subject is required'
                        })}
                        error={errors.subject?.message}
                    />
                    <Textarea
                        placeholder='Query'
                        dataQa={'query'}
                        rows={4}
                        value={watch('description')}
                        onChangeTextarea={onChangeValue}
                        error={errors.description?.message}
                    />
                    <div className='min-w-full flex flex-row justify-center'>
                        <Button
                            label={'Submit'}
                            dataQa={'submit'}
                            className={`${loadingSubmit ? 'btn-primary-rounded-less-disable' : 'btn-primary-rounded-less'} my-3 min-w-[130px] !shadow-none`}
                            type='submit'
                        />
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default RaiseTicketModal;