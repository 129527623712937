import React from 'react';
import Button from '../button';
import Modal from '../modal';

interface ICloseTicketModal {
    openCloseTicketModal: boolean;
    loadingClose?: boolean;
    handleCloseTicketModal: () => void;
    onApproveClosing: () => void;
}

const CloseTicketModal = ({
    openCloseTicketModal,
    loadingClose,
    handleCloseTicketModal,
    onApproveClosing
}: ICloseTicketModal) => {
    return (
        <Modal
            open={openCloseTicketModal}
            onClose={handleCloseTicketModal}>
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Close Ticket</p>
                </div>
                <p className='my-2'>Are you sure you want to close this ticket?</p>
                <div className='flex flex-row justify-end my-2'>
                    <Button
                        label='Continue'
                        dataQa={'continue-btn'}
                        className='btn-error'
                        onClickButton={() => !loadingClose && onApproveClosing()}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default CloseTicketModal;