import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToAssignCSUser } from '../../../../store/customer-support/orders/ordersSlice';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';


interface IAssignCsUserModal {
    openModal: boolean;
    id: string;
    itemId: string;
    users?: any;
    itemStatus: string,
    handleCloseModal: () => void;
    onSuccessfullySaved: (body: any) => void;
    currentUser: any
}

const AssignCsUserModal = ({
    openModal,
    id,
    itemId,
    users,
    itemStatus,
    handleCloseModal,
    onSuccessfullySaved,
    currentUser
}: IAssignCsUserModal) => {
    const dispatch = useAppDispatch()
    const [selectedCSUser, setSelectedCSUser] = useState<any>()
    const [submitError, setSubmitError] = useState<string>()
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

    const onChangeSelectedRole = (e: any) => {
        setSelectedCSUser(e)
    }

    const onCloseModal = () => {
        handleCloseModal();
        setSelectedCSUser(undefined)
        setSubmitError(undefined)
        setLoadingSubmit(false)
    }

    const onSubmit = async (type?: string) => {
        setLoadingSubmit(true)
        try {
            const body = {
                orderId: id,
                itemId: itemId,
                username: selectedCSUser.username,
                itemStatus: itemStatus.toLowerCase() || ''
            }
            await dispatch(tryToAssignCSUser(body)).unwrap()
            onSuccessfullySaved(body)
            onCloseModal()
        } catch (err) {
            setSubmitError(`${err}`)
        }
        setLoadingSubmit(false)
    }

    useEffect(() => {
        if (users.length > 0)
            if (currentUser) {
                setSelectedCSUser(users.find((user: any) => user.username === currentUser.username))
            } else {
                setSelectedCSUser(users[0])
            }
    }, [users])

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            dataQa='assign-cs-user'
            showInRight>
            <div className={'p-2 min-w-[450px]'}>
                <p className='mb-2 font-semibold text-sm'>Assign CS User</p>
                <p className='mb-4 text-xl font-normal'>Select user you want to assign to this <span className='font-dm_sansbold'>{itemStatus}</span> order.</p>
                {submitError && <Error text={submitError} />}
                <div className='flex flex-col items-center'>
                    <SelectCheckbox
                        name='CS Users'
                        dataQa={'cs-users'}
                        placeholder=' '
                        containerStyle='mb-8 min-w-full'
                        selectedOption={selectedCSUser}
                        onChangeSelectedOption={onChangeSelectedRole}
                        options={users || []}
                    />
                    <div className='flex flex-rows justify-around items-center mt-32'>
                        <Button
                            className={`${loadingSubmit ? 'btn-primary-rounded-less-disable' : 'btn-primary-rounded-less'} !shadow-none min-w-[200px]`}
                            label='Assign'
                            dataQa={'assign-submit-btn'}
                            onClickButton={onSubmit}
                        />
                    </div>
                </div>
            </div>
        </Modal >
    )
}
export default AssignCsUserModal;