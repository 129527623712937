import React from 'react';
import moment from 'moment';
import {
    ITicket,
    ITicketThread,
} from '../../../../interfaces/customer-care/ITicket';
import TableActions from './TableActions';
import Button from '../../../../shared/button';
import Textarea from '../../../../shared/textarea';
import WhiteContainer from '../../../../shared/white-container';


interface IContactHistory {
    history?: ITicket;
    threadReply?: string;
    loading?: boolean;
    onAddNewThread: () => void;
    onChangeValueReplyThread: (e: any) => void;
    onDeleteMyMessage: (id: string, threadId: string) => void;
}

const ContactHistory = ({
    history,
    threadReply,
    loading,
    onAddNewThread,
    onChangeValueReplyThread,
    onDeleteMyMessage,
}: IContactHistory) => {
    return (
        <WhiteContainer>
            <div className='p-4'>
                <p className='font-bold text-md'>CS History</p>
                {history ?
                    <>
                        {history?.ticket?.threads && history?.ticket?.threads?.length > 0 && (
                            <div className='flex flex-col'>
                                {history?.ticket?.threads?.map(
                                    (ticket: ITicketThread, idx: number) => {
                                        const isEdited = ticket?.updatedAt && ticket?.updatedAt !== ticket?.createdAt
                                        return (
                                            <div key={idx} className='my-1'>
                                                <div
                                                    className={`w-full flex flex-row ${ticket?.type === 'cs' ? 'justify-between' : 'justify-between'}`}
                                                >
                                                    <p className='text-[#8c8c90] font-bold text-[13px] mb-1'>
                                                        {ticket?.type === 'cs' ? `Response - ${ticket?.user}` : `Query - ${ticket?.user}`}
                                                    </p>
                                                    <div className='flex flex-row'>
                                                        <div className={`flex flex-row ${ticket?.type === 'cs' ? 'text-[#8c8c90]' : 'text-[#8c8c90]'} justify-end mt-1 mr-1`}>
                                                            <p className='text-[10px]'>{isEdited && 'Edited - '}{moment(ticket?.updatedAt || '').fromNow()}</p>
                                                        </div>
                                                        {ticket?.type === 'cs' &&
                                                            <TableActions
                                                                onClickDeleteMessage={() => onDeleteMyMessage(history?.ticket._id || '', ticket?.id || '')}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                                <div
                                                    className={`${ticket?.type === 'cs' ? 'speech-bubble-user text-[#fff]' : 'speech-bubble-admin'} p-2`}
                                                >
                                                    {ticket?.content}
                                                </div>
                                            </div>
                                        );
                                    },
                                )}
                            </div>
                        )}
                    </>
                    : <div className='my-4'>
                        <p>No History to show</p>
                    </div>
                }
                {history && (
                    <div className='mt-4'>
                        <hr className='mb-2' />
                        <Textarea
                            placeholder='Reply'
                            dataQa={'reply'}
                            containerStyle='!mb-0'
                            rows={2}
                            value={threadReply}
                            onChangeTextarea={onChangeValueReplyThread}
                        />
                        <div className='flex flex-row justify-end'>
                            <Button
                                label={'Send'}
                                dataQa={'send'}
                                className={`!shadow-none ${loading ? 'btn-primary-rounded-less-disable' : 'btn-primary-rounded-less'} !mt-0 min-w-[150px]`}
                                onClickButton={() => !loading && onAddNewThread()}
                            />
                        </div>
                    </div>
                )}
            </div>
        </WhiteContainer>
    );
};
export default ContactHistory;
