import React from 'react';
import Modal from '../../../../shared/modal';
import AddNoteForm from '../details/AddNoteForm';

interface ILeaveANoteModal {
    openModal: boolean;
    id: string;
    itemId: string;
    handleCloseModal: () => void;
    onSuccessfullySaved: (body: any) => void;
}

const LeaveANoteModal = ({
    openModal,
    id,
    itemId,
    handleCloseModal,
    onSuccessfullySaved,
}: ILeaveANoteModal) => {

    const onCloseModal = () => {
        handleCloseModal();
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            dataQa='add-a-note'
            showInRight>
            <div className={'p-2 min-w-[450px]'}>
                <p className='mb-2 font-semibold text-sm'>Order Actions</p>
                <p className='mb-4 text-xl font-bold'>Leave a note for wingenius team on this order.</p>
                <AddNoteForm onSuccessfullySaved={onSuccessfullySaved} onCloseModal={onCloseModal}
                    id={id} itemId={itemId} />
            </div>
        </Modal>
    )
}
export default LeaveANoteModal;