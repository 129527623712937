import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DisputeHistoryService } from './stripeDisputeHistoryApi';
import { IDisputesListState } from '../../../interfaces/dispute-history/IDisputesList';
import { PaginatedDisputesHistory } from '../../../interfaces/dispute-history/paginatedStore.type';

const initialState: IDisputesListState = {
    disputesHistoryAreLoading: false,
    disputeHistoryIsLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchDisputeHistory = createAsyncThunk<PaginatedDisputesHistory, any>(
    'disputeHistory/tryToFetchDisputeHistory',
    async ({ pageNumber, pageSize, filters }) => {
        const result = await DisputeHistoryService.tryToFetchDisputeHistory(
            pageNumber,
            pageSize,
            filters,
        );
        return result?.data;
    });

export const tryToFetchDisputeHistoryDetails = createAsyncThunk<any, string>(
    'disputeHistory/tryToFetchDisputeHistoryDetails',
    async (historyId) => {
        const result = await DisputeHistoryService.tryToFetchDisputeHistoryDetails(historyId);
        return result?.data;
    },
);


export const DisputesHistorySlice = createSlice({
    name: 'disputeHistory',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchDisputeHistory
            .addCase(tryToFetchDisputeHistory.pending, (state) => {
                state.disputesHistoryAreLoading = true;
            })
            .addCase(tryToFetchDisputeHistory.rejected, (state) => {
                state.disputesHistoryAreLoading = false;
            })
            .addCase(tryToFetchDisputeHistory.fulfilled, (state, action) => {
                state.disputesHistoryAreLoading = false;
                state.disputesHistory = action.payload;
            })

            // tryToFetchDisputeHistoryDetails
            .addCase(tryToFetchDisputeHistoryDetails.pending, (state) => {
                state.disputeHistoryIsLoading = true;
            })
            .addCase(tryToFetchDisputeHistoryDetails.rejected, (state) => {
                state.disputeHistoryIsLoading = false;
            })
            .addCase(tryToFetchDisputeHistoryDetails.fulfilled, (state, action) => {
                state.disputeHistoryIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                state.disputeHistoryDetail = data
            })

    },
});

export default DisputesHistorySlice.reducer;