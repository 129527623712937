import React from 'react';
import SellerHeaderContainer from './SellerHeaderContainer';
import SellerTabsContainer from './SellerTabsContainer';
import SellerBasicDetailsContainer from './SellerBasicDetailsContainer';
import SellerBankDetailsContainer from './SellerBankDetailsContainer';
import SelllerCashoutDetailsContainer from './SelllerCashoutDetailsContainer';


const SellerDetailsLoading = () => {
    return (
        <div>
            <div>
                <SellerHeaderContainer />
            </div>
            <SellerTabsContainer />
            <div className='grid grid-cols-11 gap-4 h-auto'>
                <div className='col-span-8'>
                    <SellerBasicDetailsContainer />
                    <SellerBankDetailsContainer />
                </div>
                <div className='col-span-3'>
                    <SelllerCashoutDetailsContainer />
                </div>
            </div>
        </div >
    )
}

export default SellerDetailsLoading;