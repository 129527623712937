import React from 'react';

interface IButton {
  label: string;
  className?: string;
  disabled?: boolean;
  type?: any;
  dataQa?: string;
  icon?: React.ReactNode;
  scndIcon?: React.ReactNode;
  onClickButton?: (type?: string) => void;
  clickType?: string;
}

const Button = ({ type, disabled, className, label, icon, dataQa, scndIcon, clickType, onClickButton }: IButton) => {
  return (
    <button
      className={className || 'btn-primary'}
      type={type || 'button'}
      onClick={(e) => onClickButton?.(clickType)}
      disabled={disabled || false}
      data-qa={dataQa || ''}
    >
      {icon}
      {label}
      {scndIcon}
    </button>
  );
};

export default Button;
