import React, { useEffect, useState } from 'react';
import Modal from '../../../../shared/modal';
import { tryToFetchOrderDetails } from '../../../../store/customer-support/shipments/shipmentsSlice';
import { useAppDispatch } from '../../../../app/hooks';
import { onFormatOrderStatus } from '../../../../utils/constants/format-order-statuses';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';

interface ICashoutTypeModal {
    openModal: boolean;
    handleCloseModal: () => void; 
    id: string;
    itemId: string;
    onSeeDetails: (id: string, itemId: string, country: string) => void;
}

interface IItem {
    item: {
        id: string;
        name: string;
        originalBrokerPrice: number;
        itemId: string;
        revaluedPrice: number;
        originalBrokerData: {
            username: string;
        };
        revaluated: boolean;
        categoryIcon: string
    };
    orderData: {
        status: string;
        country: string;
        initialPrice: number;
    };
}

const CashoutTypeModal = ({
    openModal,
    handleCloseModal,
    id,
    itemId,
    onSeeDetails
}: ICashoutTypeModal) => {
    const dispatch = useAppDispatch();
    const [items, setItems] = useState<IItem[]>();

    const onCloseModal = () => {
        handleCloseModal();
    };

    const getOrderDetails = async () => {
        const resp = id ? await dispatch(tryToFetchOrderDetails({ orderId: id })).unwrap() : null;
        resp && setItems(resp.data);
    };

    useEffect(() => {
        getOrderDetails();
    }, [id]);

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            dataQa='add-a-note'
            style='pb-4 border-b-[1px]'
            titleContainerStyle='flex-grow text-center justify-center'
            title='Order Actions'
            showInRight
        >
            <div className='p-2 min-w-[768px]'>
                <div>
                    <p className='font-normal text-[20px]'>Cashout ID: {id}</p>
                    <p className='text-[#8A8A8E] text-base pb-2 border-b-[1px]'>{items?.length || 0} items within this Multiple Cashout</p>
                </div>
                <div className='mt-[43px]'>
                    {items?.map((item, i) => {
                        const formatOrderStatus = onFormatOrderStatus(item?.orderData?.status || '');
                        const statusColor = formatOrderStatus.text;
                        const currency = CURRENCY_SYMBOLS?.[item?.orderData?.country];

                        return (
                            <div
                                key={i}
                                className={`py-4 px-[18px] ${item?.item?.id === itemId && 'border border-primary-default'} bg-[#F8F9FC] w-[80%] rounded-[5px] mb-4`}
                            >
                                {item?.item?.id === itemId && (
                                    <p className='text-[24px] text-primary-default font-normal my-2'>
                                        You are looking at this item
                                    </p>
                                )}
                                <div className='flex justify-between pb-4 border-b-[1px] border-dashed'>
                                    <div>
                                        <p className='text-[#8A8A8E] text-base'>Item ID: {item?.item?.id}</p>
                                        <p className='text-[#8A8A8E] text-base'>Broker: {item?.item?.originalBrokerData?.username}</p>
                                    </div>
                                    <img
                                        onClick={() => onSeeDetails(id || '', itemId || '', item?.orderData?.country || '')}
                                        title={'View Details'}
                                        alt='item details'
                                        src={'/assets/table-actions/visibility.svg'}
                                        className='object-contain cursor-pointer mr-2'
                                    />
                                </div>
                                <div className='flex justify-between py-4 gap-6'>
                                    <div className='py-4 px-8 bg-white rounded-[10px] flex flex-col justify-center align-middle'>
                                        <img src={item?.item?.categoryIcon} className='self-center' />
                                        <p className='text-center font-dm_sansregular text-[#202020] font-bold text-xl max-w-[300px]'>
                                            {item?.item?.name}
                                        </p>
                                    </div>
                                    <div className='py-4 px-8 bg-white rounded-[10px] flex flex-col justify-center align-middle'>
                                        <p className='text-[#8A8A8E] text-base text-center'>Price</p>
                                        {item?.item?.revaluedPrice && (
                                            <p className='text-[#8A8A8E] text-center text-[10px] line-through'>
                                                {currency}{item?.orderData?.initialPrice?.toFixed(2)}
                                            </p>
                                        )}
                                        <p className='text-center font-dm_sansregular text-[#202020] font-bold text-xl max-w-[300px]'>
                                            {currency}
                                            {item?.item?.revaluedPrice ? item?.item?.revaluedPrice.toFixed(2) : item?.orderData?.initialPrice?.toFixed(2)}
                                        </p>
                                    </div>
                                    <div className='py-4 px-8 bg-white rounded-[10px] flex flex-col justify-center align-middle'>
                                        <p className='text-[#8A8A8E] text-base text-center'>Item Status</p>
                                        <p className={`text-center font-dm_sansregular ${statusColor} text-xl max-w-[300px]`}>
                                            {formatOrderStatus.name}
                                        </p>
                                    </div>
                                </div>
                                {item?.item?.revaluated && <p className='text-base text-primary-default text-center'>Added into Open Cases.</p>}
                            </div>
                        );
                    })}
                </div>
            </div>
        </Modal>
    );
};

export default CashoutTypeModal;
