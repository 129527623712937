import { AxiosResponse } from 'axios';
import { ORDERS_URL } from '../../../config';
import ordersAxios from '../../../utils/axios/orders.axios';
import { PaginatedStripeDisputes } from '../../../interfaces/stripe-dispute/paginatedStore.type';

//  Find all Stripe Dispute:
const tryToFetchAllStripeDispute = (
    pageNumber: string,
    pageSize: number,
    previousPage?: string,
    nextPage?: string,
    filters?: string,
    data?: any
): Promise<AxiosResponse<PaginatedStripeDisputes>> => {
    const url = filters
        ? `${ORDERS_URL}/stripe-disputes/all?page=${pageNumber}&size=${pageSize}${previousPage ? `&previousPage=${previousPage}` : nextPage ? `&nextPage=${nextPage}` : ''}`
        : `${ORDERS_URL}/stripe-disputes/all?page=${pageNumber}&size=${pageSize}${previousPage ? `&previousPage=${previousPage}` : nextPage ? `&nextPage=${nextPage}` : ''}`;
    return ordersAxios.put<PaginatedStripeDisputes>(url, data);
};

const tryToFetchStripeDisputesDetails = (disputeId: string): Promise<AxiosResponse<any>> => {
    const url = `${ORDERS_URL}/stripe-disputes/${disputeId}`
    return ordersAxios.get<any>(url);
};

const tryToUpdateStripeDisputeCSV = (disputeId: string, data: any): Promise<AxiosResponse> => {
    return ordersAxios.put(`${ORDERS_URL}/stripe-disputes/${disputeId}/update`, data);
};

export const StripeDisputeService = {
    tryToFetchAllStripeDispute,
    tryToFetchStripeDisputesDetails,
    tryToUpdateStripeDisputeCSV,
}