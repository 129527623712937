import React, { useEffect, useState } from 'react';
import Modal from '../../../../shared/modal';
import { tryToFetchOutboundDetails, tryToTakeOutboundAction } from '../../../../store/customer-support/orders/ordersSlice';
import { useAppDispatch } from '../../../../app/hooks';
import Loading from '../../../../shared/Loading';
import AddNoteOutboundForm from '../details/AddNoteOutboundForm';

interface ICashoutTypeModal {
    openModal: boolean;
    handleCloseModal: () => void;
    id: string;
    isMultipleCashout?: boolean;
    fullName?: string;
    onGettingCashoutOrdersData: () => void;
}

const TakeActionModal = ({
    openModal,
    handleCloseModal,
    id,
    isMultipleCashout,
    fullName,
    onGettingCashoutOrdersData
}: ICashoutTypeModal) => {
    const dispatch = useAppDispatch();
    const [loadingButton, setLoadingButton] = useState<string | null>(null);

    const onCloseModal = () => {
        handleCloseModal();
    };

    const onGetOutboundDetails = async () => {
        await dispatch(tryToFetchOutboundDetails({orderId: id})).unwrap()
    }

    const takeOutboundAction = async (outbound: boolean) => {
        const button = outbound ? 'outbound' : 'answered';
        setLoadingButton(button);
        await dispatch(tryToTakeOutboundAction({ orderId: id, outboundStatus: outbound ? 'outbound_call_made' : 'answered' }));
        onGettingCashoutOrdersData();
        setLoadingButton(null);
        handleCloseModal();
    };

    useEffect(() => {
        onGetOutboundDetails()
    }, [])
    

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            dataQa='add-a-note'
            style='pb-4 border-b-[1px]'
            titleContainerStyle='flex-grow text-center justify-center'
            title='Take Action'
            showInRight
        >
            <div className='p-2 max-w-[768px]'>
                <div className='flex gap-4'>
                    <div className='self-end'>
                        <p className='text-[#8A8A8E] text-base'>{isMultipleCashout}</p>
                        <p className='font-dm_sansregular text-[#202020] font-bold text-xl max-w-[300px]'>{`CS Actions for user ${fullName}`}</p>
                        <p className='text-[#8A8A8E] text-base'>Cashout ID: {id}</p>
                    </div>
                    <div className='flex gap-2'>
                        <div
                            onClick={() => takeOutboundAction(true)}
                            className='w-[132px] h-[108px] rounded-[10px] bg-[#F8F9FC] cursor-pointer p-[16px] flex items-center justify-center'
                        >
                            {loadingButton === 'outbound' ? (
                                <Loading fill='#F7A21E'/>
                            ) : (
                                <>
                                    <img
                                        title={'View Details'}
                                        alt='item details'
                                        src={'/assets/order-actions/outboundCall.svg'}
                                        className={'h-[30px] object-contain cursor-pointer mr-2'}
                                    />
                                    <p className='text-sm w-[80%] mt-1'>Outbound Call Made</p>
                                </>
                            )}
                        </div>
                        <div
                            onClick={() => takeOutboundAction(false)}
                            className='w-[132px] h-[108px] rounded-[10px] bg-[#F8F9FC] cursor-pointer p-[16px] flex items-center justify-center'
                        >
                            {loadingButton === 'answered' ? (
                                <Loading fill='#F7A21E'/>
                            ) : (
                                <>
                                    <img
                                        title={'View Details'}
                                        alt='item details'
                                        src={'/assets/order-actions/userAnswered.svg'}
                                        className={'h-[30px] object-contain cursor-pointer mr-2'}
                                    />
                                    <p className='text-sm w-[80%] mt-1'>User Answered</p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className='mt-[16px] border-t-[1px] pt-[16px]'>
                    <AddNoteOutboundForm id={id} onCloseModal={onCloseModal} />   
                </div>
            </div>
        </Modal>
    );
};

export default TakeActionModal;
