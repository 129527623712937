import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SellersService } from './sellersApi';
import { ApiResponse } from '../../../interfaces/models/models/paginated-response.model';


const initialState: any = {
    sellersAreLoading: false,
    sellerIsLoading: false,
    sellerCashoutsAreLoading: false,
    sellerCashoutIsLoading: false,
    sellerPaymentsAreLoading: false,
    sellerTransactionsAreLoading: false,
    sellerActivitiesAreLoading: false,
    message: '',
};

export const tryToFetchSingleSeller = createAsyncThunk<any, string>(
    'sellers/tryToFetchSingleSeller',
    async (id) => {
        const result = await SellersService.tryToFetchSingleSeller(id);
        return result?.data;
    },
);

export const tryToFetchSellerCashouts = createAsyncThunk<ApiResponse<any>, any>(
    'sellers/tryToFetchSellerCashouts',
    async ({ pageNumber, pageSize, sellerId, data }) => {
        const result = await SellersService.tryToFetchSellerCashouts(pageNumber, pageSize, sellerId, data);
        return result.data;
    },
);

export const tryToFetchSellerPayments = createAsyncThunk<ApiResponse<any>, any>(
    'sellers/tryToFetchSellerPayments',
    async ({ pageNumber, pageSize, sellerId, status }) => {
        const result = await SellersService.tryToFetchSellerPayments(pageNumber, pageSize, sellerId, status);
        return result.data;
    },
);

export const tryToFetchSellerTransactions = createAsyncThunk<ApiResponse<any>, any>(
    'sellers/tryToFetchSellerTransactions',
    async ({ pageNumber, pageSize, sellerId, data }) => {
        const result = await SellersService.tryToFetchSellerTransactions(pageNumber, pageSize, sellerId, data);
        return result.data;
    },
);

export const tryToDownloadSellerTransactions = createAsyncThunk<ApiResponse<any>, any>(
    'sellers/tryToDownloadSellerTransactions',
    async ({ sellerId, data }) => {
        const result = await SellersService.tryToDownloadSellerTransactions(sellerId, data);
        return result.data;
    },
);

export const tryToFetchSellerActivity = createAsyncThunk<ApiResponse<any>, any>(
    'sellers/tryToFetchSellerActivity',
    async ({ pageNumber, pageSize, sellerId }) => {
        const result = await SellersService.tryToFetchSellerActivity(pageNumber, pageSize, sellerId);
        return result.data;
    },
);

export const sellersSlice = createSlice({
    name: 'sellers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchSingleSeller
            .addCase(tryToFetchSingleSeller.pending, (state) => {
                state.sellerIsLoading = true;
            })
            .addCase(tryToFetchSingleSeller.rejected, (state) => {
                state.sellerIsLoading = false;
            })
            .addCase(tryToFetchSingleSeller.fulfilled, (state, action) => {
                state.sellerIsLoading = false;
                const data = action.payload.data;
                state.sellerDetails = data
            })

            // tryToFetchSellerCashouts
            .addCase(tryToFetchSellerCashouts.pending, (state) => {
                state.sellerCashoutsAreLoading = true;
            })
            .addCase(tryToFetchSellerCashouts.rejected, (state) => {
                state.sellerCashoutsAreLoading = false;
            })
            .addCase(tryToFetchSellerCashouts.fulfilled, (state, action) => {
                state.sellerCashoutsAreLoading = false;
                state.sellerCashouts = action.payload
            })

            // tryToFetchSellerPayments
            .addCase(tryToFetchSellerPayments.pending, (state) => {
                state.sellerPaymentsAreLoading = true;
            })
            .addCase(tryToFetchSellerPayments.rejected, (state) => {
                state.sellerPaymentsAreLoading = false;
            })
            .addCase(tryToFetchSellerPayments.fulfilled, (state, action) => {
                state.sellerPaymentsAreLoading = false;
                state.sellerPayments = action.payload
            })

            // tryToFetchSellerTransactions
            .addCase(tryToFetchSellerTransactions.pending, (state) => {
                state.sellerTransactionsAreLoading = true;
            })
            .addCase(tryToFetchSellerTransactions.rejected, (state) => {
                state.sellerTransactionsAreLoading = false;
            })
            .addCase(tryToFetchSellerTransactions.fulfilled, (state, action) => {
                state.sellerTransactionsAreLoading = false;
                state.sellerTransactions = action.payload
            })

            // tryToFetchSellerActivity
            .addCase(tryToFetchSellerActivity.pending, (state) => {
                state.sellerActivitiesAreLoading = true;
            })
            .addCase(tryToFetchSellerActivity.rejected, (state) => {
                state.sellerActivitiesAreLoading = false;
            })
            .addCase(tryToFetchSellerActivity.fulfilled, (state, action) => {
                state.sellerActivitiesAreLoading = false;
                state.sellerActivity = action.payload
            })
    },
});

export default sellersSlice.reducer;