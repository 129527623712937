import React from 'react';
import moment from 'moment'
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';


const tableColumns = ['ID', 'User', 'Username', 'Registration Date', 'Phone Number', 'KYC Status', 'Active', 'Banned', 'Country', 'Gender', 'Subscription Type', 'Actions'];

interface ISellersTable {
    rows: any;
    rowsLoading: boolean;
    paginationData?: IPage;
    accessControl?: any;
    actions?: any;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onSeeDetails: (id: string) => void;
}

const SellersTable = ({
    rows,
    paginationData,
    rowsLoading,
    accessControl,
    actions,
    setPaginationState,
    onSeeDetails,
}: ISellersTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {rowsLoading ?
                <>
                    {[...Array(10)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {tableColumns?.map(element => {
                                    return (
                                        <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 &&
                        rows?.map((row: any, idx: number) => {
                            return (
                                <tr
                                    key={idx}
                                    className='bg-white border-b hover:bg-gray-50'>
                                    <td className='px-6 py-4'>{row?._id}</td>
                                    <td className='px-6 py-4'>
                                        <div>
                                            <p className='font-medium'>{row?.profile?.firstName} {row?.profile?.lastName}</p>
                                            <p className={'text-primary-light font-medium'}>{row?.email}</p>
                                        </div>
                                    </td>
                                    <td className='px-6 py-4'>{row?.username}</td>
                                    <td className='px-6 py-4'>{row?.dateCreated && moment(row?.dateCreated).format('DD-MM-YYYY, HH:mm')}</td>
                                    <td className='px-6 py-4'>{row?.phone}</td>
                                    <td className='px-6 py-4 min-w-[140px]'>
                                        {row?.kycStatus &&
                                            <div className={`${row?.kycStatus === 'not_initiated' ? 'border-red-600' : 'border-green-500'} border rounded text-center px-3 py-1`}>
                                                <p className={`${row?.kycStatus === 'not_initiated' ? 'text-red-600' : 'text-green-500'} font-medium text-center`}>{row?.kycStatus === 'not_initiated' ? 'Not Initiated' : row?.kycStatus === 'verified' ? 'Verified' : row?.kycStatus}</p>
                                            </div>
                                        }
                                    </td>
                                    <td className='px-6 py-4'>{row?.isActive ? 'Yes' : 'No'}</td>
                                    <td className='px-6 py-4'>{row?.isBanned ? 'Yes' : 'No'}</td>
                                    <td className='px-6 py-4'>{row?.profile?.country ? row?.profile?.country : 'UK'}</td>
                                    <td className='px-6 py-4'>{row?.profile?.gender}</td>
                                    <td className='px-6 py-4'>{row?.subscriptionType}</td>
                                    <td className='px-2 py-4'>
                                        <div className='flex flex-row justify-around'>
                                            <div data-qa={'view-details'} onClick={() => (!accessControl || actions?.['See Details']) && row?._id && onSeeDetails(row?._id)}>
                                                <img title={'View Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={'h-[14px] object-contain cursor-pointer'} />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                </>
            }
        </Table>
    );
};

export default SellersTable;