import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IPayServicesListState } from '../../../interfaces/pay-services/IPayServicesList';
import { PayServicesService } from './payServicesApi';


const initialState: IPayServicesListState = {
    payServiceIsLoading: false,
    payServicesAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchAllPayServices = createAsyncThunk<any, null | undefined>(
    'pay-service/tryToFetchAllPayServices',
    async () => {
        const result = await PayServicesService.tryToFetchAllPayServices();
        return result?.data;
    },
);

export const payServicesSlice = createSlice({
    name: 'payServices',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
    }})

export default payServicesSlice.reducer;
