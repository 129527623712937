import { AxiosResponse } from 'axios';
import { BROKERS_USERS_SERVICE } from '../../../config';
import brokersAxios from '../../../utils/axios/brokers-roles.axios';


const tryToFetchAllPayServices = (): Promise<AxiosResponse> => {
    return brokersAxios.get(`${BROKERS_USERS_SERVICE}/pay-service/all`);
};

export const PayServicesService = {
    tryToFetchAllPayServices,
};