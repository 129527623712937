import React from 'react';
import { CSGuard, GuestGuard } from '../guards/RolesGuard';
import Layout from '../container/Layout';
import Login from '../features/auth/login';
import Orders from '../features/dashboard/orders';
import Profile from '../features/dashboard/profile';
import Sellers from '../features/dashboard/sellers';
// import ReferralsUsers from '../features/dashboard/referrals';
import FlaggedUser from '../features/dashboard/flagged-user';
// import Transactions from '../features/dashboard/transactions';
import CustomerCare from '../features/dashboard/customer-care';
import SellerOrders from '../features/dashboard/sellers/orders';
// import StripeDispute from '../features/dashboard/stripe-dispute';
import SellerDetails from '../features/dashboard/sellers/details';
// import ReferralDetails from '../features/dashboard/referrals/details';
import CashoutDetails from '../features/dashboard/orders/cashout-details';
// import TransactionDetails from '../features/dashboard/transactions/details';
import FlaggedUserDetails from '../features/dashboard/flagged-user/details';
import OrderDetails from '../features/dashboard/customer-care/order-details';
import BlacklistedItems from '../features/dashboard/blacklisted-items';
import OpenCases from '../features/dashboard/open-cases';
// import RejectedRevaluations from '../features/dashboard/rejected-revaluations';
// import AutoAcceptedRevaluations from '../features/dashboard/auto-accepted-revaluations';
// import StripeDisputeDetails from '../features/dashboard/stripe-dispute/details';
// import OwnerTransactions from '../features/dashboard/referrals/referral-transactions';
// import StripeDisputeHistory from '../features/dashboard/stripe-dispute/stripe-dispute-history';
// import PendingItems from '../features/dashboard/pending-items';
// import PendingItemDetails from '../features/dashboard/pending-items/details';


export interface RouteDefinition {
    path: string;
    element: JSX.Element;
    guard?: () => boolean;
    extra?: boolean;
    csCheck?: boolean;
    index?: string,
    childrens?: RouteDefinition[];
}

export const routes: RouteDefinition[] = [
    {
        path: '/login',
        element: <Login />,
        guard: GuestGuard,
    },
    {
        path: '/tickets',
        element: <Layout />,
        guard: CSGuard,
        csCheck: true,
        childrens: [
            {
                path: '',
                element: <CustomerCare />
            },
            {
                path: ':id/:itemId/country/:country',
                element: <OrderDetails />
            },
        ]
    },
    {
        path: '/wing-users',
        element: <Layout />,
        guard: CSGuard,
        csCheck: true,
        childrens: [
            {
                path: '',
                element: <Sellers />
            },
            {
                path: ':id',
                element: <SellerDetails />
            },
            {
                path: ':id/orders',
                element: <SellerOrders />
            },
        ]
    },
    {
        path: '/cs-orders',
        element: <Layout />,
        guard: CSGuard,
        csCheck: true,
        childrens: [
            {
                path: '',
                element: <Orders />
            },
            {
                path: 'cashout/:id',
                element: <CashoutDetails />
            },
            {
                path: ':market/:id/:itemId',
                element: <OrderDetails />
            },
        ]
    },
    // {
    //     path: '/pending-items',
    //     element: <Layout />,
    //     guard: CSGuard,
    //     index: '/pending-items',
    //     childrens: [
    //         {
    //             path: '',
    //             element: <PendingItems />
    //         },
    //         {
    //             path: ':id',
    //             element: <PendingItemDetails />
    //         }
    //     ]
    // },
    // {
    //     path: '/failed-transactions',
    //     element: <Layout />,
    //     guard: CSGuard,
    //     csCheck: true,
    //     childrens: [
    //         {
    //             path: '',
    //             element: <Transactions />
    //         },
    //         {
    //             path: ':id',
    //             element: <TransactionDetails />
    //         },
    //     ]
    // },
    {
        path: '/flagged-users',
        element: <Layout />,
        guard: CSGuard,
        csCheck: true,
        childrens: [
            {
                path: '',
                element: <FlaggedUser />
            },
            {
                path: ':id',
                element: <FlaggedUserDetails />
            },
        ]
    },
    {
        path: '/open-cases',
        element: <Layout />,
        guard: CSGuard,
        csCheck: true,
        childrens: [
            {
                path: '',
                element: <OpenCases />
            },
            {
                path: ':id/:itemId/country/:country',
                element: <OrderDetails />
            }
        ]
    },
    {
        path: '/blacklisted-items',
        element: <Layout />,
        guard: CSGuard,
        csCheck: true,
        childrens: [
            {
                path: '',
                element: <BlacklistedItems />
            },
            {
                path: ':id/:itemId/country/:country',
                element: <OrderDetails />
            },
        ]
    },

    // {
    //     path: '/stripe-dispute',
    //     element: <Layout />,
    //     guard: CSGuard,
    //     csCheck: true,
    //     childrens: [
    //         {
    //             path: '',
    //             element: <StripeDispute />
    //         },
    //         {
    //             path: ':id',
    //             element: <StripeDisputeDetails />
    //         },
    //         {
    //             path: 'history',
    //             element: <StripeDisputeHistory />
    //         }
    //     ]
    // },
    // {
    //     path: '/referrals',
    //     element: <Layout />,
    //     guard: CSGuard,
    //     csCheck: true,
    //     childrens: [
    //         {
    //             path: '',
    //             element: <ReferralsUsers />
    //         },
    //         {
    //             path: ':id',
    //             element: <ReferralDetails />
    //         },
    //         {
    //             path: 'owner-transactions/:id',
    //             element: <OwnerTransactions />
    //         }
    //     ]
    // },
    {
        path: '/profile',
        element: <Layout />,
        extra: true,
        guard: CSGuard,
        index: '/profile',
        childrens: [
            {
                path: '',
                element: <Profile />
            }
        ]
    },
];
