import React from 'react';
import WhiteContainer from '../../../../shared/white-container';


interface ISellerCashoutDetails {
    sellerDetails: any
}

const SellerCashoutDetails = ({
    sellerDetails
}: ISellerCashoutDetails) => {
    return (
        <WhiteContainer containerStyle='mb-4 rounded h-auto'>
            <p className='gradient-text mb-6'>Cashout Summary</p>
            <div>
                <div className='grid grid-cols-2 gap-4 mb-4'>
                    <p>Total Cashouts(Not Shipped)</p>
                    <p className='text-right'>{sellerDetails?.insights?.totalCashout || 0}</p>
                </div>
                <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                <div className='grid grid-cols-2 gap-4 mb-4'>
                    <p>Accepted Orders</p>
                    <p className='text-right'>{sellerDetails?.insights?.acceptedOrders || 0}</p>
                </div>
                <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                <div className='grid grid-cols-2 gap-4 mb-4'>
                    <p>Rejected Orders</p>
                    <p className='text-right'>{sellerDetails?.insights?.rejectedOrders || 0}</p>
                </div >
                <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                <div className='grid grid-cols-2 gap-4 mb-4'>
                    <p>Missing Orders</p>
                    <p className='text-right'>{sellerDetails?.insights?.missingOrders || 0}</p>
                </div>
                <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                <div className='grid grid-cols-2 gap-4 mb-4'>
                    <p>Items in Review</p>
                    <p className='text-right'>{sellerDetails?.insights?.inReviewOrders || 0}</p>
                </div>
                <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
            </div >
        </WhiteContainer >
    );
};

export default SellerCashoutDetails;