import React, { useEffect, useRef } from 'react';
import Button from '../../../../shared/button';

interface ITableActions {
    onClickDeleteMessage: () => void;
}

const TableActions = ({
    onClickDeleteMessage
}: ITableActions) => {
    const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
    const dropdownMenu = useRef<any>(null);

    const closeDropdownPopover = (e: any) => {
        if (dropdownMenu?.current && !dropdownMenu?.current?.contains(e.target)) {
            setDropdownPopoverShow(false);
        }
    }

    useEffect(() => {
        document.addEventListener('click', closeDropdownPopover, false);
        return () => {
            document.removeEventListener('click', closeDropdownPopover, false);
        }
    }, [])

    const onClickDropDownItem = () => {
        onClickDeleteMessage()
        setDropdownPopoverShow(false)
    }
    return (
        <div>
            <div>
                <button
                    onClick={(e) => setDropdownPopoverShow(!dropdownPopoverShow)}
                    type='button'
                    className={'!px-4'} id='menu-button' aria-expanded='true' aria-haspopup='true'>
                    <img title={'Action'} alt='items arrow' src={'/assets/table-actions/see-more.svg'} className={'w-[7x] rotate-90 mb-2 cursor-pointer mr-2'} />
                </button>
            </div>
            <div
                className={`${dropdownPopoverShow ? '' : 'hidden'} shadow-xl max-h-[150px] overflow-auto absolute right-0 z-10 mt-0 w-50 origin-top-right divide-y divide-[#a4a4a4] bg-[#efefef] focus:outline-none`}
                role='menu'
                aria-orientation='vertical'
                aria-labelledby='menu-button'
                tabIndex={-1}>
                <div
                    onClick={() => onClickDropDownItem()}
                    className='py-1 px-4 cursor-pointer flex flex-row items-center align-flex-start  hover:bg-[#f4f4f4]'
                    role='none'>
                    <Button
                        label={'Delete Message'}
                        className={'btn-primary-text-underline'}
                    />
                </div>

            </div>
        </div>
    )
}

export default TableActions;