import React from 'react';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { IMarket } from '../../../../interfaces/markets/IMarket';
import { IPartners } from '../../../../interfaces/partners/IPartners';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface IOrderCategories {
    markets?: Array<IMarket>;
    partners?: Array<IPartners>;
    sectors?: Array<IAssetTemplate>;
    selectedMarket?: string;
    selectedPartner?: string;
    selectedSector?: string;
    onSelectFilterOption: (value: any, name?: string) => void;
    showCategory?: boolean
}

const OrderCategories = ({
    markets,
    partners,
    sectors,
    selectedMarket,
    selectedPartner,
    selectedSector,
    onSelectFilterOption,
    showCategory = true
}: IOrderCategories) => {
    return (
        <div>
            <div className='mb-6 grid grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-4'>
                <SelectCheckbox
                    name={'Market'}
                    containerStyle={'!min-w-[200px]'}
                    placeholder={selectedMarket || 'Select'}
                    options={markets || []}
                    uniqueName={'market'}
                    onChangeSelectedOption={onSelectFilterOption}
                    dataQa={`${(selectedMarket || '')?.toLowerCase().replaceAll(' ', '-')}`}
                />
                <SelectCheckbox
                    name={'Partner'}
                    containerStyle={'!min-w-[200px]'}
                    placeholder={selectedPartner || 'Select'}
                    options={partners || []}
                    uniqueName={'partner'}
                    onChangeSelectedOption={onSelectFilterOption}
                    dataQa={`${(selectedPartner || '')?.toLowerCase().replaceAll(' ', '-')}`}
                />
               {showCategory && <SelectCheckbox
                    name={'Category'}
                    containerStyle={'!min-w-[200px]'}
                    placeholder={selectedSector || 'Select'}
                    options={sectors || []}
                    uniqueName={'category'}
                    onChangeSelectedOption={onSelectFilterOption}
                    dataQa={`${selectedSector?.toLowerCase().replaceAll(' ', '-')}`}
                />}
            </div>
        </div>
    )
}

export default OrderCategories;