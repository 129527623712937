import React from 'react'
import OpenCasesFilters from '../details/CasesFilters'
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import OpenCasesTable from '../tables/OpenCasesTable';
import { IOrders } from '../../../../interfaces/orders/IOrders';
import EmptyContainer from '../../../../shared/empty-container';


interface IClosedTab {
    sectors: IAssetTemplate[]
    markets: IAllMarkets[]
    selectedMarket: string
    selectedSector: string
    assignees: any[]
    selectedAssignee: string
    orderTypes: any[]
    selectedOrderType: string
    rows: IOrders[]
    rowsLoading: boolean
    accessControl: any
    searchValue: any | undefined
    paginationData: IPaginationPayload
    onSelectFilterOption: (value: any, name?: string) => void
    setPaginationState: React.Dispatch<React.SetStateAction<IPaginationPayload>>
    onClickAction: (data: { actionName: string, id?: string, itemId?: string, country?: string, current?: any }) => void;
    onChangeSearchValue: (value: string | null | undefined, type: string) => void
    clearAllFilters: () => void;
    pageAccess: any;
}

const ClosedTab = ({
    sectors,
    markets,
    selectedMarket,
    selectedSector,
    assignees,
    selectedAssignee,
    orderTypes,
    selectedOrderType,
    rows,
    rowsLoading,
    paginationData,
    accessControl,
    searchValue,
    onSelectFilterOption,
    onChangeSearchValue,
    setPaginationState,
    onClickAction,
    clearAllFilters,
    pageAccess
}: IClosedTab) => {

    return (
        <div>
            <div className='my-10'>
                <OpenCasesFilters
                    sectors={sectors}
                    markets={markets}
                    selectedMarket={selectedMarket}
                    selectedSector={selectedSector}
                    onSelectFilterOption={onSelectFilterOption}
                    assignees={assignees}
                    selectedAssignee={selectedAssignee}
                    orderTypes={orderTypes}
                    selectedOrderType={selectedOrderType}
                    searchValue={searchValue}
                    onChangeSearchValue={onChangeSearchValue}
                    clearAllFilters={clearAllFilters}
                />
            </div>
            <div className='relative'>
                {(rows?.length > 0 || rowsLoading) ? (
                    <OpenCasesTable
                        rows={rows}
                        actions={pageAccess?.actions}
                        accessControl={accessControl}
                        paginationData={paginationData}
                        rowsLoading={rowsLoading}
                        setPaginationState={setPaginationState}
                        onClickAction={onClickAction}
                        categories={sectors || []}
                        markets={markets || []}
                        showActions={[{ name: 'View', icon: '/assets/table-actions/view.svg' }, { name: 'Write a note', icon: '/assets/table-actions/write-a-note.svg' }]}
                        selectedTab='closed' />
                ) : (
                    <EmptyContainer
                        text={'No case has been found.'}
                        showImage={true}
                    />
                )}
            </div>
        </div>
    )
}

export default ClosedTab