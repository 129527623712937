import React, { useState, useEffect } from 'react';
import DetailsPictures from '../cashout-details/DetailsPictures';
import CashoutInfo from '../cashout-details/CashoutInfo';
import ProductInfo from '../cashout-details/ProductInfo';
import ItemInfo from '../cashout-details/ItemInfo';
import ImagesCarousel from '../../../../shared/images-carousel';
import Modal from '../../../../shared/modal';


interface ICashoutItemModal {
    openModal: boolean;
    itemData?: any;
    handleCloseModal: () => void;
}

const CashoutItemModal = ({
    openModal,
    itemData,
    handleCloseModal
}: ICashoutItemModal) => {
    const [revaluateImages, setRevaluateImages] = useState<any>()
    const [showRevaluateImages, setShowRevaluateImages] = useState<boolean>(false)


    useEffect(() => {
        if (
            itemData &&
            itemData?.warehouseImages &&
            itemData?.warehouseImages?.length > 0
        ) {
            const formatImages = itemData?.warehouseImages?.map(
                (item: any) => ({ url: item }),
            );
            setRevaluateImages(formatImages || undefined);
        } else {
            setRevaluateImages(undefined);
        }
    }, [itemData]);

    const onClickRevaluateImages = () => {
        setShowRevaluateImages(true);
    };

    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            contentContainerStyle='min-w-[75vw]'
        >
            <div className={'p-2 min-w-[70vw]'}>
                {(itemData?.pictures || itemData?.photos) &&
                    <div>
                        <DetailsPictures
                            images={itemData?.pictures || itemData?.photos}
                        />
                    </div>
                }
                <div className='grid grid-cols-1 lg:grid-cols-9 gap-2 mt-8 mb-2'>
                    <div className='col-span-1 lg:col-span-4'>
                        <ProductInfo
                            itemData={itemData}
                        />
                        <CashoutInfo
                            source={itemData?.service}
                            itemId={itemData?._id}
                            itemCode={itemData?.itemCode}
                            isDonation={itemData?.isDonation}
                            category={itemData?.category}
                            subcategory={itemData?.subcategory}
                            sellerId={itemData?.sellerId}
                            country={itemData?.country}
                            hasBeenCashedOut={itemData?.hasBeenCashedOut}
                            warehouseAccepted={itemData?.warehouseAccepted}
                        />
                    </div>
                    <div className='col-span-1 lg:col-span-4'>
                        <ItemInfo
                            sku={itemData?.sku}
                            name={itemData?.name}
                            material={itemData?.material}
                            network={itemData?.network}
                            brand={itemData?.brand}
                            description={itemData?.description}
                            devicedLocked={itemData?.deviceLocked}
                            message={itemData?.message}
                            color={itemData?.color}
                            condition={itemData?.condition}
                            imei={itemData?.imei || undefined}
                            asset={itemData?.exchangeDefinition?.asset}
                            revaluateMessage={itemData?.message}
                            location={itemData?.location}
                            revaluationData={itemData?.revaluation}
                            reviewed={itemData?.reviewed}
                            revaluateImages={revaluateImages}
                            onClickRevaluateImages={onClickRevaluateImages}
                        />
                    </div>
                </div>
                {revaluateImages && revaluateImages?.length > 0 && showRevaluateImages &&
                    <ImagesCarousel
                        images={revaluateImages || []}
                        showModal={showRevaluateImages}
                        handleClose={() => setShowRevaluateImages(false)}
                        active={0}
                    />
                }
            </div>
        </Modal>
    )
}

export default CashoutItemModal;