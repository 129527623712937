import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { ITicket } from '../../../interfaces/customer-care/ITicket';
import { IPageAccess } from '../../../interfaces/components/IPageAccess';
import { ISingleOrderSchema } from '../../../interfaces/orders/IOrdersSchema';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import {
    tryToCreateThread,
    tryToDeleteTicketThread,
    tryToFetchUserTickets,
} from '../../../store/customer-support/customer-care/customerCareSlice';
import {
    tryToAcceptOrderOnUserBehalf,
    tryToFetchSingleOrder,
    tryToReturnRequired,
    tryToRemoveBlacklistedStatus,
    tryToAcceptItem,
    tryToAcceptOrderOnUserBehalfWithGesture,
    tryToRejectOrderOnUserBehalf,
    tryToCancelOrderOnUserBehalf,
    tryToResetOrder,
    tryToFetchMaxGestureAmount,
} from '../../../store/customer-support/orders/ordersSlice';
import LoadingPage from './loading/LoadingPage';
import SellerAndReturnInfo from './details/SellerAndReturnInfo';
import SellerBankDetails from './details/SellerBankDetails';
import DetailsActions from './details/DetailsActions';
import ContactHistory from './details/ContactHistory';
import ProductInfo from './details/ProductInfo';
import OrderInfo from './details/OrderInfo';
import ItemInfo from './details/ItemInfo';
import RaiseTicketFromOrderModal from './modals/RaiseTicketFromOrderModal';
import SellerBankInfoModal from './modals/SellerBankInfoModal';
import OrderTemplateModal from './modals/OrderTemplateModal';
import ActionCheckModal from './modals/ActionCheckModal';
import OldAddressModal from './modals/OldAddressModal';
import Toast from '../../../shared/toast';
import DeleteModal from '../../../shared/delete-modal';
import ImagesCarousel from '../../../shared/images-carousel';
import WhiteContainer from '../../../shared/white-container';
import AcceptOfferModal from './modals/AcceptOfferModal';
import AcceptOfferWithGestureModal from './modals/AcceptOfferWithGestureModal';
import ResetOrderModal from './modals/ResetOrderModal';
import OrderDatesDetails from './details/OrderDatesDetails';

const OrderDetails = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { id, itemId, country } = useParams();
    const [ticketsHistory, setTicketsHistory] = useState<ITicket>();
    const [showRaiseTicketModal, setShowRaiseTicketModal] = useState<boolean>(false);
    const [showAcceptOfferModal, setShowAcceptOfferModal] = useState<boolean>(false);
    const [showAcceptOfferWithGestureModal, setShowAcceptOfferWithGestureModal] = useState<boolean>(false);
    const [paginationStateTickets] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [showThreadModal, setShowThreadModal] = useState<{ show: boolean; threadId: string; ticketId: string; }>();
    const [threadReply, setThreadReply] = useState<string>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [showRevaluateImages, setShowRevaluateImages] = useState<boolean>(false);
    const [revaluateImages, setRevaluateImages] = useState<Array<{ url: string }>>();
    const [orderNotFound, setOrderNotFound] = useState<{ show: boolean; message: string; }>();
    const [loadingOrder, setLoadingOrder] = useState<boolean>(true);
    const [orderDetails, setOrderDetails] = useState<ISingleOrderSchema>();
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [openOriginalModal, setOpenOriginalModal] = useState<any>(false);
    const [loadingNewThread, setLoadingNewThread] = useState<boolean>(false);
    const [loadingAction, setLoadingAction] = useState<boolean>(false);
    const [showBankModal, setShowBankModal] = useState<boolean>(false);
    const [showFailuresDraw, setShowFailuresDraw] = useState<boolean>(false);
    const [showActionCheckModal, setShowActionCheckModal] = useState<{
        show: boolean;
        text: string;
        title: string;
        type: string;
        error: boolean;
    }>();
    const [showResetOrderModal, setShowResetOrderModal] = useState(false)
    const [pageAccess, setPageAccess] = useState<IPageAccess>();
    const stateTicket = useAppSelector((state) => state.cs);
    const orderDetailsInfo = useAppSelector((state) => state?.orders?.orderDetail);
    const totalCosts = useAppSelector((state) => state?.orders?.orderDetails?.totalCosts);
    const loadingOrderDetails = useAppSelector((state) => state?.orders?.orderIsLoading);
    const accessControl: any = useAppSelector((state) => state?.partners?.navigation);
    const isTradeIn: boolean = orderDetailsInfo?.item.sourceOrder === 'trade-in';
    const [maxGesture, setMaxGesture] = useState<number>(0);

    useEffect(() => {
        const findPage = (accessControl && accessControl?.length > 0) && accessControl?.find((item: any) => item?.name === 'Tickets');
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Order Details');
        setPageAccess(findChild || undefined);
    }, [accessControl]);

    const getOrderDetailsData = async () => {
        try {
            if (id && itemId && country) {
                const formatData = {
                    orderId: id,
                    data: {
                        itemId,
                        country,
                    },
                };
                await dispatch(tryToFetchSingleOrder(formatData)).unwrap();
                setOrderNotFound(undefined);
            }
        } catch (err) {
            setLoadingOrder(false);
            setOrderNotFound({ show: true, message: `${err}` });
        }
    };

    useEffect(() => {
        if (orderDetailsInfo && orderDetailsInfo?.item?.warehouseImages && orderDetailsInfo?.item?.warehouseImages?.length > 0) {
            const formatImages = orderDetailsInfo?.item?.warehouseImages?.map((item) => ({ url: item }));
            setRevaluateImages(formatImages || undefined);
        } else {
            setRevaluateImages(undefined);
        }
        if (orderDetailsInfo) {
            if (JSON.stringify(orderDetailsInfo) !== JSON.stringify(orderDetails)) {
                setOrderDetails(orderDetailsInfo || undefined);
            }
            setLoadingOrder(false);
        }
    }, [orderDetailsInfo]);

    useEffect(() => {
        if (id && itemId && country) {
            getOrderDetailsData();
        }
    }, [id, itemId, country]);

    const onGoBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (stateTicket?.orderTickets) {
            const ticketsData = stateTicket?.orderTickets?.data?.elements &&
                Array.isArray(stateTicket?.orderTickets?.data.elements)
                ? stateTicket?.orderTickets?.data.elements.map(
                    (r: any) => ({
                        ...r,
                        id: r._id,
                    }),
                )
                : [];
            setTicketsHistory(ticketsData?.[0]);
        }
    }, [stateTicket?.orderTickets]);

    const onGettingTickets = async () => {
        const fetchData = {
            ...(paginationStateTickets || {}),
            data: {
                ticketReadByCs: true,
                filters: {
                    value: [
                        {
                            key: 'orderId',
                            value: id,
                        },
                        {
                            key: 'itemId',
                            value: itemId,
                        },
                    ],
                },
            },
        };
        dispatch(tryToFetchUserTickets(fetchData));
    };

    useEffect(() => {
        if (id && itemId) {
            onGettingTickets();
        }
    }, [paginationStateTickets, id, itemId]);

    useEffect(() => {
        if (stateTicket.tickets) {
            const ticketsData = stateTicket?.tickets?.data?.elements &&
                Array.isArray(stateTicket?.tickets?.data.elements)
                ? stateTicket?.tickets?.data.elements.map((r: any) => ({
                    ...r,
                    id: r._id,
                }))
                : [];
            const findTicket = ticketsData.find((next) => next?.item?.id === itemId);
            setTicketsHistory(findTicket);
        }
    }, [stateTicket.tickets, id, itemId]);

    const onAddNewThreadCS = async () => {
        setLoadingNewThread(true);
        if (threadReply) {
            try {
                const formatData = {
                    ticketId: ticketsHistory?.ticket?._id || '',
                    data: {
                        content: threadReply,
                    },
                };
                await dispatch(tryToCreateThread(formatData)).unwrap();
                setThreadReply(undefined);
                setShowToast({ type: 'success', message: 'Your reply saved successfully.' });
            } catch (err: any) {
                setShowToast({ type: 'error', message: `${err?.response || err}` });
            }
            setLoadingNewThread(false);
        }
    };

    const onChangeValueReplyThread = (e: any) => {
        e.persist();
        setThreadReply(e?.target?.value);
    };

    const onClickRevaluateImages = () => {
        setShowRevaluateImages(true);
    };

    const onDeleteMyMessage = (id: string, threadId: string) => {
        setShowThreadModal({ show: true, threadId: threadId, ticketId: id });
    };

    const onCloseDeleteThreadModal = () => {
        setShowThreadModal(undefined);
    };

    const onDeleteMyMessageConfirm = async () => {
        try {
            await dispatch(tryToDeleteTicketThread({ id: showThreadModal?.ticketId || '', threadId: showThreadModal?.threadId || '' })).unwrap();
            setShowToast({ type: 'success', message: 'Message deleted successfully.' });
        } catch (err: any) {
            setShowToast({ type: 'error', message: `${err?.response || err}` });
        }
        setShowThreadModal(undefined);
    };

    const onDownloadRevaluateImage = (url: string) => {
        window.open(url, '_blank');
    };

    const toggleModal = () => {
        setShowEditModal(!showEditModal);
    };

    const toggleModalBankDetails = () => {
        setShowBankModal(!showBankModal);
    };

    const toggleOriginalModal = () => {
        setOpenOriginalModal(!openOriginalModal);
    };

    const onSaveChanges = (type: string, message: string) => {
        setShowToast({ type, message });
        // itemId(null)
    };

    const onSaveBankDetailsSuccessful = () => {
        setShowToast({ type: 'success', message: 'Seller Bank Details updated successfully' });
    };

    const onToggleActionModal = (type?: string) => {
        if (type) {
            if (type === 'return') {
                setShowActionCheckModal({
                    show: true,
                    text: 'Are you sure you want to ask the Broker for the return? This action cannot be undone!',
                    title: 'Return Requested',
                    error: true,
                    type: 'return',
                });
            }
            if (type === 'accept') {
                setShowAcceptOfferModal(true);
            }
            if (type === 'remove-blacklisted') {
                setShowActionCheckModal({
                    show: true,
                    text: 'Are you sure you want to remove the blacklisted status for the item in this order? This action cannot be undone!',
                    title: 'Remove Blacklisted status',
                    error: true,
                    type: 'remove-blacklisted',
                });
            }
            if (type === 'accept-item') {
                setShowActionCheckModal({
                    show: true,
                    text: 'Are you sure you want to accept and add the item in this order to the inventory? This action cannot be undone!',
                    title: 'Accept item and add to inventory',
                    error: true,
                    type: 'accept-item',
                });
            }
            if (type === 'return-wing') {
                setShowActionCheckModal({
                    show: true,
                    text: 'Are you sure you want to return the item in this order to Wingpay? This action cannot be undone!',
                    title: 'Return item to wing',
                    error: true,
                    type: 'return-wing',
                });
            }
            if (type === 'accept-with-gesture') {
                setShowAcceptOfferWithGestureModal(true);
            }
            if (type === 'reject') {
                setShowActionCheckModal({
                    show: true,
                    text: 'Are you sure you want to Reject the Revaluation Offer On Behalf of the User? This action cannot be undone!',
                    title: 'Reject Offer',
                    error: true,
                    type: 'reject',
                });
            }
            if (type === 'reset') {
                setShowResetOrderModal(true)
            }
            if (type === 'cancel-order') {
                setShowActionCheckModal({
                    show: true,
                    text: 'Are you sure you want to Cancel the Order On Behalf of the User? This action cannot be undone!',
                    title: 'Cancel Order',
                    error: true,
                    type: 'cancel-order',
                });
            }
        } else {
            setShowActionCheckModal(undefined);
        }
    };

    const onAcceptWithGestureForTradeIn = () => {
        setShowToast({ type: 'error', message: 'Sorry, you can\'t offer an additional gesture amount to a subscription item from wingplus' });
        setShowAcceptOfferWithGestureModal(false);
        setLoadingAction(false);
    };

    const onClickOrderDetailsAction = (action: string) => {
        switch (action) {
            case 'Return Requested':
                onToggleActionModal('return');
                break;
            case 'Raise A Ticket':
                setShowRaiseTicketModal(true);
                break;
            case 'Accept Offer':
                setShowAcceptOfferModal(true);
                break;
            case 'Accept Offer with Gesture':
                setShowAcceptOfferWithGestureModal(true);
                break;
            case 'Reject Offer':
                onToggleActionModal('reject');
                break;
            case 'Reset Order':
                onToggleActionModal('reset');
                break;
            case 'Remove Blacklist status':
                onToggleActionModal('remove-blacklisted');
                break;
            case 'Add item to inventory':
                onToggleActionModal('accept-item');
                break;
            case 'Return item to wing':
                onToggleActionModal('return-wing');
                break;
            case 'Cancel Order':
                onToggleActionModal('cancel-order');
                break;
        }
    };

    const onAcceptOffer = async () => {
        try {
            itemId && (await dispatch(tryToAcceptOrderOnUserBehalf({ orderId: id || '', itemId: itemId || '' })).unwrap());
            setShowToast({ type: 'success', message: 'Offer was accepted successfully.', });
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` });
        }
        setShowAcceptOfferModal(false);
        setLoadingAction(false);
    };

    const onAcceptOfferWithGesture = async (amount: number) => {
        if (isTradeIn) onAcceptWithGestureForTradeIn();
        else {
            try {
                itemId && (await dispatch(tryToAcceptOrderOnUserBehalfWithGesture({ orderId: id || '', itemId: itemId || '', amount })).unwrap());
                setShowToast({ type: 'success', message: 'Offer was accepted successfully.' });
                getOrderDetailsData();
            } catch (err) {
                setShowToast({ type: 'error', message: `${err}` });
            }
            onToggleActionModal();
            setShowAcceptOfferWithGestureModal(false);
            setLoadingAction(false);
        }
    };

    const onReject = async () => {
        try {
            itemId && (await dispatch(tryToRejectOrderOnUserBehalf({ orderId: id || '', itemId: itemId || '' })).unwrap());
            setShowToast({ type: 'success', message: 'Offer was rejected successfully.' });
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` });
        }
        onToggleActionModal();
        setLoadingAction(false);
    };
    const onReset = async (reason: string, details: string) => {
        try {
            itemId && (await dispatch(tryToResetOrder({ orderId: id || '', itemId: itemId || '', reason, details })).unwrap());
            setShowToast({ type: 'success', message: 'Order was reset successfully.' });
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` });
        }
        onToggleActionModal();
        setShowResetOrderModal(false);
        setLoadingAction(false);
    }

    const onCancelOrder = async () => {
        try {
            itemId && (await dispatch(tryToCancelOrderOnUserBehalf({ orderId: id || '' })));
            setShowToast({ type: 'success', message: 'Order was cancelled successfully.' });
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` });
        }
        onToggleActionModal();
        setLoadingAction(false);
    };

    const onReturnRequested = async () => {
        try {
            const formatData = {
                itemId: orderDetailsInfo?.item?.id || '',
            };
            itemId && (await dispatch(tryToReturnRequired(formatData)));
            setShowToast({ type: 'success', message: 'Return required was accepted successfully.' });
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` });
        }
        onToggleActionModal();
        setLoadingAction(false);
    };

    const onRemoveBlacklisted = async () => {
        try {
            itemId && (await dispatch(tryToRemoveBlacklistedStatus({ orderId: id || '', itemId: itemId || '' })));
            setShowToast({ type: 'success', message: 'Blacklisted status was successfully removed.' });
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` });
        }
        onToggleActionModal();
        setLoadingAction(false);
    };

    const onAcceptItem = async () => {
        try {
            itemId && (await dispatch(tryToAcceptItem({ orderId: id || '', itemId: itemId || '' })));
            setShowToast({ type: 'success', message: 'Item was successfully accepted and added to inventory.' });
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` });
        }
        onToggleActionModal();
        setLoadingAction(false);
    };

    const onReturnToWing = async () => {
        try {
            itemId && (await dispatch(tryToAcceptItem({ orderId: id || '', itemId: itemId || '' })));
            setShowToast({ type: 'success', message: 'Item was successfully requested to be returned to wing.' });
        } catch (err) {
            setShowToast({ type: 'error', message: `${err}` });
        }
        onToggleActionModal();
        setLoadingAction(false);
    };

    const onApproveActionCheck = (action: string) => {
        setLoadingAction(true);
        switch (action) {
            case 'return':
                onReturnRequested();
                break;
            case 'remove-blacklisted':
                onRemoveBlacklisted();
                break;
            case 'accept-item':
                onAcceptItem();
                break;
            case 'return-wing':
                onReturnToWing();
                break;
            case 'reject':
                onReject();
                break;
            case 'cancel-order':
                onCancelOrder();
                break;
        }
    };

    const onClickFailuresImages = () => {
        setShowFailuresDraw(!showFailuresDraw);
    };

    const onSuccessfullyRaised = () => {
        setShowToast({ type: 'success', message: 'Ticket Raised successfully!' });
        onGettingTickets();
        getOrderDetailsData();
    };

    const getMaxGesture = async () => {
        const resp = itemId ? await dispatch(tryToFetchMaxGestureAmount({ orderId: id || '', itemId })).unwrap() : null;
        resp?.data?.maximumGestureAmount && setMaxGesture(resp.data.maximumGestureAmount);
    };
    useEffect(() => {
        getMaxGesture();
    }, [itemId]);

    const showReturnRequest: boolean =
        (!(
            orderDetailsInfo?.orderData?.status === 'required_return' ||
            orderDetailsInfo?.orderData?.status === 'user_accepted_offer' ||
            orderDetailsInfo?.orderData?.status === 'device_accepted' ||
            orderDetailsInfo?.orderData?.status === 'returned' ||
            orderDetailsInfo?.orderData?.status === 'cs_required_return' ||
            orderDetailsInfo?.orderData?.status === 'awaiting_user_response' ||
            orderDetailsInfo?.orderData?.status === 'item_missing' ||
            orderDetailsInfo?.orderData?.status === 'user_rejected_offer'
        ) &&
            (!accessControl || pageAccess?.actions?.['Return Requested']) &&
            orderDetailsInfo?.orderData?.status !== 'not_shipped' &&
            orderDetailsInfo?.orderData?.status !== 'shipped' &&
            orderDetailsInfo?.orderData?.status !== 'awaiting_cs_response') ||
        false;
    const showRaiseTicket: boolean =
        ((!accessControl || pageAccess?.actions?.['Raise A Ticket']) &&
            !(ticketsHistory &&
                ticketsHistory?.ticket?.threads &&
                ticketsHistory?.ticket?.threads.length > 0
            ) && orderDetailsInfo?.orderData?.status !== 'not_shipped') || false;
    const showResetOrder: boolean = (!accessControl || pageAccess?.actions?.['Reset Order']) &&
        (orderDetailsInfo?.orderData?.status === 'awaiting_user_response' || orderDetailsInfo?.orderData?.status === 'awaiting_cs_response' || orderDetailsInfo?.orderData?.status === 'device_rejected' || orderDetailsInfo?.orderData?.status === 'user_rejected_offer' || orderDetailsInfo?.orderData?.status === 'cs_required_return') &&
        !orderDetailsInfo?.orderData?.paid && !orderDetailsInfo?.orderData?.sold && !orderDetailsInfo?.item?.salesData && !orderDetailsInfo?.item?.invoiceData || false
    const showBlacklistedActions: boolean = orderDetailsInfo?.orderData.status === 'blacklisted';
    const brokerIsWingpay: boolean = orderDetails?.item.wingpayAccount || false;
    const blacklistedExpired: boolean = orderDetails?.item.blacklistedExpired || false;
    const canCancelOrder: boolean = orderDetails?.orderData?.status === 'not_shipped';
    const canAcceptWithCustomGesture: boolean = !accessControl || Boolean(pageAccess?.actions?.['Accept Offer With Gesture custom']);
    const shouldDisableGesture: boolean = maxGesture <= 0

    return (
        <div>
            <div className={'flex flex-row items-center justify-between'}>
                <div className='flex flex-row items-center'>
                    <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-8'>
                        <img src={'/assets/shared/back.svg'} className={'w-[22px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title !text-3xl'>Item Detailed View</p>
                </div>
            </div>
            {loadingOrder ?
                <LoadingPage />
                : <>
                    {orderNotFound?.show ?
                        <div className='my-10 min-w-[100%] flex flex-col items-center'>
                            <img src={'/assets/shared/no-content.svg'} className={'w-[150px] mb-10 mt-4 object-contain'} />
                            <p className='text-[#a4a4a4] text-center font-md'>{orderNotFound?.message}</p>
                        </div>
                        : (
                            <>
                                {!loadingOrderDetails && orderDetails && (showReturnRequest || showRaiseTicket || orderDetails?.item?.showAcceptOnUserBehalf || showBlacklistedActions || canCancelOrder || showResetOrder) && (
                                    <>
                                        {(!accessControl ||
                                            pageAccess?.actions?.['Return Requested'] ||
                                            pageAccess?.actions?.['Raise A Ticket'] ||
                                            pageAccess?.actions?.['Accept Offer'] ||
                                            pageAccess?.actions?.['Remove Blacklist status'] ||
                                            pageAccess?.actions?.['Add item to inventory'] ||
                                            pageAccess?.actions?.['Return item to wing'] ||
                                            pageAccess?.actions?.['Accept Offer with Gesture'] ||
                                            pageAccess?.actions?.['Reject Offer'] ||
                                            pageAccess?.actions?.['Cancel Order']) && (
                                                <DetailsActions
                                                    showReturnRequest={showReturnRequest}
                                                    showRaiseTicket={showRaiseTicket}
                                                    showAcceptOnUserBehalf={orderDetails?.item?.showAcceptOnUserBehalf || orderDetails?.orderData?.status === 'awaiting_cs_response' || false}
                                                    showBlacklistedActions={showBlacklistedActions}
                                                    brokerIsWingpay={brokerIsWingpay}
                                                    blacklistedExpired={blacklistedExpired}
                                                    onClickOrderDetailsAction={onClickOrderDetailsAction}
                                                    canCancelOrder={canCancelOrder}
                                                    isTradeIn={isTradeIn}
                                                    pageAccess={pageAccess}
                                                    accessControl={accessControl}
                                                    showResetOrder={showResetOrder}
                                                    shouldDisableGesture={shouldDisableGesture}
                                                />
                                            )}
                                    </>
                                )}
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-0 lg:gap-2 mt-8 mb-2'>
                                    <div className='grid'>
                                        <div className='col-span-1'>
                                            <OrderInfo
                                                trackingNumber={orderDetails?.shipment?.trackingNumber}
                                                specialTrackingNumber={stateTicket.orderTickets?.data?.elements?.[0]?.ticket.specialTrackingNumber}
                                                returnTrackingNumber={orderDetails?.item?.returnTrackingNumber}
                                                source={orderDetails?.item?.sourceOrder}
                                                blackListStatus={orderDetails?.item?.blackListStatus}
                                                itemId={orderDetails?.item?.id}
                                                orderNumber={orderDetails?.shipment?.orderNumber}
                                                itemCode={orderDetails?.item?.itemCode}
                                                returnShippingLabel={orderDetails?.item?.returnShippingLabel}
                                                orderStatus={orderDetails?.orderData?.status}
                                                invoiceUrl={orderDetails?.item?.invoiceUrl}
                                            />
                                            <ProductInfo
                                                orderDetails={orderDetails}
                                                totalCost={totalCosts}
                                            /> 
                                            <OrderDatesDetails detailedStatuses={orderDetails?.orderData?.detailedStatuses}/>
                                        </div>
                                    </div>
                                    <div className='col-span-2 grid grid-cols-1 md:grid-cols-2 gap-2'>
                                        <div>
                                            <WhiteContainer containerStyle='!bg-[#202020] !rounded !h-auto mb-2'>
                                                <SellerAndReturnInfo
                                                    orderDetails={orderDetails}
                                                    accessControl={accessControl}
                                                    pageAccess={pageAccess}
                                                    toggleModal={toggleModal}
                                                    toggleOriginalModal={toggleOriginalModal}
                                                />
                                            </WhiteContainer>
                                            {orderDetails?.seller
                                                ?.showBankDetails && (
                                                    <WhiteContainer containerStyle='!bg-[#202020] !rounded !h-auto'>
                                                        <SellerBankDetails
                                                            orderDetails={orderDetails}
                                                            accessControl={accessControl}
                                                            pageAccess={pageAccess}
                                                            toggleModal={toggleModalBankDetails}
                                                        />
                                                    </WhiteContainer>
                                                )}
                                        </div>
                                        <div>
                                            <ItemInfo
                                                carbonImpact={(orderDetails?.orderData?.status === 'user_accepted_offer' || orderDetails?.orderData?.status === 'device_accepted') && orderDetails?.item?.carbonImpact ? orderDetails?.item?.carbonImpact : undefined}
                                                displayQuestion={orderDetails?.item?.displayQuestions}
                                                revaluateMessage={orderDetails?.item?.message}
                                                revaluationData={orderDetails?.item?.revaluatedDisplayQuestions}
                                                reviewed={!!orderDetails?.item?.revaluation}
                                                revaluateImages={revaluateImages}
                                                failuresDraw={orderDetails?.item?.failuresDraw}
                                                itemCode={orderDetails?.item?.itemCode}
                                                onClickRevaluateImages={onClickRevaluateImages}
                                                onClickFailuresImages={onClickFailuresImages}
                                                salesGrade={orderDetails?.item?.inventory?.sales_grade}
                                                wingpayCondition={orderDetails?.item?.wingpayCondition}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-span-3 mb-4'>
                                        <ContactHistory
                                            history={ticketsHistory}
                                            threadReply={threadReply}
                                            loading={loadingNewThread}
                                            onAddNewThread={onAddNewThreadCS}
                                            onDeleteMyMessage={onDeleteMyMessage}
                                            onChangeValueReplyThread={onChangeValueReplyThread}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                </>
            }
            {showEditModal && (
                <OrderTemplateModal
                    itemId={itemId}
                    openModal={showEditModal}
                    seller={orderDetails?.seller}
                    handleCloseModal={toggleModal}
                    onSaveChanges={onSaveChanges}
                />
            )}
            {showBankModal && (
                <SellerBankInfoModal
                    openModal={showBankModal}
                    orderId={id || ''}
                    bankDetails={orderDetails?.seller?.bankDetails}
                    handleCloseModal={toggleModalBankDetails}
                    onSuccessfullySaved={onSaveBankDetailsSuccessful}
                />
            )}
            {openOriginalModal && (
                <OldAddressModal
                    itemId={itemId}
                    shipment={orderDetails?.shipment}
                    openModal={openOriginalModal}
                    handleCloseModal={toggleOriginalModal}
                />
            )}
            {showToast?.message && (
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            )}
            <ActionCheckModal
                openMarketStatusModal={showActionCheckModal?.show || false}
                text={showActionCheckModal?.text || ''}
                title={showActionCheckModal?.title || ''}
                errorType={showActionCheckModal?.error || false}
                action={showActionCheckModal?.type || ''}
                loading={loadingAction}
                handleCloseModal={onToggleActionModal}
                onApproveAction={onApproveActionCheck}
            />
            {(revaluateImages && revaluateImages?.length > 0 && showRevaluateImages) &&
                <ImagesCarousel
                    images={revaluateImages || []}
                    showDownload={true}
                    onDownloadPictures={onDownloadRevaluateImage}
                    showModal={showRevaluateImages}
                    handleClose={() => setShowRevaluateImages(false)}
                    active={0}
                />
            }
            {(orderDetails?.item?.failuresDraw && showFailuresDraw) &&
                <ImagesCarousel
                    images={[{ url: orderDetails?.item?.failuresDraw }]}
                    showModal={showFailuresDraw}
                    handleClose={onClickFailuresImages}
                    active={0}
                />
            }
            {showRaiseTicketModal &&
                <RaiseTicketFromOrderModal
                    openModal={showRaiseTicketModal}
                    itemId={orderDetails?.item?.id}
                    brokerUsername={orderDetails?.orderData?.brokerUsername}
                    trackingNumber={orderDetails?.shipment?.trackingNumber}
                    onSuccessfullyRaised={onSuccessfullyRaised}
                    handleCloseModal={() => setShowRaiseTicketModal(false)}
                />
            }
            {showAcceptOfferModal &&
                <AcceptOfferModal
                    openModal={showAcceptOfferModal}
                    handleCloseModal={() => setShowAcceptOfferModal(false)}
                    onAcceptOffer={onAcceptOffer}
                />
            }
            {showAcceptOfferWithGestureModal &&
                <AcceptOfferWithGestureModal
                    openModal={showAcceptOfferWithGestureModal}
                    handleCloseModal={() => setShowAcceptOfferWithGestureModal(false)}
                    onAcceptOfferWithGesture={onAcceptOfferWithGesture}
                    orderDetails={orderDetails}
                    canAcceptWithCustomGesture={canAcceptWithCustomGesture}
                    itemId={itemId}
                    orderId={id}
                    maxGesture={maxGesture}
                />
            }
            {showResetOrderModal && <ResetOrderModal
                openModal={showResetOrder}
                handleCloseModal={() => setShowResetOrderModal(false)}
                onResetOrder={onReset}
            />}
            <DeleteModal
                openDeleteModal={showThreadModal?.show || false}
                handleCloseDeleteModal={onCloseDeleteThreadModal}
                onApproveDeletion={onDeleteMyMessageConfirm}
            />
        </div>
    );
};

export default OrderDetails;
