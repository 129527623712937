import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IPendingItemsListState } from '../../../interfaces/pending-items/IPendingItemsList';
import { PendingItemsService } from './pendingItemsApi';
import { PaginatedPendingItems } from '../../../interfaces/pending-items/paginatedStore.type';
import { AxiosError } from 'axios';


const initialState: IPendingItemsListState = {
    pendingItemIsLoading: false,
    pendingItemsAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchPendingItems = createAsyncThunk<PaginatedPendingItems, any>(
    'pendingItems/tryToFetchPendingItems',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await PendingItemsService.tryToFetchPendingItems(
            pageNumber,
            pageSize,
            filters,
            data
        );
        return result?.data;
    },
);

export const tryToFetchSinglePendingItem = createAsyncThunk<any, string>(
    'pendingItems/tryToFetchSinglePendingItem',
    async (pendingItemId) => {
        const result = await PendingItemsService.tryToFetchSinglePendingItem(pendingItemId);
        return result?.data;
    },
);

export const tryToRejectPendingItem = createAsyncThunk<any, any>(
    'pendingItems/tryToRejectPendingItem',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await PendingItemsService.tryToRejectPendingItem(
                id,
                data
            );
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data);
        }
    },
);

export const tryToAcceptPendingItem = createAsyncThunk<any, any>(
    'pendingItems/tryToAcceptPendingItem',
    async (id, { rejectWithValue }) => {
        try {
            const result = await PendingItemsService.tryToAcceptPendingItem(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data);
        }
    },
);


export const pendingItemsSlice = createSlice({
    name: 'pendingItems',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchPendingItems
            .addCase(tryToFetchPendingItems.pending, (state) => {
                state.pendingItemsAreLoading = true;
            })
            .addCase(tryToFetchPendingItems.rejected, (state) => {
                state.pendingItemsAreLoading = false;
            })
            .addCase(tryToFetchPendingItems.fulfilled, (state, action) => {
                state.pendingItemsAreLoading = false;
                state.pendingItems = action.payload;
            })

            // tryToFetchSinglePendingItem
            .addCase(tryToFetchSinglePendingItem.pending, (state) => {
                state.pendingItemIsLoading = true;
            })
            .addCase(tryToFetchSinglePendingItem.rejected, (state) => {
                state.pendingItemIsLoading = false;
            })
            .addCase(tryToFetchSinglePendingItem.fulfilled, (state, action) => {
                state.pendingItemIsLoading = false;
                state.pendingItem = action.payload?.data;
            })

            // tryToRejectPendingItem
            .addCase(tryToRejectPendingItem.fulfilled, (state, action) => {
                state.pendingItemIsLoading = false;
                state.requestStatus = 'success';
                if (state.pendingItems?.data.elements) {
                    state.pendingItems.data.elements =
                        { ...state }.pendingItems?.data.elements.filter(
                            (pendingItem) =>
                                pendingItem?._id !== action.meta?.arg?.id,
                        ) || [];
                    state.pendingItems.data.page = {
                        ...state.pendingItems.data.page,
                        totalElements: state.pendingItems.data.page.totalElements - 1 || 0,
                    }
                }
                state.pendingItem = undefined;
            })

            // tryToAcceptPendingItem
            .addCase(tryToAcceptPendingItem.fulfilled, (state, action) => {
                state.pendingItemIsLoading = false;
                state.requestStatus = 'success';
                if (state.pendingItems?.data.elements) {
                    state.pendingItems.data.elements =
                        { ...state }.pendingItems?.data.elements.filter(
                            (pendingItem) =>
                                pendingItem?._id !== action.meta?.arg,
                        ) || [];
                    state.pendingItems.data.page = {
                        ...state.pendingItems.data.page,
                        totalElements: state.pendingItems.data.page.totalElements - 1 || 0,
                    }
                }
                state.pendingItem = undefined;
            })
    }
})

export default pendingItemsSlice.reducer;
