import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IToast } from '../../../../interfaces/components/IToast';
import { ICashoutsFilters } from '../../../../interfaces/components/ICashoutsFilters';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { tryToFetchCashoutItems } from '../../../../store/customer-support/orders/ordersSlice';
import { tryToFetchSellerCashouts } from '../../../../store/customer-support/sellers/sellersSlice';
import { tryToGenerateLabelPacks } from '../../../../store/customer-support/customer-care/customerCareSlice';
import GenerateCashoutLabelModal from '../modals/GenerateCashoutLabelModal';
import SellerCashoutsFilters from '../details/SellerCashoutsFilters';
import SellerCashoutsTable from '../tables/SellerCashoutsTable';
import EmptyContainer from '../../../../shared/empty-container';
import Button from '../../../../shared/button';
import Toast from '../../../../shared/toast';


const CashoutsTab = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { id } = useParams()
    const [rows, setRows] = useState<any>([]);
    const [filters, setFilters] = useState<ICashoutsFilters>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [cashoutItems, setCashoutItems] = useState<any>();
    const [selectedCashout, setSelectedCashout] = useState<any>();
    const [pageAccess, setPageAccess] = useState<any>()
    const [showGenerateModal, setShowGenerateModal] = useState<any>()
    const [errorGenerateLabel, setErrorGenerateLabel] = useState<string>()
    const [showToast, setShowToast] = useState<IToast | null>();
    const [loadingGenerate, setLoadingGenerate] = useState<boolean>(false)
    const paginationData = useAppSelector((state) => state.sellers.sellerCashouts?.data?.page,);
    const rowsLoading = useAppSelector((state) => state.sellers.sellerCashoutsAreLoading);
    const state = useAppSelector((state) => state.sellers);
    const stateCashouts = useAppSelector((state) => state.orders);
    const accessControl = useAppSelector((state) => state?.partners?.navigation);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Wing Users')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Wing User Details')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    useEffect(() => {
        if (stateCashouts?.cashoutItems) {
            const items = stateCashouts?.cashoutItems?.data || [];
            setCashoutItems(items);
        } else {
            setCashoutItems(undefined)
        }
    }, [stateCashouts.cashoutItems]);

    const onGettingCashoutAllItems = async () => {
        try {
            const formatData: any = {
                data: {
                    pagination: false
                },
                id: selectedCashout?._id || ''
            }
            await dispatch(tryToFetchCashoutItems(formatData)).unwrap();
        } catch (err) {
            setCashoutItems(undefined)
        }
    }

    const onClickGenerateLabelPacks = (item: any) => {
        setCashoutItems(undefined)
        setShowGenerateModal({ show: true, item: item })
    }

    const onClickGenerateModal = () => {
        setSelectedCashout(showGenerateModal?.item)
    }

    useEffect(() => {
        if (selectedCashout) {
            onGettingCashoutAllItems()
        }
    }, [selectedCashout])

    const onCloseGenerateModal = () => {
        setShowGenerateModal(undefined)
        setSelectedCashout(undefined)
        setCashoutItems(undefined)
        setErrorGenerateLabel(undefined)
        setLoadingGenerate(false)
    }

    const onGenerateLabelPack = async () => {
        try {
            const createItemsFormat = cashoutItems && cashoutItems?.length > 0 && cashoutItems?.map((item: any) => {
                return {
                    'name': item?.name,
                    'qty': 1,
                    'price': Number(item?.price || 0).toFixed(2),
                    'currency': selectedCashout?.currency,
                    'hsCode': ''
                }
            })
            const shipmentDataFormat = {
                createShipment: {
                    'shipFrom': {
                        'name': `${selectedCashout?.seller?.profile?.firstName} ${selectedCashout?.seller?.profile?.lastName}`,
                        'contactName': `${selectedCashout?.seller?.profile?.firstName} ${selectedCashout?.seller?.profile?.lastName}`,
                        'phone': selectedCashout?.seller?.phone,
                        'email': selectedCashout?.seller?.email,
                        'address1': selectedCashout?.shippingAddress?.street,
                        'address2': selectedCashout?.shippingAddress?.addressNo,
                        'city': selectedCashout?.shippingAddress?.city,
                        'country': selectedCashout?.shippingAddress?.country || selectedCashout?.country,
                        'county': selectedCashout?.shippingAddress?.county,
                        'postalCode': selectedCashout?.shippingAddress?.postcode,
                        'shipperReference': selectedCashout?._id
                    },
                    'shipmentInformation': {
                        'category': selectedCashout?.category,
                        'shipmentDate': moment(new Date()).format('YYYY-MM-DD'),
                        'packages': 1,
                        'totalWeight': 1000,
                        'weightUnitOfMeasure': 'grams',
                        'currency': selectedCashout?.currency,
                        'value': Number(selectedCashout?.price || 0).toFixed(2),
                        'description': 'Customer Support',
                        'items': createItemsFormat || [],
                        'serviceCode': selectedCashout?.country === 'US' ? 'ups' : 'TSN'
                    }
                },
                'labelType': 'Inbound',
                'url': true,
                'instant': false,
                'qrCode': false,
                'barcode': true,
                'userId': id,
                'orderId': selectedCashout?._id,
                'itemIds': selectedCashout?.items || [],
                'sourceRequesting': 'wing_app',
                'carrierLocation': {
                    warehouseId: selectedCashout?.warehouse,
                    country: selectedCashout?.country || selectedCashout?.region,
                    carrier: selectedCashout?.shippingProvider || '',
                },
            }
            await dispatch(tryToGenerateLabelPacks(shipmentDataFormat)).unwrap()
            setShowToast({ type: 'success', message: 'Label Pack successfully generated!' })
            onCloseGenerateModal()
        } catch (err) {
            setErrorGenerateLabel(`${err}`)
            setLoadingGenerate(false)
        }
    }

    useEffect(() => {
        if (selectedCashout && cashoutItems) {
            setLoadingGenerate(true)
            onGenerateLabelPack()
        }
    }, [selectedCashout, cashoutItems])

    useEffect(() => {
        if (state.cashoutItems) {
            const rows =
                state.cashoutItems?.data?.elements &&
                    Array.isArray(state.cashoutItems?.data.elements)
                    ? state.cashoutItems?.data.elements.map((r: any) => ({
                        ...r,
                        id: r?.id || r?._id,
                    }))
                    : [];
            setRows(rows);
        } else {
            setCashoutItems(undefined)
        }
    }, [state.cashoutItems]);

    useEffect(() => {
        if (state.sellerCashouts) {
            const rows =
                state.sellerCashouts?.data?.elements &&
                    Array.isArray(state.sellerCashouts?.data.elements)
                    ? state.sellerCashouts?.data.elements.map((r: any) => ({
                        ...r,
                        id: r?.id || r?._id,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state.sellerCashouts]);

    const onGettingCashoutsData = async () => {
        try {
            let formatData: any = {
                ...paginationState || {},
                sellerId: id || ''
            }
            if (filters) {
                formatData = {
                    ...formatData || {},
                    data: filters
                }
            }
            await dispatch(tryToFetchSellerCashouts(formatData)).unwrap();
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        if (id) {
            onGettingCashoutsData()
        }
    }, [paginationState, id]);

    const onClickClearAllFilters = () => {
        onResetPaginationSettings()
        setFilters(undefined)
    }

    const onChangeFilterValue = (value: string | null | undefined, type?: string) => {
        if (type) {
            setFilters({
                ...filters,
                [type]: value || undefined,
            });
            if (type === 'toDate' || type === 'fromDate') {
                onResetPaginationSettings()
            }
        }
    };

    const onEnterKeyPressInput = async (e: any) => {
        if (e?.key === 'Enter') {
            onResetPaginationSettings()
        }
    }

    const onChangeFilterSelected = (value: any, type?: string) => {
        if (type) {
            setFilters({
                ...filters,
                [type]: value?.value || undefined,
            });
            onResetPaginationSettings()
        }
    };

    const onSeeDetails = (id: string, itemId: string, country: string) => {
        navigate(`/tickets/${id}/${itemId}/country/${country}`);
    }

    return (
        <div className='h-auto w-full flex flex-col gap-2 my-6'>
            <p className='text-primary-light'>Please make sure to press “Enter” to load the results</p>
            <div className='mb-2'>
                <SellerCashoutsFilters
                    filters={filters}
                    onHandleKeyPress={onEnterKeyPressInput}
                    onChangeFilterValue={onChangeFilterValue}
                    onChangeFilterSelected={onChangeFilterSelected}
                />
                <div className='flex flex-row justify-end mt-2'>
                    <Button
                        label={'Clear Filters'}
                        onClickButton={onClickClearAllFilters}
                        className={'btn-primary-text-underline min-w-[150px]'}
                    />
                </div>
            </div>
            <div className='mb-5 relativ'>
                <>
                    {(rowsLoading || rows?.length > 0) ? (
                        <SellerCashoutsTable
                            rows={rows}
                            actions={pageAccess?.actions}
                            accessControl={accessControl}
                            cashoutsLoading={rowsLoading}
                            paginationData={paginationData}
                            setPaginationState={setPaginationState}
                            onSeeDetails={onSeeDetails}
                            onClickGenerateLabelPacks={onClickGenerateLabelPacks}
                        />
                    ) : (
                        <EmptyContainer
                            text={'No cashouts for this user yet.'}
                            showImage={true}
                        />
                    )}
                </>
                {showGenerateModal?.show &&
                    <GenerateCashoutLabelModal
                        openModal={showGenerateModal?.show}
                        error={errorGenerateLabel}
                        loading={loadingGenerate}
                        handleCloseModal={onCloseGenerateModal}
                        onApproveAction={onClickGenerateModal}
                    />
                }
                {showToast?.message && (
                    <Toast
                        type={showToast?.type}
                        text={showToast?.message}
                        onHandleCloseToast={() => setShowToast(null)}
                    />
                )}
            </div>
        </div>
    )
}

export default CashoutsTab;