export const FILTER_ITEMS = [
    // { name: 'Inbound', label: 'Inbound' },
    { name: 'Delivered', label: 'Received' },
    // { name: 'In Review' },
    { name: 'CS', label: 'In Customer Support' },
    { name: 'Awaiting User Response', label: 'Awaiting User Response' },
    { name: 'Awaiting CS Response', label: 'Awaiting CS Response' },
    { name: 'Required Return', label: 'Required Return' },
    { name: 'Returned', label: 'Returned' },
    { name: 'Accepted', label: 'Accepted' },
    { name: 'Missing', label: 'Missing' },
]

export const STATUS_ITEMS = [
    // { name: 'History', label: 'Closed' },
    { name: 'Recent Changes', label: 'Recent Changes' },
]
export const RECENT_CHANGES_ITEMS = [
    { name: 'Delivered', status: 'reviewed' },
    { name: 'CS Ticket Response', status: 'cs' },
    { name: 'Revaluation Awaiting Response', status: 'revaluated' },
    { name: 'Price Revaluation Accepted', status: 'accepted' },
]

