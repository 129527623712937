import { useGetCurrentUserQuery } from '../store/user/userApi';
import { readFromStorage } from '../utils/webStorage';

export const GuestGuard = (): boolean => {
    const token = readFromStorage('accessToken');
    if (token) {
        return false
    }
    else {
        return true
    }
};

export const CSGuard = (): boolean => {
    const role = localStorage.getItem('roles')
    const { data }: any = !role && useGetCurrentUserQuery();
    const token = readFromStorage('accessToken');
    return token ? role ? localStorage.getItem('roles') === 'cs' : (data && data?.groups && data?.groups?.length) && Boolean(data?.groups?.includes('cs')) : false;
};