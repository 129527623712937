import React from 'react';
import { GRADES_ITEMS } from '../../../../utils/constants/grades';
import Modal from '../../../../shared/modal';

interface IRevaluateDataDetailsModal {
    openModal: boolean;
    revaluationData?: any;
    handleCloseModal: () => void;
}

const RevaluateDataDetailsModal = ({
    openModal,
    revaluationData,
    handleCloseModal
}: IRevaluateDataDetailsModal) => {
    const keys = revaluationData && Object.keys(revaluationData)
    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <div>
                    <div className='grid grid-cols-2 gap-3 my-2'>
                        <p className='font-bold text-md'>Property</p>
                        <p className='font-bold text-md'>Value</p>
                    </div>
                    {keys?.length > 0 && keys?.map((item: any) => {
                        const findGrade = (item || '')?.toLowerCase() === 'grade' && GRADES_ITEMS.find((grade: any) => (grade.value || '').toLowerCase() === (revaluationData?.[item] || '').toLowerCase())
                        return (
                            <>
                                <div className='grid grid-cols-2 gap-3 my-2'>
                                    <p className=''>{`${item[0].toUpperCase()}${item.slice(1).toLowerCase()}`}</p>
                                    <p>{(item || '')?.toLowerCase() === 'grade' ? (findGrade ? findGrade?.name : revaluationData?.[item]) : revaluationData?.[item]}</p>
                                </div>
                                <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                            </>
                        )
                    })}
                </div>
            </div>
        </Modal>
    )
}

export default RevaluateDataDetailsModal;