import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PartnersService } from './partnersApi';
import { AxiosError } from 'axios';

const initialState: any = {
	partnersAreLoading: false,
	toastSuccessRequest: false,
	partnerIsLoading: false,
	requestStatus: 'default',
	agentsAreLoading: false,
	navigationLoading: false,
	partnerMarketsAreLoading: false,
	message: '',
	cases: {
		rejectedByUser: 0,
		revaluatedByBroker: 0,
		rejectedByBroker: 0,
		total: 0,
	},
};

export const tryToFetchAllPartners = createAsyncThunk<any, any>(
	'partners/tryToFetchAllPartners',
	async () => {
		const result = await PartnersService.tryToFetchAllPartners();
		return result?.data;
	},
);

export const tryToFetchAllCsUsers = createAsyncThunk<any, any>(
	'partners/tryToFetchAllCsUsers',
	async () => {
		const result = await PartnersService.tryToFetchAllCsUsers();
		return result?.data?.data;
	},
);

export const tryToFetchPartnersByMarket = createAsyncThunk<any, any>(
	'partners/tryToFetchPartnersByMarket',
	async (id: string, { rejectWithValue }) => {
		try {
			const result = await PartnersService.tryToFetchPartnersByMarket(id);
			return result?.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);

export const tryToFetchNavigation = createAsyncThunk<any, undefined | null>(
	'partners/tryToFetchNavigation',
	async () => {
		const result = await PartnersService.tryToFetchNavigation();
		return result?.data;
	},
);

export const tryToFetchCasesCount = createAsyncThunk<any, undefined | null>(
	'partners/tryToFetchCasesCount',
	async () => {
		const result = await PartnersService.tryToFetchCasesCount();
		return result?.data;
	},
);

export const partnersSlice = createSlice({
	name: 'partners',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// tryToFetchNavigation
			.addCase(tryToFetchNavigation.pending, (state) => {
				state.navigationLoading = true;
			})
			.addCase(tryToFetchNavigation.rejected, (state) => {
				state.navigationLoading = false;
			})
			.addCase(tryToFetchNavigation.fulfilled, (state, action) => {
				state.navigationLoading = false;
				const data = action.payload.data;
				state.navigation = data;
			})
			// tryToFetchCasesCount
			.addCase(tryToFetchCasesCount.pending, (state) => {})
			.addCase(tryToFetchCasesCount.rejected, (state) => {})
			.addCase(tryToFetchCasesCount.fulfilled, (state, action) => {
				state.navigationLoading = false;
				state.cases = action.payload;
			});
	},
});

export default partnersSlice.reducer;
