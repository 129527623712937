import React from 'react';
import Input from '../../../../shared/input';
import SelectCheckbox from '../../../../shared/select-checkbox';
import { ITransactionsFilters } from '../../../../interfaces/components/ITransactionsFilters';
import { TRANSACTIONS_STATUSES } from '../../../../utils/constants/transactions-statuses';


interface ISellerTransactionsFilters {
    filters?: ITransactionsFilters;
    onChangeFilterValue: (value: string | null | undefined, type?: string) => void;
    onChangeFilterSelected: (value: any, type?: string) => void;
    onHandleKeyPress: (e: any) => void;
}

const SellerTransactionsFilters = ({
    filters,
    onChangeFilterValue,
    onChangeFilterSelected,
    onHandleKeyPress
}: ISellerTransactionsFilters) => {
    return (
        <div>
            <div className='grid grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-4'>
                <Input
                    label='Description'
                    containerStyle=''
                    showValue={true}
                    inputValue={filters?.description}
                    onChangeInput={onChangeFilterValue}
                    onHandleKeyDown={onHandleKeyPress}
                    inputUniqueName={'description'}
                />
                <SelectCheckbox
                    name='Status'
                    selectedOption={filters?.status ? TRANSACTIONS_STATUSES?.find((item) => item?.value === filters?.status) : undefined}
                    options={TRANSACTIONS_STATUSES}
                    uniqueName='status'
                    onChangeSelectedOption={onChangeFilterSelected}
                />
                <Input
                    type='text'
                    onFocus={(e: any) => {
                        e.target.type = 'date';
                        e.target.showPicker();
                    }}
                    label='From'
                    containerStyle=''
                    showValue={true}
                    inputValue={filters?.fromDate}
                    onChangeInput={onChangeFilterValue}
                    inputUniqueName={'fromDate'}
                />
                <Input
                    type='text'
                    onFocus={(e: any) => {
                        e.target.type = 'date';
                        e.target.showPicker();
                    }}
                    label='To'
                    containerStyle=''
                    showValue={true}
                    inputValue={filters?.toDate}
                    onChangeInput={onChangeFilterValue}
                    inputUniqueName={'toDate'}
                />
            </div>
        </div>
    );
};

export default SellerTransactionsFilters;