import { AxiosResponse } from 'axios';
import { ORDERS_URL } from '../../../config';
import ordersAxios from '../../../utils/axios/orders.axios';
import { ApiResponse } from '../../../interfaces/models/models/paginated-response.model';


const tryToFetchSingleSeller = (id: string): Promise<AxiosResponse<any>> => {
    return ordersAxios.get(`${ORDERS_URL}/sellers/profile/${id}`);
};

const tryToFetchSellerCashouts = (
    pageNumber: number,
    pageSize: number,
    sellerId: string,
    data?: any,
): Promise<AxiosResponse<any>> => {
    const url = `${ORDERS_URL}/sellers/${sellerId}/cashouts?page=${pageNumber}&size=${pageSize}`
    return ordersAxios.put<ApiResponse<any>>(url, data);
};

const tryToFetchSellerPayments = (
    pageNumber: any,
    pageSize: number,
    sellerId: string,
    status?: any,
): Promise<AxiosResponse<any>> => {
    const url = status ? `${ORDERS_URL}/payments/transactions/${sellerId}?page=${pageNumber}&size=${pageSize}&status=${status}` : `${ORDERS_URL}/payments/transactions/${sellerId}?page=${pageNumber}&size=${pageSize}`
    return ordersAxios.put<ApiResponse<any>>(url);
};

const tryToFetchSellerTransactions = (
    pageNumber: any,
    pageSize: number,
    sellerId: string,
    data?: any
): Promise<AxiosResponse<any>> => {
    const url = `${ORDERS_URL}/payments/user/transactions/${sellerId}?page=${pageNumber}&size=${pageSize}`
    return ordersAxios.put<ApiResponse<any>>(url, data);
};

const tryToDownloadSellerTransactions = (sellerId: string, data?: any): Promise<AxiosResponse<any>> => {
    const url = `${ORDERS_URL}/payments/user/transactions/${sellerId}`
    return ordersAxios.put<ApiResponse<any>>(url, data);
};

const tryToFetchSellerActivity = (
    pageNumber: any,
    pageSize: number,
    sellerId: string,
): Promise<AxiosResponse<any>> => {
    const url = `${ORDERS_URL}/sellers/${sellerId}/notifications?page=${pageNumber}&size=${pageSize}`
    return ordersAxios.put<ApiResponse<any>>(url);
};

export const SellersService = {
    tryToFetchSingleSeller,
    tryToFetchSellerCashouts,
    tryToFetchSellerPayments,
    tryToFetchSellerTransactions,
    tryToDownloadSellerTransactions,
    tryToFetchSellerActivity
};
