import { AxiosResponse } from 'axios';
import { CARRIERS_API_URL, CS_API_URL } from '../../../config';
import { PaginatedTicket } from '../../../interfaces/customer-care/paginatedStore.type';
import {
    IFilterTicket,
    ITicket,
    ITicketThread,
} from '../../../interfaces/customer-care/ITicket';
import csAxios from '../../../utils/axios/customer-support.axios';
import { ApiResponse } from '../../../interfaces/models/models/paginated-response.model';
import carriersAxios from '../../../utils/axios/carriers.axios';

const tryToFetchFilteredTickets = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: IFilterTicket,
): Promise<AxiosResponse<PaginatedTicket>> => {
    const url = filters
        ? `${CS_API_URL}/support/tickets/all?page=${pageNumber}&size=${pageSize}&search=${filters}`
        : `${CS_API_URL}/support/tickets/all?page=${pageNumber}&size=${pageSize}`;

    return csAxios.post<PaginatedTicket>(url, data);
};

const tryToFetchUserTickets = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: { opened: boolean },
): Promise<AxiosResponse<PaginatedTicket>> => {
    return csAxios.post<PaginatedTicket>(
        `${CS_API_URL}/support/user/tickets?page=${pageNumber}&size=${pageSize}&search=${filters}`,
        data,
    );
};

const tryToFetchCsTickets = (
    pageNumber: number,
    pageSize: number,
    filters = '',
    data?: { opened: boolean },
): Promise<AxiosResponse<PaginatedTicket>> => {
    return csAxios.post<PaginatedTicket>(
        `${CS_API_URL}/support/tickets/all?page=${pageNumber}&size=${pageSize}&search=${filters}`,
        data,
    );
};

const tryToFetchSingleTicket = (
    id: string,
): Promise<AxiosResponse<ApiResponse<ITicket>>> => {
    return csAxios.get<ApiResponse<any>>(`${CS_API_URL}/support/ticket/${id}`);
};

const tryToAddTicket = (data: any): Promise<AxiosResponse> => {
    return csAxios.post(`${CS_API_URL}/support`, data);
};

const tryToDeleteTicket = (id: string): Promise<AxiosResponse> => {
    return csAxios.delete(`${CS_API_URL}/support/ticket/${id}/delete`);
};

const tryToDeleteTicketThread = (id: string, threadId: string): Promise<AxiosResponse> => {
    return csAxios.put(`${CS_API_URL}/support/ticket/${id}/delete-thread`, { threadId });
};

const tryToRaiseATicket = (data: any): Promise<AxiosResponse> => {
    return csAxios.post(`${CS_API_URL}/support/cs/create`, data);
};

const tryToFetchMarkets = (): Promise<AxiosResponse> => {
    return csAxios.get(`${CS_API_URL}/dom/markets/`);
};

const tryToFetchWarehouses = (data: any): Promise<AxiosResponse> => {
    return carriersAxios.put(`${CARRIERS_API_URL}/dom/warehouses`, data)
}

const tryToCloseTicket = (id: string): Promise<AxiosResponse> => {
    return csAxios.put(`${CS_API_URL}/support/ticket/${id}/close`);
};

const tryToChangeAssignedUserTicket = (data: any): Promise<AxiosResponse> => {
    return csAxios.put(`${CS_API_URL}/support/ticket/assign/user`, data);
};

const tryToGenerateLabelPacks = (data: any): Promise<AxiosResponse> => {
    return carriersAxios.post(`${CARRIERS_API_URL}/label-packs`, data)
}

const tryToCreateThread = (
    ticketId: string,
    data: ITicketThread,
): Promise<AxiosResponse> => {
    return csAxios.post(
        `${CS_API_URL}/support/ticket/${ticketId}/create-thread`,
        data,
    );
};

const tryToAddTicketThread = (
    ticketId: string,
    data: ITicketThread,
): Promise<AxiosResponse> => {
    return csAxios.post(
        `${CS_API_URL}/support/ticket/${ticketId}/create-thread`,
        data,
    );
};

export const CustomerCareService = {
    tryToFetchFilteredTickets,
    tryToFetchUserTickets,
    tryToAddTicket,
    tryToFetchCsTickets,
    tryToFetchSingleTicket,
    tryToFetchMarkets,
    tryToRaiseATicket,
    tryToCloseTicket,
    tryToCreateThread,
    tryToAddTicketThread,
    tryToDeleteTicket,
    tryToDeleteTicketThread,
    tryToFetchWarehouses,
    tryToChangeAssignedUserTicket,
    tryToGenerateLabelPacks
};
