import React from 'react';
import WhiteContainer from '../../../../shared/white-container';


interface ISellerBankDetails {
    sellerDetails: any
}

const SellerBankDetails = ({
    sellerDetails
}: ISellerBankDetails) => {
    return (
        <WhiteContainer containerStyle='mb-4 rounded !h-auto'>
            <p className='gradient-text mb-6'>External Bank Account</p>
            <div>
                <div className='grid grid-cols-2 gap-4 mb-4'>
                    <p>Account Number</p>
                    <p>{sellerDetails?.bankDetails?.accountNumber}</p>
                </div>
                <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                <div className='grid grid-cols-2 gap-4  mb-4'>
                    <p>Account Name</p>
                    <p>{sellerDetails?.bankDetails?.accountName}</p>
                </div>
                <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                <div className='grid grid-cols-2 gap-4  mb-4'>
                    <p>Sort Code</p>
                    <p>{sellerDetails?.bankDetails?.sortCode}</p>
                </div>
                <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
            </div>
        </WhiteContainer>
    );
};

export default SellerBankDetails;
