import React from 'react'
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import Button from '../../../../shared/button';
import Table from '../../../../shared/table';

const tableColumns = ['Assignee', 'Country', 'Broker', 'Item Code', 'Item Name', 'User ID', 'User Full Name', 'User email', 'Order Type', 'Actions'];

interface IRejectedRevaluationsTable {
    rows: Array<any>;
    paginationData?: any;
    accessControl?: any;
    rowsLoading?: boolean;
    actions?: any;
    categories: IAssetTemplate[],
    markets: IAllMarkets[],
    showActions: { name: string, icon: string }[]
    onClickAction: (data: { actionName: string, id?: string, itemId?: string, country?: string, current?: any }) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    selectedTab: string
}

const OpenCasesTable = ({
    rows,
    paginationData,
    accessControl,
    rowsLoading,
    actions,
    categories,
    markets,
    showActions,
    onClickAction,
    setPaginationState,
    selectedTab
}: IRejectedRevaluationsTable) => {

    const getItemIcon = (item: any) => {
        const res = categories.find(category => category.name === item.category)
        return res?.disabledIcon
    }
    const getItemFlag = (item: any) => {
        const res = markets.find(market => market.displayName === item.seller.profile.country)
        return res?.flagUrl
    }

    return (
        <Table containerStyle={'mt-0'}
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {(rowsLoading ?
                <>
                    {[...Array(10)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {tableColumns?.map(element => {
                                    return (
                                        <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })
                                }
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 &&
                        rows?.map((row, idx: number) => {
                            return (
                                <tr
                                    key={idx}
                                    className={'border-b hover:bg-gray-50 font-normal bg-white'}
                                >
                                    <td className='px-6 py-4'>
                                        {row?.assignedCs ? <span onClick={() => (!accessControl || actions?.Assign) && onClickAction({ actionName: 'Assign', id: row?.id, itemId: row?.itemId, country: row?.seller?.profile?.country, current: row.assignedCs })} className={`text-[#202020] text-sm underline flex w-full justify-center ${(!accessControl || actions?.Assign) && 'cursor-pointer'}`}>{row.assignedCs.name} </span> :
                                            <Button
                                                label="Assign"
                                                onClickButton={() => (!accessControl || actions?.Assign) && onClickAction({ actionName: 'Assign', id: row?.id, itemId: row?.itemId, country: row?.seller?.profile?.country })}
                                                className={`${(!accessControl || actions?.Assign) ? 'btn-main' : 'btn-main-disable'} min-w-full pb-2e`}
                                                dataQa='open-cases-assign'
                                            />}
                                    </td>
                                    <td className='px-6 py-6 flex gap-1 items-center'>
                                        <img className='w-6 h-6' src={getItemFlag(row)} />
                                        <span className='text-nowrap'>{row?.seller.profile.country || ''}</span>
                                    </td>
                                    <td className='px-6 py-4'>{row?.exchangeDefinition.broker.name || ''}</td>
                                    <td className='px-6 py-4'>{row?.itemCode || ''}</td>
                                    <td className='px-6 py-6 flex gap-1 items-center'>
                                        <img className='w-6 h-6' src={getItemIcon(row)} />
                                        <span className='text-nowrap'>{row?.itemName || ''}</span>
                                    </td>
                                    <td className='px-6 py-4'>{row?.seller._id || ''}</td>
                                    <td className='px-6 py-4'>{row?.seller.profile.firstName + ' ' + row?.seller.profile.lastName || ''}</td>
                                    <td className='px-6 py-4'>{row?.seller.email || ''}</td>
                                    <td className='px-6 py-4'>{row?.isMultipleCashout ? 'Multiple Cashout' : 'Single Cashout'}</td>
                                    <td className='px-6 py-4'>
                                        <div className='flex flex-row gap-2'>
                                            {showActions.filter((action: { name: string, icon: string }) => action.name !== 'Mark as completed' || ((selectedTab === 'rejectedFromUser' || selectedTab === 'awaitingUserResponse') && row.deliveryStatus === 'reviewed' || selectedTab === 'rejectedFromBroker')).map((action: { name: string, icon: string }, index: number) => (<div key={index} className='h-6 w-6' data-qa={action.name.toLowerCase().replaceAll(' ', '-')} onClick={() => (!accessControl || actions?.[action.name]) && row?.id && row.itemId && onClickAction({ actionName: action.name, id: row?.id, itemId: row?.itemId, country: row?.seller?.profile?.country, current: row })}>
                                                <img title={action.name} alt={action.name} src={action.icon} className={`object-contain ${(!accessControl || actions?.[action.name]) ? 'cursor-pointer' : 'cursor-not-allowed'}`} />
                                            </div>))
                                            }
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                </>
            )}
        </Table>
    )
}

export default OpenCasesTable