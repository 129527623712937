import React from 'react';
import WhiteContainer from '../../../../shared/white-container';
import { onFindSourceName } from '../../../../utils/constants/order-source-format';


interface ICashoutInfo {
    isDonation?: boolean;
    warehouseAccepted?: boolean;
    category?: string;
    subcategory?: string;
    source?: string;
    sellerId?: string;
    hasBeenCashedOut?: boolean;
    orderStatus?: string;
    itemCode?: string;
    itemId?: string;
    country?: string;
    invoiceUrl?: string;
}

const CashoutInfo = ({
    isDonation,
    warehouseAccepted,
    source,
    country,
    category,
    subcategory,
    sellerId,
    itemCode,
    itemId,
    hasBeenCashedOut,
}: ICashoutInfo) => {
    const findSource = onFindSourceName(source || '')
    return (
        <WhiteContainer containerStyle='!h-auto mb-2 !bg-[#f4f4f4] rounded'>
            <p className='font-bold text-[20px] mb-4'>Cashout Details</p>
            <div>
                <div className='grid grid-cols-2 gap-3 my-2'>
                    <p className='font-bold text-md'>Property</p>
                    <p className='font-bold text-md'>Value</p>
                </div>
                {itemCode &&
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>Item Code</p>
                            {itemCode}
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                }
                {itemId &&
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p>Item ID</p>
                            <p className='!break-all'>{itemId}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                }
                {itemId &&
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p>Item ID</p>
                            <p className='!break-all'>{itemId}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                }
                {country &&
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>Country</p>
                            {country}
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                }
                {category &&
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p>Category</p>
                            <p className='!break-all'>{category}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                }
                {subcategory &&
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p>Subcategory</p>
                            <p className='!break-all'>{subcategory}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                }
                <>
                    <div className='grid grid-cols-2 gap-3 my-2'>
                        <p>Is Donation</p>
                        <p className='!break-all'>{isDonation ? 'Yes' : 'No'}</p>
                    </div>
                    <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                </>
                <>
                    <div className='grid grid-cols-2 gap-3 my-2'>
                        <p>Warehouse Accepted</p>
                        <p className='!break-all'>{warehouseAccepted ? 'Yes' : 'No'}</p>
                    </div>
                    <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                </>
                <>
                    <div className='grid grid-cols-2 gap-3 my-2'>
                        <p>Has Been Cashed Out</p>
                        <p className='!break-all'>{hasBeenCashedOut ? 'Yes' : 'No'}</p>
                    </div>
                    <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                </>
                {sellerId &&
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p>Seller ID</p>
                            <p className='!break-all'>{sellerId}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                }
                {source && (
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>Order Source</p>
                            <p>{findSource || source}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                )}
            </div>
        </WhiteContainer>
    );
};

export default CashoutInfo;
