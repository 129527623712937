
export const ORDER_DETAILS_ACTIONS = [
    { name: 'Accept Offer', label: 'Accept Offer', icon: '/assets/order-actions/accept.svg' },
    { name: 'Accept Offer with Gesture', label: 'Accept Offer with Gesture', icon: '/assets/order-actions/accept.svg' },
    { name: 'Reject Offer', label: 'Reject Offer', icon: '/assets/order-actions/reject.svg' },
    { name: 'Cancel Order', label: 'Cancel Order', icon: '/assets/order-actions/reject-new.svg' },
    { name: 'Reset Order', label: 'Reset Order', icon: '/assets/order-actions/reset-order.svg' },

    // { name: 'Reject', icon: '/assets/order-actions/reject.svg' },
    // { name: 'Missing Package', label: 'Missing Package', icon: '/assets/order-actions/damaged-package-new.svg' },
    // { name: 'Revalue', icon: '/assets/order-actions/revalue.svg' },
    // { name: 'Inventory', label: 'Inventory', icon: '/assets/order-actions/inventory.svg' },
    // { name: 'Delivered', label: 'Received', icon: '/assets/order-actions/delivered.svg' },
    { name: 'Raise A Ticket', label: 'Raise A Ticket', icon: '/assets/order-actions/contact-cs-new.svg' },
    { name: 'Remove Blacklist status', label: 'Remove Blacklist status', icon: '/assets/order-actions/accept.svg' },
    { name: 'Add item to inventory', label: 'Add item to inventory', icon: '/assets/order-actions/inventory.svg' },
    { name: 'Return item to wing', label: 'Return item to wing', icon: '/assets/order-actions/inventory.svg' },
    // { name: 'Review', label: 'Review', icon: '/assets/order-actions/revalue-new.svg' },
    { name: 'Return Requested', label: 'Return Requested', icon: '/assets/order-actions/product-return1.svg' },
]

export const ORDER_TYPES = [
    {
        displayName: 'All Order Types',
        name: 'All Order Types',
        label: 'All Order Types'
    },
    {
        displayName: 'Single Cashout',
        name: 'Single Cashout',
        label: 'Single Cashout',
        value: false
    },
    {
        displayName: 'Multiple Cashout',
        name: 'Multiple Cashout',
        label: 'Multiple Cashout',
        value: true
    },
]