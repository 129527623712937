import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../../../../store/auth/authApi';
import { ErrorPayload } from '../../../../interfaces/auth/IErrorPayload';
import { UserPassPayload } from '../../../../interfaces/auth/IUser';
import Input from '../../../../shared/input';
import Loading from '../../../../shared/Loading';


const LoginForm = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<UserPassPayload>();
    const [loginMutation, { isLoading, error }] = useLoginMutation();
    const [loginError, setLoginError] = useState<string>()
    const navigate = useNavigate();

    const onSubmit = async (payload: UserPassPayload) => {
        const { data } = await loginMutation(payload).unwrap();
        const roles = data.servicePayload.accessToken.payload['cognito:groups'];

        if (roles?.[0] === 'cs') {
            localStorage.setItem('accessToken', data.token);
            localStorage.setItem('refreshToken', data.refreshToken);
            localStorage.setItem('roles', roles?.[0] || '');
            localStorage.setItem('cgToken', data?.servicePayload?.accessToken?.jwtToken || '')
            localStorage.setItem('user',JSON.stringify(data?.servicePayload?.idToken?.payload || ''))
            navigate('/tickets');
        } else {
            setLoginError('Only Customer Support users can login')
        }


    }
    const err = { ...error as ErrorPayload };
    const errorMsg = loginError || err.status === 401 ? 'Username or password are incorrect' : 'Something went wrong';
    return (
        <>
            <form className='mt-8 mb-5' onSubmit={handleSubmit(onSubmit)}>
                {(!!err.data || loginError) && <div className='p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800' role='alert'>
                    <span className='font-medium'>{loginError || err.data.message || errorMsg} </span>
                </div>}
                <Input
                    placeholder='Enter username or email address'
                    register={register('username', {
                        required: {
                            message: 'Username is required',
                            value: true,
                        },
                        validate: (value: string) => !!value.trim() || 'Username is required'
                    })}
                    error={errors.username?.message}
                    dataQa={'username-login'}
                />
                <Input
                    placeholder='Password'
                    type='password'
                    register={register('password', {
                        required: {
                            message: 'Password is required',
                            value: true,
                        },
                    })}
                    error={errors.password?.message}
                    dataQa={'password-login'}
                />
                <button data-qa={'submit-login'} className=' mt-1 w-full justify-center flex btn-orange-rounded-less py-2 px-5 rounded' type='submit'>
                    {isLoading && <Loading />}
                    Login
                </button>
            </form>
        </>
    )
}

export default LoginForm;