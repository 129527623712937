import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IToast } from '../../../interfaces/components/IToast';
import { IFlaggedUser } from '../../../interfaces/flagged-users/IFlaggedUser';
import { tryToFetchFlaggedUsersDetails } from '../../../store/customer-support/flagged-user/FlaggedUserSlice';
import LockedModal from './modals/LockedModal';
import DisableFeeModal from './modals/DisableFeeModal';
import Toast from '../../../shared/toast';
import Button from '../../../shared/button';
import Loading from '../../../shared/Loading';
import EmptyContainer from '../../../shared/empty-container';


const FlaggedUserDetails = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const [flaggedUserDetails, setFlaggedUserDetails] = useState<IFlaggedUser>()
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [showToast, setShowToast] = useState<IToast | null>();
    const [openLockedModal, setOpenLockedModal] = useState<boolean>(false);
    const [showActionsModal, setShowActionsModal] = useState<boolean>(false)
    const [pageAccess, setPageAccess] = useState<any>()
    const [showDisableConfirmModal, setShowDisableConfirmModal] = useState<{ show: boolean, item: any }>()
    const accessControl = useAppSelector((state) => state?.partners?.navigation);
    const loading = useAppSelector((state) => state.flaggedUsers.flaggedUserIsLoading);
    const state = useAppSelector((state) => state.flaggedUsers);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Flagged Users')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Flagged User Details')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state?.flaggedUserDetail) {
            setFlaggedUserDetails(state?.flaggedUserDetail)
        }
    }, [state?.flaggedUserDetail,]);


    useEffect(() => {
        if (id) {
            dispatch(tryToFetchFlaggedUsersDetails(id));
        }
    }, [id]);

    const onGoBack = () => {
        navigate('/flagged-users');
    };

    const onClickChangeStatusButton = () => {
        setShowEditModal(!showEditModal)
        if (id) {
            dispatch(tryToFetchFlaggedUsersDetails(id));
        }
    }

    const onSuccessfullyChanged = (type: string, message: string) => {
        setShowToast({ type, message })
        setShowModal(false)
        setShowDisableConfirmModal(undefined)
    }

    const onClickActionsButton = () => {
        setShowActionsModal(!showActionsModal)
    }

    const onUnlockAccount = (id: string) => {
        onClickActionsButton();
        setShowActionsModal(true);
        setShowModal(true);
        setOpenLockedModal(true);
    };

    const onCloseModal = () => {
        setOpenLockedModal(false)
    }

    const onClickDisableFee = (item: any) => {
        setShowDisableConfirmModal({ show: true, item: item })
    }

    return (
        <div className='h-full w-full flex flex-col gap-6'>
            <div className='flex h-10'>
                <div className='flex flex-row items-center'>
                    <div data-qa={'back-button'} onClick={onGoBack} className='md:-ml-2 xl:-ml-12 md:mr-2 xl:mr-10'>
                        <img src={'/assets/shared/go-back.svg'} className={'w-[18px] object-contain cursor-pointer'} />
                    </div>
                    <p className='page-title'>Flagged User Details</p>
                </div>
            </div>
            <div className='mb-5 relativ'>
                {loading ? (
                    <div className='my-3 flex flex-row justify-center items-center'>
                        <Loading />
                    </div>
                ) : (
                    <>
                        {flaggedUserDetails ? (
                            <div className='grid grid-cols-9 gap-4 mb-4'>
                                <div className='bg-white py-2 px-4 col-span-4'>
                                    <div className='mb-3 shadow-md rounded w-full p-4 bg-[#fff]'>
                                        <p className='font-medium mr-2'>Cashout Info History</p>
                                        <div className='grid grid-cols-1 gaps-4'>
                                            {flaggedUserDetails?.cashoutInfo?.events.map((event, idx: number) => {
                                                return (
                                                    <div
                                                        className='mb-3 shadow-md rounded w-full p-4 bg-[#fff]'
                                                        key={idx}>
                                                        {event?.user &&
                                                            <div className='grid grid-cols-2 gap-1 my-2'>
                                                                <p className='font-medium mr-2'>Username</p>
                                                                <p className='text-primary-light !break-all'>{event?.user || ''}</p>
                                                            </div>
                                                        }
                                                        {event?.type &&
                                                            <div className='grid grid-cols-2 gap-1 my-2'>
                                                                <p className='font-medium mr-2'>Action</p>
                                                                <p className='text-primary-light !break-all'>{event?.type === 'CASHOUT_ENABLED' ? 'This account is now activated.' : 'Account has been locked. This user cannot currently cashout in the App.'}</p>
                                                            </div>
                                                        }
                                                        {event?.timestamp &&
                                                            <div className='grid grid-cols-2 gap-1 my-2'>
                                                                <p className='font-medium mr-2'>Date</p>
                                                                <p className='text-primary-light !break-all'>{event?.timestamp && moment(event?.timestamp).format('MMMM Do YYYY, h:mm:ss a')}</p>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-white py-2 px-4 col-span-3'>
                                    <div className='mb-3 shadow-md rounded w-full p-4 bg-[#fff]'>
                                        <p className='font-medium mr-2'>Cashout Fees</p>
                                        <div className='grid grid-cols-1 gaps-4'>
                                            {flaggedUserDetails?.cashoutInfo?.cashoutFees.map((fees, idx: number) => {
                                                return (
                                                    <div
                                                        className='mb-3 shadow-md rounded w-full p-4 bg-[#fff]'
                                                        key={idx}>
                                                        {fees?.amount &&
                                                            <div className='grid grid-cols-2 gap-1 my-2'>
                                                                <p className='font-medium mr-2'>Fees</p>
                                                                <p className='text-primary-light !break-all'>{fees?.amount}</p>
                                                            </div>
                                                        }
                                                        {fees?.active &&
                                                            <>
                                                                <div className='grid grid-cols-2 gap-1 my-2'>
                                                                    <p className='font-medium mr-2'>Is Active</p>
                                                                    <p className='text-primary-light !break-word'>{(fees?.active) === true ? 'User has to pay an additional fee to ship their items.' : 'User doesen`t has to pay an additional fee for shipping.'}</p>
                                                                </div>
                                                                <div className='flex flex-row justify-end'>
                                                                    <Button
                                                                        className={`${(!accessControl || pageAccess?.actions?.['Disable Fee']) ? 'btn-error' : 'btn-main-disable'} my-3`}
                                                                        label='Disable Fee'
                                                                        dataQa={'disable-fee'}
                                                                        onClickButton={() => (!accessControl || pageAccess?.actions?.['Disable Fee']) && onClickDisableFee(fees)}
                                                                    />
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-white py-2 px-4 col-span-2'>
                                    {flaggedUserDetails &&
                                        (<div className='grid grid-cols-2 gap-1 my-2'>
                                            <p className='font-medium mr-2'>Status</p>
                                            <p className='text-primary-light !break-all'>{flaggedUserDetails.cashoutInfo?.isCashoutEnabled === true ? 'Unlocked Account' : 'Locked Account'}</p>
                                        </div>)
                                    }
                                    <div className='pb-4 pt-4'>
                                        <p className='font-medium mr-2'>Actions</p>
                                    </div>
                                    <div className='grid grid-cols-4'>
                                        {flaggedUserDetails?.cashoutInfo?.isCashoutEnabled === true ? (
                                            <div data-qa={'unlock-account'} onClick={() => (!accessControl || pageAccess?.actions?.['Lock Account']) && id && onUnlockAccount(id)} className={`flex flex-col  items-center mt-4 ${(!accessControl || pageAccess?.actions?.['Lock Account']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}>
                                                <img title={'Unlock Account'} alt='Unlock Account' src={'/assets/flagged-actions/unlock-account.svg'} className={'h-[40px] object-contain cursor-pointer mr-1 pb-1'} />
                                                <p className='text-xs text-center w-[80%]'>Lock Account</p>
                                            </div>
                                        ) : (
                                            <div data-qa={'locked-account'} onClick={() => (!accessControl || pageAccess?.actions?.['Lock Account']) && id && onUnlockAccount(id)} className={`flex flex-col  items-center mt-4 ${(!accessControl || pageAccess?.actions?.['Lock Account']) ? 'cursor-pointer' : 'cursor-not-allowed'}`}>
                                                <img title={'Locked Account'} alt='Locked Account' src={'/assets/flagged-actions/locked-account.svg'} className={'h-[40px] object-contain cursor-pointer mr-1 pb-1'} />
                                                <p className='text-xs text-center w-[80%]'>Unlock Account</p>
                                            </div>
                                        )
                                        }
                                    </div>
                                    {showModal &&
                                        <LockedModal
                                            openLockedModal={openLockedModal}
                                            handleCloseModal={() => onCloseModal()}
                                            onSuccessfullyChanged={onClickChangeStatusButton}
                                            selectedId={id || ''}
                                        />
                                    }
                                </div>
                            </div>
                        ) : (
                            <EmptyContainer
                                text={'No Transaction found with this id!'}
                                showImage={true}
                            />
                        )}
                    </>
                )}
            </div>
            {showDisableConfirmModal?.show &&
                <DisableFeeModal
                    openModal={showDisableConfirmModal?.show}
                    userId={id || ''}
                    handleCloseModal={() => setShowDisableConfirmModal(undefined)}
                    onApproveAction={onSuccessfullyChanged}
                />
            }
            {showToast?.message &&
                <Toast
                    type={showToast?.type}
                    text={showToast?.message}
                    onHandleCloseToast={() => setShowToast(null)}
                />
            }
        </div >
    );
};

export default FlaggedUserDetails;