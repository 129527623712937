import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { tryToFetchSellerActivity } from '../../../../store/customer-support/sellers/sellersSlice';
import SellerUserActivityTable from '../tables/SellerUserActivityTable';
import EmptyContainer from '../../../../shared/empty-container';


const UserActivityTab = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams()
    const [rows, setRows] = useState<any>([]);
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>()
    const paginationData = useAppSelector((state) => state.sellers.sellerActivity?.data?.page);
    const rowsLoading = useAppSelector((state) => state.sellers.sellerActivitiesAreLoading);
    const state = useAppSelector((state) => state.sellers);
    const accessControl = useAppSelector((state) => state?.partners?.navigation);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Wing Users')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Wing User Details')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    useEffect(() => {
        if (state.sellerActivity) {
            const rows = state.sellerActivity?.data?.elements || []
            setRows(rows);
        }
    }, [state.sellerActivity]);

    const onGettingActivityData = async () => {
        try {
            const formatData: any = {
                ...paginationState || {},
                sellerId: id || ''
            }
            await dispatch(tryToFetchSellerActivity(formatData)).unwrap();
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        if (id) {
            onGettingActivityData()
        }
    }, [paginationState, id]);

    return (
        <div className='h-auto w-full flex flex-col gap-2 my-6'>
            <div className='mb-5 relativ'>
                <>
                    {(rowsLoading || rows?.length > 0) ? (
                        <SellerUserActivityTable
                            rows={rows}
                            actions={pageAccess?.actions}
                            accessControl={accessControl}
                            cashoutsLoading={rowsLoading}
                            paginationData={paginationData}
                            setPaginationState={setPaginationState}
                        />
                    ) : (
                        <EmptyContainer
                            text={'No activity yet.'}
                            showImage={true}
                        />
                    )}
                </>
            </div>
        </div>
    )
}

export default UserActivityTab;