
import React from 'react'
import { IMarket } from '../../../../interfaces/markets/IMarket';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Input from '../../../../shared/input';
import Button from '../../../../shared/button';


interface IOpenCasesFilters {
    markets?: Array<IMarket>;
    sectors?: Array<IAssetTemplate>;
    selectedMarket?: string;
    selectedSector?: string;
    assignees: any[],
    selectedAssignee: string | undefined,
    orderTypes: object[],
    selectedOrderType: string | undefined,
    searchValue: any,
    onSelectFilterOption: (value: any, name?: string) => void;
    onChangeSearchValue: (value: string | null | undefined, type: string) => void,
    clearAllFilters: () => void
}

const OpenCasesFilters = ({
    markets,
    sectors,
    selectedMarket,
    selectedSector,
    assignees,
    selectedAssignee,
    orderTypes,
    selectedOrderType,
    searchValue,
    onSelectFilterOption,
    onChangeSearchValue,
    clearAllFilters
}: IOpenCasesFilters) => {
    return (
        <div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1 md:gap-2'>
                <SelectCheckbox
                    name={'Assignee'}
                    containerStyle={'!min-w-[200px]'}
                    placeholder={selectedAssignee || 'Select'}
                    options={assignees || []}
                    uniqueName={'assignee'}
                    onChangeSelectedOption={onSelectFilterOption}
                    dataQa={`${selectedAssignee}`}
                />
                <SelectCheckbox
                    name={'Market'}
                    containerStyle={'!min-w-[200px]'}
                    placeholder={selectedMarket || 'Select'}
                    options={markets || []}
                    uniqueName={'market'}
                    onChangeSelectedOption={onSelectFilterOption}
                    dataQa={`${(selectedMarket || '')?.toLowerCase().replaceAll(' ', '-')}`}
                />
                <SelectCheckbox
                    name={'Category'}
                    containerStyle={'!min-w-[200px]'}
                    placeholder={selectedSector || 'Select'}
                    options={sectors || []}
                    uniqueName={'category'}
                    onChangeSelectedOption={onSelectFilterOption}
                    dataQa={`${selectedSector?.toLowerCase().replaceAll(' ', '-')}`}
                />
                <SelectCheckbox
                    name={'Order Type'}
                    containerStyle={'!min-w-[200px]'}
                    placeholder={selectedOrderType || 'Select'}
                    options={orderTypes || []}
                    uniqueName={'orderType'}
                    onChangeSelectedOption={onSelectFilterOption}
                    dataQa={`${selectedOrderType?.toLowerCase().replaceAll(' ', '-')}`}
                />
            </div>
            <div className={'grid grid-cols-12 py-2 pl-3 rounded-xl bg-[#F7A21E1A]'}>
                <div className={'col-span-7'}>
                    <Input
                        placeholder={'Filter by User ID, Email, Item ID, Tracking Number or Order ID  '}
                        dataQa={'filter-table'}
                        containerStyle='mb-0 pb-0 relative'
                        inputStyle={'mb-0 border-0 focus:outline-none rounded-xl'}
                        icon={'/assets/shared/search.svg'}
                        showValue={true}
                        inputValue={searchValue}
                        inputUniqueName={'search'}
                        onChangeInput={onChangeSearchValue}
                        hideInputSpace={true}
                    />
                </div>
                <Button
                    label={'Clear all'}
                    dataQa={'clear-all-filters'}
                    className={'btn-primary-text-underline min-w-auto flex flex-row justify-end bg-transparent mt-1'}
                    onClickButton={clearAllFilters}
                />
            </div>
        </div>)
}

export default OpenCasesFilters