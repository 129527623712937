import React from 'react';
import { ISingleOrderSchema } from '../../../../interfaces/orders/IOrdersSchema';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import WhiteContainer from '../../../../shared/white-container';


interface IProductInfo {
    orderDetails?: ISingleOrderSchema,
    totalCost?: number;
    pricesStyle?: string;
}

const ProductInfo = ({
    orderDetails,
    totalCost,
    pricesStyle
}: IProductInfo) => {
    return (
        <WhiteContainer containerStyle='!h-auto !rounded'>
            <div>
                <p className='font-bold text-[20px] mb-4'>Purchase Summary</p>
                <div className={pricesStyle || ''}>
                    <div className='flex flex-row justify-between items-center my-2'>
                        <p>{(orderDetails?.item?.revaluedPrice || orderDetails?.item?.revaluedPrice === 0) ? 'Revalued Price' : 'Purchase Price'}</p>
                        <p className='font-bold'>{CURRENCY_SYMBOLS?.[orderDetails?.orderData?.localizedSalePrice?.currencyCode || '']}{Number(orderDetails?.item?.revaluedPrice || orderDetails?.orderData?.localizedSalePrice?.amount || 0).toFixed(2)}</p>
                    </div>
                    {(orderDetails?.item?.revaluedPrice || orderDetails?.item?.revaluedPrice === 0) &&
                        <div className='flex flex-row justify-between items-center my-2'>
                            <p>Initial Price</p>
                            <p className='font-bold'>{CURRENCY_SYMBOLS?.[orderDetails?.orderData?.localizedInitialPrice?.currencyCode || '']}{Number(orderDetails?.orderData?.localizedInitialPrice?.amount || 0).toFixed(2)}</p>
                        </div>
                    }
                    <div className='flex flex-row justify-between items-center my-2'>
                        <p>Commission</p>
                        <p className='font-bold'>{CURRENCY_SYMBOLS?.[(orderDetails?.orderData?.localizedCommission?.currencyCode || '')]}{Number(orderDetails?.orderData?.localizedCommission?.amount || 0).toFixed(2)}</p>
                    </div>
                    <div className='flex flex-row justify-between items-center my-2'>
                        <p>Inbound Logistics Cost</p>
                        <p className='font-bold'>{CURRENCY_SYMBOLS?.[(orderDetails?.orderData?.localizedInboundCost?.currencyCode || '')]}{Number(orderDetails?.orderData?.localizedInboundCost?.amount || 0).toFixed(2)}</p>
                    </div>
                    {orderDetails?.item?.gestureAmount && <div className='flex flex-row justify-between items-center my-2'>
                        <p>Gesture Amount</p>
                        <p className='font-bold'>{CURRENCY_SYMBOLS?.[(orderDetails?.orderData?.localizedInboundCost?.currencyCode || '')]}{Number(orderDetails?.item?.gestureAmount || 0).toFixed(2)}</p>
                    </div>}
                    <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    <div className='flex flex-row justify-between items-center my-4'>
                        <p>Total</p>
                        <p className='font-bold'>{CURRENCY_SYMBOLS?.[(orderDetails?.orderData?.localizedSalePrice?.currencyCode || '')]}{Number(totalCost || 0)?.toFixed(2)}</p>
                    </div>
                </div>
            </div>
        </WhiteContainer>
    )
}

export default ProductInfo;