import React from 'react';
import WhiteContainer from '../../../../shared/white-container';


const ContactHistoryContainer = () => {
    return (
        <WhiteContainer>
            <div className='p-4'>
                <p className='font-bold text-md mb-4'>CS History</p>

                {[...Array(3)].map((x, i) => {
                    return (
                        <div key={i} className='my-2 max-w animate-pulse'>
                            <div className='h-3 bg-gray-200 rounded-full dark:bg-gray-200 w-[95%] mb-4'></div>
                            {/* <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[80%] mb-1'></div> */}
                        </div>
                    )
                })
                }
            </div>
        </WhiteContainer>
    )
}

export default ContactHistoryContainer;