import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToChangeAssignedUserTicket } from '../../../../store/customer-support/customer-care/customerCareSlice';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';


interface ICsAssignedModal {
    openModal: boolean;
    id: string;
    users?: any;
    assignedUser?: any;
    handleCloseModal: () => void;
    onSuccessfullySaved: (body: any) => void;
}

const CsAssignedModal = ({
    openModal,
    id,
    users,
    assignedUser,
    handleCloseModal,
    onSuccessfullySaved,
}: ICsAssignedModal) => {
    const dispatch = useAppDispatch()
    const [selectedCSUser, setSelectedCSUser] = useState<any>()
    const [submitError, setSubmitError] = useState<string>()
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

    const onChangeSelectedRole = (e: any) => {
        setSelectedCSUser(e)
    }

    const onCloseModal = () => {
        handleCloseModal();
        setSelectedCSUser(undefined)
        setSubmitError(undefined)
        setLoadingSubmit(false)
    }

    useEffect(() => {
        if (assignedUser) {
            setSelectedCSUser({ label: assignedUser?.name, value: assignedUser?.username })
        } else {
            setSelectedCSUser(undefined)
        }
    }, [assignedUser])

    const onSubmit = async (type?: string) => {
        setLoadingSubmit(true)
        try {
            const body = {
                ticketId: id,
                username: type === 'delete' ? null : selectedCSUser?.value
            }
            await dispatch(tryToChangeAssignedUserTicket(body)).unwrap()
            onSuccessfullySaved(body)
            onCloseModal()
        } catch (err) {
            setSubmitError(`${err}`)
        }
        setLoadingSubmit(false)
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[350px] min-h-[300px]'}>
                <p className='mb-2 font-semibold text-sm'>Assign CS User</p>
                <p className='mb-4 text-sm'>Select user you want to assign to this ticket.</p>
                {submitError && <Error text={submitError} />}
                <div className='flex flex-col items-center'>
                    <SelectCheckbox
                        name='CS Users'
                        dataQa={'cs-users'}
                        placeholder=' '
                        containerStyle='mb-8 min-w-full'
                        selectedOption={selectedCSUser}
                        onChangeSelectedOption={onChangeSelectedRole}
                        options={users || []}
                    />
                    <div className='flex flex-rows justify-around items-center'>
                        {assignedUser &&
                            <Button
                                className='btn-error !shadow-none min-w-[130px] mr-3'
                                label='Remove assingment'
                                dataQa={'remove-assignment'}
                                onClickButton={() => onSubmit('delete')}
                            />
                        }
                        <Button
                            className={`${loadingSubmit ? 'btn-primary-rounded-less-disable' : 'btn-primary-rounded-less'} !shadow-none min-w-[130px]`}
                            label='Submit'
                            dataQa={'submit-btn'}
                            onClickButton={onSubmit}
                        />
                    </div>
                </div>
            </div>
        </Modal >
    )
}
export default CsAssignedModal;