import React from 'react';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import { onFormatCashoutType, onFormatPackageStatus } from '../../../../utils/constants/format-order-statuses';
import Table from '../../../../shared/table';
import Button from '../../../../shared/button';

const tableColumns = ['Cashout Id', 'Item ID', 'Full Name', 'Price', 'Status', 'Cashout Type', 'Tracking Number', 'Order Number', 'Shipping Provider', 'Shipping  Label', 'Generate Label Pack', 'Invoice URL', 'Actions']
const tableKeys = ['id', 'itemId', 'fullName', 'price', 'orderStatus', 'cashoutType', 'trackingNumber', 'orderNumber', 'shippingProvider', 'shippingLabel', 'generateLabel', 'invoiceUrl']

interface ISellerCashoutsTable {
    rows: any;
    paginationData?: IPage;
    cashoutsLoading: boolean;
    accessControl?: any;
    actions?: any;
    onSeeDetails: (id: string, itemId: string, country: string) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onClickGenerateLabelPacks: (item: any) => void;
}

const SellerCashoutsTable = ({
    rows,
    paginationData,
    cashoutsLoading,
    accessControl,
    actions,
    onSeeDetails,
    setPaginationState,
    onClickGenerateLabelPacks
}: ISellerCashoutsTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}>
            {cashoutsLoading ?
                <>
                    {[...Array(10)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {tableColumns?.map(element => {
                                    return (
                                        <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                {tableKeys?.length > 0 && tableKeys?.map((key, index) => {
                                    if (key !== 'trackingNumber') {
                                        let value = row?.[key]
                                        let item = null
                                        if (key === 'orderStatus') {
                                            value = onFormatPackageStatus(row?.[key])?.name
                                            const color = onFormatPackageStatus(row?.[key])?.color
                                            const text = onFormatPackageStatus(row?.[key])?.text || 'text-black'
                                            item = color && <div className={`${color} flex flex-row justify-center text-center ${text} px-1 min-w-[150px] text-sm py-1 font-medium rounded-2xl`}>{value}</div>
                                        }
                                        if (key === 'price') {
                                            value = Number(row?.[key] || 0).toFixed(2)
                                            item = `${CURRENCY_SYMBOLS?.[`${(row?.localizedPrice?.currencyCode || row?.currency || '')}`]}${value}`
                                        }
                                        if (key === 'id') {
                                            value = row?.id
                                        }
                                        if (key === 'cashoutType') {
                                            value = onFormatCashoutType(row?.[key])?.name
                                            const color = onFormatCashoutType(row?.[key])?.color
                                            item = color && <div className={`${color} flex flex-row justify-center text-center text-red px-1 min-w-[150px] text-black text-sm py-1 font-medium rounded-2xl`}>{value}</div>
                                        }
                                        if (key === 'fullName') {
                                            value = `${row?.seller?.profile?.firstName || ''} ${row?.seller?.profile?.lastName || ''}`
                                        }
                                        if (key === 'generateLabel') {
                                            value = <Button
                                                className={row?.status === 'not_shipped' ? 'btn-main' : 'btn-main-disable'}
                                                label='Generate Label'
                                                onClickButton={() => row?.status === 'not_shipped' && onClickGenerateLabelPacks(row)}
                                            />
                                        }
                                        if (key === 'shippingLabel' && (row?.qrCode || row?.shippingLabel || row?.shippingUrl)) {
                                            value = <a
                                                rel='noreferrer'
                                                href={row?.qrCode || row?.shippingLabel || row?.shippingUrl}
                                                target='_blank'
                                                className='text-primary-default'
                                                data-qa={`shipping_label_${row?.trackingNumber || ''}`}
                                            >{row?.qrCode ? 'QR CODE' : row?.shippingLabel ? 'SHIPPING LABEL' : row?.shippingUrl ? 'SHIPPING URL' : null}</a>
                                        }
                                        if (key === 'invoiceUrl' && row?.invoiceUrl) {
                                            value = <a
                                                rel='noreferrer'
                                                href={row?.invoiceUrl}
                                                target='_blank'
                                                className='text-primary-default'
                                                data-qa={`invoice_url_${row?.trackingNumber}`}
                                            >{row?.invoiceUrl}</a>
                                        }
                                        return (
                                            <td
                                                key={index}
                                                className={key === 'generateLabel' ? 'px-6 py-4 min-w-[170px]' : 'px-6 py-4'}>
                                                {item || value}
                                            </td>
                                        )
                                    } else {
                                        return (
                                            <td key={index} className='px-6 py-4'>
                                                <a
                                                    rel='noreferrer'
                                                    href={`https://parcelsapp.com/en/tracking/${row?.[key]}`}
                                                    target='_blank'
                                                    className='text-primary-default'
                                                >
                                                    {row?.[key]}
                                                </a>
                                            </td>
                                        )
                                    }
                                })}
                                <td className='px-6 py-4 min-w-[120px]'>
                                    <div className='flex flex-row justify-around'>
                                        <div onClick={() => (!accessControl || actions?.['See Cashout Details']) && onSeeDetails(row?.id || '', row?.itemId || '', row?.region || '')}>
                                            <img title={'View Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={'h-[14px] object-contain cursor-pointer mr-2'} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default SellerCashoutsTable;