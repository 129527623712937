import React from 'react';
import moment from 'moment'
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import { onFormatOrderStatus } from '../../../../utils/constants/format-order-statuses';
import Table from '../../../../shared/table';

const tableColumns = ['Order Id', 'Shipped Date', 'Item Id', 'Item Name', 'Purchase Price', 'Inventory Set', 'Order Source', 'Tracking Number', 'Order Status', 'Invoice URL', 'Auto-accept revalue', 'Actions']
const tableKeys = ['id', 'shippedDate', 'itemId', 'itemName', 'localizedPrice', 'location', 'sourceOrder', 'trackingNumber', 'orderStatus', 'invoiceUrl', 'autoAccept']

interface IOrdersTable {
    rows: any;
    paginationData?: IPage;
    ordersLoading: boolean;
    accessControl?: any;
    actions?: any;
    onSeeDetails: (id: string, itemId: string, country: string) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
}

const SellerOrdersTable = ({
    rows,
    paginationData,
    ordersLoading,
    accessControl,
    actions,
    onSeeDetails,
    setPaginationState,
}: IOrdersTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {ordersLoading ?
                <>
                    {[...Array(10)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {tableColumns?.map(element => {
                                    return (
                                        <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })
                                }
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                {tableKeys?.length > 0 && tableKeys?.map((key, index) => {
                                    if (key !== 'trackingNumber') {
                                        let value = row?.[key]
                                        let item = null
                                        if (key === 'orderStatus') {
                                            value = onFormatOrderStatus(row?.[key], '', false)?.name
                                            const color = onFormatOrderStatus(row?.[key], '', false)?.color
                                            const text = onFormatOrderStatus(row?.[key], '', false)?.text || 'text-black'
                                            item = color && <div className={`${color} flex flex-row justify-center text-center px-1 min-w-[150px] ${text} text-sm py-1 font-medium rounded-2xl`}>{value}</div>
                                        }
                                        if (key === 'localizedPrice') {
                                            value = Number(row?.[key]?.amount || 0).toFixed(2)
                                            item = `${CURRENCY_SYMBOLS?.[`${(row?.localizedPrice?.currencyCode || '')}`]}${value}`
                                        }
                                        if (key === 'id') {
                                            value = row?.id
                                        }
                                        if (key === 'shippedDate') {
                                            value = moment(row?.[key]).fromNow()
                                        }
                                        if (key === 'receivedDate') {
                                            value = moment(row?.[key]).fromNow()
                                        }
                                        if (key === 'location') {
                                            value = row?.location ? 'Yes' : 'No'
                                        }
                                        if (key === 'sourceOrder') {
                                            let findSource = row?.sourceOrder
                                            switch ((row?.sourceOrder)?.toLowerCase()) {
                                                case 'app':
                                                    findSource = 'Wing'
                                                    break;
                                                case 'trade_in':
                                                    findSource = 'Trade In'
                                                    break;
                                                case 'sellWing':
                                                    findSource = 'Wing Web'
                                                    break;
                                                case 'sellwing':
                                                    findSource = 'Wing Web'
                                                    break;
                                                default:
                                                    findSource = row?.sourceOrder
                                                    break;
                                            }
                                            value = findSource || row?.sourceOrder
                                        }
                                        if (key === 'invoiceUrl' && row?.invoiceUrl) {
                                            value = <a
                                                rel='noreferrer'
                                                href={row?.invoiceUrl}
                                                target='_blank'
                                                className='text-primary-default'
                                                data-qa={`invoice_url_${row?.trackingNumber}`}
                                            >{row?.invoiceUrl}</a>
                                        }
                                        if (key === 'autoAccept') {
                                            value = row?.autoAccept || 'No'
                                        }
                                        return (
                                            <td
                                                key={index}
                                                className={'px-6 py-4'}>
                                                {item || value}
                                            </td>
                                        )
                                    } else {
                                        return (
                                            <td key={index} className='px-6 py-4'>
                                                <a
                                                    rel='noreferrer'
                                                    href={`https://parcelsapp.com/en/tracking/${row?.[key]}`}
                                                    target='_blank'
                                                    className='text-primary-default'
                                                >
                                                    {row?.[key]}
                                                </a>
                                            </td>
                                        )
                                    }
                                })}
                                <td className='px-6 py-4 min-w-[120px]'>
                                    <div className='flex flex-row justify-around'>
                                        <div onClick={() => (!accessControl || actions?.['See Order Details']) && row?.id && row?.itemId && onSeeDetails(row?.id, row?.itemId || '', row?.region || '')}>
                                            <img title={'View Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={'h-[14px] object-contain cursor-pointer mr-2'} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default SellerOrdersTable;