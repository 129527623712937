import React from 'react';
import Input from '../../../../shared/input';
import { ISellerFilters } from '../../../../interfaces/components/ISellerFilters';
import SelectCheckbox from '../../../../shared/select-checkbox';


interface ISellerDetailsFilters {
    filters?: ISellerFilters;
    errors?: ISellerFilters;
    markets?: Array<any>;
    selectedMarket?: any;
    onChangeSelectedMarket: (value: any, type?: string) => void;
    onChangeFilterValue: (value: string | null | undefined, type: string) => void;
    onHandleKeyPress: (e: any) => void;
}

const SellerDetailsFilters = ({
    filters,
    selectedMarket,
    markets,
    onChangeSelectedMarket,
    onChangeFilterValue,
    onHandleKeyPress
}: ISellerDetailsFilters) => {
    return (
        <div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1 md:gap-2'>
                <SelectCheckbox
                    placeholder='Market'
                    dataQa={selectedMarket ? selectedMarket?.name.toLowerCase().replaceAll(' ', '-') : 'market'}
                    selectedOption={selectedMarket}
                    options={markets || []}
                    onChangeSelectedOption={onChangeSelectedMarket}
                />
                <Input
                    placeholder='User ID'
                    containerStyle=''
                    showValue={true}
                    dataQa={filters?.id ? filters?.id : 'user-id'}
                    inputValue={filters?.id}
                    onChangeInput={onChangeFilterValue}
                    onHandleKeyDown={onHandleKeyPress}
                    inputUniqueName={'id'}
                />
                <Input
                    placeholder='Full Name'
                    containerStyle=''
                    showValue={true}
                    dataQa={filters?.fullName ? filters?.fullName.toLowerCase().replaceAll(' ', '-') : 'full-name'}
                    inputValue={filters?.fullName}
                    onChangeInput={onChangeFilterValue}
                    onHandleKeyDown={onHandleKeyPress}
                    inputUniqueName={'fullName'}
                />
                <Input
                    placeholder='Username'
                    containerStyle=''
                    showValue={true}
                    dataQa={filters?.username ? filters?.username.toLowerCase().replaceAll(' ', '-') : 'user-name'}
                    inputValue={filters?.username}
                    onChangeInput={onChangeFilterValue}
                    onHandleKeyDown={onHandleKeyPress}
                    inputUniqueName={'username'}
                />
                <Input
                    placeholder='Email'
                    containerStyle=''
                    showValue={true}
                    dataQa={filters?.email ? filters?.email.toLowerCase().replaceAll(' ', '-') : 'email'}
                    inputValue={filters?.email}
                    onChangeInput={onChangeFilterValue}
                    onHandleKeyDown={onHandleKeyPress}
                    inputUniqueName={'email'}
                />
                 <Input
                    placeholder='Account Number'
                    containerStyle=''
                    showValue={true}
                    dataQa={filters?.accountNumber ? filters?.accountNumber.toLowerCase().replaceAll(' ', '-') : 'account-number'}
                    inputValue={filters?.accountNumber}
                    onChangeInput={onChangeFilterValue}
                    onHandleKeyDown={onHandleKeyPress}
                    inputUniqueName={'accountNumber'}
                />
                 <Input
                    placeholder='Personal Account Number'
                    containerStyle=''
                    showValue={true}
                    dataQa={filters?.personalAccountNumber ? filters?.personalAccountNumber.toLowerCase().replaceAll(' ', '-') : 'personal-account-nr'}
                    inputValue={filters?.personalAccountNumber}
                    onChangeInput={onChangeFilterValue}
                    onHandleKeyDown={onHandleKeyPress}
                    inputUniqueName={'personalAccountNumber'}
                />
                  <Input
                    placeholder='Post Code'
                    containerStyle=''
                    showValue={true}
                    dataQa={filters?.postCode ? filters?.postCode.toLowerCase().replaceAll(' ', '-') : 'post-code'}
                    inputValue={filters?.postCode}
                    onChangeInput={onChangeFilterValue}
                    onHandleKeyDown={onHandleKeyPress}
                    inputUniqueName={'postCode'}
                />
                <Input
                    placeholder='Phone Number'
                    containerStyle=''
                    showValue={true}
                    dataQa={filters?.phoneNumber ? filters?.phoneNumber.toLowerCase().replaceAll(' ', '-') : 'phone-number'}
                    inputValue={filters?.phoneNumber}
                    onChangeInput={onChangeFilterValue}
                    onHandleKeyDown={onHandleKeyPress}
                    inputUniqueName={'phoneNumber'}
                />
                <Input
                    type='text'
                    onFocus={(e: any) => {
                        e.target.type = 'date';
                        e.target.showPicker();
                    }}
                    placeholder='From'
                    containerStyle=''
                    showValue={true}
                    dataQa={'from'}
                    inputValue={filters?.minDateRegistered}
                    onChangeInput={onChangeFilterValue}
                    inputUniqueName={'minDateRegistered'}
                />
                <Input
                    type='text'
                    onFocus={(e: any) => {
                        e.target.type = 'date';
                        e.target.showPicker();
                    }}
                    placeholder='To'
                    dataQa={'to'}
                    containerStyle=''
                    showValue={true}
                    inputValue={filters?.maxDateRegistered}
                    onChangeInput={onChangeFilterValue}
                    inputUniqueName={'maxDateRegistered'}
                />
            </div>
        </div>
    );
};

export default SellerDetailsFilters;
