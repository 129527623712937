import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { PAYMENT_STATUSES } from '../../../../utils/constants/payment-statuses';
import { tryToFetchSellerPayments } from '../../../../store/customer-support/sellers/sellersSlice';
import SellerPaymentsTable from '../tables/SellerPaymentsTable';
import EmptyContainer from '../../../../shared/empty-container';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';


const PaymentsTab = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams()
    const [rows, setRows] = useState<any>([]);
    const [selectedStatus, setSelectedStatus] = useState<any>()
    const [paginationState, setPaginationState] = useState<any>({
        pageNumber: '',
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>()
    const paginationData = useAppSelector((state) => state.sellers.sellerPayments?.data?.page);
    const rowsLoading = useAppSelector((state) => state.sellers.sellerPaymentsAreLoading);
    const state = useAppSelector((state) => state.sellers);
    const accessControl = useAppSelector((state) => state?.partners?.navigation);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Wing Users')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Wing User Details')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: '',
            pageSize: paginationState?.pageSize || 10,
        })
    }

    useEffect(() => {
        if (state.sellerPayments) {
            const rows = state.sellerPayments?.data?.elements || []
            setRows(rows);
        }
    }, [state.sellerPayments]);

    const onGettingCashoutsData = async () => {
        try {
            let formatData: any = {
                ...paginationState || {},
                sellerId: id || ''
            }
            if (selectedStatus) {
                formatData = {
                    ...formatData || {},
                    status: selectedStatus?.value
                }
            }
            await dispatch(tryToFetchSellerPayments(formatData)).unwrap();
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        if (id) {
            onGettingCashoutsData()
        }
    }, [paginationState, id]);

    const onClickClearAllFilters = () => {
        onResetPaginationSettings()
        setSelectedStatus(undefined)
    }

    const onChangeSelectedStatus = (value: any, type?: string) => {
        setSelectedStatus(value)
        onResetPaginationSettings()
    };

    return (
        <div className='h-auto w-full flex flex-col gap-2 my-6'>
            <div className='mb-2'>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4'>
                    <SelectCheckbox
                        name={'Status'}
                        selectedOption={selectedStatus}
                        options={PAYMENT_STATUSES || []}
                        onChangeSelectedOption={onChangeSelectedStatus}
                    />
                </div>
                <div className='flex flex-row justify-center md:justify-end mt-2'>
                    <Button
                        label={'Clear Filters'}
                        onClickButton={onClickClearAllFilters}
                        className={'btn-primary-text-underline min-w-[150px]'}
                    />
                </div>
            </div>
            <div className='mb-5 relativ'>
                <>
                    {(rowsLoading || rows?.length > 0) ? (
                        <SellerPaymentsTable
                            rows={rows}
                            actions={pageAccess?.actions}
                            accessControl={accessControl}
                            cashoutsLoading={rowsLoading}
                            paginationData={paginationData}
                            setPaginationState={setPaginationState}
                        />
                    ) : (
                        <EmptyContainer
                            text={'No payments for this user yet.'}
                            showImage={true}
                        />
                    )}
                </>
            </div>
        </div>
    )
}

export default PaymentsTab;