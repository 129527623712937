import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PaginatedStripeDisputes } from '../../../interfaces/stripe-dispute/paginatedStore.type';
import { StripeDisputeService } from './stripeDisputeApi';
import { IStripeDisputeListState } from '../../../interfaces/stripe-dispute/IStripeDisputeList';
import { ApiResponse } from '../../../interfaces/models/models/paginated-response.model';
import { AxiosError } from 'axios';

const initialState: IStripeDisputeListState = {
    stripeDisputesAreLoading: false,
    stripeDisputeIsLoading: false,
    requestStatus: 'default',
    message: '',
};
export const tryToFetchAllStripeDispute = createAsyncThunk<PaginatedStripeDisputes, any>(
    'stripeDispute/tryToFetchAllStripeDispute',
    async ({ pageNumber, pageSize, previousPage, nextPage, filters, data }) => {
        const result = await StripeDisputeService.tryToFetchAllStripeDispute(
            pageNumber,
            pageSize,
            previousPage,
            nextPage,
            filters,
            data,
        );
        return result?.data;
    },
);

export const tryToFetchStripeDisputesDetails = createAsyncThunk<any, string>(
    'stripeDispute/tryToFetchStripeDisputesDetails',
    async (disputeId) => {
        const result = await StripeDisputeService.tryToFetchStripeDisputesDetails(disputeId);
        return result?.data;
    },
);

export const tryToUpdateStripeDisputeCSV = createAsyncThunk<
    ApiResponse<any>,
    any
>('stripeDispute/tryToUpdateStripeDisputeCSV', async (data: any, { rejectWithValue }) => {
    try {
        const result = await StripeDisputeService.tryToUpdateStripeDisputeCSV(
            data?.id || '',
            data?.data,
        );
        return result.data;
    } catch (error) {
        const err: any = error as AxiosError;
        return rejectWithValue(err?.response?.data?.message);
    }
});

export const StripeDisputesSlice = createSlice({
    name: 'stripeDispute',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchAllStripeDispute
            .addCase(tryToFetchAllStripeDispute.pending, (state) => {
                state.stripeDisputesAreLoading = true;
            })
            .addCase(tryToFetchAllStripeDispute.rejected, (state) => {
                state.stripeDisputesAreLoading = false;
            })
            .addCase(tryToFetchAllStripeDispute.fulfilled, (state, action) => {
                state.stripeDisputesAreLoading = false;
                state.stripeDisputes = action.payload;
            })

            // tryToFetchStripeDisputeDetails
            .addCase(tryToFetchStripeDisputesDetails.pending, (state) => {
                state.stripeDisputeIsLoading = true;
            })
            .addCase(tryToFetchStripeDisputesDetails.rejected, (state) => {
                state.stripeDisputeIsLoading = false;
            })
            .addCase(tryToFetchStripeDisputesDetails.fulfilled, (state, action) => {
                state.stripeDisputeIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                state.stripeDisputeDetail = data
            })

            // tryToUpdateStripeDisputeCSV
            .addCase(tryToUpdateStripeDisputeCSV.pending, (state) => {
                state.stripeDisputeIsLoading = true;
            })
            .addCase(tryToUpdateStripeDisputeCSV.rejected, (state) => {
                state.stripeDisputeIsLoading = false;
            })
            .addCase(tryToUpdateStripeDisputeCSV.fulfilled, (state, action) => {
                state.stripeDisputeIsLoading = false;
                if (state.stripeDisputes?.data.elements)
                    state.stripeDisputes.data.elements =
                        { ...state }.stripeDisputes?.data.elements.map((dispute) => {
                            if (dispute?.id === action.meta?.arg?._id) {
                                return {
                                    ...action.payload.data,
                                };
                            } else {
                                return dispute;
                            }
                        }) || [];
                state.stripeDisputeDetail = action.payload.data;
            })

    },
});

export default StripeDisputesSlice.reducer;