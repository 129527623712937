import React from 'react';
import moment from 'moment';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';

const tableColumns = ['ID', 'User ID','Title', 'Body', 'Created At', 'Updated At', 'Type',]
const tableKeys = ['id', 'userId','title', 'body', 'createdAt', 'updatedAt', 'type',]

interface ISellerUserActivityTable {
    rows: any;
    paginationData?: IPage;
    cashoutsLoading: boolean;
    accessControl?: any;
    actions?: any;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
}

const SellerUserActivityTable = ({
    rows,
    paginationData,
    cashoutsLoading,
    accessControl,
    actions,
    setPaginationState,
}: ISellerUserActivityTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}>
            {cashoutsLoading ?
                <>
                    {[...Array(10)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {tableColumns?.map(element => {
                                    return (
                                        <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                {tableKeys?.length > 0 && tableKeys?.map((key, index) => {
                                    let value = row?.[key]
                                    const item = null
                                    if (key === 'createdAt') {
                                        value = moment(row?.createdAt).format('DD-MM-YYYY, HH:mm')
                                    }
                                    if (key === 'updatedAt') {
                                        value = moment(row?.updatedAt).format('DD-MM-YYYY, HH:mm')
                                    }
                                    if (key === 'type') {
                                        value = <div className='border border-2 border-[#F7A21E] px-4 py-1 text-[#F7A21E] text-center font-medium'>{row?.type ? `${row?.type[0].toUpperCase()}${row?.type?.slice(1)?.toLowerCase()}` : ''}</div>
                                    }
                                    return (
                                        <td
                                            key={index}
                                            className={'px-6 py-4'}>
                                            {item || value}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default SellerUserActivityTable;