import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { tryToRaiseATicket } from '../../../../store/customer-support/customer-care/customerCareSlice';
import { useAppDispatch } from '../../../../app/hooks';
import Textarea from '../../../../shared/textarea';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';
import Input from '../../../../shared/input';


interface IRaiseTicketModal {
    openModal: boolean;
    itemId: any;
    trackingNumber: any;
    brokerUsername: any;
    handleCloseModal: () => void;
    onSuccessfullyRaised: (type: string, message: string) => void;
}

type FormValues = {
    subject: string;
    description: string;
};

const RaiseTicketFromOrderModal = ({
    openModal,
    itemId,
    trackingNumber,
    brokerUsername,
    handleCloseModal,
    onSuccessfullyRaised
}: IRaiseTicketModal) => {
    const dispatch = useAppDispatch();
    const [saveError, setSaveError] = useState<string>();
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        watch
    } = useForm<FormValues>({});

    const onCloseModal = () => {
        handleCloseModal();
        setSaveError(undefined)
        setLoadingSubmit(false)
        reset();
    }

    const onSubmit = async (data: FormValues) => {
        if (loadingSubmit) {
            return;
        }
        setLoadingSubmit(true)
        try {
            const formatData = {
                ...data,
                itemId: itemId || '',
                brokerUsername: brokerUsername || '',
                trackingNumber: trackingNumber || '',
            }
            await dispatch(tryToRaiseATicket(formatData)).unwrap()
            onSuccessfullyRaised('success', 'Ticket Raised successfully.')
            setSaveError(undefined)
            reset();
            onCloseModal()
        } catch (err) {
            setSaveError(`${err}`)
            setLoadingSubmit(false)
        }
    }

    const onChangeValue = (e: any) => {
        setValue('description', e?.target?.value)
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mb-4 font-semibold text-sm'>Raise A Ticket</p>
                {saveError && <Error text={saveError} />}
                <form className='mt-5' onSubmit={handleSubmit(onSubmit)}>
                    <Input
                        label='Subject'
                        placeholder=' '
                        dataQa={'subject'}
                        register={register('subject', {
                            required: {
                                message: 'Subject is required',
                                value: true,
                            },
                            validate: (value: string) => !!value.trim() || 'Subject is required'
                        })}
                        error={errors.subject?.message}
                    />
                    <Textarea
                        placeholder='Query'
                        dataQa={'query'}
                        rows={4}
                        value={watch('description')}
                        onChangeTextarea={onChangeValue}
                        error={errors.description?.message}
                    />
                    <div className='min-w-full flex flex-row justify-center'>
                        <Button
                            label={'Submit'}
                            dataQa={'submit'}
                            className={`${loadingSubmit ? 'btn-primary-rounded-less-disable' : 'btn-primary-rounded-less'} my-3 min-w-[130px] !shadow-none`}
                            type='submit'
                        />
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default RaiseTicketFromOrderModal;