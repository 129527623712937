import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IFlaggedUser } from '../../../../interfaces/flagged-users/IFlaggedUser';
import { tryToEnableSeller } from '../../../../store/customer-support/flagged-user/FlaggedUserSlice';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';


interface ILockedModal {
    openLockedModal: boolean;
    selectedId: string;
    onSuccessfullyChanged: (type: string, message: string) => void;
    handleCloseModal: () => void;
}

const LockedModal = ({
    openLockedModal,
    selectedId,
    handleCloseModal,
    onSuccessfullyChanged,
}: ILockedModal) => {
    const dispatch = useAppDispatch();
    const [saveError, setSaveError] = useState<string>()
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const [flaggedUserDetails, setFlaggedUserDetails] = useState<IFlaggedUser>()
    const state = useAppSelector((state) => state.flaggedUsers);

    useEffect(() => {
        if (state?.flaggedUserDetail) {
            setFlaggedUserDetails(state?.flaggedUserDetail)
        }
    }, [state?.flaggedUserDetail, loadingSubmit]);

    const onCloseModal = () => {
        handleCloseModal();
        setSaveError(undefined)
        setLoadingSubmit(false)
    }

    const onSubmit = async () => {
        try {
            if (selectedId) {
                await dispatch(tryToEnableSeller(selectedId)).unwrap()
                onSuccessfullyChanged('success', 'Status changed successfully.')
            }
            setSaveError(undefined)
            onCloseModal()
        } catch (err) {
            setSaveError(`${err}`)
            setLoadingSubmit(false)
        }
    }

    return (
        <Modal
            open={openLockedModal}
            onClose={onCloseModal}>
            {flaggedUserDetails?.cashoutInfo?.isCashoutEnabled === false ?
                (<div className={'p-2 min-w-[500px]'}>
                    <p className='mb-4 font-bold text-sm'>User Account</p>
                    <p className='font-semibold text-[15px] mb-5 mt-3'>This action will activate the user’s account and enable them to cashout again. Click Activate to confirm.</p>
                    {saveError && <Error text={saveError} />}
                    <div className='min-w-full flex flex-row pt-4 justify-center'>
                        <Button
                            label={'Activate'}
                            dataQa={'activate'}
                            className={`${loadingSubmit ? 'btn-primary-rounded-less-disable' : 'btn-primary-rounded-less'} my-3 min-w-[130px] !shadow-none`}
                            onClickButton={() => !loadingSubmit && onSubmit()}
                        />
                    </div>
                </div>
                ) : (
                    <div className={'p-2 min-w-[500px]'}>
                        <p className='mb-4 font-bold text-sm'>User Account</p>
                        <p className='font-semibold text-[15px] mb-5 mt-3'>The user is already enabled for cashout. </p>
                        {saveError && <Error text={saveError} />}
                    </div>
                )
            }
        </Modal>
    )
}

export default LockedModal;