import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { classNames } from '../../utils/classNames';

interface IInput {
    label?: string;
    register?: UseFormRegisterReturn;
    placeholder?: string;
    onFocus?: any
    onChange?: any
    min?: any
    error?: string;
    type?: string;
    containerStyle?: string;
    disabled?: boolean;
    dataQa?: string;
    inputValue?: string | number;
    showValue?: boolean;
    onChangeInput?: (value: string | null | undefined, type: string) => void;
    inputUniqueName?: string;
    inputStyle?: string;
    hideInputSpace?: boolean;
    icon?: string;
    dateType?: boolean;
    onHandleKeyDown?: (e: any) => void;
}

const Input = ({
    register,
    placeholder,
    type,
    error,
    label,
    containerStyle,
    inputValue,
    showValue,
    dataQa,
    disabled,
    onChangeInput,
    inputUniqueName,
    inputStyle,
    hideInputSpace,
    icon,
    onFocus,
    onChange,
    min,
    dateType,
    onHandleKeyDown
}: IInput) => {    
    return (
        <div className={containerStyle || 'mb-3'}>
            {!!label && <label className='block text-primary-light font-normal text-base mb-1'>{label}</label>}
            {icon && <img src={icon} className={'absolute w-[18px] object-contain left-2 top-[27%]'} />}
            <input
                {...register}
                type={type}
                data-qa={dataQa || ''}
                      {...showValue && ({
                    value: inputValue || '',
                    onChange: (e) => onChangeInput?.(e?.target?.value, inputUniqueName || ''),
                    onKeyDown: (e) => onHandleKeyDown?.(e)
                })}
                min={min}
                disabled={disabled || false}
                placeholder={placeholder}
                onFocus={onFocus}
                autoComplete='off'
                className={classNames('border border-borderColor rounded w-full px-3 py-2', error ? 'border-red-500' : '', icon ? 'pl-8' : '', inputStyle || '')}
            />
            {error ? <span className='block text-xs text-red-500'>{error}</span> : !hideInputSpace && <span className='block h-2 mt-2' />}
        </div>

    )
}

export default Input;