import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { tryToFetchSellerOrders } from '../../../../store/customer-support/orders/ordersSlice';
import SellerOrdersTable from '../tables/SellerOrdersTable';
import SearchWithButton from '../../../../shared/search-with-button';
import EmptyContainer from '../../../../shared/empty-container';


const OrdersTab = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { id } = useParams()
    const [rows, setRows] = useState<any>([]);
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
        filters: '',
    });
    const [pageAccess, setPageAccess] = useState<any>()
    const paginationData = useAppSelector((state) => state.orders.sellerOrders?.data?.page,);
    const rowsLoading = useAppSelector((state) => state.orders.sellerOrdersAreLoading);
    const state = useAppSelector((state) => state.orders);
    const accessControl = useAppSelector((state) => state?.partners?.navigation);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Wing Users')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Wing User Details')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onChangeSearchValue = (value: string | null | undefined, type?: string) => {
        setPaginationState({
            pageNumber: 1,
            pageSize: 10,
            filters: value || '',
        });
    };

    useEffect(() => {
        if (state.sellerOrders) {
            const rows =
                state.sellerOrders?.data?.elements &&
                    Array.isArray(state.sellerOrders?.data.elements)
                    ? state.sellerOrders?.data.elements.map((r: any) => ({
                        ...r,
                        id: r.id,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state.sellerOrders]);

    useEffect(() => {
        const formatData = {
            ...paginationState || {},
            sellerId: id || ''
        }
        dispatch(tryToFetchSellerOrders(formatData));
    }, [paginationState]);

    const onClickClearAllFilters = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: 10,
            filters: '',
        });
    }

    const onSeeDetails = (id: string, itemId: string, country: string) => {
        navigate(`/tickets/${id}/${itemId}/country/${country}`)
    }

    return (
        <div className='h-auto w-full flex flex-col gap-6 my-6'>
            <div className='flex h-10'>
                <div className='w-full'>
                    <SearchWithButton
                        placeholder='Search'
                        buttonLabel='Clear all filters'
                        buttonStyle='btn-primary-text-underline min-w-auto !col-span-2 flex flex-row justify-end'
                        onChangeSearchValue={onChangeSearchValue}
                        search={paginationState?.filters}
                        onClickButton={onClickClearAllFilters}
                        mainContainerStyle={'!bg-white !grid-cols-10'}
                    />
                </div>
            </div>
            <div className='mb-5 relativ'>
                <>
                    {(rowsLoading || rows?.length > 0) ? (
                        <SellerOrdersTable
                            rows={rows}
                            actions={pageAccess?.actions}
                            accessControl={accessControl}
                            ordersLoading={rowsLoading}
                            paginationData={paginationData}
                            setPaginationState={setPaginationState}
                            onSeeDetails={onSeeDetails}
                        />
                    ) : (
                        <EmptyContainer
                            text={'No orders for this user yet.'}
                            showImage={true}
                        />
                    )}
                </>
            </div>
        </div>
    )
}

export default OrdersTab;