import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';

const resetReasons = [
    'Incorrect Grading',
    'Fault Retest',
    'Missing Part Received',
    'Mistreated Item',
    'Order Mix Up / Wrong Device',
    'Other'
];

interface IResetOrderModal {
    openModal: boolean;
    handleCloseModal: () => void;
    onResetOrder: (reason: string, details: string) => void;
}

type FormValues = {
    reason: string;
    details: string;
};

const ResetOrderModal = ({ openModal, handleCloseModal, onResetOrder }: IResetOrderModal) => {
    const { handleSubmit, control, watch, reset, formState: { errors } } = useForm<FormValues>();
    const selectedReason = watch('reason');

    const handleFormSubmit = (data: { reason: string; details: string }) => {
        onResetOrder(data.reason, data.details);
        handleCloseModal();
        reset();
    }

    return (
        <Modal open={openModal} onClose={handleCloseModal} showInRight title="Reset Order">
            <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-4 p-2 min-w-[450px]">
                <p>Provide reason for resetting the order</p>
                <div>
                    <label className="block mb-2">Reason</label>
                    <Controller
                        name="reason"
                        control={control}
                        defaultValue=""
                        rules={{ required: 'This field is required' }}
                        render={({ field }) => (
                            <select {...field} className="border p-2 rounded w-full">
                                <option value="">Select a reason</option>
                                {resetReasons.map((reason) => (
                                    <option key={reason} value={reason}>
                                        {reason}
                                    </option>
                                ))}
                            </select>
                        )}
                    />
                    {errors.reason?.message && <Error text={errors.reason?.message} />}
                </div>
                {selectedReason === 'Other' && (
                    <div>
                        <label className="block mb-2">Please specify</label>
                        <Controller
                            name="details"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'This field is required', maxLength: {
                                    value: 100,
                                    message: 'Details cannot exceed 100 characters',
                                },
                            }}
                            render={({ field }) => (
                                <textarea
                                    {...field}
                                    className="border p-2 rounded w-full"
                                    placeholder="Enter details (max 100 characters)"
                                />
                            )}
                        />
                        {errors.details?.message && <Error text={errors.details?.message} />}
                    </div>
                )}
                <div className="flex justify-end">
                    <button
                        type="submit"
                        className="btn-primary text-white py-2 px-4 rounded"
                    >
                        Submit
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default ResetOrderModal;
