import React, { useState, useEffect, useLayoutEffect } from 'react';
import {
    useLocation,
    useSearchParams
} from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import CashoutsTab from './tabs/CashoutsTab';
import OrdersTab from './tabs/OrdersTab';
import ItemsTab from './tabs/ItemsTab';


const orderTabs = [
    { name: 'Cashouts', element: <CashoutsTab /> },
    { name: 'Packages', element: <OrdersTab /> },
    { name: 'Items', element: <ItemsTab /> },
]

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Orders = () => {
    const query = useQuery();
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState<any>(orderTabs[0])
    const [pageAccess, setPageAccess] = useState<any>()
    const accessControl = useAppSelector((state) => state?.partners?.navigation);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Orders')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
        if (findChild) {
            const findActiveTab = findChild?.actions?.[orderTabs[0]?.name] ? orderTabs[0] : orderTabs?.find((item: any) => findChild?.actions?.[item?.name])
            setActiveTab(findActiveTab)
        }
    }, [accessControl])

    const onChangeActiveTab = (item: any) => {
        setActiveTab(item)
        searchParams.set('type', item?.name)
        setSearchParams(searchParams)
    }

    useLayoutEffect(() => {
        if (query?.get('type') && query?.get('type') !== activeTab?.name) {
            const findActiveTab = orderTabs?.find((item) => item?.name === query?.get('type'))
            setActiveTab(findActiveTab || undefined)
        }
    }, [query?.get('type')])

    return (
        <div>
            <p className='page-title mb-10'>Orders</p>
            <div className='flex flex-row items-center my-5'>
                {orderTabs?.filter((item: any) => (!accessControl || pageAccess?.actions?.[item?.name]))?.map((tab, index) => {
                    return (
                        <div
                            key={index}
                            className={`btn-categories whitespace-nowrap mr-3 mb-3  cursor-pointer text-center ${activeTab.name === tab?.name && 'bg-gradient-to-r from-[#FCEE21] to-[#F7931E] border-none'}`}
                            onClick={() => onChangeActiveTab(tab)}>
                            <p data-qa={tab?.name} className={activeTab.name === tab?.name ? 'text-[#202020]' : 'text-[#484A4B]'}>{tab?.name}</p>
                        </div>
                    )
                })}
            </div>
            <div className='my-2'>
                {(!accessControl || pageAccess?.actions?.[activeTab?.name]) ? activeTab.element : <></>}
            </div>
        </div >
    )
}

export default Orders;