import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ReferralsService } from './referralsUsersApi';
import { IReferralsListState } from '../../../interfaces/referrals/IReferralsList';
import { PaginatedReferrals } from '../../../interfaces/referrals/paginatedStore.type';
import { ApiResponse } from '../../../interfaces/models/models/paginated-response.model';
import { AxiosError } from 'axios';

const initialState: IReferralsListState = {
    referralsAreLoading: false,
    referralIsLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchAllReferrals = createAsyncThunk<PaginatedReferrals, any>(
    'referrals/tryToFetchAllReferrals',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await ReferralsService.tryToFetchAllReferrals(
            pageNumber,
            pageSize,
            filters,
            data
        );
        return result?.data;
    });


export const tryToFetchReferralDetails = createAsyncThunk<any, any>(
    'referrals/tryToFetchReferralDetails',
    async ({ pageNumber, pageSize, referralId, data }) => {
        const result = await ReferralsService.tryToFetchReferralDetails(
            pageNumber,
            pageSize,
            referralId,
            data
        );
        return result?.data;
    },
);

export const tryToFetchReferralTransactions = createAsyncThunk<ApiResponse<any>, any>(
    'referrals/tryToFetchReferralTransactions',
    async ({ pageNumber, pageSize, id }) => {
        const result = await ReferralsService.tryToFetchReferralTransactions(
            pageNumber,
            pageSize,
            id
        );
        return result?.data;
    },
);

export const tryToFetchCompletedReferralsTransactions = createAsyncThunk<any, any>(
    'referrals/tryToFetchCompletedReferralsTransactions',
    async ({ _id, data }, { rejectWithValue }) => {
        try {
            const result = await ReferralsService.tryToFetchCompletedReferralsTransactions(
                _id,
                data
            );
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data);
        }
    },
);

export const ReferralsSlice = createSlice({
    name: 'referrals',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchAllReferrals
            .addCase(tryToFetchAllReferrals.pending, (state) => {
                state.referralsAreLoading = true;
            })
            .addCase(tryToFetchAllReferrals.rejected, (state) => {
                state.referralsAreLoading = false;
            })
            .addCase(tryToFetchAllReferrals.fulfilled, (state, action) => {
                state.referralsAreLoading = false;
                state.referrals = action.payload;
            })

            // tryToFetchReferralDetails
            .addCase(tryToFetchReferralDetails.pending, (state) => {
                state.referralIsLoading = true;
            })
            .addCase(tryToFetchReferralDetails.rejected, (state) => {
                state.referralIsLoading = false;
            })
            .addCase(tryToFetchReferralDetails.fulfilled, (state, action) => {
                state.referralIsLoading = false;
                state.referralDetails = action.payload;
            })


            // tryToFetchReferralTransactions
            .addCase(tryToFetchReferralTransactions.pending, (state) => {
                state.referralsAreLoading = true;
            })
            .addCase(tryToFetchReferralTransactions.rejected, (state) => {
                state.referralsAreLoading = false;
            })
            .addCase(tryToFetchReferralTransactions.fulfilled, (state, action) => {
                state.referralsAreLoading = false;
                state.referralTransactions = action.payload;
            })

            // tryToFetchCompletedReferralsTransactions
            .addCase(tryToFetchCompletedReferralsTransactions.rejected, (state, action) => {
                const data : any = action?.payload
                if (state.referralTransactions?.data.elements && data?.error && data?.error?.key === 'payment-failed')
                    state.referralTransactions.data.elements =
                        { ...state }.referralTransactions?.data.elements.map((transaction: any) => {
                            if (transaction?._id === action.meta?.arg?._id) {
                                return {
                                    ...transaction || {},
                                    status: 'completed'
                                };
                            } else {
                                return transaction;
                            }
                        }) || [];
            })
            .addCase(tryToFetchCompletedReferralsTransactions.fulfilled, (state, action) => {
                if (state.referralTransactions?.data.elements)
                    state.referralTransactions.data.elements =
                        { ...state }.referralTransactions?.data.elements.map((transaction: any) => {
                            if (transaction?._id === action.meta?.arg?._id) {
                                return {
                                    ...transaction || {},
                                    status: 'completed'
                                };
                            } else {
                                return transaction;
                            }
                        }) || [];
                state.referralDetails = action.payload.data;
            })
    },
});

export default ReferralsSlice.reducer;