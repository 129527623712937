import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './../../../app/hooks';
import { IAssetTemplate } from './../../../interfaces/asset-templates/IAssetTemplate';
import { IPaginationPayload } from './../../../interfaces/shared/IPaginationPayload';
import { IOrderFilterBody } from './../../../interfaces/orders/IOrderFilterBody';
import { IAllMarkets } from './../../../interfaces/markets/IAllMarkets';
import { IMarket } from './../../../interfaces/markets/IMarket';
import { IOrders } from './../../../interfaces/orders/IOrders';
import { useGetAllMarketsMutation } from './../../../store/user/userApi';
import { tryToFetchAllBlacklistedItems } from './../../../store/customer-support/orders/ordersSlice';
import { tryToFetchAllAssetsTemplates } from './../../../store/customer-support/asset-templates/assetsTemplatesSlice';
import BlacklistedItemsFilters from './details/BlacklistedItemsFilters'
import BlacklistedItemsTable from './tables/BlacklistedItemsTable';
import EmptyContainer from './../../../shared/empty-container';

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};

const BlacklistedItems = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const query = useQuery();
    const [markets, setMarkets] = useState<IAllMarkets[]>();
    const [sectors, setSectors] = useState<IAssetTemplate[]>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedMarket, setSelectedMarket] = useState<string>();
    const [selectedSector, setSelectedSector] = useState<string>();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
        filters: undefined,
    });
    const [rows, setRows] = useState<Array<IOrders>>([]);
    const [pageAccess, setPageAccess] = useState<any>()
    const state = useAppSelector((state) => state.orders);
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const accessControl = useAppSelector((state) => state?.partners?.navigation);
    const rowsLoading = useAppSelector((state) => state?.orders?.ordersAreLoading);
    const paginationData = useAppSelector((state) => state?.orders?.orders?.data?.page);
    const [getAllMarkets] = useGetAllMarketsMutation();

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Blacklisted Items')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
            filters: paginationState?.filters || undefined,
        });
        searchParams.set('page', '1');
        searchParams.set('size', `${paginationState?.pageSize || 10}`);
        setSearchParams(searchParams);
    };

    const onSeeBlacklistedItemDetails = (id: string, itemId: string, country: string) => {
        navigate(`/tickets/${id}/${itemId}/country/${country}`);
    }

    const onSelectFilterOption = (value: any, name?: string) => {
        if (name) {
            switch (name) {
                case 'market':
                    setSelectedMarket(value?.name);
                    searchParams.set('market', value?.name);
                    break;
                case 'category':
                    setSelectedSector(value?.name);
                    searchParams.set('category', value?.name);
                    break;
            }
            setSearchParams(searchParams);
            onResetPaginationSettings();
        }
    };

    const getFiltersData = async () => {
        const marketResponse = await getAllMarkets(null).unwrap();
        const formatMarkets =
            marketResponse &&
            marketResponse?.length > 0 &&
            marketResponse?.map((market: IMarket) => ({
                ...market,
                name: market?.label,
                displayName: market?.name,
            }));
        setMarkets(formatMarkets || []);
        if (!selectedMarket) {
            setSelectedMarket(query?.get('market') || formatMarkets?.[0]?.name || undefined);
        }
        onResetPaginationSettings();
    };

    const getBlacklistedItemsData = async () => {
        const findCategory =
            sectors &&
            sectors?.length > 0 &&
            sectors?.find((category: any) => category?.name === selectedSector);
        if (!selectedSector || !sectors || !findCategory || !selectedMarket) {
            return;
        }
        const data: IOrderFilterBody = {};
        if (selectedMarket && markets) {
            const findMarket: any =
                markets &&
                markets?.length > 0 &&
                markets?.find(
                    (market: IMarket) => market?.name === selectedMarket,
                );
            data.country = findMarket?.displayName || undefined;
            data.marketId = findMarket?._id;
        } else {
            return;
        }
        if (selectedSector) {
            data.category = {
                id: findCategory?._id || '',
                name: selectedSector,
            };
        }
        const resolvedData = { ...paginationState, data };
        searchParams.set('page', `${paginationState?.pageNumber}`);
        searchParams.set('size', `${paginationState?.pageSize}`);
        setSearchParams(searchParams);
        dispatch(tryToFetchAllBlacklistedItems(resolvedData)).unwrap()
    };

    useEffect(() => {
        getFiltersData();
        dispatch(tryToFetchAllAssetsTemplates());
    }, []);

    useEffect(() => {
        if (state.orders) {
            const rowsData =
                state.orders?.data?.elements &&
                    Array.isArray(state.orders?.data.elements)
                    ? state.orders?.data.elements.map((r) => ({
                        ...r,
                        id: r.id,
                    }))
                    : [];
            if (JSON.stringify(rows) !== JSON.stringify(rowsData)) {
                setRows(rowsData);
            }
        }
    }, [state.orders]);

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates &&
                    Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({
                        ...(r || {}),
                        name: r?.displayName,
                        label: r?.displayName,
                        value: r?._id,
                    }))
                    : [];
            const formatAssets =
                rows?.length > 0
                    ? rows?.filter(
                        (r) =>
                            (r?.name || '').toLowerCase() !== 'currencies',
                    )
                    : [];
            setSectors(formatAssets);
            if (!selectedSector) {
                setSelectedSector(query?.get('category') || formatAssets?.[0]?.name);
            }
            onResetPaginationSettings();
        }
    }, [stateAssets.allAssetTemplates]);

    useLayoutEffect(() => {
        if (selectedMarket && selectedSector && paginationState) {
            getBlacklistedItemsData();
        }
    }, [selectedMarket, paginationState]);

    return (
        <div>
            <div className='mb-4'>
                <p className='page-title'>Blacklisted Items</p>
            </div>
            <div className='my-10'>
                <BlacklistedItemsFilters
                    sectors={sectors}
                    markets={markets}
                    selectedMarket={selectedMarket}
                    selectedSector={selectedSector}
                    onSelectFilterOption={onSelectFilterOption}
                />
            </div>
            <div className='relative'>
                {(rows?.length > 0 || rowsLoading) ?
                    <BlacklistedItemsTable
                        rows={rows}
                        actions={pageAccess?.actions}
                        accessControl={accessControl}
                        paginationData={paginationData}
                        rowsLoading={rowsLoading}
                        setPaginationState={setPaginationState}
                        onSeeBlacklistedItemDetails={onSeeBlacklistedItemDetails}
                    /> :
                    <EmptyContainer
                        text={'No item has been blacklisted yet.'}
                        showImage={true}
                    />
                }
            </div>
        </div>
    )
}

export default BlacklistedItems