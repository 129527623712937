import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { ITransactionsFilters } from '../../../../interfaces/components/ITransactionsFilters';
import { tryToDownloadSellerTransactions, tryToFetchSellerTransactions } from '../../../../store/customer-support/sellers/sellersSlice';
import SellerTransactionsFilters from '../details/SellerTransactionsFilters';
import SellerTransactionsTable from '../tables/SellerTransactionsTable';
import EmptyContainer from '../../../../shared/empty-container';
import Button from '../../../../shared/button';


const TransactionsTab = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams()
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const [rows, setRows] = useState<any>([]);
    const [filters, setFilters] = useState<ITransactionsFilters>()
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>()
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    const [transactionsToExport, setTransactionsToExport] = useState<any>()
    const paginationData = useAppSelector((state) => state.sellers.sellerTransactions?.data?.page);
    const rowsLoading = useAppSelector((state) => state.sellers.sellerTransactionsAreLoading);
    const state = useAppSelector((state) => state.sellers);
    const accessControl = useAppSelector((state) => state?.partners?.navigation);

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Wing Users')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.name === 'Wing User Details')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    useEffect(() => {
        if (state.sellerTransactions) {
            const rows = state.sellerTransactions?.data?.elements || []
            setRows(rows);
        }
    }, [state.sellerTransactions]);

    const onGettingTransactionsData = async () => {
        try {
            let formatData: any = {
                ...paginationState || {},
                sellerId: id || ''
            }
            if (filters) {
                formatData = {
                    ...formatData || {},
                    data: filters
                }
            }
            await dispatch(tryToFetchSellerTransactions(formatData)).unwrap();
        } catch (err) {
            // error here
        }
    }

    useEffect(() => {
        if (id) {
            onGettingTransactionsData()
        }
    }, [paginationState, id]);

    const onClickClearAllFilters = () => {
        setFilters(undefined)
        onResetPaginationSettings()
    }

    const onChangeFilterValue = (value: string | null | undefined, type?: string) => {
        if (type) {
            setFilters({
                ...filters,
                [type]: value || undefined,
            });
            if (type === 'toDate' || type === 'fromDate') {
                onResetPaginationSettings()
            }
        }
    };

    const onEnterKeyPressInput = async (e: any) => {
        if (e?.key === 'Enter') {
            onResetPaginationSettings()
        }
    }

    const onChangeFilterSelected = (value: any, type?: string) => {
        if (type) {
            setFilters({
                ...filters,
                [type]: type === 'export' ? value?.value === 'Yes' : value?.value || undefined,
            });
            onResetPaginationSettings()
        }
    };

    const onDownloadCsvData = async () => {
        if (loadingDownload) {
            return;
        }
        setLoadingDownload(true)
        try {
            const formatFilters = {
                ...filters || {},
                export: true
            }
            const response: any = await dispatch(tryToDownloadSellerTransactions({ sellerId: id || '', data: formatFilters })).unwrap();
            setTransactionsToExport(response || [])
        } catch (err) {
            setLoadingDownload(false)
        }
    }

    useEffect(() => {
        if (transactionsToExport && loadingDownload) {
            csvLink.current.link.click()
        }
        setLoadingDownload(false)
    }, [transactionsToExport])

    return (
        <div className='h-auto w-full flex flex-col gap-2 my-6'>
            <p className='text-primary-light'>Please make sure to press “Enter” to load the results</p>
            <div className='mb-2'>
                <SellerTransactionsFilters
                    filters={filters}
                    onHandleKeyPress={onEnterKeyPressInput}
                    onChangeFilterValue={onChangeFilterValue}
                    onChangeFilterSelected={onChangeFilterSelected}
                />
                <div className='flex flex-row justify-center md:justify-end mt-2'>
                    <Button
                        label={'Clear Filters'}
                        onClickButton={onClickClearAllFilters}
                        className={'btn-primary-text-underline min-w-[150px]'}
                    />
                    <Button
                        label={loadingDownload ? 'Loading data...' : 'Export CSV'}
                        onClickButton={() => (!accessControl || pageAccess?.actions?.['Export Transactions CSV']) && onDownloadCsvData()}
                        className={`${(!accessControl || pageAccess?.actions?.['Export Transactions CSV']) ? 'btn-main' : 'btn-main-disable'} mr-2 !py-2 !shadow-none`}
                    />
                    <CSVLink
                        ref={csvLink}
                        data={(transactionsToExport && transactionsToExport?.length > 0) ? transactionsToExport?.filter((item: any, idx: number) => idx > 0) : []}
                        headers={transactionsToExport?.[0] || []}
                        className={'hidden'}
                        asyncOnClick={true}
                        target={'_blank'}
                        filename={`Transactions-${id}-data.csv`}
                    >
                    </CSVLink>
                </div>
            </div>
            <div className='mb-5 relativ'>
                <>
                    {(rowsLoading || rows?.length > 0) ? (
                        <SellerTransactionsTable
                            rows={rows}
                            actions={pageAccess?.actions}
                            accessControl={accessControl}
                            cashoutsLoading={rowsLoading}
                            paginationData={paginationData}
                            setPaginationState={setPaginationState}
                        />
                    ) : (
                        <EmptyContainer
                            text={'No transactions for this user yet.'}
                            showImage={true}
                        />
                    )}
                </>
            </div>
        </div>
    )
}

export default TransactionsTab;