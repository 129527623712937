import { createApi } from '@reduxjs/toolkit/query/react'
import { AxiosResponse } from 'axios'
import { IUser, TwigLoginResponse, UserPassPayload } from '../../interfaces/auth/IUser'
import { authAxiosQuery } from '../../utils/axios/auth.axios'
import { readFromStorage } from '../../utils/webStorage'

export const authApi = createApi({
  reducerPath: 'auth',
  baseQuery: authAxiosQuery(),
  endpoints: (builder) => ({
    login: builder.mutation<TwigLoginResponse, UserPassPayload>({
        query: (payload) => ({
            url: '/dom/login',
            method: 'POST',
            data: payload
        })
    }),
    currentUser: builder.query<AxiosResponse<IUser>, {}>({
        query: (payload) => ({
            url: '/authorize',
            method: 'POST',
            data: payload,
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${readFromStorage('accessToken')}`,
            },
        })
    }),
    refreshToken: builder.mutation<TwigLoginResponse, {}>({
        query: () => ({
            url: '/refresh-token',
            method: 'POST',
            data: {token: readFromStorage('refreshToken')},
        })
    }),
  }),
})

export const { 
    useLoginMutation,
    useCurrentUserQuery,
    useRefreshTokenMutation
} = authApi