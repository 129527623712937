import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { tryToFetchAllFlaggedUsers } from '../../../store/customer-support/flagged-user/FlaggedUserSlice';
import { IToast } from '../../../interfaces/components/IToast';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import FlaggedUserTable from './tables/FlaggedUserTables';
import EmptyContainer from '../../../shared/empty-container';
import Toast from '../../../shared/toast';
import Input from '../../../shared/input';


const FlaggedUser = () => {
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<any[]>([]);
    const [search, setSearch] = useState<string>();
    const [showToast, setShowToast] = useState<IToast | null>();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
        filters: '',
    });
    const [pageAccess, setPageAccess] = useState<any>()
    const paginationData = useAppSelector((state) => state?.flaggedUsers?.flaggedUsers?.data?.page);
    const rowsLoading = useAppSelector((state) => state.flaggedUsers.flaggedUsersAreLoading);
    const accessControl = useAppSelector((state) => state?.partners?.navigation);
    const state = useAppSelector((state) => state?.flaggedUsers);
    const navigate = useNavigate();

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Flagged Users')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationState = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        })
    }

    useEffect(() => {
        if (state?.flaggedUsers) {
            const rows =
                state.flaggedUsers?.data?.elements &&
                    Array.isArray(state.flaggedUsers?.data.elements)
                    ? state.flaggedUsers?.data.elements.map((r) => ({
                        ...r,
                    }))
                    : [];
            setRows(rows);
        }
    }, [state?.flaggedUsers]);

    useEffect(() => {
        let data: any = {
            ...paginationState || {}
        }
        if (search) {
            data = {
                ...data || {},
                filters: {
                    search: search
                }
            }
        }
        dispatch(tryToFetchAllFlaggedUsers(data));
    }, [paginationState]);

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearch(value || undefined);
        onResetPaginationState()
    };

    const onSeeFlaggedUserDetails = async (id: string) => {
        navigate(`/flagged-users/${id}`);
    };

    return (
        <div className='h-full w-full flex flex-col gap-6'>
            <div className='flex h-10'>
                <div className='w-1/2 flex justify-start'>
                    <p className='page-title'>Flagged Users</p>
                </div>
            </div>
            <div className={'min-w-[100px]'}>
                <Input
                    placeholder={'Filter Table'}
                    dataQa={'filter-table'}
                    containerStyle='mb-0 pb-0 relative'
                    inputStyle={'mb-0 border-0 focus:outline-none'}
                    icon={'/assets/shared/search.svg'}
                    showValue={true}
                    inputValue={search}
                    inputUniqueName={'search'}
                    onChangeInput={onChangeSearchValue}
                    hideInputSpace={true}
                />
            </div>
            <div className='relativ'>
                {rows?.length > 0 || rowsLoading ? (
                    <FlaggedUserTable
                        rows={rows}
                        actions={pageAccess?.actions}
                        accessControl={accessControl}
                        paginationData={paginationData}
                        rowsLoading={rowsLoading}
                        setPaginationState={setPaginationState}
                        onSeeFlaggedUserDetails={onSeeFlaggedUserDetails}
                    />
                ) : (
                    <EmptyContainer
                        text={'No Failed Flagged User happened yet.'}
                        showImage={true}
                    />
                )}
                {showToast?.message && (
                    <Toast
                        type={showToast?.type}
                        text={showToast?.message}
                        onHandleCloseToast={() => setShowToast(null)}
                    />
                )}
            </div>
        </div>
    );
};

export default FlaggedUser;