import { AxiosResponse } from 'axios';
import { ORDERS_URL } from '../../../config';
import brokersAxios from '../../../utils/axios/brokers-roles.axios';
import ordersAxios from '../../../utils/axios/orders.axios';


const tryToFetchShipmentDetails = (orderId: string, data: { itemId: string, marketId: string }): Promise<AxiosResponse> => {
    const url = `${ORDERS_URL}/shipments/shipment-details/${orderId}`
    return brokersAxios.put(url, data)
}

const tryToFetchShipmentFailedPayments = (data?: any): Promise<AxiosResponse> => {
    const url = `${ORDERS_URL}/shipments/failed-payment/orders-data`
    return ordersAxios.put(url, data)
}

const tryToFetchOrderDetails = (
	orderId: string,
): Promise<AxiosResponse<any>> => {
	return ordersAxios.get(
		`${ORDERS_URL}/shipments/${orderId}`,
	);
};

export const ShipmentService = {
    tryToFetchShipmentDetails,
    tryToFetchShipmentFailedPayments,
    tryToFetchOrderDetails
}