import React from 'react';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';

interface IActionCheckModal {
    openMarketStatusModal: boolean;
    errorType?: boolean;
    text: string;
    title: string;
    action: string;
    loading?: boolean;
    handleCloseModal: () => void;
    onApproveAction: (type: string) => void;
}

const ActionCheckModal = ({
    openMarketStatusModal,
    errorType,
    text,
    title,
    action,
    loading,
    handleCloseModal,
    onApproveAction,
}: IActionCheckModal) => {
    return (
        <Modal
            open={openMarketStatusModal}
            onClose={() => handleCloseModal()}>
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>{title}</p>
                </div>
                <p className='my-2'>{text}</p>
                <div className='flex flex-row justify-end my-2'>
                    <Button
                        label='Continue'
                        className={errorType ? 'btn-error' : 'btn-primary'}
                        onClickButton={() => !loading && onApproveAction(action)}
                    />
                </div>
            </div>
        </Modal>
    )
}
export default ActionCheckModal;