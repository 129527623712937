import React, { useEffect, useState } from 'react';
import ChangePassword from './modals/ChangePassword';
import EditUsername from './modals/EditUsernameModal';
import WhiteContainer from '../../../shared/white-container';
import Button from '../../../shared/button';
import Toast from '../../../shared/toast';


const Profile = () => {
    const [profileData, setProfileData] = useState<any>()
    const [showEditInfo, setShowEditInfo] = useState<boolean>(false)
    const [showChangePassword, setShowChangePassword] = useState<boolean>(false)
    const [toatsStatus, setToastStatus] = useState<{ type: string, msg: string }>()

    const getDataFromToken = () => {
        try {
            const userRes = localStorage.getItem('user')
            const userInfo = JSON.parse(userRes || '')
            setProfileData(userInfo)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getDataFromToken()
    }, [])

    const onShowHidePersonalInformation = () => {
        setShowEditInfo(!showEditInfo)
    }

    const onShowHideChangePasswordModal = () => {
        setShowChangePassword(!showChangePassword)
    }

    const passwordChangedSuccessfully = () => {
        setToastStatus({ type: 'success', msg: 'Password changed successfully!' })
        setShowChangePassword(false)
    }

    const usernameUpdatedSuccessfully = (data: any) => {
        const formatProfileData = {
            ...profileData,
            name: data?.name,
            email: data?.email,
            'custom:country': data?.country,
        }
        localStorage.setItem('user', JSON.stringify(formatProfileData))
        setProfileData(formatProfileData)
        setToastStatus({ type: 'success', msg: 'Profile updated successfully!' })
        setShowEditInfo(false)
    }

    return (
        <div>
            <div className='mb-8'>
                <p className='page-title'>Profile</p>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                <WhiteContainer>
                    <p className='text-primary-default text-lg font-bold'>User Information</p>
                    <div className='mt-7 mb-5'>
                        <p className='mb-3'><span className='font-medium mr-1'>Username:</span>{profileData?.['cognito:username']}</p>
                        <p className='mb-3'><span className='font-medium mr-1'>Name:</span>{profileData?.name}</p>
                        <p className='mb-3'><span className='font-medium mr-1'>Email:</span>{profileData?.email}</p>
                        <p className='mb-3'><span className='font-medium mr-1'>Country:</span>{profileData?.['custom:country']}</p>
                        <p className='mb-3'><span className='font-medium mr-1'>Groups:</span>{profileData?.['cognito:groups']}</p>
                        <div className='mt-4 mb-2'>
                            <Button
                                label={'Edit details'}
                                dataQa={'edit-details'}
                                className={'btn-primary-text-underline'}
                                onClickButton={onShowHidePersonalInformation}
                            />
                            <Button
                                label={'Change password'}
                                dataQa={'change-password'}
                                className={'btn-primary-text-underline'}
                                onClickButton={onShowHideChangePasswordModal}
                            />
                        </div>
                    </div>
                </WhiteContainer>
            </div>
            <ChangePassword
                openChangePasswordModal={showChangePassword}
                handleCloseModal={onShowHideChangePasswordModal}
                passwordChangedSuccessfully={passwordChangedSuccessfully}
                username={profileData?.['cognito:username'] || ''}
            />
            <EditUsername
                currentData={{ name: profileData?.name, country: profileData?.['custom:country'], email: profileData?.email }}
                openModal={showEditInfo}
                username={profileData?.['cognito:username'] || ''}
                handleCloseModal={onShowHidePersonalInformation}
                usernameUpdatedSuccessfully={usernameUpdatedSuccessfully}
            />
            {toatsStatus &&
                <Toast
                    type={toatsStatus?.type}
                    text={toatsStatus?.msg}
                    onHandleCloseToast={() => setToastStatus(undefined)}
                />
            }
        </div>
    );
};

export default Profile;