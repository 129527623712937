import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { IMarket } from '../../../interfaces/markets/IMarket';
import { IAllMarkets } from '../../../interfaces/markets/IAllMarkets';
import { IPaginationPayload } from '../../../interfaces/shared/IPaginationPayload';
import { useGetAllMarketsMutation } from '../../../store/user/userApi';
import { tryToFetchAllSellers } from '../../../store/customer-support/orders/ordersSlice';
import SellerDetailsFilters from './details/SellerDetailsFilters';
import SellersTable from './tables/SellersTable';
import EmptyContainer from '../../../shared/empty-container';
import Button from '../../../shared/button';


const Sellers = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<any>([]);
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>();
    const [filters, setFilters] = useState<any>();
    const [markets, setMarkets] = useState<IAllMarkets[]>();
    const [selectedMarket, setSelectedMarket] = useState<any>();
    const paginationData = useAppSelector((state) => state.orders.sellers?.data?.page);
    const rowsLoading = useAppSelector((state) => state.orders.sellersAreLoading);
    const accessControl = useAppSelector((state) => state?.partners?.navigation);
    const state = useAppSelector((state) => state.orders);
    const [getAllMarkets] = useGetAllMarketsMutation();

    useEffect(() => {
        const findPage = accessControl && accessControl?.length > 0 && accessControl?.find((item: any) => item?.name === 'Wing Users')
        const findChild = findPage && findPage?.children && findPage?.children?.length > 0 && findPage?.children?.find((item: any) => item?.path === '')
        setPageAccess(findChild || undefined)
    }, [accessControl])

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10
        })
    }

    useEffect(() => {
        if (paginationState) {
            const rows =
                state.sellers?.data?.elements &&
                    Array.isArray(state.sellers?.data.elements)
                    ? state.sellers?.data.elements.map((r: any) => ({
                        ...r,
                        id: r._id,
                    }))
                    : [];
            setRows(rows);
        } else {
            setRows([])
        }
    }, [paginationState, state?.sellers]);

    const onGettingSellersData = async () => {
        const data = {
            ...paginationState || {},
            data: {
                ...filters || {},
                country: selectedMarket?.displayName || ''
            }
        }
        await dispatch(tryToFetchAllSellers(data));
    }

    const allUndefined = (obj: any) => {
        if (typeof obj === 'undefined') {
            return true;
        }
        if (typeof obj !== 'object' || obj === null) {
            return false;
        }
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                if (typeof obj[key] !== 'undefined' || !allUndefined(obj[key])) {
                    return false;
                }
            }
        }
        return true;
    }

    useEffect(() => {
        if (selectedMarket?.displayName && !allUndefined(filters)) {
            onGettingSellersData()
        } else {
            setRows(undefined)
        }
    }, [paginationState, selectedMarket]);

    const getMarketsData = async () => {
        const marketResponse = await getAllMarkets(null).unwrap();
        const formatMarkets =
            marketResponse &&
            marketResponse?.length > 0 &&
            marketResponse?.map((market: IMarket) => ({ ...market, name: market?.label, value: market?._id, displayName: market?.name }));
        setMarkets(formatMarkets || []);
        if (!selectedMarket) {
            const findMarketUK = (formatMarkets && formatMarkets?.length > 0) && formatMarkets?.find((item: any) => (item?.label || '').toLowerCase() === 'united kingdom')
            setSelectedMarket(findMarketUK || formatMarkets?.[0]?.name || undefined);
        }
    }

    useEffect(() => {
        getMarketsData()
    }, [])

    const onClickClearAllFilters = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: 10,
        });
        setFilters(undefined)
    };

    const onSeeDetails = (id: string) => {
        navigate(`/wing-users/${id}`);
    };

    const onChangeFilterValue = (value: string | null | undefined, type: string) => {
        setFilters({
            ...filters,
            [type]: value || undefined,
        });
        if (type === 'minDateRegistered' || type === 'maxDateRegistered') {
            onResetPaginationSettings()
        }
    };

    const onEnterKeyPressInput = async (e: any) => {
        if (e?.key === 'Enter') {
            onResetPaginationSettings()
        }
    }

    const onChangeSelectedMarket = (value: any, type?: string) => {
        setSelectedMarket(value)
    }

    return (
        <div className='h-full w-full flex flex-col gap-6'>
            <div className='flex h-10'>
                <div className='w-1/2 flex justify-start'>
                    <p className='page-title'>Wing Users</p>
                </div>
            </div>
            <p className='text-primary-light'>Please make sure to press “Enter” to load the results</p>
            <SellerDetailsFilters
                filters={filters}
                markets={markets}
                selectedMarket={selectedMarket}
                onChangeSelectedMarket={onChangeSelectedMarket}
                onChangeFilterValue={onChangeFilterValue}
                onHandleKeyPress={onEnterKeyPressInput}
            />
            <div className='flex flex-row justify-end'>
                <Button
                    label={'Clear All Filters'}
                    dataQa={'clear-all-filters'}
                    className='btn-primary-text-underline'
                    onClickButton={onClickClearAllFilters}
                />
            </div>
            <div className='mb-5 relativ'>
                {(rows?.length > 0 || rowsLoading) ?
                    <SellersTable
                        rows={rows}
                        rowsLoading={rowsLoading}
                        actions={pageAccess?.actions}
                        accessControl={accessControl}
                        paginationData={paginationData}
                        setPaginationState={setPaginationState}
                        onSeeDetails={onSeeDetails}
                    />
                    : <EmptyContainer
                        text={
                            !paginationState.filters
                                ? 'Search For A User'
                                : 'The User Not Found.'
                        }
                        showImage={true}
                    />
                }
            </div>
        </div>
    );
};

export default Sellers;