import React from 'react';
import WhiteContainer from '../../../../shared/white-container';


interface IProductInfo {
    itemData?: any,
    totalCost?: number;
}

const ProductInfo = ({
    itemData,
    totalCost
}: IProductInfo) => {
    return (
        <WhiteContainer containerStyle='!h-auto !bg-[#f4f4f4] mb-2'>
            <div>
                <p className='font-bold text-[20px] mb-4'>Price Summary</p>
                <div className='grid grid-cols-2 gap-4 my-6'>
                    <p>{(itemData?.revaluedPrice || itemData?.revaluedPrice === 0)  ? 'Revalued Price' : 'Purchase Price'}</p>
                    <div className='grid'>
                        <p className='justify-self-end text-primary-default text-4xl font-bold'>{Number(itemData?.revaluedPrice || itemData?.price || 0).toFixed(2)}</p>
                        {(itemData?.revaluedPrice || itemData?.revaluedPrice === 0) &&
                            <p className='justify-self-end line-through text-primary-default text-2xl font-bold'>{Number(itemData?.price || 0).toFixed(2)}</p>
                        }
                    </div>
                </div>
            </div>
        </WhiteContainer>
    )
}

export default ProductInfo;