import React from 'react';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';


interface IGenerateCashoutLabelModal {
    openModal: boolean;
    error?: string;
    loading?: boolean;
    handleCloseModal: () => void;
    onApproveAction: () => void;
}

const GenerateCashoutLabelModal = ({
    openModal,
    error,
    loading,
    handleCloseModal,
    onApproveAction,
}: IGenerateCashoutLabelModal) => {
    return (
        <Modal
            open={openModal}
            onClose={() => handleCloseModal()}
            dataQa={'close-button'}
        >
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Generate Cashout Label Pack</p>
                    {error && <Error text={error} />}
                </div>
                <p className='mt-4 mb-6'>Are you sure you want to Generate Label Pack for all items that belong to the cashout selected?</p>
                <div className='flex flex-row justify-center mb-3 mt-10'>
                    <Button
                        label={loading ? 'Generating...' : 'Continue'}
                        dataQa={'continue-button'}
                        className={loading ? 'btn-main-disable min-w-[200px] rounded' : 'btn-main min-w-[200px]'}
                        onClickButton={() => !loading && onApproveAction()}
                    />
                </div>
            </div>
        </Modal>
    )
}
export default GenerateCashoutLabelModal;