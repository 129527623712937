import React, { useEffect } from 'react';
import moment from 'moment'
import { IOrders } from '../../../../interfaces/orders/IOrders';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import { onFormatOrderStatus } from '../../../../utils/constants/format-order-statuses';
import Table from '../../../../shared/table';

let tableColumns = ['Order Id', 'Shipped Date', 'Item Name', 'Broker', 'User Full Name', 'User Email', 'Price', 'Inventory Set', 'Order Source', 'Tracking Number', 'Order Status', 'Single/Multiple', 'Invoice URL', 'Paid', 'Auto-accept revalue', 'Actions']
let tableKeys = ['id', 'shippedDate', 'itemName', 'brokerUsername', 'sellerFullName', 'seller.email', 'localizedPrice', 'location', 'sourceOrder', 'trackingNumber', 'orderStatus', 'isMultipleCashout', 'paid', 'invoiceUrl', 'autoAccept']

interface IOrdersTable {
    rows: Array<IOrders>;
    paginationData?: IPage;
    selectedFilter?: string;
    activeMarket?: any;
    isRecentChanges?: boolean;
    ordersLoading: boolean;
    categorySelected: string;
    markets?: any;
    accessControl?: any;
    actions?: any;
    onSeeDetails: (id: string, itemId: string, country: string) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onCashoutType?: (id: string, itemId: string) => void;
}

const OrdersTable = ({
    rows,
    paginationData,
    selectedFilter,
    activeMarket,
    isRecentChanges,
    ordersLoading,
    categorySelected,
    markets,
    accessControl,
    actions,
    onSeeDetails,
    setPaginationState,
    onCashoutType
}: IOrdersTable) => {
    useEffect(() => {
        switch (selectedFilter) {
            case 'Inbound':
                tableColumns = ['Order Id', 'Updated Date', 'Item Name', 'Broker', 'User Full Name', 'User Email', 'Price', 'Inventory Set', 'Order Source', 'Tracking Number', 'Order Status', 'Single/Multiple', 'Paid', 'Invoice URL', 'Auto-accept revalue', 'Actions']
                tableKeys = ['id', 'shippedDate', 'itemName', 'brokerUsername', 'sellerFullName','seller.email', 'localizedPrice', 'location', 'sourceOrder', 'trackingNumber', 'orderStatus', 'isMultipleCashout', 'paid', 'invoiceUrl', 'autoAccept']
                break;
            case 'In Review':
            case 'CS': {
                tableColumns = ['Order Id', 'Updated Date', 'Item Name', 'Broker', 'User Full Name','User Email', 'Price', 'Inventory Set', 'Order Source', 'Tracking Number', 'Order Status', 'Single/Multiple', 'Paid', 'Invoice URL', 'Auto-accept revalue', 'Imei', 'Actions']
                tableKeys = ['id', 'shippedDate', 'itemName', 'brokerUsername', 'sellerFullName','seller.email', 'localizedPrice', 'location', 'sourceOrder', 'trackingNumber', 'orderStatus', 'isMultipleCashout', 'paid', 'invoiceUrl', 'autoAccept', 'imei',]
                if (!((categorySelected || '').toLowerCase()).includes('electronics')) {
                    tableColumns.splice(-2, 1)
                    tableKeys.splice(-1, 1)
                }
                break;
            }
            case 'History': {
                tableColumns = ['Order Id', 'Updated Date', 'Item Name', 'Broker', 'User Full Name','User Email', 'Price', 'Inventory Set', 'Order Source', 'Tracking Number', 'Order Status', 'Single/Multiple', 'Paid', 'Invoice URL', 'Imei', 'Auto-accept revalue', 'Actions']
                tableKeys = ['id', 'shippedDate', 'itemName', 'brokerUsername', 'sellerFullName','seller.email', 'localizedPrice', 'location', 'sourceOrder', 'trackingNumber', 'orderStatus', 'isMultipleCashout', 'paid', 'invoiceUrl', 'imei', 'autoAccept']
                if (!((categorySelected || '').toLowerCase()).includes('electronics')) {
                    tableColumns.splice(-2, 1)
                    tableKeys.splice(-1, 1)
                }
                break;
            }
            default:
                tableColumns = ['Order Id', 'Updated Date', 'Item Name', 'Broker', 'User Full Name','User Email', 'Price', 'Inventory Set', 'Order Source', 'Tracking Number', 'Order Status', 'Single/Multiple', 'Paid', 'Invoice URL', 'Auto-accept revalue', 'Actions']
                tableKeys = ['id', 'shippedDate', 'itemName', 'brokerUsername', 'sellerFullName','seller.email', 'localizedPrice', 'location', 'sourceOrder', 'trackingNumber', 'orderStatus', 'isMultipleCashout', 'paid', 'invoiceUrl', 'autoAccept']
                break;
        }
    }, [selectedFilter])
    const findMarket = markets && markets?.length > 0 && markets?.find((market: any) => market?.name === activeMarket)
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {ordersLoading ?
                <>
                    {[...Array(10)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {tableColumns?.map(element => {
                                    return (
                                        <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })
                                }
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                {tableKeys?.length > 0 && tableKeys?.map((key, index) => {
                                    if (key !== 'trackingNumber') {
                                        let value = row?.[key]
                                        let item = null
                                        if (key === 'orderStatus') {
                                            value = onFormatOrderStatus(row?.[key], selectedFilter, isRecentChanges)?.name
                                            const text = onFormatOrderStatus(row?.[key], selectedFilter, isRecentChanges)?.text || 'text-black'
                                            const borderColor = onFormatOrderStatus(row?.[key], selectedFilter, isRecentChanges)?.borderColor || 'black'
                                            item = <div className={`flex flex-row justify-center items-center text-center px-1 min-w-[150px] h-[34px] ${text} text-sm py-1 font-medium border ${borderColor} rounded-[10px]`}>{value}</div>
                                        }
                                        if (key === 'isMultipleCashout') {
                                            value = row?.[key] ? <button type='button' className='bg-transparent outline-none underline' onClick={() => onCashoutType && onCashoutType(row?.id, row?.itemId)}>Multiple Cashout</button> : <p className='text-center'>Single Cashout</p>
                                        }
                                        if (key === 'localizedPrice') {
                                            value = Number(row?.[key]?.amount || 0).toFixed(2)
                                            item = `${CURRENCY_SYMBOLS?.[`${(findMarket?.currency?.name || '')}`]}${value}`
                                        }
                                        if (key === 'shippedDate') {
                                            value = moment(row?.[key]).fromNow()
                                        }
                                        if (key === 'seller.email') {
                                            value = row?.seller?.email || ''
                                        }
                                        if (key === 'sellerFullName') {
                                            value = `${row?.seller?.profile?.firstName} ${row?.seller?.profile?.lastName}` || ''
                                        }
                                        if (key === 'paid') {
                                            value = row?.paid ? 'Yes' : 'No'
                                        }
                                        if (key === 'receivedDate') {
                                            value = moment(row?.[key]).fromNow()
                                        }
                                        if (key === 'location') {
                                            value = row?.location ? 'Yes' : 'No'
                                        }
                                        if (key === 'sourceOrder') {
                                            let findSource = row?.sourceOrder
                                            switch ((row?.sourceOrder)?.toLowerCase()) {
                                                case 'app':
                                                    findSource = 'Wing'
                                                    break;
                                                case 'trade_in':
                                                    findSource = 'Trade In'
                                                    break;
                                                case 'sellWing':
                                                    findSource = 'Wing Web'
                                                    break;
                                                case 'sellwing':
                                                    findSource = 'Wing Web'
                                                    break;
                                                default:
                                                    findSource = row?.sourceOrder
                                                    break;
                                            }
                                            value = findSource || row?.sourceOrder
                                        }
                                        if (key === 'invoiceUrl' && row?.invoiceUrl) {
                                            value = <a
                                                rel='noreferrer'
                                                href={row?.invoiceUrl}
                                                target='_blank'
                                                className='text-primary-default'
                                                data-qa={`invoice_url_${row?.trackingNumber}`}
                                            >{row?.invoiceUrl}</a>
                                        }
                                        if (key === 'autoAccept') {
                                            value = row?.autoAccept || 'No'
                                        }
                                        return (
                                            <td
                                                key={index}
                                                className={'px-6 py-4'}>
                                                {item || value}
                                            </td>
                                        )
                                    } else {
                                        return (
                                            <td data-qa={`${row?.[key]}`} key={index} className='px-6 py-4'>
                                                {row?.[key] ?
                                                    <a
                                                        rel='noreferrer'
                                                        href={`https://parcelsapp.com/en/tracking/${row?.[key]}`}
                                                        target='_blank'
                                                        className='text-primary-default'
                                                    >
                                                        {row?.[key]}
                                                    </a>
                                                    : <p className='!text-[#F7A21E]'>{'NO TRACKING'}</p>
                                                }
                                            </td>
                                        )
                                    }
                                })}
                                <td className='px-6 py-4 min-w-[120px]'>
                                    <div className='flex flex-row justify-around'>
                                        <div data-qa={'view-details'} onClick={() => (!accessControl || actions?.['See Order Details']) && row?.id && row?.itemId && row?.isMultipleCashout && onCashoutType ? onCashoutType(row?.id, row?.itemId) : onSeeDetails(row?.id, row?.itemId || '', row?.region || '')}>
                                            <img title={'View Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={`h-[14px] object-contain ${(!accessControl || actions?.['See Order Details']) ? 'cursor-pointer' : 'cursor-not-allowed'} mr-2`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default OrdersTable;