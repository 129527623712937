import React from 'react';
import moment from 'moment';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import { onFormatTransactionStatus } from '../../../../utils/constants/format-order-statuses';
import Table from '../../../../shared/table';

const tableColumns = ['Transaction ID', 'Sender', 'Receiver', 'Type', 'Amount', 'Date And Time', 'Transaction Status']
const tableKeys = ['id', 'sender', 'receiverName', 'moneyDirection', 'amount', 'date', 'status']

interface ISellerTransactionsTable {
    rows: any;
    paginationData?: IPage;
    cashoutsLoading: boolean;
    accessControl?: any;
    actions?: any;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
}

const SellerTransactionsTable = ({
    rows,
    paginationData,
    cashoutsLoading,
    accessControl,
    actions,
    setPaginationState,
}: ISellerTransactionsTable) => {
    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}>
            {cashoutsLoading ?
                <>
                    {[...Array(10)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {tableColumns?.map(element => {
                                    return (
                                        <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: any, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                {tableKeys?.length > 0 && tableKeys?.map((key, index) => {
                                    let value = row?.[key]
                                    let item = null
                                    if (key === 'status') {
                                        value = onFormatTransactionStatus(row?.[key])?.name
                                        const color = onFormatTransactionStatus(row?.[key])?.color
                                        item = color && <div className={`${color} flex flex-row justify-center text-center text-red px-1 min-w-[150px] text-black text-sm py-1 font-medium rounded-2xl`}>{value}</div>
                                    }
                                    if (key === 'amount') {
                                        value = Number(row?.amount || 0).toFixed(2)
                                        item = `${CURRENCY_SYMBOLS?.[`${(row?.currency || '')}`]}${value}`
                                    }
                                    if (key === 'date') {
                                        value = moment(row?.updatedAt).format('DD-MM-YYYY, HH:mm')
                                    }
                                    if (key === 'sender') {
                                        value = row?.sender || 'No value'
                                    }
                                    if (key === 'moneyDirection') {
                                        value = row?.moneyDirection ? <div className='border border-2 border-[#F7A21E] px-4 py-1 text-center text-[#F7A21E] font-medium'>{row?.moneyDirection ? `${row?.moneyDirection[0].toUpperCase()}${row?.moneyDirection?.slice(1)?.toLowerCase()}` : ''}</div> : <div></div>
                                    }
                                    return (
                                        <td
                                            key={index}
                                            className={'px-6 py-4'}>
                                            {item || value}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default SellerTransactionsTable;