import React, { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToDisableCashoutFee } from '../../../../store/customer-support/flagged-user/FlaggedUserSlice';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';


interface IDisableFeeModal {
    openModal: boolean;
    userId: string,
    onApproveAction: (type: string, message: string) => void;
    handleCloseModal: () => void;
}

const DisableFeeModal = ({
    openModal,
    userId,
    handleCloseModal,
    onApproveAction,
}: IDisableFeeModal) => {
    const dispatch = useAppDispatch();
    const [saveError, setSaveError] = useState<string>()
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

    const onCloseModal = () => {
        handleCloseModal();
        setLoadingSubmit(false)
    }

    const onSubmit = async () => {
        setLoadingSubmit(true)
        try {
            await (dispatch(tryToDisableCashoutFee(userId || ''))).unwrap()
            onApproveAction('success', 'Cashout fee successfully disabled!')
            onCloseModal()
        } catch (err) {
            setSaveError(`${err}`)
        }
        setLoadingSubmit(false)
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                <p className='mb-4 font-bold text-sm border-b-1'>Disable Fees</p>
                {saveError && <Error text={saveError} />}
                <p className='font-semibold text-[15px] mb-2 mt-3'>Are you sure you want to disable Cashout fees for this user.</p>
                <div className='min-w-full flex flex-row pt-4 justify-end'>
                    <Button
                        label={'Continue'}
                        dataQa={'continue'}
                        className={`${loadingSubmit ? 'btn-error-disable' : 'btn-error'} my-3 min-w-[130px] !shadow-none`}
                        onClickButton={() => !loadingSubmit && onSubmit()}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default DisableFeeModal;