import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IShipmentListState } from '../../../interfaces/shipments/IShipmentList';
import { ShipmentService } from './shipmentsApi';
import { AxiosError } from 'axios';


const initialState: IShipmentListState = {
    shipmentIsLoading: false,
    shipmentsAreLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchShipmentDetails = createAsyncThunk<any, any>(
    'shipments/tryToFetchShipmentDetails',
    async ({ orderId, data }) => {
        const result = await ShipmentService.tryToFetchShipmentDetails(orderId, data);
        return result.data;
    },
);

export const tryToFetchShipmentFailedPayments = createAsyncThunk<any, any>(
    'shipments/tryToFetchShipmentFailedPayments',
    async (data?: any) => {
        const result = await ShipmentService.tryToFetchShipmentFailedPayments(data);
        return result.data;
    },
);

export const tryToFetchOrderDetails = createAsyncThunk<any, any>(
	'shipments/tryToFetchOrderDetails',
	async ({ orderId }, { rejectWithValue }) => {
		try {
			const results = await ShipmentService.tryToFetchOrderDetails(
				orderId,
			);
			return results.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);


export const ShipmentsSlice = createSlice({
    name: 'shipments',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            // tryToFetchShipmentDetails
            .addCase(tryToFetchShipmentDetails.pending, (state) => {
                state.shipmentsAreLoading = true;
            })
            .addCase(tryToFetchShipmentDetails.rejected, (state) => {
                state.shipmentsAreLoading = false;
            })
            .addCase(tryToFetchShipmentDetails.fulfilled, (state, action) => {
                state.shipmentsAreLoading = false;
                state.shipment = action.payload;
            })

    }
})
export default ShipmentsSlice.reducer;
