import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { removeFromStorage } from '../../utils/webStorage';
import { useNavigate } from 'react-router-dom';
import { useGetCurrentUserQuery } from '../../store/user/userApi';
import { GuestGuard } from '../../guards/RolesGuard';
import { useAppSelector } from '../../app/hooks';

// const userNavigation = [
//     { name: 'Your Profile', href: '#' },
//     { name: 'Settings', href: '#' },
//     { name: 'Sign out', href: '#' },
// ]

const Profile = () => {
    const navigate = useNavigate();
    const navigation = useAppSelector(state => state?.partners?.navigation)
    const { data: userInfo } = useGetCurrentUserQuery();
    const handleLogout = () => {
        removeFromStorage('accessToken');
        removeFromStorage('refreshToken');
        removeFromStorage('roles');
        window.location.reload();
        navigate('/');
    }

    const onClickLogo = () => {
        if (!GuestGuard()) {
            const findIfInsightsExists = (navigation && navigation?.length > 0) && navigation?.find((item: any) => item?.path === '/insights')
            if (navigation && !findIfInsightsExists) {
                const getFirstPage = (navigation && navigation?.length > 0) && navigation?.[0]
                navigate(getFirstPage?.path)
            } else {
                navigate('/tickets')
            }
        }
        else {
            navigate('/')
        }
    }
    return (
        <div className='flex gap-4'>
            <div data-qa={'broker-engine'} className='pt-2 cursor-pointer' onClick={onClickLogo}>
                <img
                    className='w-40 h-12 bg-white '
                    src='/assets/auth/wingenius-yellow.svg'
                    alt='wingenius'
                />
            </div>
            {/* Profile dropdown */}
            <Menu as='div' className='my-auto relative'>
                <div  className='md:ml-24 '>
                    <Menu.Button className=' bg-white flex items-center text-sm rounded-full focus:outline-none'>
                        <span className='sr-only'>Open user menu</span>
                        <img
                            className='w-12 h-12 bg-black rounded-xl'
                            src='/assets/auth/main-photo-yellow.svg'
                            alt='main photo'
                        />
                        <div className='text-left ml-4 mr-3'>
                            <div className='font-dm_sansregular text-sm font-normal text-primary-light'>{userInfo?.username}</div>
                        </div>
                        <img alt='arrow down' src='/svg/layout/arrow-down.svg' />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                >
                    <Menu.Items className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                        <Menu.Item >
                            <button data-qa={'logout-button'} className='block w-full text-left px-4 py-2 text-sm text-gray-700' onClick={handleLogout} type='button'>
                                Logout
                            </button>
                        </Menu.Item>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}

export default Profile;