import React, { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToAssignCSUserOutbound } from '../../../../store/customer-support/orders/ordersSlice';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';


interface IAssignCsUserModal {
    openModal: boolean;
    id: string;
    users?: any;
    handleCloseModal: () => void;
    onGettingCashoutOrdersData: () => void
}

const AssignCSUserOutboundModal = ({
    openModal,
    id,
    users,
    handleCloseModal,
    onGettingCashoutOrdersData
}: IAssignCsUserModal) => {
    const dispatch = useAppDispatch()
    const [selectedCSUser, setSelectedCSUser] = useState<any>()
    const [submitError, setSubmitError] = useState<string>()
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

    const onChangeSelectedRole = (e: any) => {
        setSelectedCSUser(e)
    }

    const onCloseModal = () => {
        handleCloseModal();
        setSelectedCSUser(undefined)
        setSubmitError(undefined)
        setLoadingSubmit(false)
    }

    const onSubmit = async () => {
        setLoadingSubmit(true)
        try {
            const body = {
                orderId: id,
                data: {csAgentUsername: selectedCSUser.username}
            }
            await dispatch(tryToAssignCSUserOutbound(body)).unwrap()
            onGettingCashoutOrdersData()
            onCloseModal()
        } catch (err) {
            setSubmitError(`${err}`)
        }
        setLoadingSubmit(false)
    }

    return (
        <Modal
            open={openModal}
            onClose={onCloseModal}
            dataQa='assign-cs-user'
            showInRight>
            <div className={'p-2 min-w-[450px]'}>
                <p className='mb-2 font-semibold text-sm'>Assign CS User</p>
                <p className='mb-4 text-xl font-normal'>Select user you want to assign to this order.</p>
                {submitError && <Error text={submitError} />}
                <div className='flex flex-col items-center'>
                    <SelectCheckbox
                        name='CS Users'
                        dataQa={'cs-users'}
                        placeholder='Select CS User'
                        containerStyle='mb-8 min-w-full'
                        selectedOption={selectedCSUser}
                        onChangeSelectedOption={onChangeSelectedRole}
                        options={users || []}
                    />
                    <div className='flex flex-rows justify-around items-center mt-32'>
                        <Button
                            className={`${loadingSubmit ? 'btn-primary-rounded-less-disable' : 'btn-primary-rounded-less'} !shadow-none min-w-[200px]`}
                            label='Assign'
                            dataQa={'assign-submit-btn'}
                            onClickButton={onSubmit}
                        />
                    </div>
                </div>
            </div>
        </Modal >
    )
}
export default AssignCSUserOutboundModal;