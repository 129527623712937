import decode from 'jwt-decode';
import { JWTModel } from '../interfaces/models/models';

export function validateJWT(token: string | null) {
    if (!token || token === 'null') {
        return false;
    }

    try {
        const decoded: JWTModel = decode(token);
        const now = Math.floor(Date.now() / 1000);
        const { exp } = decoded;
        return exp > now;
    } catch (e) {
        return false;
    }
}