import React from 'react'
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import Table from '../../../../shared/table';

const tableColumns = ['Country', 'Broker', 'Item Code (IMEI)', 'Item ID', 'Item Name', 'User ID', 'User full name', 'User E-mail', 'Actions'];

interface IBlacklistedItemsTable {
    rows: Array<any>;
    paginationData?: any;
    accessControl?: any;
    rowsLoading?: boolean;
    actions?: any;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onSeeBlacklistedItemDetails: (id: string, itemId: string, country: string) => void;
}

const BlacklistedItemsTable = ({
    rows,
    paginationData,
    accessControl,
    rowsLoading,
    actions,
    setPaginationState,
    onSeeBlacklistedItemDetails }:
    IBlacklistedItemsTable) => {
    return (
        <Table containerStyle={'mt-0'}
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}>
            {(rowsLoading ?
                <>
                    {[...Array(10)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {tableColumns?.map(element => {
                                    return (
                                        <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })
                                }
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 &&
                        rows?.map((row, idx: number) => {
                            return (
                                <tr
                                    key={idx}
                                    className={'border-b hover:bg-gray-50 font-normal bg-white'}>
                                    <td className='px-6 py-4'>{row?.seller.profile.country || ''}</td>
                                    <td className='px-6 py-4'>{row?.exchangeDefinition.broker.name || ''}</td>
                                    <td className='px-6 py-4'>{row?.itemCode || ''}</td>
                                    <td className='px-6 py-4'>{row?.itemId || ''}</td>
                                    <td className='px-6 py-4'>{row?.itemName || ''}</td>
                                    <td className='px-6 py-4'>{row?.seller._id || ''}</td>
                                    <td className='px-6 py-4'>{row?.seller.profile.firstName + ' ' + row?.seller.profile.lastName || ''}</td>
                                    <td className='px-6 py-4'>{row?.seller.email || ''}</td>
                                    <td className='px-6 py-4'>
                                        <div className='flex flex-row justify-around'>
                                            <div data-qa={'view-details'} onClick={() => (!accessControl || actions?.['See Details']) && row?.id && row.itemId && onSeeBlacklistedItemDetails(row.id, row.itemId, row.seller.profile.country)}>
                                                <img title={'View Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={'h-[14px] object-contain cursor-pointer'} />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                </>
            )}
        </Table>
    )
}

export default BlacklistedItemsTable