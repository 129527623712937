import React from 'react';
import WhiteContainer from '../../../../shared/white-container';


const SelllerCashoutDetailsContainer = () => {
    return (
        <WhiteContainer containerStyle='mb-4 rounded h-auto'>
            <p className='gradient-text mb-6'>Cashout Summary</p>
            <div>
                {[...Array(5)].map((x, i) => {
                    return (
                        <>
                            <div className='grid grid-cols-2 gap-4 mb-4 animate-pulse'>
                                <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[80%] mb-1'></div>
                                <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[80%] mb-1'></div>
                            </div>
                            {i !== 4 &&
                                <div className='mb-5 mt-3 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                            }
                        </>
                    )
                })
                }
            </div>

        </WhiteContainer>
    )
}

export default SelllerCashoutDetailsContainer;