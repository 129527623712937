import React from 'react';
import { ORDER_DETAILS_ACTIONS } from '../../../../utils/constants/order-details-actions';
import WhiteContainer from '../../../../shared/white-container';
import { IPageAccess } from '../../../../interfaces/components/IPageAccess';
import { IAccessControl } from '../../../../interfaces/components/IAccessControl';


interface IDetailsActions {
    showReturnRequest: boolean;
    showRaiseTicket: boolean;
    showAcceptOnUserBehalf: boolean;
    showBlacklistedActions: boolean;
    brokerIsWingpay: boolean;
    blacklistedExpired: boolean;
    canCancelOrder: boolean;
    isTradeIn: boolean;
    pageAccess: IPageAccess | undefined;
    accessControl: IAccessControl
    onClickOrderDetailsAction: (action: string) => void;
    showResetOrder: boolean;
    shouldDisableGesture: boolean;
}

const DetailsActions = ({
    showReturnRequest,
    showRaiseTicket,
    showAcceptOnUserBehalf,
    showBlacklistedActions,
    brokerIsWingpay,
    blacklistedExpired,
    canCancelOrder,
    isTradeIn,
    pageAccess,
    accessControl,
    onClickOrderDetailsAction,
    showResetOrder,
    shouldDisableGesture
}: IDetailsActions) => {

    return (
        <WhiteContainer containerStyle='mb-2 px-4 py-0'>
            <div className='my-5 flex flex-row flex-wrap py-2 min-w-full items-center'>
                <p className='font-bold mr-3 text-md'>Order Actions</p>
                {ORDER_DETAILS_ACTIONS?.filter(
                    (action) =>
                        (action?.name === 'Return Requested' && showReturnRequest) ||
                        (action?.name === 'Raise A Ticket' && showRaiseTicket) ||
                        (action?.name === 'Accept Offer' && showAcceptOnUserBehalf && (!accessControl || pageAccess?.actions?.['Accept Offer'])) ||
                        (action?.name === 'Accept Offer with Gesture' && showAcceptOnUserBehalf && (!accessControl || pageAccess?.actions?.['Accept Offer with Gesture'])) ||
                        (action?.name === 'Reject Offer' && showAcceptOnUserBehalf && (!accessControl || pageAccess?.actions?.['Reject Offer'])) ||
                        (action?.name === 'Remove Blacklist status' && showBlacklistedActions && !blacklistedExpired && (!accessControl || pageAccess?.actions?.['Remove Blacklist status'])) ||
                        (action?.name === 'Add item to inventory' && showBlacklistedActions && brokerIsWingpay && blacklistedExpired && (!accessControl || pageAccess?.actions?.['Add item to inventory'])) ||
                        (action?.name === 'Return item to wing' && showBlacklistedActions && !brokerIsWingpay && blacklistedExpired && (!accessControl || pageAccess?.actions?.['Return item to wing'])) ||
                        (action?.name === 'Cancel Order' && canCancelOrder && (!accessControl || pageAccess?.actions?.['Cancel Order'])) ||
                        (action?.name === 'Reset Order' && showResetOrder && (!accessControl || pageAccess?.actions?.['Reset Order']))
                ).map((action, index) => {
                    if (action?.name === 'Accept Offer with Gesture' && shouldDisableGesture) {
                        return <div
                            data-qa={`${action?.name.toLowerCase().replaceAll(' ', '-')}`}
                            key={index}
                            className={'flex flex-row items-center sm:mb-1 lg:mb-0 mr-3 cursor-pointer bg-[#f3f3f9] hover:bg-[#e3e3e9] rounded-full px-4 py-2 opacity-60'}
                            title='You cannot offer a gesture amount because the revalued price is higher than the initial price'>
                            <img
                                src={action?.icon}
                                className={'w-[24px] object-contain'}
                            />
                            <p className='text-md text-center ml-2'>
                                {action?.label}
                            </p>
                        </div>

                    }
                    return (
                        <div
                            onClick={() =>
                                onClickOrderDetailsAction(action?.name)
                            }
                            data-qa={`${action?.name.toLowerCase().replaceAll(' ', '-')}`}
                            key={index}
                            className={`flex flex-row items-center sm:mb-1 lg:mb-0 mr-3 cursor-pointer bg-[#f3f3f9] hover:bg-[#e3e3e9] rounded-full px-4 py-2 ${action?.name === 'Accept Offer with Gesture' && isTradeIn && 'opacity-60'}`}>
                            <img
                                src={action?.icon}
                                className={'w-[24px] object-contain'}
                            />
                            <p className='text-md text-center ml-2'>
                                {action?.label}
                            </p>
                        </div>
                    );
                })}
            </div>
        </WhiteContainer>
    );
};

export default DetailsActions;
