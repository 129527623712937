import { createApi } from '@reduxjs/toolkit/query/react';
import { IUserSchema } from '../../interfaces/user/IUserSchema';
import { ApiResponse } from '../../interfaces/models/models/paginated-response.model';
import { userAxiosQuery } from '../../utils/axios/user.axios';
import { IUser } from '../../interfaces/user/IUser';
import { IPaginationPayload } from '../../interfaces/shared/IPaginationPayload';
import { PaginatedUsers } from '../../interfaces/user/paginatedUser.type';
import { IChangePassword } from '../../interfaces/user/IChangePassword';
import { readFromStorage } from '../../utils/webStorage';

export const userAPI = createApi({
	reducerPath: 'users',
	baseQuery: userAxiosQuery(),
	tagTypes: ['Posts'],
	endpoints: (builder) => ({
		getAllUsers: builder.query<PaginatedUsers, IPaginationPayload>({
			query: ({ filters, pageNumber, pageSize }: IPaginationPayload) => ({
				url: filters
					? `/users?page=${pageNumber}&size=${pageSize}&filters=${filters}`
					: `/users?page=${pageNumber}&size=${pageSize}`,
				method: 'GET',
			}),
		}),
		setPassword: builder.mutation<
			PaginatedUsers,
			{ username: string; password: string }
		>({
			query: ({ username, password }) => ({
				url: `/${username}/set-password`,
				method: 'PUT',
				data: { password },
			}),
		}),
		getCurrentUser: builder.query<IUser, void>({
			query: () => ({
				url: '/dom/authorize',
				method: 'POST',
				data: {
					token: readFromStorage('accessToken'),
				},
			}),
		}),
		getSingleUser: builder.query<IUserSchema, string>({
			query: (id: string) => ({
				url: `/user/${id}`,
				method: 'GET',
			}),
		}),
		postUser: builder.mutation<ApiResponse<IUserSchema>, IUserSchema>({
			query: (data) => ({
				url: '/sign-up',
				method: 'POST',
				data,
			}),
		}),
		addToGroup: builder.mutation<
			IUserSchema,
			{ username: string; group: string }
		>({
			query: ({ username, group }) => ({
				url: `/${username}/add-to-group`,
				method: 'POST',
				data: { group },
			}),
		}),
		removeFromGroup: builder.mutation<
			IUserSchema,
			{ username: string; group: string }
		>({
			query: ({ username, group }) => ({
				url: `/${username}/remove-from-group`,
				method: 'POST',
				data: { group },
			}),
		}),
		confirmUser: builder.mutation<IUserSchema, string>({
			query: (username) => ({
				url: `/${username}/confirm`,
				method: 'GET',
			}),
		}),
		editUser: builder.mutation<
			ApiResponse<IUserSchema>,
			{ data: any; id: string }
		>({
			query: ({ data, id }) => ({
				url: `/cs-users/${id}`,
				method: 'PUT',
				data: data,
			}),
		}),
		changePasswordData: builder.mutation<
			ApiResponse<IChangePassword>,
			{ username: string; data: IChangePassword }
		>({
			query: ({ username, data }) => ({
				url: `/${username}/change-password`,
				method: 'PUT',
				data: data,
			}),
		}),
		changePassword: builder.mutation<
			ApiResponse<IChangePassword>,
			IChangePassword
		>({
			query: (data) => ({
				url: '/dom/change-password',
				method: 'PUT',
				data: data,
				headers: {
					'Access-Control-Allow-Origin': '*',
					Authorization: `Bearer ${readFromStorage('accessToken')}`,
				},
			}),
		}),
		getAllMarkets: builder.mutation<any, undefined | null>({
			query: () => ({
				url: '/dom/markets',
				method: 'GET',
			}),
		}),
		getAllCsUsers: builder.mutation<any, undefined | null>({
			query: () => ({
				url: '/dom/users/cs',
				method: 'GEt',
			}),
		}),
	}),
});

export const {
	useGetAllUsersQuery,
	useSetPasswordMutation,
	useGetSingleUserQuery,
	useGetCurrentUserQuery,
	usePostUserMutation,
	useAddToGroupMutation,
	useRemoveFromGroupMutation,
	useConfirmUserMutation,
	useEditUserMutation,
	useChangePasswordMutation,
	useGetAllMarketsMutation,
	useGetAllCsUsersMutation,
} = userAPI;
