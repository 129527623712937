export const onFindSourceName = (source: string) => {
    let value = source;
    switch ((source || '')?.toLowerCase()) {
        case 'app':
            value = 'Wing'
            break;
        case 'trade_in':
            value = 'Trade In'
            break;
        case 'sellWing':
            value = 'Wing Web'
            break;
        case 'sellwing':
            value = 'Wing Web'
            break;
        case 'rentaltradein':
            value = 'Rental Trade In'
            break;
        default:
            value = source
            break;
    }
    return value
}