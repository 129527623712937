import React, { useState } from 'react'
import Modal from '../../../../shared/modal';


interface IAcceptOfferModal {
    openModal: boolean;
    handleCloseModal: () => void;
    onAcceptOffer: () => Promise<void>
}

const AcceptOfferModal = ({ openModal, handleCloseModal, onAcceptOffer }: IAcceptOfferModal) => {
    const [isYesSelected, setIsYesSelected] = useState(false);

    const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsYesSelected(e.target.value === 'yes');
    };

    const handleSealTheDeal = async () => {
        if (isYesSelected) {
            onAcceptOffer()
        }
    };

    return (
        <Modal open={openModal} onClose={handleCloseModal} showInRight title="Accept Offer" contentContainerStyle='px-10'>
            <p className='mb-4 text-xl font-bold'>Accept Offer</p>

            <div onChange={handleOptionChange} className='flex flex-col p-4 gap-3 bg-[#F8F9FC]'>
                <p>Are you sure you want to <span className='font-dm_sansbold'>Accept</span> the revaluation offer on behalf of the user?</p>
                <p className='text-sm text-[#202020]'>This Action cannot be undone!</p>
                <label className='flex bg-white max-w-[70%] justify-between p-2'>
                    <span>Yes</span><input type="radio" className='w-6 h-6' name="offer" value="yes" />
                </label>
                <label className='flex bg-white max-w-[70%] justify-between p-2'>
                    <span>No</span><input type="radio" className='w-6 h-6' name="offer" value="no" />
                </label>
            </div>
            <div className="flex justify-center min-w-full">
                <button
                    onClick={handleSealTheDeal}
                    disabled={!isYesSelected}
                    className={`mt-4 ${!isYesSelected ? 'btn-primary-rounded-less-disable' : 'btn-primary-rounded-less'} text-white py-2 min-w-[300px] rounded`}
                >
                    Seal the Deal
                </button>
            </div>
        </Modal>
    )
}

export default AcceptOfferModal