import React from 'react';
import Input from '../../../../shared/input';
import { ICashoutsFilters } from '../../../../interfaces/components/ICashoutsFilters';
import SelectCheckbox from '../../../../shared/select-checkbox';
import { CASHOUT_TYPES } from '../../../../utils/constants/cashout-types';
import { CASHOUT_STATUSES } from '../../../../utils/constants/cashout-statuses';


interface ISellerCashoutsFilters {
    filters?: ICashoutsFilters;
    onChangeFilterValue: (value: string | null | undefined, type?: string) => void;
    onChangeFilterSelected: (value: any, type?: string) => void;
    onHandleKeyPress: (e: any) => void;
}

const SellerCashoutsFilters = ({
    filters,
    onChangeFilterValue,
    onChangeFilterSelected,
    onHandleKeyPress
}: ISellerCashoutsFilters) => {
    return (
        <div>
            <div className='grid grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-4'>
                <Input
                    label='Item ID'
                    containerStyle=''
                    showValue={true}
                    inputValue={filters?.itemId}
                    onChangeInput={onChangeFilterValue}
                    onHandleKeyDown={onHandleKeyPress}
                    inputUniqueName={'itemId'}
                />
                <Input
                    label='Package Id'
                    containerStyle=''
                    showValue={true}
                    inputValue={filters?.packageId}
                    onChangeInput={onChangeFilterValue}
                    onHandleKeyDown={onHandleKeyPress}
                    inputUniqueName={'packageId'}
                />
                <Input
                    label='Order Number'
                    containerStyle=''
                    showValue={true}
                    inputValue={filters?.orderNumber}
                    onChangeInput={onChangeFilterValue}
                    onHandleKeyDown={onHandleKeyPress}
                    inputUniqueName={'orderNumber'}
                />
                <Input
                    label='Tracking Number'
                    containerStyle=''
                    showValue={true}
                    inputValue={filters?.trackingNumber}
                    onChangeInput={onChangeFilterValue}
                    onHandleKeyDown={onHandleKeyPress}
                    inputUniqueName={'trackingNumber'}
                />
                <Input
                    label='Country'
                    containerStyle=''
                    showValue={true}
                    inputValue={filters?.country}
                    onChangeInput={onChangeFilterValue}
                    onHandleKeyDown={onHandleKeyPress}
                    inputUniqueName={'country'}
                />
                <SelectCheckbox
                    name='Status'
                    selectedOption={filters?.status ? CASHOUT_STATUSES?.find((item)=> item?.value === filters?.status) : undefined}
                    options={CASHOUT_STATUSES}
                    uniqueName='status'
                    onChangeSelectedOption={onChangeFilterSelected}
                />
                <SelectCheckbox
                    name='Cashout Type'
                    selectedOption={filters?.cashoutType ? CASHOUT_TYPES?.find((item)=> item?.value === filters?.cashoutType) : undefined}
                    options={CASHOUT_TYPES}
                    uniqueName='cashoutType'
                    onChangeSelectedOption={onChangeFilterSelected}
                />
                <Input
                    type='text'
                    onFocus={(e: any) => {
                        e.target.type = 'date';
                        e.target.showPicker();
                    }}
                    label='From'
                    containerStyle=''
                    showValue={true}
                    inputValue={filters?.fromDate}
                    onChangeInput={onChangeFilterValue}
                    inputUniqueName={'fromDate'}
                />
                <Input
                    type='text'
                    onFocus={(e: any) => {
                        e.target.type = 'date';
                        e.target.showPicker();
                    }}
                    label='To'
                    containerStyle=''
                    showValue={true}
                    inputValue={filters?.toDate}
                    onChangeInput={onChangeFilterValue}
                    inputUniqueName={'toDate'}
                />
            </div>
        </div>
    );
};

export default SellerCashoutsFilters;