import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { TICKETS_STATUS } from '../../../../utils/constants/tickets-status';
import { ITicketFilters } from '../../../../interfaces/components/ITicketFilters';
import {
    tryToFetchMarkets,
    tryToFetchWarehouses
} from '../../../../store/customer-support/customer-care/customerCareSlice';
import {
    tryToFetchAllPartners,
    tryToFetchPartnersByMarket
} from '../../../../store/customer-support/partners/partnersSlice';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Input from '../../../../shared/input';


interface IFilters {
    filters?: ITicketFilters;
    errors?: ITicketFilters;
    users?: any;
    selectedUser?: any;
    selectedStatus?: any;
    onChangeStatusSelected: (value: any, type?: string) => void;
    onChangeSelectedAssignedUser: (value: any, type?: string) => void;
    onChangeFilterValue: (value: string | null | undefined, type: string) => void;
    onChangeSelectedCountry: (e: any, type?: string) => void;
    onChangeSelectedBroker: (e: any, type?: string) => void;
    onChangeSelectedWarehouse: (e: any, type?: string) => void;
}

const TicketFilters = ({
    filters,
    errors,
    users,
    selectedUser,
    selectedStatus,
    onChangeStatusSelected,
    onChangeSelectedAssignedUser,
    onChangeFilterValue,
    onChangeSelectedCountry,
    onChangeSelectedBroker,
    onChangeSelectedWarehouse,
}: IFilters) => {
    const dispatch = useAppDispatch();
    const markets = useAppSelector((state) => state?.cs?.markets?.data);
    const [brokers, setBrokers] = useState<any>()
    const [warehouses, setWarehouses] = useState<any>()

    const getPartnersData = async () => {
        const partnerResponse: any = await dispatch(tryToFetchAllPartners(null)).unwrap()
        const formatPartners = partnerResponse?.data && partnerResponse?.data?.length > 0 && partnerResponse?.data?.map((partner: any) => ({ ...partner, label: partner?.companyName, name: partner?._id, value: partner?._id }))
        setBrokers(formatPartners || [])
    }
    useEffect(() => {
        dispatch(tryToFetchMarkets())
        getPartnersData()
    }, [])

    const getPartnersByMarket = async () => {
        if (filters?.country) {
            try {
                const partnerResponse = await dispatch(tryToFetchPartnersByMarket(filters.country._id)).unwrap()
                const formatPartners = partnerResponse?.data && partnerResponse?.data?.length > 0 && partnerResponse?.data?.map((partner: any) => ({ ...partner, label: partner?.companyName, name: partner?.id, value: partner?.id }))
                setBrokers(formatPartners)
            } catch (err) {
                // Error here;
            }
        }
    }

    const getWarehousesData = async () => {
        let data: any = {
            brokerId: filters?.name?._id || filters?.name?.id,
        }
        if (filters?.country?._id) {
            data = {
                ...data || {},
                marketId: filters?.country?._id,
            }
        }
        try {
            const warehousesResponse = await dispatch(tryToFetchWarehouses(data)).unwrap()
            const formatWarehouses = (warehousesResponse && warehousesResponse?.length > 0) && warehousesResponse?.map((item: any) => ({ ...item, label: item?.name, name: item?._id, value: item?._id }))
            setWarehouses(formatWarehouses || undefined)
        } catch (err) {
            // Error here
        }
    }

    useEffect(() => {
        if (filters?.name) {
            getWarehousesData()
        }
    }, [filters?.name, filters?.country])

    useEffect(() => {
        getPartnersByMarket()
    }, [filters?.country])

    const formatMarkets = markets && markets?.length > 0 && markets?.map((item: any) => ({ ...item, value: item?._id }))
    return (
        <div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1 md:gap-2 lg:gap-4'>
                <SelectCheckbox
                    placeholder='Tickets Status'
                    selectedOption={selectedStatus}
                    dataQa={selectedStatus?.name ? selectedStatus?.name.toLowerCase().replaceAll(' ', '-') : 'tickets-status'}
                    options={TICKETS_STATUS}
                    uniqueName={'status'}
                    onChangeSelectedOption={onChangeStatusSelected}
                />
                <SelectCheckbox
                    placeholder='Country'
                    containerStyle=''
                    selectedOption={filters?.country || null}
                    onChangeSelectedOption={onChangeSelectedCountry}
                    dataQa={filters?.country ? filters?.country?.label.toLowerCase().replaceAll(' ', '-') : 'country'}
                    options={formatMarkets}
                />
                <SelectCheckbox
                    placeholder='Brokers'
                    containerStyle=''
                    dataQa={filters?.name ? filters?.name?.label?.toLowerCase().replaceAll(' ', '-') : 'brokers'}
                    selectedOption={filters?.name || null}
                    onChangeSelectedOption={onChangeSelectedBroker}
                    options={brokers}

                />
                {filters?.name &&
                    <SelectCheckbox
                        placeholder='Warehouses'
                        containerStyle=''
                        dataQa={filters?.warehouseId ? filters?.warehouseId?.label : 'warehouses'}
                        selectedOption={filters?.warehouseId || null}
                        onChangeSelectedOption={onChangeSelectedWarehouse}
                        options={warehouses}
                    />
                }
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1 md:gap-2 lg:gap-4'>
                <SelectCheckbox
                    placeholder='CS User Assigned'
                    containerStyle=''
                    dataQa={'cs-user-assigned'}
                    selectedOption={selectedUser || null}
                    onChangeSelectedOption={onChangeSelectedAssignedUser}
                    options={users ? [{ label: 'Unassigned', value: 'unassigned' }, ...users || []] : []}

                />
                <Input
                    type='text'
                    onFocus={(e: any) => {
                        e.target.type = 'date';
                        e.target.showPicker();
                    }}
                    placeholder='Date Created (min)'
                    dataQa={'date-created-(min)'}
                    containerStyle=''
                    showValue={true}
                    inputValue={filters?.minDateCreated}
                    error={errors?.minDateCreated || ''}
                    onChangeInput={onChangeFilterValue}
                    inputUniqueName={'minDateCreated'}
                />
                <Input
                    type='text'
                    onFocus={(e: any) => {
                        e.target.type = 'date';
                        e.target.showPicker();
                    }}
                    placeholder='Date Created (max)'
                    dataQa={'date-created-(max)'}
                    containerStyle=''
                    showValue={true}
                    inputValue={filters?.maxDateCreated}
                    error={errors?.maxDateCreated || ''}
                    onChangeInput={onChangeFilterValue}
                    inputUniqueName={'maxDateCreated'}
                />
            </div>
        </div>
    );
};

export default TicketFilters;
