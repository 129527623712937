import React from 'react';
import { ICashoutsFilters } from '../../../../interfaces/components/ICashoutsFilters';
import { CASHOUT_TYPES } from '../../../../utils/constants/cashout-types';
import { CASHOUT_STATUSES } from '../../../../utils/constants/cashout-statuses';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Input from '../../../../shared/input';
import Button from '../../../../shared/button';
import { CS_ACTIONS } from '../../../../utils/constants/cs-actions';


interface ICashoutsFiltersInterface {
    filters?: ICashoutsFilters;
    markets?: Array<any>;
    selectedMarket?: any;
    onChangeSelectedMarket: (value: any, type?: string) => void;
    onChangeFilterValue: (value: string | null | undefined, type?: string) => void;
    onChangeFilterSelected: (value: any, type?: string) => void;
    onHandleKeyPress: (e: any) => void;
    showOrders?: boolean;
    setShowOrders: React.Dispatch<React.SetStateAction<boolean>>
}

const CashoutsFilters = ({
    filters,
    markets,
    selectedMarket,
    onChangeSelectedMarket,
    onChangeFilterValue,
    onChangeFilterSelected,
    onHandleKeyPress,
    showOrders,
    setShowOrders
}: ICashoutsFiltersInterface) => {
    return (
        <div>
            <div className='grid grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-4'>
                <SelectCheckbox
                    name="Market"
                    dataQa={selectedMarket ? selectedMarket?.name.toLowerCase().replaceAll(' ', '-') : 'market'}
                    selectedOption={selectedMarket}
                    options={markets || []}
                    onChangeSelectedOption={onChangeSelectedMarket}
                />
                <SelectCheckbox
                    name="CS Actions"
                    dataQa={filters?.csActionStatus ? filters?.csActionStatus?.toLowerCase().replaceAll(' ', '-') : 'csActionStatus'}
                    selectedOption={filters?.csActionStatus
                        ? CS_ACTIONS?.find((item) => item?.value === filters?.csActionStatus)
                        : CS_ACTIONS[0]}
                    options={CS_ACTIONS}
                    uniqueName='csActionStatus'
                    onChangeSelectedOption={onChangeFilterSelected}
                    disabled={!showOrders}
                />
                <div className='place-self-center bg-white h-[40%] items-center justify-center place-content-center rounded w-full'>
                    <Button onClickButton={() => setShowOrders(false)} label='Item View' className={`w-[50%] h-[100%] rounded-[5px] ${!showOrders ? 'bg-gradient-to-br from-[#FCEE21] to-[#F7931E]' : null}`}/>
                    <Button onClickButton={() => setShowOrders(true)} label='Order View' className={`w-[50%] h-[100%] rounded-[5px] ${showOrders ? 'bg-gradient-to-br from-[#FCEE21] to-[#F7931E]' : null}`}/>
                </div>
                <Input
                    label='Item ID'
                    containerStyle=''
                    showValue={true}
                    inputValue={filters?.itemId}
                    dataQa={filters?.itemId ? filters?.itemId?.toLowerCase().replaceAll(' ', '-') : 'item-id'}
                    onChangeInput={onChangeFilterValue}
                    onHandleKeyDown={onHandleKeyPress}
                    inputUniqueName={'itemId'}
                    disabled={showOrders}
                />
                <Input
                    label='Package Id'
                    containerStyle=''
                    showValue={true}
                    dataQa={filters?.packageId ? filters?.packageId?.toLowerCase().replaceAll(' ', '-') : 'package-id'}
                    inputValue={filters?.packageId}
                    onChangeInput={onChangeFilterValue}
                    onHandleKeyDown={onHandleKeyPress}
                    inputUniqueName={'packageId'}
                />
                <Input
                    label='Order Number'
                    containerStyle=''
                    showValue={true}
                    dataQa={filters?.orderNumber ? filters?.orderNumber?.toLowerCase().replaceAll(' ', '-') : 'order-number'}
                    inputValue={filters?.orderNumber}
                    onChangeInput={onChangeFilterValue}
                    onHandleKeyDown={onHandleKeyPress}
                    inputUniqueName={'orderNumber'}
                />
                <Input
                    label='Tracking Number'
                    containerStyle=''
                    showValue={true}
                    dataQa={filters?.trackingNumber ? filters?.trackingNumber?.toLowerCase().replaceAll(' ', '-') : 'tracking-number'}
                    inputValue={filters?.trackingNumber}
                    onChangeInput={onChangeFilterValue}
                    onHandleKeyDown={onHandleKeyPress}
                    inputUniqueName={'trackingNumber'}
                />
                <Input
                    label='User Email'
                    containerStyle=''
                    showValue={true}
                    dataQa={filters?.sellerEmail ? filters?.sellerEmail?.toLowerCase().replaceAll(' ', '-') : 'seller-email'}
                    inputValue={filters?.sellerEmail}
                    onChangeInput={onChangeFilterValue}
                    onHandleKeyDown={onHandleKeyPress}
                    inputUniqueName={'sellerEmail'}
                />
                <Input
                    label='User name'
                    containerStyle=''
                    showValue={true}
                    dataQa={filters?.username ? filters?.username?.toLowerCase().replaceAll(' ', '-') : 'user-name'}
                    inputValue={filters?.username}
                    onChangeInput={onChangeFilterValue}
                    onHandleKeyDown={onHandleKeyPress}
                    inputUniqueName={'username'}
                />
                <Input
                    label='Post Code'
                    containerStyle=''
                    showValue={true}
                    dataQa={filters?.postCode ? filters?.postCode?.toLowerCase().replaceAll(' ', '-') : 'post-code'}
                    inputValue={filters?.postCode}
                    onChangeInput={onChangeFilterValue}
                    onHandleKeyDown={onHandleKeyPress}
                    inputUniqueName={'postCode'}
                />
                <SelectCheckbox
                    name='Status'
                    selectedOption={filters?.status ? CASHOUT_STATUSES?.find((item) => item?.value === filters?.status) : undefined}
                    options={CASHOUT_STATUSES}
                    dataQa={filters?.status ? filters?.status.toLowerCase().replaceAll(' ', '-') : 'status'}
                    uniqueName='status'
                    onChangeSelectedOption={onChangeFilterSelected}
                />
                <SelectCheckbox
                    name='Cashout Type'
                    selectedOption={filters?.cashoutType ? CASHOUT_TYPES?.find((item) => item?.value === filters?.cashoutType) : undefined}
                    dataQa={filters?.cashoutType ? filters?.cashoutType.toLowerCase().replaceAll(' ', '-') : 'cashoutType'}
                    options={CASHOUT_TYPES}
                    uniqueName='cashoutType'
                    onChangeSelectedOption={onChangeFilterSelected}
                />
                <Input
                    type='text'
                    onFocus={(e: any) => {
                        e.target.type = 'date';
                        e.target.showPicker();
                    }}
                    label='From'
                    containerStyle=''
                    dataQa={filters?.fromDate ? filters?.fromDate.toLowerCase().replaceAll(' ', '-') : 'from-date'}
                    showValue={true}
                    inputValue={filters?.fromDate}
                    onChangeInput={onChangeFilterValue}
                    inputUniqueName={'fromDate'}
                />
                <Input
                    type='text'
                    onFocus={(e: any) => {
                        e.target.type = 'date';
                        e.target.showPicker();
                    }}
                    label='To'
                    containerStyle=''
                    showValue={true}
                    dataQa={filters?.toDate ? filters?.toDate.toLowerCase().replaceAll(' ', '-') : 'to-date'}
                    inputValue={filters?.toDate}
                    onChangeInput={onChangeFilterValue}
                    inputUniqueName={'toDate'}
                />
            </div>
        </div>
    );
};

export default CashoutsFilters;