import React from 'react';
import ContactHistoryContainer from './ContactHistoryContainer';
import OrderInfoContainer from './OrderInfoContainer';
import ProductInfoContainer from './ProductInfoContainer';
import SellersInfoContainer from './SellersInfoContainer';


const LoadingPage = () => {
    return (
        <div>
            <div className='grid grid-cols-11 gap-2 my-8'>
                <div className='col-span-8 grid grid-cols-2 gap-2'>
                    <div className='col-span-1'>
                        <OrderInfoContainer />
                    </div>
                    <div className='col-span-1'>
                        <ProductInfoContainer />
                    </div>
                    <div className='col-span-2 mb-1'>
                        <div className='mb-4'>
                            <ContactHistoryContainer />
                        </div>
                    </div>
                </div>
                <div className='col-span-3'>
                    <SellersInfoContainer />
                </div>
            </div>
        </div>
    )
}

export default LoadingPage;