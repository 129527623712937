import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../app/hooks';
import {
    tryToAddNote,
    tryToFetchNotes
} from '../../../../store/customer-support/orders/ordersSlice';
import Textarea from '../../../../shared/textarea'
import Button from '../../../../shared/button'
import Error from '../../../../shared/error';

type FormValues = {
    text: string;
};

interface IAddNoteForm {
    onSuccessfullySaved: (body: any) => void;
    onCloseModal: () => void;
    id: string,
    itemId: string
}

const AddNoteForm = ({
    onSuccessfullySaved,
    onCloseModal,
    id,
    itemId
}: IAddNoteForm) => {
    const dispatch = useAppDispatch()
    const [submitError, setSubmitError] = useState<string>()
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const [notes, setNotes] = useState<any[]>([])

    const {
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        watch
    } = useForm<FormValues>({});

    const onChangeValue = (e: any) => {
        setValue('text', e?.target?.value)
    }

    const getNotes = async () => {
        const resp = await dispatch(tryToFetchNotes(itemId)).unwrap()
        setNotes(resp.data)
    }

    const onClose = () => {
        reset()
        setSubmitError(undefined)
        setLoadingSubmit(false)
    }

    const onSubmit = async (data: FormValues) => {
        setLoadingSubmit(true)
        try {
            const body = {
                orderId: id,
                itemId: itemId,
                text: data.text
            }
            await dispatch(tryToAddNote(body)).unwrap()
            onSuccessfullySaved(body)
            getNotes()
            reset()
        } catch (err) {
            setSubmitError(`${err}`)
        }
        setLoadingSubmit(false)
    }

    useEffect(() => {
        getNotes()
        return onClose()
    }, [id, itemId])

    return (
        <form className='flex flex-col items-start bg-[#F8F9FC] px-4 py-6' onSubmit={handleSubmit(onSubmit)}>
            <Textarea
                placeholder='Add note'
                dataQa={'add-note'}
                rows={4}
                value={watch('text')}
                onChangeTextarea={onChangeValue}
                error={errors.text?.message}
                className='block p-2.5 w-full text-sm text-[#8A8A8E] bg-white'
            />
            <div className='flex flex-col justify-start items-start'>
                <Button
                    className={`${loadingSubmit || watch('text') === '' || !watch('text') ? 'btn-primary-rounded-less-disable' : 'btn-primary-rounded-less'} !shadow-none min-w-[200px]`}
                    label='Add note'
                    dataQa={'assign-submit-btn'}
                    type='submit'
                />
                {submitError && <Error text={submitError} />}
            </div>
            <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
            <div className='flex flex-col gap-4'>
                {notes.map((note: any, key: number) => <div key={key} className='flex flex-col text-sm'>
                    <span className='font-dm_sansbold text-black'>Note from {note.createdBy.name}</span>
                    <span className='text-[#202020]'>{note.text}</span>
                    <span>{moment(note.createdAt).format('DD-MM-YYYY, HH:mm')}</span>
                </div>)}
            </div>
        </form>
    )
}

export default AddNoteForm