import React from 'react';
import { NavLink } from 'react-router-dom';
import LoginForm from './login-form';

const Login = () => {
    return (
        <div className='md:min-h-screen px-10 py-12'>
            <NavLink to='/'>
                {/* Logo here */}
                {/* <img className='max-w-[290px]' alt='for the earth' src='/logo/logo.svg' /> */}
            </NavLink>
            <div className='max-w-[330px] m-auto mt-[9%]'>
                <h1 className='font-dm_sansbold text-4xl'>Please fill your details</h1>
                <LoginForm />
            </div>
        </div>
    )
}

export default Login;