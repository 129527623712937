import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse, AxiosError } from 'axios';
import { OrdersService } from './ordersApi';
import {
	IPaginationPayload,
	IPaginationPayloadWithId,
} from '../../../interfaces/shared/IPaginationPayload';
import { IOrderListState } from '../../../interfaces/orders/IOrdersList';
import { OrdersFilterView } from '../../../interfaces/orders/OrdersTypes';
import { ISingleOrderSchema } from '../../../interfaces/orders/IOrdersSchema';
import { ChangeImeiInterface } from '../../../interfaces/orders/IOrdersActions';
import { PaginatedOrders } from '../../../interfaces/orders/paginatedStore.type';
import { ApiResponse } from '../../../interfaces/models/models/paginated-response.model';

const initialState: IOrderListState = {
	ordersAreLoading: true,
	sellersAreLoading: false,
	sellerOrdersAreLoading: false,
	cashoutsAreLoading: false,
	cashoutIsLoading: false,
	ordersFilterView: 'INBOUND_ORDERS',
	orderIsLoading: false,
	orderIsActioned: {
		loading: false,
		dirty: false,
	},
	orderDetails: {
		isInReview: true,
		isOrderAccepted: false,
		isOrderRejected: false,
		awaitingUserConfirmation: false,
		commission: 0,
		postageFee: 0,
		totalCosts: 0,
		csRequired: false,
		tickets: {
			loading: false,
		},
	},
	imeiSetterModal: {
		isModalOpen: false,
	},
	revalueModal: {
		isModalOpen: false,
		readyToSubmit: false,
	},
	rejectModal: {
		isModalOpen: false,
		readyToSubmit: false,
	},
	cashoutOrdersAreLoading: false
};

export const tryToFetchOrders = createAsyncThunk<
	PaginatedOrders,
	IPaginationPayload
>(
	'ordersList/tryToFetchOrders',
	async ({ pageNumber, pageSize, filters, data }) => {
		const result = await OrdersService.tryToFetchOrders(
			pageNumber,
			pageSize,
			filters,
			data,
		);
		return result.data;
	},
);

export const tryToFetchCashouts = createAsyncThunk<
	PaginatedOrders,
	IPaginationPayload
>('ordersList/tryToFetchCashouts', async ({ pageNumber, pageSize, data, filters }) => {
	const result = await OrdersService.tryToFetchCashouts(
		pageNumber,
		pageSize,
		data,
		filters
	);
	return result.data;
});

export const tryToFetchCashoutItems = createAsyncThunk<
	PaginatedOrders,
	IPaginationPayloadWithId
>(
	'ordersList/tryToFetchCashoutItems',
	async ({ pageNumber, pageSize, id, data }) => {
		const result = await OrdersService.tryToFetchCashoutItems(
			pageNumber,
			pageSize,
			id,
			data,
		);
		return result.data;
	},
);

export const tryToFetchCashoutOrders = createAsyncThunk<
	any,
	IPaginationPayload
>(
	'ordersList/tryToFetchCashoutOrders',
	async ({ pageNumber, pageSize, filters, data }) => {
		const result = await OrdersService.tryToFetchCashoutOrders(
			pageNumber,
			pageSize,
			filters,
			data,
		);
		return result.data;
	},
);

export const tryToFetchOrdersCSV = createAsyncThunk<
	any,
	IPaginationPayload
>(
	'ordersList/tryToFetchOrdersCSV',
	async ({ pageNumber, pageSize, filters, data }) => {
		const result = await OrdersService.tryToFetchOrdersCSV(
			pageNumber,
			pageSize,
			filters,
			data,
		);
		return result.data;
	},
);

export const tryToFetchHighLevelOrders = createAsyncThunk<
	any,
	IPaginationPayload
>(
	'ordersList/tryToFetchHighLevelOrders',
	async ({ pageNumber, pageSize, filters, data }) => {
		const result = await OrdersService.tryToFetchHighLevelOrders(
			pageNumber,
			pageSize,
			filters,
			data,
		);
		return result.data;
	},
);

export const tryToFetchInboundOrders = createAsyncThunk<
	PaginatedOrders,
	IPaginationPayload
>(
	'ordersList/tryToFetchInboundOrders',
	async ({ pageNumber, pageSize, filters, data }) => {
		const result = await OrdersService.tryToFetchInboundOrders(
			pageNumber,
			pageSize,
			filters,
			data,
		);
		return result.data;
	},
);

export const tryToFetchOrdersInReview = createAsyncThunk<
	PaginatedOrders,
	IPaginationPayload
>(
	'ordersList/tryToFetchOrdersInReview',
	async ({ pageNumber, pageSize, filters, data }) => {
		const result = await OrdersService.tryToFetchOrdersInReview(
			pageNumber,
			pageSize,
			filters,
			data,
		);
		return result.data;
	},
);

export const tryToFetchOrdersInCs = createAsyncThunk<
	PaginatedOrders,
	IPaginationPayload
>(
	'ordersList/tryToFetchOrdersInCs',
	async ({ pageNumber, pageSize, filters, data }) => {
		const result = await OrdersService.tryToFetchOrdersInCs(
			pageNumber,
			pageSize,
			filters,
			data,
		);
		return result.data;
	},
);

export const tryToFetchResolvedOrders = createAsyncThunk<PaginatedOrders, any>(
	'ordersList/tryToFetchResolvedOrders',
	async ({ pageNumber, pageSize, filters, data }) => {
		const result = await OrdersService.tryToFetchResolvedOrders(
			pageNumber,
			pageSize,
			filters,
			data,
		);
		return result.data;
	},
);

export const tryToFetchRecentOrders = createAsyncThunk<PaginatedOrders, any>(
	'ordersList/tryToFetchRecentOrders',
	async ({ pageNumber, pageSize, filters, data }) => {
		const result = await OrdersService.tryToFetchRecentOrders(
			pageNumber,
			pageSize,
			filters,
			data,
		);
		return result.data;
	},
);

export const tryToFetchSingleOrder = createAsyncThunk<
	ApiResponse<ISingleOrderSchema>,
	any
>('ordersList/tryToFetchSingleOrder', async ({ orderId, data }) => {
	const result = await OrdersService.tryToFetchSingleOrder(orderId, data);
	return result.data;
});

export const tryToFetchAllSellers = createAsyncThunk<
	ApiResponse<ISingleOrderSchema>,
	any
>(
	'ordersList/tryToFetchAllSellers',
	async ({ pageNumber, pageSize, filters, data }) => {
		const result = await OrdersService.tryToFetchAllSellers(
			pageNumber,
			pageSize,
			filters,
			data,
		);
		return result.data;
	},
);

export const tryToFetchSellerOrders = createAsyncThunk<
	ApiResponse<ISingleOrderSchema>,
	any
>(
	'ordersList/tryToFetchSellerOrders',
	async ({ pageNumber, pageSize, sellerId, filters }) => {
		const result = await OrdersService.tryToFetchSellerOrders(
			pageNumber,
			pageSize,
			sellerId,
			filters,
		);
		return result.data;
	},
);

export const tryToFetchAllBlacklistedItems = createAsyncThunk<
	PaginatedOrders,
	IPaginationPayload
>(
	'ordersList/tryToFetchAllBlacklistedItems',
	async ({ pageNumber, pageSize, data }) => {
		const result = await OrdersService.tryToFetchAllBlacklistedItems(
			pageNumber,
			pageSize,
			data,
		);
		return result.data;
	},
);

export const tryToFetchAllAutoAcceptedRevaluations = createAsyncThunk<
	PaginatedOrders,
	IPaginationPayload
>(
	'ordersList/tryToFetchAllAutoAcceptedRevaluations',
	async ({ pageNumber, pageSize, data, filters }) => {
		const result =
			await OrdersService.tryToFetchAllAutoAcceptedRevaluations(
				pageNumber,
				pageSize,
				data,
				filters,
			);
		return result.data;
	},
);

export const tryToFetchAllRejectedRevaluations = createAsyncThunk<
	PaginatedOrders,
	IPaginationPayload
>(
	'ordersList/tryToFetchAllRejectedRevaluations',
	async ({ pageNumber, pageSize, data, filters }) => {
		const result = await OrdersService.tryToFetchAllRejectedRevaluations(
			pageNumber,
			pageSize,
			data,
			filters,
		);
		return result.data;
	},
);

export const tryToFindItemByImei = createAsyncThunk<any, string>(
	'orderDetail/tryToFindItemByImei',
	async (imei, { rejectWithValue }) => {
		try {
			const result = await OrdersService.tryToFindItemByImei(imei);
			return result.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);

export const tryToEditBankDetails = createAsyncThunk<any, any>(
	'orderDetail/tryToEditBankDetails',
	async ({ orderId, data }, { rejectWithValue }) => {
		try {
			const result = await OrdersService.tryToEditBankDetails(
				orderId,
				data,
			);
			return result.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);

export const tryToFindItemByTrackingNumber = createAsyncThunk<any, string>(
	'orderDetail/tryToFindItemByTrackingNumber',
	async (trackingNumber, { rejectWithValue }) => {
		try {
			const result = await OrdersService.tryToFindItemByTrackingNumber(
				trackingNumber,
			);
			return result.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);

export const tryToFetchOrdersCount = createAsyncThunk<
	any,
	{ country: string; marketId: string }
>('orderDetail/tryToFetchOrdersCount', async (data) => {
	const result = await OrdersService.tryToFetchOrdersCount(data);
	return result.data;
});

export const tryToSetMissingPackage = createAsyncThunk<
	void,
	{ shipmentId: string; itemId: string }
>('orderDetail/tryToSetMissingPackage', async (data) => {
	const result = await OrdersService.tryToSetMissingPackage(data);
	return result.data;
});

export const tryToAcceptOrder = createAsyncThunk<void, any>(
	'orderDetail/tryToAcceptOrder',
	async ({ orderId, data }) => {
		const result = await OrdersService.tryToAcceptOrder(orderId, data);
		return result.data;
	},
);

export const tryToDeliverOrder = createAsyncThunk<any, string>(
	'orderDetail/tryToDeliverOrder',
	async (trackingNumber, { rejectWithValue }) => {
		try {
			const result = await OrdersService.tryToDeliverOrder(
				trackingNumber,
			);
			return result.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);

export const tryToRejectOrder = createAsyncThunk<
	void,
	{ orderId: string; data: any }
>(
	'orderDetail/tryToRejectOrder',
	async ({ orderId, data }, { rejectWithValue }) => {
		try {
			const result = await OrdersService.tryToRejectOrder(orderId, data);
			return result.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);

export const tryToRevalueOrder = createAsyncThunk<
	any,
	{ orderId: string; data: any }
>(
	'orderDetail/tryToRevalueOrder',
	async ({ orderId, data }, { rejectWithValue }) => {
		try {
			const result = await OrdersService.tryToRevalueOrder(orderId, data);
			return result.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);

export const tryToAddLocationOnOrder = createAsyncThunk<void, any>(
	'orderDetail/tryToAddLocationOnOrder',
	async ({ trackingNumber, data }) => {
		const result = await OrdersService.tryToAddLocationOnOrder(
			trackingNumber,
			data,
		);
		return result.data;
	},
);

export const tryToEditSellerInfo = createAsyncThunk<ApiResponse<any>, any>(
	'orderDetails/tryToEditSellerInfo',
	async ({ data }, { rejectWithValue }) => {
		try {
			const result = await OrdersService.tryToEditSellerInfo(data);
			return result.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);

export const tryToReturnRequired = createAsyncThunk<any, any>(
	'orderDetails/tryToReturnRequired',
	async (itemId, { rejectWithValue }) => {
		try {
			const result = await OrdersService.tryToReturnRequired(
				itemId.itemId,
			);
			return result.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);

export const tryToAcceptOrderOnUserBehalf = createAsyncThunk<
	void,
	{ orderId: string; itemId: string }
>(
	'orderDetails/tryToAcceptOrderOnUserBehalf',
	async ({ orderId, itemId }, { rejectWithValue }) => {
		try {
			const result = await OrdersService.tryToAcceptOrderOnUserBehalf(
				orderId,
				itemId,
			);
			return result.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);
export const tryToAcceptOrderOnUserBehalfWithGesture = createAsyncThunk<
	void,
	{ orderId: string; itemId: string; amount: number }
>(
	'orderDetails/tryToAcceptOrderOnUserBehalfWithGesture',
	async ({ orderId, itemId, amount }, { rejectWithValue }) => {
		try {
			const result =
				await OrdersService.tryToAcceptOrderOnUserBehalfWithGesture(
					orderId,
					itemId,
					amount,
				);
			return result.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);

export const tryToRejectOrderOnUserBehalf = createAsyncThunk<
	void,
	{ orderId: string; itemId: string }
>(
	'orderDetails/tryToRejectOrderOnUserBehalf',
	async ({ orderId, itemId }, { rejectWithValue }) => {
		try {
			const result = await OrdersService.tryToRejectOrderOnUserBehalf(
				orderId,
				itemId,
			);
			return result.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);

export const tryToCancelOrderOnUserBehalf = createAsyncThunk<
	void,
	{ orderId: string }
>(
	'orderDetails/tryToCancelOrderOnUserBehalf',
	async ({ orderId }, { rejectWithValue }) => {
		try {
			const result = await OrdersService.tryToCancelOrderOnUserBehalf(
				orderId,
			);
			return result.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);

export const tryToRemoveBlacklistedStatus = createAsyncThunk<
	void,
	{ orderId: string; itemId: string }
>(
	'orderDetails/tryToRemoveBlacklistedStatus',
	async ({ orderId, itemId }, { rejectWithValue }) => {
		try {
			const result = await OrdersService.tryToRemoveBlacklistedStatus(
				orderId,
				itemId,
			);
			return result.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);

export const tryToAcceptItem = createAsyncThunk<
	void,
	{ orderId: string; itemId: string }
>(
	'orderDetails/tryToAcceptItem',
	async ({ orderId, itemId }, { rejectWithValue }) => {
		try {
			const result = await OrdersService.tryToAcceptItem(orderId, itemId);
			return result.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);

export const tryToReturnToWing = createAsyncThunk<
	void,
	{ orderId: string; itemId: string }
>(
	'orderDetails/tryToReturnToWing',
	async ({ orderId, itemId }, { rejectWithValue }) => {
		try {
			const result = await OrdersService.tryToReturnToWing(
				orderId,
				itemId,
			);
			return result.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);

export const openImeiModal = createAsyncThunk<string, string>(
	'ordersList/openImeiModal',
	async (orderId: string) => {
		return orderId;
	},
);

export const closeImeiModal = createAsyncThunk<void, void>(
	'ordersList/closeImeiModal',
	async () => {},
);

export const tryToChangeImei = createAsyncThunk<
	AxiosResponse<void>,
	ChangeImeiInterface
>('ordersList/changeImei', async (payload) => {
	return await OrdersService.tryToChangeImei(payload.itemId, payload.imei);
});

export const tryToAddReturnTrackingNumber = createAsyncThunk<
	AxiosResponse<any>,
	any
>('ordersList/tryToAddReturnTrackingNumber', async (payload) => {
	return await OrdersService.tryToAddReturnTrackingNumber(
		payload.itemId,
		payload.data,
	);
});

export const tryToToggleFMI = createAsyncThunk<
	AxiosResponse<void>,
	{ orderId: string; fmi: boolean }
>('ordersList/tryToToggleFMI', async (payload) => {
	return await OrdersService.tryToToggleFMI(payload.orderId, payload.fmi);
});

export const tryToFetchAllRevaluedFromBroker = createAsyncThunk<
	PaginatedOrders,
	IPaginationPayload
>(
	'ordersList/tryToFetchAllRevaluedFromBroker',
	async ({ pageNumber, pageSize, filters, data }) => {
		const result = await OrdersService.tryToFetchAllRevaluedFromBroker(
			pageNumber,
			pageSize,
			data,
			filters,
		);
		return result.data;
	},
);

export const tryToFetchAllRejectedFromBroker = createAsyncThunk<
	PaginatedOrders,
	IPaginationPayload
>(
	'ordersList/tryToFetchAllRejectedFromBroker',
	async ({ pageNumber, pageSize, data, filters }) => {
		const result = await OrdersService.tryToFetchAllRejectedFromBroker(
			pageNumber,
			pageSize,
			data,
			filters,
		);
		return result.data;
	},
);

export const tryToFetchAllAwaitingUserResponse = createAsyncThunk<
	PaginatedOrders,
	IPaginationPayload
>(
	'ordersList/tryToFetchAllAwaitingUserResponse',
	async ({ pageNumber, pageSize, data, filters }) => {
		const result = await OrdersService.tryToFetchAllAwaitingUserResponse(
			pageNumber,
			pageSize,
			data,
			filters,
		);
		return result.data;
	},
);

export const tryToFetchAllClosedCases = createAsyncThunk<
	PaginatedOrders,
	IPaginationPayload
>(
	'ordersList/tryToFetchAllClosedCases',
	async ({ pageNumber, pageSize, data, filters }) => {
		const result = await OrdersService.tryToFetchAllClosedCases(
			pageNumber,
			pageSize,
			data,
			filters,
		);
		return result.data;
	},
);

export const tryToAssignCSUser = createAsyncThunk<ApiResponse<any>, any>(
	'tickets/tryToAssignCSUser',
	async (data, { rejectWithValue }) => {
		try {
			const results = await OrdersService.tryToAssignCSUser(data);
			return results.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);

export const tryToAddNote = createAsyncThunk<ApiResponse<any>, any>(
	'tickets/tryToAddNote',
	async (data, { rejectWithValue }) => {
		try {
			const results = await OrdersService.tryToAddNote(data);
			return results.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);

export const tryToFetchNotes = createAsyncThunk<ApiResponse<any>, string>(
	'tickets/tryToFetchNotes',
	async (itemId, { rejectWithValue }) => {
		try {
			const results = await OrdersService.tryToFetchNotes(itemId);
			return results.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);

export const tryToMarkAsContacted = createAsyncThunk<ApiResponse<any>, any>(
	'tickets/tryToMarkAsContacted',
	async (data, { rejectWithValue }) => {
		try {
			const results = await OrdersService.tryToMarkAsContacted(data);
			return results.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);
export const tryToMarkAsCompleted = createAsyncThunk<ApiResponse<any>, any>(
	'tickets/tryToMarkAsCompleted',
	async (data, { rejectWithValue }) => {
		try {
			const results = await OrdersService.tryToMarkAsCompleted(data);
			return results.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);

export const openRevalueModal = createAction(
	'orderDetails/openRevalueModal',
	(orderId: string) => ({
		payload: {
			orderId,
		},
	}),
);
export const closeRevalueModal = createAction('orderDetails/closeRevalueModal');

export const openRejectModal = createAction(
	'orderDetails/openRejectModal',
	(orderId: string) => ({
		payload: {
			orderId,
		},
	}),
);
export const closeRejectModal = createAction('orderDetails/closeRejectModal');

export const setRevalueMessage = createAction(
	'orderDetails/setRevalueMessage',
	(message: string) => ({
		payload: {
			message,
		},
	}),
);

export const setRevalueNewPrice = createAction(
	'orderDetails/setRevalueNewPrice',
	(newPrice: number) => ({
		payload: {
			newPrice,
		},
	}),
);

export const setRejectReason = createAction(
	'orderDetails/setRejectReason',
	(reason: string) => ({
		payload: {
			reason,
		},
	}),
);

export const changeOrdersFilter = createAction(
	'ordersList/changeOrdersFilter',
	(filter: OrdersFilterView) => ({
		payload: {
			filter,
		},
	}),
);

export const tryToFetchMaxGestureAmount = createAsyncThunk<
	ApiResponse<any>,
	{ orderId: string; itemId: string }
>(
	'orderDetails/tryToFetchMaxGestureAmount',
	async ({ orderId, itemId }, { rejectWithValue }) => {
		try {
			const results = await OrdersService.tryToFetchMaxGestureAmount(
				orderId,
				itemId,
			);
			return results.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);
export const tryToResetOrder = createAsyncThunk<
	void,
	{ orderId: string; itemId: string; reason: string; details: string }
>(
	'orderDetail/tryToResetOrder',
	async ({ orderId, itemId, reason, details }, { rejectWithValue }) => {
		try {
			const result = await OrdersService.tryToResetOrder(
				orderId,
				itemId,
				reason,
				details,
			);
			return result.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);

export const tryToTakeOutboundAction = createAsyncThunk<
  void,
  { orderId: string; outboundStatus: 'outbound_call_made' | 'answered'  }
>(
  'orderDetail/tryToTakeOutboundAction',
  async ({ orderId, outboundStatus }, { rejectWithValue }) => {
    try {
      const result = await OrdersService.tryToTakeOutboundAction(orderId, outboundStatus);
      return result.data;
    } catch (error) {
      const err: any = error as AxiosError;
      return rejectWithValue(err?.response?.data?.message);
    }
  },
);

export const tryToAssignCSUserOutbound = createAsyncThunk<ApiResponse<any>, any>(
	'tickets/tryToAssignCSUserOutbound',
	async ({orderId, data}, { rejectWithValue }) => {
		try {
			const results = await OrdersService.tryToAssignCSUserOutbound(orderId, data);
			return results.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);

export const tryToFetchOutboundDetails = createAsyncThunk<
	ApiResponse<any>,
	{ orderId: string }
>(
	'orderDetails/tryToFetchOutboundDetails',
	async ({ orderId }, { rejectWithValue }) => {
		try {
			const results = await OrdersService.tryToFetchOutboundDetails(
				orderId,
			);
			return results.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);

export const tryToAddOutboundNote = createAsyncThunk<ApiResponse<any>, any>(
	'tickets/tryToAddOutboundNote',
	async ({orderId, data}, { rejectWithValue }) => {
		try {
			const results = await OrdersService.tryToAddOutboundNote(orderId, data);
			return results.data;
		} catch (error) {
			const err: any = error as AxiosError;
			return rejectWithValue(err?.response?.data?.message);
		}
	},
);

export const ordersSlice = createSlice({
	name: 'orders',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(changeOrdersFilter, (state, action) => {
				state.ordersFilterView = action.payload.filter;
			})
			.addCase(openRevalueModal, (state, action) => {
				state.revalueModal.isModalOpen = true;
				state.revalueModal.shipmentId = action.payload.orderId;
			})
			.addCase(closeRevalueModal, (state) => {
				state.revalueModal.isModalOpen = false;
				state.revalueModal.message = undefined;
				state.revalueModal.newPrice = undefined;
				state.revalueModal.shipmentId = undefined;
			})
			.addCase(setRevalueNewPrice, (state, action) => {
				if (action.payload.newPrice !== null) {
					state.revalueModal.newPrice = action.payload.newPrice;
				} else {
					state.revalueModal.newPrice = undefined;
				}
				state.revalueModal.readyToSubmit = !!(
					state.revalueModal.shipmentId &&
					state.revalueModal.message &&
					state.revalueModal.newPrice
				);
			})
			.addCase(setRevalueMessage, (state, action) => {
				if (action.payload.message !== null) {
					state.revalueModal.message = action.payload.message;
				} else {
					state.revalueModal.message = undefined;
				}
				state.revalueModal.readyToSubmit = !!(
					state.revalueModal.shipmentId &&
					state.revalueModal.newPrice &&
					state.revalueModal.message
				);
			})
			.addCase(openRejectModal, (state, action) => {
				state.rejectModal.isModalOpen = true;
				state.rejectModal.shipmentId = action.payload.orderId;
			})
			.addCase(closeRejectModal, (state) => {
				state.rejectModal.isModalOpen = false;
				state.rejectModal.reason = undefined;
				state.rejectModal.shipmentId = undefined;
			})
			.addCase(setRejectReason, (state, action) => {
				if (action.payload.reason !== null) {
					state.rejectModal.reason = action.payload.reason;
				} else {
					state.rejectModal.reason = undefined;
				}
				state.rejectModal.readyToSubmit = !!(
					state.rejectModal.shipmentId && state.rejectModal.reason
				);
			})

			// tryToFetchAllSellers
			.addCase(tryToFetchAllSellers.pending, (state) => {
				state.sellersAreLoading = true;
			})
			.addCase(tryToFetchAllSellers.rejected, (state) => {
				state.sellersAreLoading = false;
			})
			.addCase(tryToFetchAllSellers.fulfilled, (state, action) => {
				state.sellersAreLoading = false;
				state.sellers = action.payload;
			})

			// tryToFetchSellerOrders
			.addCase(tryToFetchSellerOrders.pending, (state) => {
				state.sellerOrdersAreLoading = true;
			})
			.addCase(tryToFetchSellerOrders.rejected, (state) => {
				state.sellerOrdersAreLoading = false;
			})
			.addCase(tryToFetchSellerOrders.fulfilled, (state, action) => {
				state.sellerOrdersAreLoading = false;
				state.sellerOrders = action.payload;
			})

			// tryToFetchOrders
			.addCase(tryToFetchOrders.pending, (state) => {
				state.ordersAreLoading = true;
			})
			.addCase(tryToFetchOrders.rejected, (state) => {
				state.ordersAreLoading = false;
			})
			.addCase(tryToFetchOrders.fulfilled, (state, action) => {
				state.ordersAreLoading = false;
				state.orders = action.payload;
			})

			// tryToFetchCashouts
			.addCase(tryToFetchCashouts.pending, (state) => {
				state.cashoutsAreLoading = true;
			})
			.addCase(tryToFetchCashouts.rejected, (state) => {
				state.cashoutsAreLoading = false;
			})
			.addCase(tryToFetchCashouts.fulfilled, (state, action) => {
				state.cashoutsAreLoading = false;
				state.cashouts = action.payload;
			})

			// tryToFetchCashoutItems
			.addCase(tryToFetchCashoutItems.pending, (state) => {
				state.cashoutIsLoading = true;
			})
			.addCase(tryToFetchCashoutItems.rejected, (state) => {
				state.cashoutIsLoading = false;
			})
			.addCase(tryToFetchCashoutItems.fulfilled, (state, action) => {
				state.cashoutIsLoading = false;
				state.cashoutItems = action.payload;
			})

			// tryToFetchCashoutOrderView
			.addCase(tryToFetchCashoutOrders.pending, (state) => {
				state.cashoutOrdersAreLoading = true;
			})
			.addCase(tryToFetchCashoutOrders.rejected, (state) => {
				state.cashoutOrdersAreLoading = false;
			})
			.addCase(tryToFetchCashoutOrders.fulfilled, (state, action) => {
				state.cashoutOrdersAreLoading = false;
				state.cashoutOrders = action.payload;
			})

			// tryToFetchHighLevelOrders
			.addCase(tryToFetchHighLevelOrders.pending, (state) => {
				state.ordersAreLoading = true;
			})
			.addCase(tryToFetchHighLevelOrders.rejected, (state) => {
				state.ordersAreLoading = false;
			})
			.addCase(tryToFetchHighLevelOrders.fulfilled, (state, action) => {
				state.ordersAreLoading = false;
				state.highLevelOrders = action.payload;
			})

			// tryToFetchInboundOrders
			.addCase(tryToFetchInboundOrders.pending, (state) => {
				state.ordersAreLoading = true;
			})
			.addCase(tryToFetchInboundOrders.rejected, (state) => {
				state.ordersAreLoading = false;
			})
			.addCase(tryToFetchInboundOrders.fulfilled, (state, action) => {
				state.ordersAreLoading = false;
				state.orders = action.payload;
			})

			// tryToFetchResolvedOrders
			.addCase(tryToFetchResolvedOrders.pending, (state) => {
				state.ordersAreLoading = true;
			})
			.addCase(tryToFetchResolvedOrders.rejected, (state) => {
				state.ordersAreLoading = false;
			})
			.addCase(tryToFetchResolvedOrders.fulfilled, (state, action) => {
				state.ordersAreLoading = false;
				state.orders = action.payload;
			})

			// tryToFetchRecentOrders
			.addCase(tryToFetchRecentOrders.pending, (state) => {
				state.ordersAreLoading = true;
			})
			.addCase(tryToFetchRecentOrders.rejected, (state) => {
				state.ordersAreLoading = false;
			})
			.addCase(tryToFetchRecentOrders.fulfilled, (state, action) => {
				state.ordersAreLoading = false;
				state.orders = action.payload;
			})

			// tryToFetchOrdersInReview
			.addCase(tryToFetchOrdersInReview.pending, (state) => {
				state.ordersAreLoading = true;
			})
			.addCase(tryToFetchOrdersInReview.rejected, (state) => {
				state.ordersAreLoading = false;
			})
			.addCase(tryToFetchOrdersInReview.fulfilled, (state, action) => {
				state.ordersAreLoading = false;
				state.orders = action.payload;
			})

			// tryToFetchOrdersInCs
			.addCase(tryToFetchOrdersInCs.pending, (state) => {
				state.ordersAreLoading = true;
			})
			.addCase(tryToFetchOrdersInCs.rejected, (state) => {
				state.ordersAreLoading = false;
			})
			.addCase(tryToFetchOrdersInCs.fulfilled, (state, action) => {
				state.ordersAreLoading = false;
				state.orders = action.payload;
			})

			// tryToFetchSingleOrder
			.addCase(tryToFetchSingleOrder.pending, (state) => {
				state.orderIsLoading = !state.orderIsActioned.dirty;
				state.orderIsActioned = {
					loading: false,
					dirty: false,
					action: undefined,
				};
			})
			.addCase(tryToFetchSingleOrder.rejected, (state) => {
				state.orderIsLoading = false;
			})
			.addCase(tryToFetchSingleOrder.fulfilled, (state, action) => {
				state.orderIsLoading = false;
				state.orderDetail = action.payload?.data;
				const orderStatus = action.payload.data.orderData.status;
				const saleData = action.payload.data.orderData;
				state.orderDetails.awaitingUserConfirmation =
					orderStatus === 'awaiting_user_response';
				state.orderDetails.isOrderAccepted =
					orderStatus === 'device_accepted' ||
					orderStatus === 'user_accepted_offer';
				state.orderDetails.isOrderRejected =
					orderStatus === 'device_rejected' ||
					orderStatus === 'user_rejected_offer';
				state.orderDetails.isInReview =
					orderStatus === 'device_in_review';
				state.orderDetails.commission = saleData.commission;
				state.orderDetails.postageFee = saleData.inboundCost;
				state.orderDetails.totalCosts =
					state.orderDetails.commission +
					state.orderDetails.postageFee +
					saleData.salePrice;
				state.orderDetails.csRequired =
					state.orderDetail?.shipment?.csRequired || false;
			})

			// tryToFetchOrdersCount
			.addCase(tryToFetchOrdersCount.pending, (state) => {
				state.orderIsLoading = true;
			})
			.addCase(tryToFetchOrdersCount.rejected, (state) => {
				state.orderIsLoading = false;
			})
			.addCase(tryToFetchOrdersCount.fulfilled, (state, action) => {
				state.orderIsLoading = false;
				state.ordersCount = action.payload?.data;
			})

			// tryToSetMissingPackage
			.addCase(tryToSetMissingPackage.pending, (state) => {
				state.orderIsLoading = true;
			})
			.addCase(tryToSetMissingPackage.rejected, (state) => {
				state.orderIsLoading = false;
			})
			.addCase(tryToSetMissingPackage.fulfilled, (state) => {
				state.orderIsLoading = false;
				if (state.orderDetail) {
					state.orderDetail = {
						...(state.orderDetail || {}),
						orderData: {
							...state?.orderDetail?.orderData,
							status: 'item_missing',
						},
					};
				}
			})

			// tryToAcceptOrderOnUserBehalf
			.addCase(tryToAcceptOrderOnUserBehalf.pending, (state) => {
				state.orderIsLoading = true;
			})
			.addCase(tryToAcceptOrderOnUserBehalf.rejected, (state) => {
				state.orderIsLoading = false;
			})
			.addCase(tryToAcceptOrderOnUserBehalf.fulfilled, (state) => {
				state.orderIsLoading = false;
				if (state.orderDetail) {
					state.orderDetail = {
						...(state.orderDetail || {}),
						orderData: {
							...state?.orderDetail?.orderData,
							status: 'user_accepted_offer',
						},
						item: {
							...state?.orderDetail?.item,
							showAcceptOnUserBehalf: false,
						},
					};
				}
			})

			// tryToAcceptOrderOnUserBehalfWithGesture
			.addCase(
				tryToAcceptOrderOnUserBehalfWithGesture.pending,
				(state) => {
					state.orderIsLoading = true;
				},
			)
			.addCase(
				tryToAcceptOrderOnUserBehalfWithGesture.rejected,
				(state) => {
					state.orderIsLoading = false;
				},
			)
			.addCase(
				tryToAcceptOrderOnUserBehalfWithGesture.fulfilled,
				(state, action) => {
					state.orderIsLoading = false;
					state.orderDetails.totalCosts =
						state.orderDetails.totalCosts +
						action?.meta?.arg?.amount;
					if (state.orderDetail) {
						state.orderDetail = {
							...(state.orderDetail || {}),
							orderData: {
								...state?.orderDetail?.orderData,
								status: 'user_accepted_offer',
							},
							item: {
								...state?.orderDetail?.item,
								showAcceptOnUserBehalf: false,
								revaluedPrice:
									(state?.orderDetail?.item.revaluedPrice ||
										0) + action?.meta?.arg?.amount,
							},
						};
					}
				},
			)

			// tryToRejectOrderOnUserBehalf
			.addCase(tryToRejectOrderOnUserBehalf.pending, (state) => {
				state.orderIsLoading = true;
			})
			.addCase(tryToRejectOrderOnUserBehalf.rejected, (state) => {
				state.orderIsLoading = false;
			})
			.addCase(tryToRejectOrderOnUserBehalf.fulfilled, (state) => {
				state.orderIsLoading = false;
				if (state.orderDetail) {
					state.orderDetail = {
						...(state.orderDetail || {}),
						orderData: {
							...state?.orderDetail?.orderData,
							status: 'user_rejected_offer',
						},
						item: {
							...state?.orderDetail?.item,
							showAcceptOnUserBehalf: false,
						},
					};
				}
			})

			// tryToRemoveBlacklistedStatus
			.addCase(tryToRemoveBlacklistedStatus.pending, (state) => {
				state.orderIsLoading = true;
			})
			.addCase(tryToRemoveBlacklistedStatus.rejected, (state) => {
				state.orderIsLoading = false;
			})
			.addCase(tryToRemoveBlacklistedStatus.fulfilled, (state) => {
				state.orderIsLoading = false;
				if (state.orderDetail) {
					state.orderDetail = {
						...(state.orderDetail || {}),
						orderData: {
							...state?.orderDetail?.orderData,
							status: 'device_in_review',
						},
					};
				}
			})

			// tryToAcceptItem
			.addCase(tryToAcceptItem.pending, (state) => {
				state.orderIsLoading = true;
			})
			.addCase(tryToAcceptItem.rejected, (state) => {
				state.orderIsLoading = false;
			})
			.addCase(tryToAcceptItem.fulfilled, (state) => {
				state.orderIsLoading = false;
				if (state.orderDetail) {
					state.orderDetail = {
						...(state.orderDetail || {}),
						item: {
							...state.orderDetail?.item,
							warehouseAccepted: true,
							showAccept: false,
							showInventory: true,
						},
						orderData: {
							...state?.orderDetail?.orderData,
							status: 'device_accepted',
						},
					};
				}
			})

			// tryToReturnToWing
			.addCase(tryToReturnToWing.pending, (state) => {
				state.orderIsLoading = true;
			})
			.addCase(tryToReturnToWing.rejected, (state) => {
				state.orderIsLoading = false;
			})
			.addCase(tryToReturnToWing.fulfilled, (state) => {
				state.orderIsLoading = false;
				if (state.orderDetail) {
					state.orderDetail = {
						...(state.orderDetail || {}),
						orderData: {
							...state?.orderDetail?.orderData,
							status: 'ship_to_wingpay',
						},
					};
				}
			})

			// tryToReturnRequired
			.addCase(tryToReturnRequired.pending, (state) => {
				state.orderIsLoading = true;
			})
			.addCase(tryToReturnRequired.rejected, (state) => {
				state.orderIsLoading = false;
			})

			.addCase(tryToReturnRequired.fulfilled, (state) => {
				state.orderIsLoading = false;
				if (state.orderDetail) {
					state.orderDetail = {
						...(state.orderDetail || {}),
						orderData: {
							...state?.orderDetail?.orderData,
							status: 'required_return',
						},
					};
				}
			})

			// tryToEditBankDetails
			.addCase(tryToEditBankDetails.pending, (state) => {
				state.orderIsLoading = true;
			})
			.addCase(tryToEditBankDetails.rejected, (state) => {
				state.orderIsLoading = false;
			})

			.addCase(tryToEditBankDetails.fulfilled, (state, action) => {
				state.orderIsLoading = false;
				if (state.orderDetail) {
					state.orderDetail = {
						...(state.orderDetail || {}),
						seller: {
							...(state?.orderDetail?.seller || {}),
							bankDetails: {
								...(action?.payload?.data?.bankDetails ||
									action?.payload?.data ||
									action?.meta?.arg?.data ||
									{}),
							},
						},
					};
				}
			})

			// tryToAddLocationOnOrder
			.addCase(tryToAddLocationOnOrder.fulfilled, (state, action) => {
				if (state.orderDetail) {
					state.orderDetail = {
						...(state.orderDetail || {}),
						item: {
							...(state?.orderDetail?.item || {}),
							// location: action?.meta?.arg?.data?.locations,
							showInventory: false,
						},
					};
				}
			})

			.addCase(openImeiModal.fulfilled, (state, action) => {
				state.imeiSetterModal.isModalOpen = true;
				state.imeiSetterModal.shipmentId = action.payload;
			})

			.addCase(closeImeiModal.fulfilled, (state) => {
				state.imeiSetterModal.isModalOpen = false;
				state.imeiSetterModal.shipmentId = undefined;
			})

			.addCase(tryToChangeImei.fulfilled, (state) => {
				state.imeiSetterModal.isModalOpen = false;
				state.imeiSetterModal.shipmentId = undefined;
				// window.location.reload();
			})
			.addCase(tryToChangeImei.rejected, (state) => {
				state.imeiSetterModal.isModalOpen = false;
				state.imeiSetterModal.shipmentId = undefined;
			})

			.addCase(tryToAcceptOrder.pending, (state) => {
				state.orderIsActioned = {
					loading: true,
					dirty: true,
					action: 'ACCEPTED',
				};
			})
			.addCase(tryToRejectOrder.pending, (state) => {
				state.orderIsActioned = {
					loading: true,
					dirty: true,
					action: 'REJECTED',
				};
			})
			.addCase(tryToRevalueOrder.pending, (state) => {
				state.orderIsActioned = {
					loading: true,
					dirty: true,
					action: 'REVALUED',
				};
			})
			.addCase(tryToAcceptOrder.fulfilled, (state) => {
				if (state.orderDetail) {
					state.orderDetail = {
						...(state.orderDetail || {}),
						item: {
							...state.orderDetail?.item,
							warehouseAccepted: true,
							showAccept: false,
							showInventory: true,
						},
						orderData: {
							...state?.orderDetail?.orderData,
							status: 'device_accepted',
						},
					};
				}
			})
			.addCase(tryToRejectOrder.fulfilled, (state) => {
				state.orderIsActioned = {
					loading: false,
					dirty: true,
					action: 'REJECTED',
				};
			})
			.addCase(tryToRevalueOrder.fulfilled, (state, action) => {
				const object: any = {};
				action?.meta?.arg?.data &&
					action?.meta?.arg?.data?.forEach((value: any, key: any) => {
						object[key] = value;
					});
				const jsonData: any = object;
				if (state.orderDetail) {
					state.orderDetail = {
						...state.orderDetail,
						item: {
							...state?.orderDetail?.item,
							revaluedPrice:
								jsonData?.review === 'true'
									? undefined
									: Number(jsonData?.newPrice),
							message:
								jsonData?.review === 'true'
									? undefined
									: jsonData?.message,
							showRevaluate: false,
							showReview: false,
							warehouseImages:
								action?.payload?.item?.warehouseImages ||
								state?.orderDetail?.item?.warehouseImages ||
								undefined,
						},
						orderData: {
							...state?.orderDetail?.orderData,
							localizedSalePrice: {
								...state?.orderDetail?.orderData
									?.localizedSalePrice,
								amount:
									jsonData?.review === 'true'
										? state?.orderDetail?.orderData
												?.localizedSalePrice?.amount
										: Number(jsonData?.newPrice || 0),
							},
							status:
								jsonData?.review === 'true'
									? 'device_accepted'
									: 'awaiting_user_response',
						},
					};
				}
			})
			.addCase(tryToDeliverOrder.pending, (state) => {
				state.orderIsLoading = true;
			})
			.addCase(tryToDeliverOrder.rejected, (state) => {
				state.orderIsLoading = false;
			})
			.addCase(tryToDeliverOrder.fulfilled, (state) => {
				state.orderIsLoading = false;
			})

			.addCase(tryToToggleFMI.pending, (state) => {
				state.orderIsActioned = {
					loading: true,
					dirty: true,
					action: 'FMI',
				};
			})
			.addCase(tryToToggleFMI.fulfilled, (state) => {
				state.orderIsActioned = {
					loading: false,
					dirty: true,
					action: 'FMI',
				};
			})

			// tryToEditSellerInfo
			.addCase(tryToEditSellerInfo.pending, (state) => {
				state.orderIsLoading = true;
			})
			.addCase(tryToEditSellerInfo.rejected, (state) => {
				state.orderIsLoading = false;
			})
			.addCase(tryToEditSellerInfo.fulfilled, (state, action) => {
				state.orderIsLoading = false;
				if (state.orderDetail) {
					state.orderDetail.shipment = {
						...state?.orderDetail?.shipment,
						deliveryStatus:
							state?.orderDetail?.shipment?.deliveryStatus || '',
						trackingNumber:
							state?.orderDetail?.shipment?.trackingNumber || '',
						postageLabel:
							state?.orderDetail?.shipment?.postageLabel || '',
						shippingProvider:
							state?.orderDetail?.shipment?.shippingProvider ||
							'',
						csRequired:
							state?.orderDetail?.shipment?.csRequired || false,
						fmi: state?.orderDetail?.shipment?.fmi || false,
						oldShippingAddressData:
							action?.payload?.data?.oldShippingAddressData,
					};
					state.orderDetail.seller = {
						...state?.orderDetail?.seller,
						...action?.payload?.data?.shippingAddress,
					};
				}
			})

			// tryToFetchAllBlacklistedItems
			.addCase(tryToFetchAllBlacklistedItems.pending, (state) => {
				state.ordersAreLoading = true;
			})
			.addCase(tryToFetchAllBlacklistedItems.rejected, (state) => {
				state.ordersAreLoading = false;
			})
			.addCase(
				tryToFetchAllBlacklistedItems.fulfilled,
				(state, action) => {
					state.ordersAreLoading = false;
					state.orders = action.payload;
				},
			)

			// tryToFetchAllAutoAcceptedRevaluations
			.addCase(tryToFetchAllAutoAcceptedRevaluations.pending, (state) => {
				state.ordersAreLoading = true;
			})
			.addCase(
				tryToFetchAllAutoAcceptedRevaluations.rejected,
				(state) => {
					state.ordersAreLoading = false;
				},
			)
			.addCase(
				tryToFetchAllAutoAcceptedRevaluations.fulfilled,
				(state, action) => {
					state.ordersAreLoading = false;
					state.orders = action.payload;
				},
			)

			// tryToFetchAllRejectedRevaluations
			.addCase(tryToFetchAllRejectedRevaluations.pending, (state) => {
				state.ordersAreLoading = true;
			})
			.addCase(tryToFetchAllRejectedRevaluations.rejected, (state) => {
				state.ordersAreLoading = false;
			})
			.addCase(
				tryToFetchAllRejectedRevaluations.fulfilled,
				(state, action) => {
					state.ordersAreLoading = false;
					state.orders = action.payload;
				},
			)

			// tryToCancelOrderOnUserBehalf
			.addCase(tryToCancelOrderOnUserBehalf.pending, (state) => {
				state.orderIsLoading = true;
			})
			.addCase(tryToCancelOrderOnUserBehalf.rejected, (state) => {
				state.orderIsLoading = false;
			})
			.addCase(tryToCancelOrderOnUserBehalf.fulfilled, (state) => {
				state.orderIsLoading = false;
				if (state.orderDetail) {
					state.orderDetail = {
						...(state.orderDetail || {}),
						orderData: {
							...state?.orderDetail?.orderData,
							status: 'canceled',
						},
					};
				}
			})

			// tryToFetchAllRevaluedFromBroker
			.addCase(tryToFetchAllRevaluedFromBroker.pending, (state) => {
				state.ordersAreLoading = true;
			})
			.addCase(tryToFetchAllRevaluedFromBroker.rejected, (state) => {
				state.ordersAreLoading = false;
			})
			.addCase(
				tryToFetchAllRevaluedFromBroker.fulfilled,
				(state, action) => {
					state.ordersAreLoading = false;
					state.orders = action.payload;
				},
			)

			// tryToFetchAllRejectedFromBroker
			.addCase(tryToFetchAllRejectedFromBroker.pending, (state) => {
				state.ordersAreLoading = true;
			})
			.addCase(tryToFetchAllRejectedFromBroker.rejected, (state) => {
				state.ordersAreLoading = false;
			})
			.addCase(
				tryToFetchAllRejectedFromBroker.fulfilled,
				(state, action) => {
					state.ordersAreLoading = false;
					state.orders = action.payload;
				},
			)

			// tryToFetchAllAwaitingUserResponse
			.addCase(tryToFetchAllAwaitingUserResponse.pending, (state) => {
				state.ordersAreLoading = true;
			})
			.addCase(tryToFetchAllAwaitingUserResponse.rejected, (state) => {
				state.ordersAreLoading = false;
			})
			.addCase(
				tryToFetchAllAwaitingUserResponse.fulfilled,
				(state, action) => {
					state.ordersAreLoading = false;
					state.orders = action.payload;
				},
			)
			// tryToFetchAllClosedCases
			.addCase(tryToFetchAllClosedCases.pending, (state) => {
				state.ordersAreLoading = true;
			})
			.addCase(tryToFetchAllClosedCases.rejected, (state) => {
				state.ordersAreLoading = false;
			})
			.addCase(tryToFetchAllClosedCases.fulfilled, (state, action) => {
				state.ordersAreLoading = false;
				state.orders = action.payload;
			})

			// tryToAssignCSUser
			.addCase(tryToAssignCSUser.pending, (state) => {
				state.ordersAreLoading = true;
			})
			.addCase(tryToAssignCSUser.rejected, (state) => {
				state.ordersAreLoading = false;
			})
			.addCase(tryToAssignCSUser.fulfilled, (state, action) => {
				state.ordersAreLoading = false;
				if (state?.orders?.data.elements)
					state.orders.data.elements = state.orders.data.elements.map(
						(order) => {
							const orderToReturn = order;
							const { assignedCs } =
								action?.payload?.data.items.find(
									(item: any) =>
										item._id === action?.meta?.arg.itemId,
								) || {};
							if (
								order.id === action?.meta?.arg?.orderId &&
								order.itemId === action?.meta?.arg?.itemId
							)
								orderToReturn.assignedCs = assignedCs;
							return orderToReturn;
						},
					);
			})

			// tryToResetOrder
			.addCase(tryToResetOrder.pending, (state) => {
				state.orderIsLoading = true;
			})
			.addCase(tryToResetOrder.rejected, (state) => {
				state.orderIsLoading = false;
			})
			.addCase(tryToResetOrder.fulfilled, (state) => {
				state.orderIsLoading = false;
				if (state.orderDetail) {
					state.orderDetail = {
						...(state.orderDetail || {}),
						orderData: {
							...state?.orderDetail?.orderData,
							status: 'device_in_review',
						},
						item: {
							...state?.orderDetail?.item,
							revaluedPrice: undefined,
							showAcceptOnUserBehalf: false,
						},
					};
				}
			});
	},
});

export default ordersSlice.reducer;
