import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ApiResponse } from '../../../interfaces/models/models/paginated-response.model';
import { ITransactionListState } from '../../../interfaces/transactions/ITransactionList';
import { PaginatedTransaction } from '../../../interfaces/transactions/paginatedStore.type';
import { TransactionsService } from './transactionsApi';


const initialState: ITransactionListState = {
    transactionsAreLoading: false,
    transactionIsLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchTransactions = createAsyncThunk<PaginatedTransaction, any>(
    'transactions/tryToFetchTransactions',
    async ({ pageNumber, pageSize, filters, data }) => {
        const result = await TransactionsService.tryToFetchTransactions(
            pageNumber,
            pageSize,
            filters,
            data,
        );
        return result?.data;
    },
);

export const tryToFetchTransactionById = createAsyncThunk<any, string>(
    'transactions/tryToFetchTransactionById',
    async (id: string) => {
        const result = await TransactionsService.tryToFetchTransactionById(id);
        return result?.data?.data;
    },
);

export const tryToUpdateTransactionById = createAsyncThunk<ApiResponse<any>, any>(
    'transactions/tryToUpdateTransactionById',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await TransactionsService.tryToUpdateTransactionById(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const transactionsSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchTransactions
            .addCase(tryToFetchTransactions.pending, (state) => {
                state.transactionsAreLoading = true;
            })
            .addCase(tryToFetchTransactions.rejected, (state) => {
                state.transactionsAreLoading = false;
            })
            .addCase(tryToFetchTransactions.fulfilled, (state, action) => {
                state.transactionsAreLoading = false;
                state.transactions = action.payload;
            })

            // tryToUpdateTransactionById
            .addCase(tryToUpdateTransactionById.pending, (state) => {
                state.transactionIsLoading = true;
            })
            .addCase(tryToUpdateTransactionById.rejected, (state) => {
                state.transactionIsLoading = false;
            })
            .addCase(tryToUpdateTransactionById.fulfilled, (state, action) => {
                state.transactionIsLoading = false;
                state.requestStatus = 'success';
                const data = action.payload.data || action.meta.arg;
                state.transationDetails = data
            })

            // tryToFetchTransactionById
            .addCase(tryToFetchTransactionById.pending, (state) => {
                state.transactionIsLoading = true;
            })
            .addCase(tryToFetchTransactionById.rejected, (state) => {
                state.transactionIsLoading = false;
            })
            .addCase(tryToFetchTransactionById.fulfilled, (state, action) => {
                state.transactionIsLoading = false;
                state.transationDetails = action.payload;
            })
    },
});

export default transactionsSlice.reducer;