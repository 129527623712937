import React from 'react';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IFlaggedUser } from '../../../../interfaces/flagged-users/IFlaggedUser';
import Table from '../../../../shared/table';

const tableColumns = [
    'User ID',
    'Full Name',
    'Email Address',
    'Country',
    // 'Status',
    'Actions',
];

interface IFlaggedUserTable {
    rows: Array<IFlaggedUser>;
    paginationData?: any;
    accessControl?: any;
    rowsLoading?: boolean;
    actions?: any;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onSeeFlaggedUserDetails: (id: string) => void;
}

const FlaggedUserTable = ({
    rows,
    paginationData,
    accessControl,
    rowsLoading,
    actions,
    setPaginationState,
    onSeeFlaggedUserDetails,
}: IFlaggedUserTable) => {
    return (
        <Table
            containerStyle={'mt-0'}
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
        >
            {(rowsLoading ?
                    <>
                        {[...Array(10)].map((x, i) => {
                            return (
                                <tr key={i}>
                                    {tableColumns?.map(element => {
                                        return (
                                            <td key={element} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                        )
                                    })
                                    }
                                </tr>
                            )
                        })}
                    </>
                    : <>
                        {rows?.length > 0 &&
                            rows?.map((row: IFlaggedUser, idx: number) => {
                                return (
                                    <tr
                                        key={idx}
                                        className={'border-b hover:bg-gray-50 font-normal bg-white'}
                                    >
                                        <td className='px-6 py-4'>{row?._id || ''}</td>
                                        <td className='px-6 py-4'>{row?.profile.firstName + ' ' + row?.profile.lastName || ''}</td>
                                        <td className='px-6 py-4'>{row?.email || ''}</td>
                                        <td className='px-6 py-4'>{row?.profile.country || ''}</td>
                                        {/* <td className='px-6 py-4'>{row?.cashoutInfo?.isCashoutEnabled === true ? 'Unlocked Account' : 'Locked Account'}</td> */}
                                        <td className='px-6 py-4 flex gap-6 pt-4'>
                                            <div className='flex flex-row justify-around'>
                                                <div data-qa={'view-details'} onClick={() => (!accessControl || actions?.['See Details']) && row?._id && onSeeFlaggedUserDetails(row?._id)}>
                                                    <img title={'View Details'} alt='item details' src={'/assets/table-actions/visibility.svg'} className={'h-[14px] object-contain cursor-pointer'} />
                                                </div>
                                            </div>
                                            <div className='flex'>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                    </>
                )}
        </Table >
    );
};

export default FlaggedUserTable;