import React, { useState } from 'react';
import { GRADES_ITEMS } from '../../../../utils/constants/grades';
import RevaluateDataDetailsModal from '../modals/RevaluateDataDetailsModal';
import Button from '../../../../shared/button';
import WhiteContainer from '../../../../shared/white-container';


interface IItemInfo {
    sku?: string;
    name?: string;
    brand?: string;
    network?: string;
    message?: string;
    condition?: string;
    material?: string;
    color?: string;
    imei?: string;
    asset?: any;
    devicedLocked?: string;
    description?: string;
    revaluateMessage?: string;
    location?: any;
    reviewed?: boolean;
    revaluateImages?: Array<any>;
    revaluationData?: any;
    orderStatus?: string;
    carbonImpact?: number;
    onClickRevaluateImages: () => void;
}

const ItemInfo = ({
    name,
    network,
    brand,
    condition,
    material,
    color,
    description,
    message,
    imei,
    sku,
    asset,
    devicedLocked,
    revaluateMessage,
    location,
    reviewed,
    orderStatus,
    revaluateImages,
    revaluationData,
    onClickRevaluateImages,
}: IItemInfo) => {
    const [showRevaluationData, setShowRevaluationData] = useState<boolean>(false);

    const onClickRevaluationData = () => {
        setShowRevaluationData(!showRevaluationData);
    };

    const keys = asset?.descriptor && Object.keys(asset?.descriptor);
    const locationKeys = location && Object.keys(location);
    return (
        <WhiteContainer containerStyle='!bg-[#f4f4f4]'>
            <p className='font-bold text-[20px] mb-4'>Item Details</p>
            <div>
                <div className='grid grid-cols-2 gap-3 my-2'>
                    <p className='font-bold text-md'>Property</p>
                    <p className='font-bold text-md'>Value</p>
                </div>
                {sku && (
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>SKU</p>
                            <p className='!break-words'>{sku}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                )}
                {!(asset?.descriptor && keys) ? (
                    <>
                        {name && (
                            <>
                                <div className='grid grid-cols-2 gap-3 my-2'>
                                    <p className=''>Name</p>
                                    <p className='!break-words'>{name}</p>
                                </div>
                                <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                            </>
                        )}
                        {brand && (
                            <>
                                <div className='grid grid-cols-2 gap-3 my-2'>
                                    <p className=''>Brand</p>
                                    <p className='!break-words'>{brand}</p>
                                </div>
                                <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                            </>
                        )}
                        {network && (
                            <>
                                <div className='grid grid-cols-2 gap-3 my-2'>
                                    <p className=''>Network Lock</p>
                                    <p>
                                        {network &&
                                            `${network[0].toUpperCase()}${network
                                                .slice(1)
                                                .toLowerCase()}`}
                                    </p>
                                </div>
                                <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                            </>
                        )}
                        {condition && (
                            <>
                                <div className='grid grid-cols-2 gap-3 my-2'>
                                    <p className=''>Condition</p>
                                    <p>
                                        {condition &&
                                            `${condition[0].toUpperCase()}${condition
                                                .slice(1)
                                                .toLowerCase()}`}
                                    </p>
                                </div>
                                <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                            </>
                        )}
                        {material && (
                            <>
                                <div className='grid grid-cols-2 gap-3 my-2'>
                                    <p className=''>Material</p>
                                    <p>
                                        {condition &&
                                            `${material[0].toUpperCase()}${material
                                                .slice(1)
                                                .toLowerCase()}`}
                                    </p>
                                </div>
                                <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        {asset?.name && (
                            <>
                                <div className='grid grid-cols-2 gap-3 my-2'>
                                    <p className=''>Name</p>
                                    <p className='break-words'>{asset?.name}</p>
                                </div>
                                <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                            </>
                        )}
                        {keys?.length > 0 &&
                            keys?.map((item: any) => {
                                const findGrade = (item || '')?.toLowerCase() === 'grade' && GRADES_ITEMS.find((grade: any) => grade.value.toLowerCase() === (asset?.descriptor?.[item] || '').toLowerCase());
                                return (
                                    <>
                                        <div className='grid grid-cols-2 gap-3 my-2'>
                                            <p className=''>{`${item[0].toUpperCase()}${item.slice(1).toLowerCase()}`}</p>
                                            <p className='break-words'>
                                                {(item || '')?.toLowerCase() === 'grade' ? findGrade ? findGrade?.name : asset?.descriptor?.[item] : asset?.descriptor?.[item]}
                                            </p>
                                        </div>
                                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                                    </>
                                );
                            })}
                    </>
                )}

                {imei && (
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>IMEI</p>
                            <p>{imei}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                )}
                {color &&
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>Color</p>
                            <p className='!break-words'>{color}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                }
                {description &&
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>Description</p>
                            <p className='!break-words'>{description}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                }
                {message &&
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>Message</p>
                            <p className='!break-words'>{message}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                }
                {devicedLocked &&
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>Device Locked</p>
                            <p className='!break-words'>{devicedLocked}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                }
                {revaluationData && (
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>Revaluation Data</p>
                            <Button
                                label={'See Details'}
                                dataQa={'see-details'}
                                className={'btn-primary-rounded-less !shadow-none'}
                                onClickButton={onClickRevaluationData}
                            />
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                )}
                {revaluateMessage && (
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>Revaluate Message</p>
                            <p>{revaluateMessage}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                )}
                {revaluateImages && revaluateImages?.length > 0 && (
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>{orderStatus === 'item_missing' ? 'Missing Images' : 'Warehouse Images'}</p>
                            <Button
                                label={'See Images'}
                                dataQa={'see-images'}
                                className={'btn-primary-rounded-less !shadow-none'}
                                onClickButton={onClickRevaluateImages}
                            />
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                )}
                {reviewed && (
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>Item Reviewed</p>
                            <p>Yes</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                )}
                {location && locationKeys && locationKeys?.length > 0 && (
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>Inventory</p>
                            <p>
                                {locationKeys?.map(
                                    (loc: any, index: number) => {
                                        return index > 0
                                            ? `, ${loc}: ${location?.[loc]}`
                                            : `${loc}: ${location?.[loc]}`;
                                    },
                                )}
                            </p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                )}
            </div>
            <RevaluateDataDetailsModal
                openModal={showRevaluationData}
                handleCloseModal={onClickRevaluationData}
                revaluationData={revaluationData}
            />
        </WhiteContainer>
    );
};

export default ItemInfo;
