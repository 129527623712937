import { AxiosResponse } from 'axios';
import { ORDERS_URL } from '../../../config';
import ordersAxios from '../../../utils/axios/orders.axios';
import { PaginatedPendingItems } from '../../../interfaces/pending-items/paginatedStore.type';


const tryToFetchPendingItems = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
    data?: any
): Promise<AxiosResponse<PaginatedPendingItems>> => {
    const url = filters
        ? `${ORDERS_URL}/shipments/pending-cashout?page=${pageNumber}&size=${pageSize}&search=${filters?.search}`
        : `${ORDERS_URL}/shipments/pending-cashout?page=${pageNumber}&size=${pageSize}`;
    return ordersAxios.put<PaginatedPendingItems>(url, data);
};

const tryToFetchSinglePendingItem = (pendingItemId: string): Promise<AxiosResponse<any>> => {
    const url = `${ORDERS_URL}/shipments/pending-cashout/${pendingItemId}`
    return ordersAxios.get<any>(url);
};

const tryToRejectPendingItem = (pendingItemId: string, data: any): Promise<AxiosResponse<any>> => {
    const url = `${ORDERS_URL}/shipments/pending-cashout/reject/${pendingItemId}`
    return ordersAxios.put<any>(url, data);
};

const tryToAcceptPendingItem = (pendingItemId: string): Promise<AxiosResponse<any>> => {
    const url = `${ORDERS_URL}/shipments/pending-cashout/accept/${pendingItemId}`
    return ordersAxios.put<any>(url);
};

export const PendingItemsService = {
    tryToFetchPendingItems,
    tryToFetchSinglePendingItem,
    tryToRejectPendingItem,
    tryToAcceptPendingItem
};