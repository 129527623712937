import React from 'react';
import moment from 'moment';
import WhiteContainer from '../../../../shared/white-container';
import { IAddress } from '../../../../interfaces/auth/ICreateUserSchema';


interface ISellerBasicDetails {
    sellerDetails: any
}

const SellerBasicDetails = ({
    sellerDetails
}: ISellerBasicDetails) => {
    const otherShippingAddresses = sellerDetails?.seller?.profile?.shippingAddress?.filter((address: IAddress) => !address.default) || []
    return (
        <WhiteContainer containerStyle='mb-4 rounded !h-auto'>
            <p className='gradient-text mb-6'>User Details</p>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4'>
                <div>
                    <div className='grid grid-cols-2 gap-4 mb-4'>
                        <p>Status</p>
                        <div className={`${sellerDetails?.seller?.isActive ? 'border-[#F7A21E]' : 'border-red-600'} border rounded text-center px-3`}>
                            <p className={`${sellerDetails?.seller?.isActive ? 'text-[#F7A21E]' : 'text-red-600'} font-medium text-center`}>{sellerDetails?.seller?.isActive ? 'Active' : 'Inactive'}</p>
                        </div>
                    </div>
                    <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    <div className='grid grid-cols-2 gap-4  mb-4'>
                        <p>Full Name</p>
                        <p>{sellerDetails?.seller?.profile?.firstName} {sellerDetails?.seller?.profile?.lastName}</p>
                    </div>
                    <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    {sellerDetails?.seller?.profile?.gender &&
                        <>
                            <div className='grid grid-cols-2 gap-4  mb-4'>
                                <p>Gender</p>
                                <p>{sellerDetails?.seller?.profile?.gender}</p>
                            </div>
                            <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                        </>
                    }
                    {sellerDetails?.seller?.profile?.dateOfBirth &&
                        <>
                            <div className='grid grid-cols-2 gap-4  mb-4'>
                                <p>Date of Birth</p>
                                <p>{moment(sellerDetails?.seller?.profile?.dateOfBirth).format('DD-MM-YYYY')}</p>
                            </div>
                            <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                        </>
                    }
                    {sellerDetails?.seller?.defaultShippingAddress?.addressNo &&
                        <>
                            <div className='grid grid-cols-2 gap-4  mb-4'>
                                <p>Address Number</p>
                                <p>{sellerDetails?.seller?.defaultShippingAddress?.addressNo}</p>
                            </div>
                            <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                        </>
                    }
                    {sellerDetails?.seller?.defaultShippingAddress?.street &&
                        <>
                            <div className='grid grid-cols-2 gap-4  mb-4'>
                                <p>Street</p>
                                <p>{sellerDetails?.seller?.defaultShippingAddress?.street}</p>
                            </div>
                            <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                        </>
                    }
                </div>
                <div>
                    {sellerDetails?.seller?.defaultShippingAddress?.city &&
                        <>
                            <div className='grid grid-cols-2 gap-4  mb-4'>
                                <p>City</p>
                                <p>{sellerDetails?.seller?.defaultShippingAddress?.city}</p>
                            </div>
                            <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                        </>
                    }
                    {sellerDetails?.seller?.defaultShippingAddress?.state &&
                        <>
                            <div className='grid grid-cols-2 gap-4  mb-4'>
                                <p>State</p>
                                <p>{sellerDetails?.seller?.defaultShippingAddress?.state}</p>
                            </div>
                            <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                        </>
                    }
                    {sellerDetails?.seller?.defaultShippingAddress?.postcode &&
                        <>
                            <div className='grid grid-cols-2 gap-4  mb-4'>
                                <p>Postal Code</p>
                                <p>{sellerDetails?.seller?.defaultShippingAddress?.postcode}</p>
                            </div>
                            <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                        </>
                    }
                    {sellerDetails?.seller?.profile?.country &&
                        <>
                            <div className='grid grid-cols-2 gap-4  mb-4'>
                                <p>Country</p>
                                <p>{sellerDetails?.seller?.profile?.country}</p>
                            </div>
                            <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                        </>
                    }

                    {sellerDetails?.seller?.phone &&
                        <>
                            <div className='grid grid-cols-2 gap-4  mb-4'>
                                <p>Phone</p>
                                <p>{sellerDetails?.seller?.phone}</p>
                            </div>
                            <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                        </>
                    }
                    <>
                        <div className='grid grid-cols-2 gap-4  mb-4'>
                            <p>Cashouts Number</p>
                            <p>{sellerDetails?.cashoutsNumber || 0}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                </div>

            </div>
            {
                otherShippingAddresses.length > 0 && <div className='my-4'>
                    <p className='gradient-text mb-6'>Other Shipping Addresses</p>
                    {otherShippingAddresses.map((address: IAddress, index: number) => <div key={index}>
                        <p>Address {index + 1}</p>
                        <div className='grid grid-cols-2 gap-4  mb-4'>
                            <p>Street</p>
                            <p>{address.street}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                        <div className='grid grid-cols-2 gap-4  mb-4'>
                            <p>Address Number</p>
                            <p>{address.addressNo}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                        <div className='grid grid-cols-2 gap-4  mb-4'>
                            <p>Postal Code</p>
                            <p>{address.postcode}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                        <div className='grid grid-cols-2 gap-4  mb-4'>
                            <p>City</p>
                            <p>{address.city}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                        {typeof address.state === 'string' && address.state !== '' &&
                            <>
                                <div className='grid grid-cols-2 gap-4  mb-4'>
                                    <p>State</p>
                                    <p>{address.state}</p>
                                </div>
                                <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                            </>}
                        <div className='grid grid-cols-2 gap-4  mb-4'>
                            <p>Country</p>
                            <p>{address.country}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </div>)}
                </div>
            }

        </WhiteContainer>
    );
};

export default SellerBasicDetails;
