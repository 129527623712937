import { AxiosResponse } from 'axios';
import { BROKERS_USERS_SERVICE, ORDERS_URL } from '../../../config';
import userAxios from '../../../utils/axios/user.axios';
import ordersAxios from '../../../utils/axios/orders.axios';

const tryToFetchAllPartners = (): Promise<AxiosResponse<any>> => {
	return userAxios.put(`${BROKERS_USERS_SERVICE}/partners?flag=true`);
};

const tryToFetchAllCsUsers = (): Promise<AxiosResponse<any>> => {
	return userAxios.get(`${BROKERS_USERS_SERVICE}/dom/users/cs`);
};

// TODO: Check if this endpoint works
const tryToFetchPartnersByMarket = (id: string): Promise<AxiosResponse> => {
	return userAxios.put(`${BROKERS_USERS_SERVICE}/partners/market/${id}`);
};

const tryToFetchNavigation = (): Promise<AxiosResponse<any[]>> => {
	return userAxios.get(`${BROKERS_USERS_SERVICE}/users/access-control`);
};

const tryToFetchCasesCount = (): Promise<AxiosResponse<any>> => {
	return ordersAxios.get(`${ORDERS_URL}/shipments/count/unassigned-cases`);
};

export const PartnersService = {
	tryToFetchAllPartners,
	tryToFetchNavigation,
	tryToFetchAllCsUsers,
	tryToFetchPartnersByMarket,
	tryToFetchCasesCount,
};
