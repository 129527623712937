import { AxiosResponse } from 'axios';
import ordersAxios from '../../../utils/axios/orders.axios';
import { ApiResponse } from '../../../interfaces/models/models/paginated-response.model';
import { ORDERS_URL } from '../../../config';
import { PaginatedDisputesHistory } from '../../../interfaces/dispute-history/paginatedStore.type';

const tryToFetchDisputeHistory = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
): Promise<AxiosResponse<PaginatedDisputesHistory>> => {
    const url = filters 
    ? `${ORDERS_URL}/stripe-disputes-history/all?page=${pageNumber}&size=${pageSize}&search=${filters?.search}`
    : `${ORDERS_URL}/stripe-disputes-history/all?page=${pageNumber}&size=${pageSize}`
    return ordersAxios.put<ApiResponse<any>>(url);
};

const tryToFetchDisputeHistoryDetails = (historyId: string): Promise<AxiosResponse<any>> => {
    const url = `${ORDERS_URL}/stripe-disputes-history/${historyId}`
    return ordersAxios.get<any>(url);
};

export const DisputeHistoryService = {
    tryToFetchDisputeHistory,
    tryToFetchDisputeHistoryDetails
}