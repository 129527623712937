import React, { useEffect, useState } from 'react';
import RevaluateDataDetailsModal from '../modals/RevaluateDataDetailsModal';
import WhiteContainer from '../../../../shared/white-container';
import Button from '../../../../shared/button';


interface IItemInfo {
    displayQuestion?: any;
    revaluateMessage?: string;
    reviewed?: boolean;
    revaluateImages?: Array<any>;
    revaluationData?: any;
    orderStatus?: string;
    failuresDraw?: string;
    carbonImpact?: number;
    itemCode?: string;
    onClickRevaluateImages: () => void;
    onClickFailuresImages: () => void;
    salesGrade: string | undefined
    wingpayCondition?: string
}

const ItemInfo = ({
    displayQuestion,
    revaluateMessage,
    reviewed,
    failuresDraw,
    orderStatus,
    carbonImpact,
    revaluateImages,
    revaluationData,
    itemCode,
    onClickFailuresImages,
    onClickRevaluateImages,
    salesGrade,
    wingpayCondition
}: IItemInfo) => {
    const [showRevaluationData, setShowRevaluationData] = useState<boolean>(false);
    const [showQuestions, setShowQuestions] = useState<boolean>(true)

    const onClickRevaluationData = () => {
        setShowRevaluationData(!showRevaluationData);
    };

    const checkOtherInfo = () => {
        if (itemCode || carbonImpact || revaluationData || revaluateMessage || revaluateImages || reviewed || salesGrade) {
            setShowQuestions(false);
        }
    };
    
    useEffect(() => {
        checkOtherInfo();
    }, []);
    
    return (
        <WhiteContainer containerStyle='!rounded'>
            <p className='font-bold text-[20px] mb-4'>Item Details</p>
            <div>
                <div className='flex'>
                    <p className='mr-2 font-dm_sansbold text-lg'>Seller’s Cashout details</p>
                    <button onClick={() => setShowQuestions(!showQuestions)}>
                        <img
                            className={`transform ${showQuestions ? '' : 'rotate-180'}`}
                            src={'/assets/order-actions/arrowUp.svg'}
                            alt="Arrow Icon"
                        />
                    </button>
                </div>
                {(displayQuestion) && showQuestions &&
                    <>
                        {displayQuestion?.length > 0 &&
                            displayQuestion?.map((item: any, i: number) => {
                            return  displayQuestion?.[i - 1]?.question.includes('network') ?  (
                                    <>
                                        <div className='grid grid-cols-2 gap-3 my-2'>
                                            <p>wingpay Condition</p>
                                            <p className='break-words'>
                                            {wingpayCondition} 
                                            </p>
                                        </div>
                                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                                    </>
                                ) :
                                 (
                                    <>
                                        <div className='grid grid-cols-2 gap-3 my-2'>
                                            <p>{item?.question}</p>
                                            <p className='break-words'>
                                                {(item?.displayValue) ?
                                                    item?.displayValue :
                                                    (item?.value) ?
                                                        item?.value :
                                                        ((item?.answers && item?.answers?.length > 0)
                                                            ? item?.answers?.map((answer: { answer: string }, index: number) => `${index ? ', ' : ''}${answer?.answer}`) : 'No answer')
                                                }
                                            </p>
                                        </div>
                                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                                    </>
                                );
                            })}
                    </>
                }
                {itemCode && (
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>Item Code</p>
                            <p>{itemCode}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                )}
                {carbonImpact && (
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>Avoided Emissions</p>
                            Up to {carbonImpact} kg of CO2
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                )}
                {revaluationData && (
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>Revaluation Data</p>
                            <Button
                                label={'See Details'}
                                dataQa={'see-details'}
                                className={'btn-main !shadow-none'}
                                onClickButton={onClickRevaluationData}
                            />
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                )}
                {revaluateMessage && (
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>Revaluate Message</p>
                            <p>{revaluateMessage}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                )}
                {revaluateImages && revaluateImages?.length > 0 && orderStatus !== 'item_missing' && (
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>{['required_return']?.includes(orderStatus || '') ? 'Rejected Images' : 'Warehouse Images'}</p>
                            <Button
                                label={'See Images'}
                                dataQa={'see-images'}
                                className={'btn-main !shadow-none'}
                                onClickButton={onClickRevaluateImages}
                            />
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                )}
                {/* {failuresDraw &&
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>Item Failures Image</p>
                            <Button
                                label={'See Image'}
                                dataQa={'see-failure-image'}
                                className={'btn-main !shadow-none'}
                                onClickButton={onClickFailuresImages}
                            />
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                } */}
                {reviewed && (
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>Item Reviewed</p>
                            <p>Yes</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                )}
                {salesGrade && (
                    <>
                        <div className='grid grid-cols-2 gap-3 my-2'>
                            <p className=''>Sales Grade</p>
                            <p>{salesGrade}</p>
                        </div>
                        <div className='my-4 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                    </>
                )}
            </div>
            <RevaluateDataDetailsModal
                openModal={showRevaluationData}
                handleCloseModal={onClickRevaluationData}
                revaluationData={revaluationData}
            />
        </WhiteContainer>
    );
};

export default ItemInfo;
