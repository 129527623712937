import React, { useState, useEffect } from 'react';
import Modal from '../modal';

interface IImagesCarousel {
    images: Array<{ url: string }>;
    active?: number;
    showModal: boolean;
    showDownload?: boolean;
    onDownloadPictures?: (url: string) => void;
    handleClose: () => void;
}

const ImagesCarousel = ({
    images,
    active,
    showDownload,
    showModal,
    onDownloadPictures,
    handleClose
}: IImagesCarousel) => {
    const [activeImage, setActiveImage] = useState<number>(active || 0)

    useEffect(() => {
        if (active) {
            setActiveImage(active || 0)
        }
    }, [active])

    const onChangeSlideStep = (type: string) => {
        if (type === 'next') {
            const findNextStep = (images && (images?.length - 1 >= activeImage + 1)) ? activeImage + 1 : 0
            setActiveImage(findNextStep)
        } else {
            const findPrevStep = (activeImage - 1 >= 0) ? activeImage - 1 : 0
            setActiveImage(findPrevStep)
        }
    }

    return (
        <Modal
            open={showModal}
            onClose={handleClose}
        >
            <div className='min-w-[600px]'>
                <div className='relative w-[600px] mx-auto'>

                    {images && images?.length > 0 && images?.map((image, idx) => {
                        return (
                            <>
                                <div
                                    key={image?.url}
                                    className={`slide relative ${idx === activeImage ? '' : 'hidden'}`}>
                                    <img className='w-full h-[500px] object-contain'
                                        src={image?.url} />
                                </div>
                                {showDownload && idx === activeImage &&
                                    <div data-qa={'download'} onClick={() => onDownloadPictures?.(image?.url)} className='absolute top-[-32px] left-[10px] cursor-pointer'>
                                    <img src={'/assets/shared/download.svg'} className={'object-contain w-[30px]'} />
                                    </div>
                                }
                            </>
                        )
                    })}

                    <div className='slide hidden relative'>
                        <img className='w-full h-[300px] object-contain'
                            src='https://www.kindacode.com/wp-content/uploads/2022/07/flower-2.jpeg' />
                    </div>

                    <div className='slide hidden relative'>
                        <img className='w-full h-[300px] object-cover'
                            src='https://www.kindacode.com/wp-content/uploads/2022/07/flower-3.jpeg' />
                    </div>
                    <a className='absolute left-0 top-1/2 p-4 -translate-y-1/2 bg-black/30 hover:bg-black/50 text-white hover:text-amber-500 cursor-pointer'
                        data-qa={'prev'} onClick={() => onChangeSlideStep('prev')}
                    >❮</a>

                    <a className='absolute right-0 top-1/2 p-4 -translate-y-1/2 bg-black/30 hover:bg-black/50 text-white hover:text-amber-500 cursor-pointer'
                        data-qa={'next'} onClick={() => onChangeSlideStep('next')}
                    >❯</a>

                </div>
                <br />
            </div>
        </Modal>
    )
}

export default ImagesCarousel;