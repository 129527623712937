import { AxiosResponse } from 'axios';
import { ORDERS_URL } from '../../../config';
import { PaginatedFlaggedUsers } from '../../../interfaces/flagged-users/paginatedStore.type';
import ordersAxios from '../../../utils/axios/orders.axios';

//  Find all flagged users:
const tryToFetchAllFlaggedUsers = (
    pageNumber: number,
    pageSize: number,
    filters?: string,
): Promise<AxiosResponse<PaginatedFlaggedUsers>> => {
    const url = filters
        ? `${ORDERS_URL}/sellers/all/flagged?page=${pageNumber}&size=${pageSize}&search=${filters?.search}`
        : `${ORDERS_URL}/sellers/all/flagged?page=${pageNumber}&size=${pageSize}`;
    return ordersAxios.get<PaginatedFlaggedUsers>(url);
};

//  Flagged seller details
const tryToFetchFlaggedUsersDetails = (sellerId: string): Promise<AxiosResponse<any>> => {
    const url = `${ORDERS_URL}/sellers/${sellerId}/flagged-seller-details`
    return ordersAxios.get<any>(url);
};

//  Enable seller for cashout
const tryToEnableSeller = (userId: string): Promise<AxiosResponse> => {
    return ordersAxios.put(`${ORDERS_URL}/cashout-limits/user/${userId}/enable-cashout`);
};


// Disable seller for cashout
const tryToDisableCashoutFee = (userId: string): Promise<AxiosResponse> => {
    return ordersAxios.put(`${ORDERS_URL}/cashout-limits/user/${userId}/disable-cashout-fee`);
};

export const FlaggedUserService = {
    tryToFetchAllFlaggedUsers,
    tryToFetchFlaggedUsersDetails,
    tryToEnableSeller,
    tryToDisableCashoutFee
} 