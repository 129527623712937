import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { BROKERS_USERS_SERVICE } from '../../config';
// import { AuthService } from '../../features/auth/_stores/authAPI';
import { removeFromStorage } from '../webStorage';

const axiosConfig: AxiosRequestConfig = {
    baseURL: `${BROKERS_USERS_SERVICE}` || 'http://localhost:3000/',
};
const authAxios = axios.create(axiosConfig);

const logout = () => {
    removeFromStorage('accessToken');
    removeFromStorage('refreshToken');
    window.location.reload();

};

// Add a response interceptor
authAxios.interceptors.response.use(
    (response) => {
        if (response?.data?.error === 'No permission!') logout();
        return response;
    },
    async (error) => {
        const err = { ...error };
        // if (error.response.status === 401) {
        // const err = { ...error };
        // const originalRequest = err.config;
        // if (!originalRequest._retry) {
        // originalRequest._retry = true;
        // try {
        // const token = readFromStorage('refreshToken');
        // const response = await authAxios.post('/refresh-token', { token });
        // const accessToken = response.data.data.token;
        //  axios.defaults.headers.common.Authorization = 'Bearer ' + accessToken;
        //   return authAxios(originalRequest);
        // } catch (e) {
        // console.error(e);
        // logout();
        // }
        // // const accessToken = await AuthService.tryToRefreshAccessToken();
        // // Try to get access token 

        // }
        // }
        return Promise.reject(err);
    },
);

export const authAxiosQuery =
    (): BaseQueryFn<{
        url: string;
        method: AxiosRequestConfig['method'];
        data?: AxiosRequestConfig['data'];
        headers?: AxiosRequestConfig['headers'];
    }> =>
        async ({ url, method, data, headers }) => {
            try {
                const result = await authAxios({ url, method, data, headers });
                return { data: result.data };
            } catch (axiosError) {
                const err = axiosError as AxiosError;
                return {
                    error: { status: err.response?.status, data: err.response?.data },
                };
            }
        };

export default authAxios;
