import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { UserGuard } from '../guards/UserGuard';

export type RouteWrapperProps = {
    guard?: () => boolean;
    redirectPath?: string;
    element: JSX.Element;
};

export default function RouteWrapper({ guard, redirectPath, element }: RouteWrapperProps) {
    const location = useLocation();
    const redirectNotExistingRoutes = UserGuard() ? '/customer-care' : '/login'
    const redirect = redirectPath || redirectNotExistingRoutes || '/login';
    if ((guard && guard()) || location.pathname === redirect || guard === undefined) {
        return <React.Fragment>{element}</React.Fragment>;
    } else {
        return (
            <React.Fragment>
                <Navigate to={{ pathname: redirect }} />
            </React.Fragment>
        );
    }
}
