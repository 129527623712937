import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IMarket } from '../../../../interfaces/markets/IMarket';
import { IAllMarkets } from '../../../../interfaces/markets/IAllMarkets';
import { ICashoutsFilters } from '../../../../interfaces/components/ICashoutsFilters';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { tryToFetchCashoutOrders, tryToFetchCashouts, tryToFetchOrdersCSV } from '../../../../store/customer-support/orders/ordersSlice';
import { useGetAllCsUsersMutation, useGetAllMarketsMutation } from '../../../../store/user/userApi';
import CashoutsTable from '../tables/CashoutsTable';
import CashoutsFilters from '../details/CashoutsFilters';
import EmptyContainer from '../../../../shared/empty-container';
import Button from '../../../../shared/button';
import CashoutTypeModal from '../modals/CashoutTypeModal';
import TakeActionModal from '../modals/TakeActionModal';
import AssignCSUserOutboundModal from '../modals/AssignCSUserOutboundModal';
import Input from '../../../../shared/input';
import { CSVLink } from 'react-csv';

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};

const CashoutsTab = () => {
    const query = useQuery();
    const csvLink = React.useRef() as React.MutableRefObject<any>;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<any>([]);
    const [orderRows, setOrderRows] = useState<any>([]);
    const [markets, setMarkets] = useState<IAllMarkets[]>();
    const [selectedMarket, setSelectedMarket] = useState<any>();
    const [filters, setFilters] = useState<ICashoutsFilters>();
    const [paginationState, setPaginationState] = useState<IPaginationPayload>({
        pageNumber: 1,
        pageSize: 10,
    });
    const [pageAccess, setPageAccess] = useState<any>();
    const [showCashoutOrders, setShowCashoutOrders] = useState<boolean>(true)
    const [csUsers, setCsUsers] = useState<[]>()
    const [selectedSector, setSelectedSector] = useState<string>();
    const [searchParams, setSearchParams] = useSearchParams();
    const paginationData = useAppSelector(
        (state) => state.orders.cashouts?.data?.page
    );
    const rowsLoading = useAppSelector(
        (state) => state.orders.cashoutsAreLoading
    );
    const ordersPaginationData = useAppSelector(
        (state) => state.orders.cashoutOrders?.data?.page
    );
    const stateAssets = useAppSelector((state) => state?.assetsTemplates);
    const [showCashoutTypeModal, setShowCashoutTypeModal] = useState<{ show: boolean, id: string, itemId: string }>()
    const [showTakeActionModal, setShowTakeActionModal] = useState<{ show: boolean, id: string, isMultipleCashout: boolean, fullName: string }>()
    const [showAssignCsUserOutboundModal ,setShowAssignCsUserOutboundModal] = useState<{ show: boolean, id: string}>()
    const [ordersExport, setOrdersExport] = useState<any>()
    const [searchValue, setSearchValue] = useState<any>()
    const state = useAppSelector((state) => state.orders);
    const accessControl = useAppSelector(
        (state) => state?.partners?.navigation
    );
    const [getAllMarkets] = useGetAllMarketsMutation();
    const [getAllCsUsers] = useGetAllCsUsersMutation();

    useEffect(() => {
        const findPage =
            accessControl &&
            accessControl?.length > 0 &&
            accessControl?.find((item: any) => item?.name === 'Orders');
        const findChild =
            findPage &&
            findPage?.children &&
            findPage?.children?.length > 0 &&
            findPage?.children?.find((item: any) => item?.path === '');
        setPageAccess(findChild || undefined);
    }, [accessControl]);

    const onResetPaginationSettings = () => {
        setPaginationState({
            pageNumber: 1,
            pageSize: paginationState?.pageSize || 10,
        });
        searchParams.set('page', '1');
        searchParams.set('size', `${paginationState?.pageSize || 10}`);
        setSearchParams(searchParams);
    };

    useEffect(() => {
        if (state.cashouts) {
            const rows =
                state.cashouts?.data?.elements &&
                Array.isArray(state.cashouts?.data.elements)
                    ? state.cashouts?.data.elements.map((r: any) => ({
                          ...r,
                          id: r._id || r?.id,
                      }))
                    : [];
            setRows(rows);
        }

        if(state.cashoutOrders) {
            const rows =
                state.cashoutOrders?.data?.elements &&
                Array.isArray(state.cashoutOrders?.data.elements)
                    ? state.cashoutOrders?.data.elements.map((r: any) => ({
                          ...r,
                          id: r._id || r?.id,
                      }))
                    : [];
            setOrderRows(rows);
        }
    }, [state.cashouts, state.cashoutOrders]);


    const onGettingCashoutsData = async () => {
        try {
            let formatData: any = {
                ...(paginationState || {}),
            };
            if (filters) {
                formatData = {
                    ...(formatData || {}),
                    data: filters,
                };
            }
            if(searchValue) {
                formatData = {
                    ...(formatData || {}),
                    filters: searchValue
                }
            }
            if (selectedMarket) {
                formatData = {
                    ...(formatData || {}),
                    data: {
                        ...(formatData?.data || {}),
                        country: selectedMarket?.displayName || '',
                        allTypes: true
                    },
                };
            }
            searchParams.set('page', `${paginationState?.pageNumber}`)
            searchParams.set('size', `${paginationState?.pageSize}`)
            searchParams.set('search', searchValue)
            setSearchParams(searchParams)
            await dispatch(tryToFetchCashouts(formatData)).unwrap();
        } catch (err) {
            // error here
        }
    };

    const onGettingCashoutOrdersData = async () => {
        try {
            let formatData: any = {
                ...(paginationState || {}),
            };
            if (filters) {
                formatData = {
                    ...(formatData || {}),
                    data: filters,
                };
            }
            if (selectedMarket) {
                formatData = {
                    ...(formatData || {}),
                    data: {
                        ...(formatData?.data || {}),
                        country: selectedMarket?.displayName,
                    },
                };
            }
            searchParams.set('page', `${paginationState?.pageNumber}`)
            searchParams.set('size', `${paginationState?.pageSize}`)
            setSearchParams(searchParams)
            await dispatch(tryToFetchCashoutOrders(formatData)).unwrap();
        } catch (err) {
            // error here
        }
    };

    const onGettingCashoutsOrdersCSV = async () => {
        try {
            let formatData: any = {
                ...(paginationState || {}),
            };
            if (filters) {
                formatData = {
                    ...(formatData || {}),
                    data: filters,
                };
            }
            if (selectedMarket) {
                formatData = {
                    ...(formatData || {}),
                    data: {
                        ...(formatData?.data || {}),
                        country: selectedMarket?.displayName,
                    },
                };
            }
            const resp = await dispatch(tryToFetchOrdersCSV(formatData)).unwrap();
            setOrdersExport(resp?.data)
        } catch (err) {
            // error here
        }
    };

    useEffect(() => {
        if (selectedMarket?.displayName) {
          !showCashoutOrders ?  onGettingCashoutsData() : onGettingCashoutOrdersData()
        }
    }, [paginationState, selectedMarket, showCashoutOrders, searchValue]);

    const getMarketsData = async () => {
        const marketResponse = await getAllMarkets(null).unwrap();
        const formatMarkets =
            marketResponse &&
            marketResponse?.length > 0 &&
            marketResponse?.map((market: IMarket) => ({
                ...market,
                name: market?.label,
                value: market?._id,
                displayName: market?.name,
            }));
        setMarkets(formatMarkets || []);
        if (!selectedMarket) {
            const findMarketUK =
                formatMarkets &&
                formatMarkets?.length > 0 &&
                formatMarkets?.find(
                    (item: any) =>
                        (item?.label || '').toLowerCase() === 'united kingdom'
                );
            setSelectedMarket(
                findMarketUK || formatMarkets?.[0]?.name || undefined
            );
            onResetPaginationSettings();
        }
    };

    const onGetAllCsUsers = async () => {
        const csUsersResponse = await getAllCsUsers(null).unwrap();
        const formatCsUsers = csUsersResponse && csUsersResponse.data?.length > 0 && csUsersResponse.data.map((user: any) => ({ ...user, displayName: user.name, name: user.name, label: user.name, value: user._id }))
        setCsUsers(formatCsUsers)
    }

    useEffect(() => {
        getMarketsData();
        onGetAllCsUsers()
    }, []);

    const onClickClearAllFilters = () => {
        onResetPaginationSettings();
        setFilters(undefined);
        setSearchParams(undefined)
        setSearchValue(undefined)
    };

    const onChangeFilterValue = (
        value: string | null | undefined,
        type?: string
    ) => {
        if (query?.get('search') && query?.get('search') !== paginationState?.filters) {
            paginationState.filters = query.get('search') || undefined;
            setSearchValue(query.get('search') || undefined)
        }
        if (type) {
            setFilters({
                ...filters,
                [type]: value || undefined,
            });
            if (type === 'toDate' || type === 'fromDate') {
                onResetPaginationSettings();
            }
        }
    };

    const onChangeFilterSelected = (value: any, type?: string) => {
        if (type) {
            setFilters({
                ...filters,
                [type]: value?.value || undefined,
            });
            onResetPaginationSettings();
        }
    };

    const onEnterKeyPressInput = async (e: any) => {
        if (e?.key === 'Enter') {
            onResetPaginationSettings();
        }
    };

    const onChangeSelectedMarket = (value: any, type?: string) => {
        setSelectedMarket(value);
    };

    const onSeeDetails = (id: string, itemId: string, country: string) => {
        navigate(`/tickets/${id}/${itemId}/country/${country}`);
    };

    const onCashoutType = (id: string, itemId: string) => {
       setShowCashoutTypeModal({
        show: true,
        id,
        itemId
       })
    }

    const onTakeAction = (id: string, isMultipleCashout: boolean, fullName: string) => {
        setShowTakeActionModal({
         show: true,
         id,
         isMultipleCashout,
         fullName
        })
    }

    const onAssignCsUser = (id: string) => {
        setShowAssignCsUserOutboundModal({
         show: true,
         id,
        })
    }

    useEffect(() => {
        if (stateAssets.allAssetTemplates) {
            const rows =
                stateAssets.allAssetTemplates &&
                    Array.isArray(stateAssets.allAssetTemplates)
                    ? stateAssets.allAssetTemplates.map((r) => ({
                        ...(r || {}),
                        name: r?.displayName,
                        label: r?.displayName,
                        value: r?._id,
                    }))
                    : [];
            const formatAssets =
                rows?.length > 0
                    ? rows?.filter(
                        (r) =>
                            (r?.name || '').toLowerCase() !== 'currencies',
                    )
                    : [];
            if (!selectedSector) {
                const findPhones = (formatAssets && formatAssets?.length > 0) && formatAssets?.find((r) => (r?.name || '').toLowerCase() === 'phones')
                setSelectedSector(query?.get('category') || findPhones?.name || formatAssets?.[0]?.name || undefined)
            }
            onResetPaginationSettings();
        }
    }, [stateAssets.allAssetTemplates]);

    const onChangeSearchValue = (value: string | null | undefined, type: string) => {
        setSearchValue(value)
        setPaginationState({
            ...(paginationState || {}),
            pageNumber: 1,
            filters: (value && encodeURIComponent(value)) || undefined,
        });
        if (value) searchParams.set('search', value);
        else searchParams.delete('search');
        searchParams.set('page', '1');
        setSearchParams(searchParams);
    };

    useEffect(() => {
        if (ordersExport) {
            csvLink.current.link.click()
        }
    }, [ordersExport])

    return (
        <div className='h-auto w-full flex flex-col gap-2 my-6'>
            <p className='text-primary-light'>
                Please make sure to press “Enter” to load the results
            </p>
            <div className='mb-7'>
                <CashoutsFilters
                    filters={filters}
                    markets={markets}
                    selectedMarket={selectedMarket}
                    onChangeSelectedMarket={onChangeSelectedMarket}
                    onHandleKeyPress={onEnterKeyPressInput}
                    onChangeFilterValue={onChangeFilterValue}
                    onChangeFilterSelected={onChangeFilterSelected}
                    showOrders={showCashoutOrders}
                    setShowOrders={setShowCashoutOrders}
                />
                <div className='flex py-2 pl-3 rounded-xl bg-[#F7A21E1A] items-center'>
                    <div className={'col-span-7 mr-4 w-[50%]'}>
                        <Input
                            placeholder={'Filter by user email, order number, tracking number or return tracking number'}
                            dataQa={'filter-table'}
                            containerStyle='mb-0 pb-0 relative'
                            inputStyle={'mb-0 border-0 focus:outline-none'}
                            icon={'/assets/shared/search.svg'}
                            showValue={true}
                            inputValue={searchValue}
                            inputUniqueName={'search'}
                            onChangeInput={onChangeSearchValue}
                            hideInputSpace={true}
                        />
                    </div>
                <div className='flex flex-row md:justify-end'>
                    <Button
                        label={'Clear All'}
                        dataQa={'clear-filters'}
                        onClickButton={onClickClearAllFilters}
                        className={'btn-primary-text-underline min-w-[150px] text-xs'}
                    />
                </div>
                    {showCashoutOrders && <Button onClickButton={() => onGettingCashoutsOrdersCSV()} label='Export CSV' className='h-[45px] w-[140px] absolute right-10 rounded-[5px] bg-gradient-to-br from-[#FCEE21] to-[#F7931E]'/>}
                    <CSVLink
                        ref={csvLink}
                        data={(ordersExport && ordersExport?.length > 0) ? ordersExport?.filter((item: any, idx: number) => idx > 0) : []}
                        headers={ordersExport?.[0] || []}
                        className={'hidden'}
                        asyncOnClick={true}
                        target={'_blank'}
                        filename='Orders-data.csv'
                    >
                    </CSVLink>
                </div>
            </div>
            <div className='mb-5 relativ'>
                <>
                    {rowsLoading || rows?.length > 0 || orderRows?.length > 0 ? (
                        <CashoutsTable
                            rows={rows}
                            actions={pageAccess?.actions}
                            accessControl={accessControl}
                            cashoutsLoading={rowsLoading}
                            paginationData={paginationData}
                            setPaginationState={setPaginationState}
                            onSeeDetails={onSeeDetails}
                            onCashoutType={onCashoutType}
                            orderRows={orderRows}
                            showOrders={showCashoutOrders}
                            onTakeAction={onTakeAction}
                            onAssignCsUser={onAssignCsUser}
                            ordersPaginationData={ordersPaginationData}
                        />
                    ) : (
                        <EmptyContainer
                            text={'No cashouts found.'}
                            showImage={true}
                        />
                    )}
                </>
            </div>
           {showCashoutTypeModal?.show && <CashoutTypeModal onSeeDetails={onSeeDetails} id={showCashoutTypeModal.id} itemId={showCashoutTypeModal.itemId} openModal={showCashoutTypeModal?.show} handleCloseModal={() => setShowCashoutTypeModal(undefined)}/>}
           {showTakeActionModal?.show && <TakeActionModal id={showTakeActionModal?.id} isMultipleCashout={showTakeActionModal?.isMultipleCashout} fullName={showTakeActionModal?.fullName} openModal={showTakeActionModal?.show} 
           handleCloseModal={() => setShowTakeActionModal(undefined)} onGettingCashoutOrdersData={onGettingCashoutOrdersData}
           />}
           {showAssignCsUserOutboundModal?.show && <AssignCSUserOutboundModal users={csUsers} id={showAssignCsUserOutboundModal.id} openModal={showAssignCsUserOutboundModal?.show} handleCloseModal={() => setShowAssignCsUserOutboundModal(undefined)} onGettingCashoutOrdersData={onGettingCashoutOrdersData}/>}
        </div>
    );
};

export default CashoutsTab;
