import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { classNames } from '../../utils/classNames';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { tryToFetchNavigation } from '../../store/customer-support/partners/partnersSlice';

const navigationCustomerSupport = [
    // { name: 'Home', href: '/home', icon: <img alt='insights' src='/assets/navigation/insights.svg' />, enabled: true },
    { name: 'Tickets', dataQa: 'tickets', href: '/tickets', icon: <img alt='tickets' src='/assets/navigation-icons/tickets.svg' />, iconActive: <img alt='tickets-active' src='/assets/navigation-icons/tickets-black.svg' />, enabled: true },
    { name: 'Wing Users', dataQa: 'wing-users', href: '/wing-users', icon: <img alt='wing-users' src='/assets/navigation-icons/wing-users.svg' />, iconActive: <img alt='wing-users-active' src='/assets/navigation-icons/wing-users-black.svg' />, enabled: true },
    { name: 'Flagged Users', dataQa: 'flagged-users', href: '/flagged-users', icon: <img alt='flagged-users' src='/assets/navigation-icons/flagged-users.svg' />, iconActive: <img alt='flagged-users-active' src='/assets/navigation-icons/flagged-users-black.svg' />, enabled: true },
    { name: 'Orders', dataQa: 'orders', href: '/cs-orders', icon: <img alt='orders' src='/assets/navigation-icons/orders.svg' />, iconActive: <img alt='orders-active' src='/assets/navigation-icons/orders-black.svg' />, enabled: true },
    // { name: 'Auto accepted Revaluations', dataQa: 'auto-accepted-revaluations', href: '/auto-accepted-revaluations', icon: <img alt='auto-accepted-revaluations' src='/assets/navigation-icons/auto-accepted-revaluations.svg' />, iconActive: <img alt='auto-accepted-revaluations-active' src='/assets/navigation-icons/auto-accepted-revaluations-black.svg' />, enabled: true },
    // { name: 'Rejected Revaluations', dataQa: 'rejected-revaluations', href: '/rejected-revaluations', icon: <img alt='rejected-revaluations' src='/assets/navigation-icons/rejected-revaluations.svg' />, iconActive: <img alt='rejected-revaluations-active' src='/assets/navigation-icons/rejected-revaluations-black.svg' />, enabled: true },
    { name: 'Open Cases', dataQa: 'open-cases', href: '/open-cases', icon: <img alt='open-cases' src='/assets/navigation-icons/open-cases.svg' />, iconActive: <img alt='open-cases-active' src='/assets/navigation-icons/open-cases-black.svg' />, enabled: true },

    { name: 'Blacklisted Items', dataQa: 'blacklisted-items', href: '/blacklisted-items', icon: <img alt='blacklisted-items' src='/assets/navigation-icons/blacklisted-items.svg' />, iconActive: <img alt='blacklisted-items-active' src='/assets/navigation-icons/blacklisted-items-black.svg' />, enabled: true },
    // { name: 'Pending Items', dataQa: 'pending-items', href: '/pending-items', icon: <img alt='pending-items' src='/assets/navigation-icons/pending-items.svg' />, iconActive: <img alt='pending-items-active' src='/assets/navigation-icons/pending-items-black.svg' />, enabled: true },
    // { name: 'Failed Transactions', dataQa: 'failed-transactions', href: '/failed-transactions', icon: <img alt='failed-transactions' src='/assets/navigation-icons/failed-transactions.svg' />, iconActive: <img alt='failed-transactions-active' src='/assets/navigation-icons/failed-transactions-black.svg' />, enabled: true },
    // { name: 'Stripe Dispute', dataQa: 'stripe-dispute', href: '/stripe-dispute', icon: <img alt='stripe-dispute' src='/assets/navigation-icons/stripe-dispute.svg' />, iconActive: <img alt='stripe-dispute-active' src='/assets/navigation-icons/stripe-dispute-black.svg' />, enabled: true },
    // { name: 'Referrals', dataQa: 'referrals', href: '/referrals', icon: <img alt='referrals' src='/assets/navigation-icons/referrals.svg' />, iconActive: <img alt='referrals-active' src='/assets/navigation-icons/referrals-black.svg' />, enabled: true },
]

const extraNavigation = [
    { name: 'Profile', dataQa: 'profile', href: '/profile', icon: <img alt='profile' src='/assets/navigation-icons/profile.svg' />, iconActive: <img alt='profile-active' src='/assets/navigation-icons/profile-black.svg' />, enabled: true },
]

const Navigation = () => {
    const location = useLocation();
    const dispatch = useAppDispatch()
    const [navigationData, setNavigationData] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false)
    const [activeRoute, setActiveRoute] = useState<string>()
    const navigationDetails = useAppSelector((state) => state.partners?.navigation);
    const cases = useAppSelector(state => state?.partners?.cases)

    useEffect(() => {
        const formatNav = (navigationDetails && navigationDetails?.length > 0) ? navigationDetails?.filter((item: any) => !item?.extras)?.map((item: any) => ({ name: item?.name, href: item?.path, enabled: true, icon: <img alt={item?.name} src={item?.icon} /> }))
            : navigationCustomerSupport
        setNavigationData(formatNav || undefined)
        setLoading(false)
    }, [navigationDetails])

    const getNavData = async () => {
        await dispatch(tryToFetchNavigation()).unwrap()
    }


    useEffect(() => {
        setLoading(true)
        getNavData()
    }, [])

    useEffect(() => {
        setActiveRoute(location.pathname)
    }, [location])

    return (
        <>
            {(!loading && navigationData && navigationData?.length > 0) && navigationData?.map((item: any) => (
                <NavLink
                    key={item.name}
                    to={item.href}
                    data-qa={item?.dataQa}
                    className={({ isActive }) => classNames('text-base text-secondary group flex items-center px-2 py-2 font-medium rounded-md mb-2',
                        (item.enabled && isActive) ? 'font-dm_sansbold !text-[#202020] bg-gradient-to-br from-[#FCEE21] to-[#F7931E] !rounded-lg' : 'font-dm_sansregular'
                    )}
                >
                    {/* <item.icon className='mr-3 flex-shrink-0 h-6 w-6 text-indigo-300' aria-hidden='true' /> */}
                    <span className='flex mr-2 flex-shrink-0 h-6 w-6 justify-center items-center'>
                        {activeRoute === item?.href ?
                            React.cloneElement(item.iconActive || item.icon, {
                                width: 20,
                                height: 20,
                            })
                            : React.cloneElement(item.icon, {
                                width: 20,
                                height: 20,
                            })
                        }
                    </span>
                    {item.name}
                    {item.name === 'Open Cases' && activeRoute?.includes(item.href) && <div className='bg-[#E50000] absolute right-6 w-7 h-7 text-center rounded-full border-white border-2 text-xs font-primary font-normal text-white flex justify-center items-center'>
                        <span>{cases.total}</span>
                    </div>}
                </NavLink>
            ))}
            <div className='mt-14'>
                {!loading && extraNavigation?.map((item) => (
                    <NavLink
                        key={item.name}
                        to={item.href}
                        className={({ isActive }) => classNames('text-base text-secondary group flex items-center px-2 py-2 font-medium rounded-md mb-2',
                            (item.enabled && isActive) ? 'font-dm_sansbold !text-[#202020] bg-gradient-to-br from-[#FCEE21] to-[#F7931E] !rounded-lg' : 'font-dm_sansregular'
                        )}
                    >
                        {/* <item.icon className='mr-3 flex-shrink-0 h-6 w-6 text-indigo-300' aria-hidden='true' /> */}
                        <span className='flex mr-2 flex-shrink-0 h-6 w-6 justify-center items-center'>
                            {activeRoute === item?.href ?
                                React.cloneElement(item.iconActive || item.icon, {
                                    width: 20,
                                    height: 20,
                                })
                                : React.cloneElement(item.icon, {
                                    width: 20,
                                    height: 20,
                                })
                            }
                        </span>
                        {item.name}
                    </NavLink>
                ))}
            </div>
        </>

    )
}

export default Navigation;