import React from 'react';
import { useAppSelector } from '../../../../app/hooks';
import SellerBasicDetails from '../details/SellerBasicDetails';
import SellerBankDetails from '../details/SellerBankDetails';
import SellerCashoutDetails from '../details/SellerCashoutDetails';


const DetailsTab = () => {
    const sellerDetails = useAppSelector((state) => state?.sellers?.sellerDetails)
    return (
        <div className='grid grid-cols-1 lg:grid-cols-11 gap-2 lg:gap-4 h-auto'>
            <div className='col-span-1 lg:col-span-8'>
                <SellerBasicDetails
                    sellerDetails={sellerDetails} />
                {sellerDetails?.bankDetails &&
                    <SellerBankDetails
                        sellerDetails={sellerDetails}
                    />
                }
            </div>
            <div className='col-span-1 lg:col-span-3'>
                <SellerCashoutDetails
                    sellerDetails={sellerDetails} />
            </div>
        </div>
    );
};

export default DetailsTab;
