import React from 'react';


const SellerHeaderContainer = () => {
    return (
        <div>
            <div className='flex flex-row justify-start items-center mb-10'>
                <div className='max-w-sm animate-pulse mr-3'>
                    <div className='h-[50px] bg-gray-200 rounded-full dark:bg-gray-200 w-[50px] mb-1'></div>
                </div>
                <div className='max-w-sm animate-pulse'>
                    <div className='h-4 bg-gray-200 rounded-full dark:bg-gray-200 w-[300px] mb-1'></div>
                    <div className='flex flex-row items-center mb-1'>
                        <p>User ID:</p>
                        <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[200px] ml-2'></div>
                    </div>
                    <div className='flex flex-row items-center'>
                        <p>Username:</p>
                        <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[200px] ml-2'></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SellerHeaderContainer;