import React, { useState } from 'react';
import ImagesCarousel from '../../../../shared/images-carousel';


interface IDetailsPictures {
    images?: Array<{ url: string }>;
}

const DetailsPictures = ({
    images,
}: IDetailsPictures) => {
    const [showImageCarousel, setShowImageCarouse] = useState<{ status: boolean, id?: number }>()

    const onToggleImageCarousel = (id?: number) => {
        setShowImageCarouse({ status: !showImageCarousel?.status, id: id })
    }

    const showImages = (images && images?.length > 0) && images?.find((item) => item?.url !== '' && item?.url)
    return (
        <div>
            {(showImages) && <p className='font-bold text-md my-3'>Pictures</p>}
            <div className='grid grid-cols-10 gap-4 items-center'>
                {(images && images?.length > 0) && images?.map((picture, index) => {
                    return (
                        <div
                            key={index}
                            className={'cursor-pointer'}
                            data-qa={'pictures'}
                            onClick={() => onToggleImageCarousel(index)}>
                            <img
                                src={picture?.url}
                                className={'object-contain w-[100%]'}
                            />
                        </div>
                    )
                })}
            </div>
            {images && images?.length > 0 && showImageCarousel?.status &&
                <ImagesCarousel
                    images={images}
                    showModal={showImageCarousel?.status || false}
                    handleClose={onToggleImageCarousel}
                    active={showImageCarousel?.id}
                />
            }
        </div>
    )
}

export default DetailsPictures;