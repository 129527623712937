import React from 'react';
import Navigation from './Navigation';

const Sidebar = () => {
    return (
        <div className='hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 pt-24'>
                <div className='flex flex-col flex-grow pt-5 bg-white overflow-y-auto'>
                    <div className='flex items-center flex-shrink-0 px-4'>
                        {/* <form className='w-full flex md:ml-0' action='#' method='GET'>
                            <label htmlFor='search-field' className='sr-only'>
                                Search
                            </label>
                            <div className='relative w-full text-gray-400 focus-within:text-gray-600'>
                                <div className='absolute inset-y-0 left-2 flex items-center pointer-events-none'>
                                    <img alt='search' className='h-4 w-4' src='/svg/layout/search-icon.svg' />
                                </div>
                                <input
                                    id='search-field'
                                    className='bg-bgColor-search rounded block h-full pl-8 pr-3 py-2 border-transparent placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm'
                                    placeholder='Search'
                                    type='search'
                                    name='search'
                                />
                            </div>
                        </form> */}
                    </div>
                    <div className='mt-8 flex-1 flex flex-col'>
                        <nav className='flex-1 px-4 pb-4'>
                            <Navigation />
                        </nav>
                    </div>
                </div>
            </div>
    )
}

export default Sidebar;