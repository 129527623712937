import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { authApi } from '../store/auth/authApi';
import { userAPI } from '../store/user/userApi';
import { userDomAPI } from '../store/user/userDomApi';
import DisputesHistorySlice from '../store/customer-support/stripe-dispute-history/stripeDisputeHistorySlice';
import assetsTemplatesSlice from '../store/customer-support/asset-templates/assetsTemplatesSlice';
import stripeDisputeSlice from '../store/customer-support/stripe-dispute/stripeDisputeSlice';
import customerCareSlice from '../store/customer-support/customer-care/customerCareSlice';
import pendingItemsSlice from '../store/customer-support/pending-items/pendingItemsSlice';
import transactionsSlice from '../store/customer-support/transactions/transactionsSlice';
import FlaggedUserSlice from '../store/customer-support/flagged-user/FlaggedUserSlice';
import ReferralsSlice from '../store/customer-support/referrals/referralsUsersSlice';
import shipmentsSlice from '../store/customer-support/shipments/shipmentsSlice';
import partnersSlice from '../store/customer-support/partners/partnersSlice';
import sellersSlice from '../store/customer-support/sellers/sellersSlice';
import ordersSlice from '../store/customer-support/orders/ordersSlice';

export const store = configureStore({
    reducer: {
        [userAPI.reducerPath]: userAPI.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [userDomAPI.reducerPath]: userDomAPI.reducer,
        cs: customerCareSlice,
        orders: ordersSlice,
        partners: partnersSlice,
        assetsTemplates: assetsTemplatesSlice,
        shipments: shipmentsSlice,
        transactions: transactionsSlice,
        flaggedUsers: FlaggedUserSlice,
        sellers: sellersSlice,
        stripeDispute: stripeDisputeSlice,
        disputeHistory: DisputesHistorySlice,
        referrals: ReferralsSlice,
        pendingItems: pendingItemsSlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            userAPI.middleware,
            authApi.middleware,
            userDomAPI.middleware
        ),
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;