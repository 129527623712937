import React from 'react';
import WhiteContainer from '../../../../shared/white-container';


const OrderInfoContainer = () => {
    return (
        <WhiteContainer>
            <div>
                <div className='grid grid-cols-2 gap-3 mt-2 mb-10'>
                    <p className='font-bold text-md'>Property</p>
                    <p className='font-bold text-md'>Value</p>
                </div>
                {[...Array(5)].map((x, i) => {
                    return (
                        <>
                            <div className='grid grid-cols-2 gap-3 my-2 max-w-sm animate-pulse'>
                                <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[80%] mb-1'></div>
                                <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-200 w-[80%] mb-1'></div>
                            </div>
                            {i !== 4 &&
                                <div className='mb-5 mt-3 min-w-full !h-[1px] !bg-borderColor border-b-[2px] border-borderColor'></div>
                            }
                        </>
                    )
                })
                }
            </div>
        </WhiteContainer>
    )
}

export default OrderInfoContainer;