import React, { useState } from 'react';
import Modal from '../../../../shared/modal';
import { ISingleOrderSchema } from '../../../../interfaces/orders/IOrdersSchema';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';

interface IAcceptOfferWithGestureModal {
    openModal: boolean;
    orderDetails?: ISingleOrderSchema;
    canAcceptWithCustomGesture: boolean;
    itemId?: string;
    orderId?: string;
    onAcceptOfferWithGesture: (amount: number) => Promise<void>;
    handleCloseModal: () => void;
    maxGesture: number | undefined
}

const AcceptOfferWithGestureModal = ({
    openModal,
    orderDetails,
    canAcceptWithCustomGesture,
    itemId,
    orderId,
    handleCloseModal,
    onAcceptOfferWithGesture,
    maxGesture
}: IAcceptOfferWithGestureModal) => {
    const [selectedAmount, setSelectedAmount] = useState<string | null>(null);
    const [customAmount, setCustomAmount] = useState<string>('');
    const [error, setError] = useState<string>('');
    const currency = CURRENCY_SYMBOLS?.[orderDetails?.orderData?.localizedSalePrice?.currencyCode];

    const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== 'custom') {
            setSelectedAmount(e.target.value);
            setCustomAmount('');
        } else {
            setSelectedAmount('custom');
        }
    };

    const handleCustomAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setError('');
        e.stopPropagation();
        setCustomAmount(e.target.value);
        if (Number(e.target.value) <= 0)
            setError('The minimum gesture amount that can be offered for this item must be a positive number. Please enter another amount.');
        else if (maxGesture && Number(e.target.value) > maxGesture)
            setError(`The maximum gesture amount you can offer for this item is ${currency}${maxGesture}. Please enter an amount within this limit.`);
    };

    const handleSealTheDeal = () => {
        const amount = selectedAmount === 'custom' ? customAmount : selectedAmount;
        if (amount) {
            onAcceptOfferWithGesture(Number(amount));
            handleCloseModal();
        }
    };

    const isAmountValid = () => {
        if (selectedAmount === 'custom') {
            const customAmountValue = Number(customAmount);
            return (
                maxGesture !== undefined &&
                customAmountValue > 0 &&
                customAmountValue <= maxGesture
            );
        } else {
            const selectedAmountValue = Number(selectedAmount);
            return (
                maxGesture !== undefined &&
                selectedAmountValue > 0 &&
                selectedAmountValue <= maxGesture
            );
        }
    };

    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            showInRight={true}
            title='Accept Offer with Gesture'>
            <p>Are you sure you want to{' '}<span className='font-dm_sansbold'>Accept</span> the revaluation offer on behalf of the user?</p>
            <p className='text-sm text-[#202020]'>This Action cannot be undone!</p>
            <div onChange={handleOptionChange} className='flex flex-col p-4 gap-3 bg-[#F8F9FC]'>
                <label className='flex bg-white max-w-[70%] justify-between p-2'>
                    <span>{CURRENCY_SYMBOLS?.[orderDetails?.orderData?.localizedSalePrice?.currencyCode || '']}5</span>{' '}
                    <input
                        type='radio'
                        className='w-6 h-6'
                        name='amount'
                        value='5'
                    />
                </label>
                <label className='flex bg-white max-w-[70%] justify-between p-2'>
                    <span>{CURRENCY_SYMBOLS?.[orderDetails?.orderData?.localizedSalePrice?.currencyCode || '']}10</span>{' '}
                    <input
                        type='radio'
                        className='w-6 h-6'
                        name='amount'
                        value='10'
                    />
                </label>
                {canAcceptWithCustomGesture &&
                    <div className='flex flex-col'>
                        <label className='flex bg-white max-w-[70%] justify-between items-center gap-3 p-2'>
                            {selectedAmount === 'custom' ?
                                <input
                                    type='number'
                                    value={customAmount}
                                    onChange={handleCustomAmountChange}
                                    className='border border-borderColor rounded w-[90%] px-3 py-2'
                                    placeholder={`Enter custom amount (max: ${currency}${maxGesture})`}
                                />
                                : <span>Custom</span>}
                            <input
                                type='radio'
                                className='w-6 h-6'
                                name='amount'
                                value='custom'
                                onChange={() => setSelectedAmount('custom')}
                            />
                        </label>
                        {error && <span className='text-red-500 mt-2 text-sm max-w-xs break-words'>{error}</span>}
                    </div>
                }
            </div>
            <div className='flex justify-center min-w-full'>
                <button
                    onClick={handleSealTheDeal}
                    disabled={!selectedAmount || (selectedAmount === 'custom' && !customAmount) || !isAmountValid()}
                    className={`mt-4 ${!selectedAmount || (selectedAmount === 'custom' && !customAmount) || !isAmountValid() ? 'btn-primary-rounded-less-disable' : 'btn-primary-rounded-less'} text-white py-2 min-w-[300px] rounded`}>
                    Seal the Deal
                </button>
            </div>
        </Modal>
    );
};

export default AcceptOfferWithGestureModal;
